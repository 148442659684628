<template>
  <div>
    <div class="mb-6">
      <PageHeading>
        <div class="flex items-center">
          <h1 class="ph-no-capture mr-2.5">
            {{ user.firstName }} {{ user.lastName }}
          </h1>
          <UserStatusBadge :status="user.status" />
        </div>
        <template #actions>
          <div class="flex items-center space-x-4">
            <BaseButton
              v-if="user.pendingAcceptInvitation"
              :is-primary="false"
              icon="MailIcon"
              as="a"
              :href="resendInvitationUrl"
              data-turbo-method="post"

              :is-disabled="!allowedToEdit"
            >
              {{ $t('components.edit_user_form.resend_invitation') }}
            </BaseButton>
            <BaseButton
              v-if="user.status === 'deactivated'"
              :is-primary="false"
              icon="LockOpenIcon"
              as="a"
              :href="deactivationUrl"
              data-turbo-method="delete"

              :is-disabled="!allowedToEdit"
            >
              {{ $t("defaults.activate") }}
            </BaseButton>
            <BaseButton
              v-else
              :is-primary="false"
              icon="LockClosedIcon"
              as="a"
              :href="deactivationUrl"
              data-turbo-method="post"

              :is-disabled="!allowedToEdit"
            >
              {{ $t("defaults.deactivate") }}
            </BaseButton>
            <BaseButton
              :is-primary="false"
              icon="TrashIcon"
              as="a"
              :href="actionUrl"
              data-turbo-method="delete"
              :is-disabled="!allowedToEdit"
              @click="deleteUser"
            >
              {{ $t("defaults.delete") }}
            </BaseButton>
            <SaveButton
              :is-loading="submitting"
              :is-disabled="!allowedToEdit"
              form="edit-user-form"
            />
          </div>
        </template>
      </PageHeading>
    </div>

    <div
      v-if="warningEmail"
      class="w-full mb-6"
    >
      <alert type="warning">
        <template #content>
          {{ $t("components.edit_user_form.currently_waiting_confirmation_for_email",
                { email: warningEmail })
          }}
          <template v-if="!user.pendingAcceptInvitation">
            {{ $t("components.edit_user_form.waiting_for_confirmation_notice") }}
          </template>
        </template>
      </alert>
    </div>

    <div
      v-if="editingCurrentUser"
      class="w-full mb-6"
    >
      <alert type="information">
        <template #content>
          {{ $t("components.edit_user_form.editing_current_user_warning") }}
        </template>
      </alert>
    </div>

    <form
      id="edit-user-form"
      ref="form"
      :action="actionUrl"
      accept-charset="UTF-8"

      method="post"
      enctype="multipart/form-data"
      @submit="submitting = true"
    >
      <input
        type="hidden"
        name="_method"
        value="patch"
      >
      <input
        type="hidden"
        name="authenticity_token"
        :value="$csrfToken"
      >
      <div class="space-y-5 mb-5">
        <TwoColumnCard
          :header="$t('components.edit_user_form.categories.profile.header')"
        >
          <div class="space-y-5">
            <div class="flex space-x-6">
              <div class="flex-1">
                <BaseInput
                  v-model="user.firstName"
                  name="user[first_name]"
                  :label="$t('activerecord.attributes.user.first_name')"
                  type="text"
                  :disabled="!allowedToEdit"
                  :error="getError('first_name')"
                />
              </div>
              <div class="flex-1">
                <BaseInput
                  v-model="user.lastName"
                  :label="$t('activerecord.attributes.user.last_name')"
                  type="text"
                  name="user[last_name]"
                  :error="getError('last_name')"
                  :disabled="!allowedToEdit"
                />
              </div>
            </div>
            <BaseInput
              v-model="user.email"
              :label="$t('activerecord.attributes.user.email')"
              type="text"
              name="user[email]"
              :error="getError('email')"
              :disabled="!allowedToEdit"
            />
          </div>
        </TwoColumnCard>

        <TwoColumnCard
          :header="$t('components.edit_user_form.categories.preferences.header')"
          :helptext="$t('components.edit_user_form.categories.preferences.helptext')"
        >
          <div class="space-y-5">
            <SelectList
              :label="$t('activerecord.attributes.user.locale')"
              :selected="initialLocale"
              name="user[locale]"
              :options="localeOptions"
              :error="getError('locale')"
              :disabled="!allowedToEdit"
            />
            <SearchableSelectList
              id="select-timezone"
              :selected="initialTimezone"
              :label="$t('activerecord.attributes.user.time_zone')"
              :options="timezoneOptions"
              secondary-label-prop="secondary"
              name="user[time_zone]"
              :disabled="!allowedToEdit"
              :allow-reset="false"
            />
          </div>
        </TwoColumnCard>

        <TwoColumnCard
          :header="$t('components.edit_user_form.categories.roles.header')"
          :helptext="$t('components.edit_user_form.categories.roles.helptext')"
          :info-link="$t('components.edit_user_form.categories.roles.info_link')"
        >
          <div class="space-y-5">
            <BaseCheckbox
              v-if="isSuperAdmin"
              value="true"
              unchecked-value="false"
              :model-value="superAdminRole"
              :label="$t(`components.shared.roles.super_administrator.name`)"
              :secondary-label="$t(`components.shared.roles.super_administrator.helptext`)"
              name="user[super_admin]"
              :disabled="!allowedToEdit"
            />
            <div
              v-for="role in roleOptions"
              :key="role.name"
            >
              <BaseCheckbox
                :value="role.name"
                :unchecked-value="''"
                :model-value="userRoles[role.name]"
                :label="$t(`components.shared.roles.${role.name}.name`)"
                :secondary-label="$t(`components.shared.roles.${role.name}.helptext`)"
                name="user[role_names][]"
                :disabled="!allowedToEdit || !role.editable"
                :qa-class="`user-role-${role.name}`"
                @update:model-value="userRoles[role.name] = $event"
              />
            </div>
          </div>
        </TwoColumnCard>

        <OrganisationAccessCard
          v-if="isWorkspaceOwner"
          :organisation-membership-ids="userData.organisationIds"
          :organisations="organisations"
          :has-admin-role-selected="hasAdminRoleSelected"
        />

        <TwoColumnCard
          v-if="!editingCurrentUser && passwordAuthEnabled"
          :header="$t('components.edit_user_form.categories.security.header')"
          :helptext="$t('components.edit_user_form.categories.security.helptext')"
        >
          <div class="space-y-5">
            <BaseInput
              v-model="password"
              :label="$t('activerecord.attributes.user.password')"
              type="password"
              autocomplete="off"
              :error="getError('password')"
              name="user[password]"
              :disabled="!allowedToEdit"
            />
            <password-requirements v-model="password" />
            <BaseInput
              :label="$t('activerecord.attributes.user.password_confirmation')"
              type="password"
              autocomplete="off"
              :error="getError('password_confirmation')"
              name="user[password_confirmation]"
              :disabled="!allowedToEdit"
            />
          </div>
        </TwoColumnCard>
      </div>
    </form>
  </div>
</template>

<script>
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/generic/BaseCheckbox/BaseCheckbox.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import SaveButton from '@/components/generic/SaveButton/SaveButton.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import useLocaleOptions from '@/composables/localeOptions';
import { useTimezoneOptions } from '@/composables/timezoneOptions';
import { useConfirmDialogModal } from '@/composables/useConfirmDialogModal';
import { resendUserInvitationPath, userDeactivationPath, userPath } from '@/util/url-helpers';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import PasswordRequirements from '@/components/generic/PasswordRequirements/PasswordRequirements.vue';
import UserStatusBadge from '../UserStatusBadge/UserStatusBadge.component.vue';
import OrganisationAccessCard from '../OrganisationAccessCard/OrganisationAccessCard.vue';

export default {
  name: 'EditUserForm',
  components: {
    PageHeading,
    TwoColumnCard,
    BaseInput,
    SelectList,
    SearchableSelectList,
    BaseCheckbox,
    SaveButton,
    BaseButton,
    UserStatusBadge,
    OrganisationAccessCard,
    PasswordRequirements,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
    isSuperAdmin: {
      type: Boolean,
      required: true,
    },
    isWorkspaceOwner: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    availableTimeZones: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    availableRoles: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    editingCurrentUser: {
      type: Boolean,
      required: true,
    },
    allowedToEdit: {
      type: Boolean,
      required: true,
    },
    passwordAuthEnabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { roles, availableRoles } = toRefs(props);
    const roleOptions = computed(() => roles.value.map((role) => ({
      name: role.name,
      label: role.label,
      editable: availableRoles.value.some((availableRole) => availableRole.name === role.name),
    })));

    const user = { ...props.userData };

    const userRoles = ref({});
    roleOptions.value.forEach((role) => {
      userRoles.value[role.name] = props.userData.roles.includes(role.name);
    });

    const hasAdminRoleSelected = computed(() => userRoles.value.admin === true);

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];
    const { availableLocales, t } = useI18n();

    const actionUrl = userPath(user);
    const deactivationUrl = userDeactivationPath(user);
    const resendInvitationUrl = resendUserInvitationPath(user);

    const { timezoneOptions, initialTimezone } = useTimezoneOptions(props.availableTimeZones, user.timeZone);
    const { localeOptions, initialLocale } = useLocaleOptions(availableLocales, user.locale);

    const submitting = ref(false);

    const warningEmail = ref(null);
    if (user.pendingAcceptInvitation) {
      warningEmail.value = user.email;
    } else if (user.unconfirmedEmail) {
      warningEmail.value = user.unconfirmedEmail;
    }

    const { openConfirmModal } = useConfirmDialogModal();

    const deleteUser = (event) => {
      event.preventDefault();
      openConfirmModal({
        open: true,
        message: t('components.edit_user_form.do_you_want_to_delete'),
        challenge: user.name,
        sourceElement: event.target.cloneNode(),
      });
    };
    const password = ref('');

    return {
      submitting,
      user,
      deactivationUrl,
      resendInvitationUrl,
      timezoneOptions,
      initialTimezone,
      localeOptions,
      initialLocale,
      getError,
      actionUrl,
      warningEmail,
      deleteUser,
      hasAdminRoleSelected,
      roleOptions,
      userRoles,
      superAdminRole: ref(user.roles.includes('super_admin')),
      password,
    };
  },
};
</script>
