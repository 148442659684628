<template>
  <td
    :class="[
      'sticky right-[-1px] h-full w-14 p-0 bg-white group-hover:bg-gray-50',
      !!container ? 'z-20 ' : ''
    ]"
  >
    <div class="flex justify-center border-l border-gray-100">
      <div class="px-1 py-3 max-h-max relative justify-end ">
        <Dropdown
          :with-popper-overlay="true"
          item-classes="right-4"
        >
          <template #button>
            <MenuButton
              ref="trigger"
              class="rounded-full text-gray-400 focus:outline-none ring-primary-500 ring-offset-2
          transition-colors duration-200 hover:text-gray-500 focus:ring-2 hover:bg-gray-200 p-1.5 qa-base-table-actions"
              @click.stop
            >
              <DotsVerticalIcon
                class="h-6 w-6"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $t('defaults.manage') }}</span>
            </MenuButton>
          </template>
          <div
            ref="container"
            class="z-50 origin-top-right absolute right-0 mt-2 w-56 min-w-fit rounded-md shadow-lg
          bg-white ring-1 ring-black/5 focus:outline-none text-sm font-normal py-1"
          >
            <slot name="menu-items" />
          </div>
        </Dropdown>
      </div>
    </div>
  </td>
</template>

<script>
import Dropdown from '@/components/generic/Dropdown/Dropdown.vue';
import usePopper from '@/util/use-popper';
import { MenuButton } from '@headlessui/vue';
import { DotsVerticalIcon } from '@heroicons/vue/outline';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseTableActions',
  components: {
    DotsVerticalIcon,
    Dropdown,
    MenuButton,
  },
  props: {
  },
  setup() {
    const [trigger, container] = usePopper({
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [
        { name: 'preventOverflow', options: { mainAxis: false } },
        { name: 'offset', options: { offset: [-10, 5] } },
      ],
    });

    const isActive = computed(() => !!container);

    return {
      trigger,
      container,
      isActive,
    };
  },
});
</script>
