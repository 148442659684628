<template>
  <Card>
    <div class="px-4 py-2 overflow-hidden sm:p-6 flex flex-row items-center justify-between">
      <div>
        <dt class="text-sm font-medium text-gray-500 truncate">
          {{ name }}
        </dt>
        <dd class="mt-1 text-3xl font-semibold text-gray-900">
          {{ stat }}
        </dd>
      </div>
      <heroicon
        :icon="iconName"
        class="h-10 text-gray-300 mr-4 block sm:hidden lg:block"
      />
    </div>
  </Card>
</template>

<script>
import * as Icons from '@heroicons/vue/solid';
import Heroicon from '@/components/Heroicon.vue';

export default {
  name: 'StatsCard',
  components: {
    Heroicon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    stat: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return Object.keys(Icons).includes(value);
      },
    },
  },
};
</script>
