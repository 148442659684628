<template>
  <BaseTable
    :columns="columns"
    :records="templates"
    :pages="pages"
    :emit-row-click="true"
    @on-row-click="visitCompetenceModelTemplatePath"
  >
    <template #default="{ record: competenceModel }">
      <td
        v-dompurify-html:mark="highlightedText(competenceModel.name)"
        class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
      />
      <td
        v-if="!isWithInOrganisation"
        v-dompurify-html:mark="highlightedText(competenceModel.organisation)"
        class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm text-gray-500"
      />
      <TemplateActions
        :competence-model="competenceModel"
        :organisation-id="organisationId"
      />
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import { useI18n } from '@/plugins/i18n';
import { editCompetenceModelTemplatePath, editOrganisationCompetenceModelTemplatePath } from '@/util/url-helpers';
import { useSearchHighlighter } from '@/composables/useSearchHighlighter';
import TemplateActions from './TemplateActions/TemplateActions.vue';

export default {
  components: {
    BaseTable,
    TemplateActions,
  },
  props: {
    templates: {
      type: Array,
      required: true,
    },
    pages: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const { highlightedText } = useSearchHighlighter();

    const isWithInOrganisation = !!props.organisationId;

    const columns = [
      {
        key: 'name',
        name: t('activerecord.attributes.competence_model.name'),
        sortable: true,
      },
      ...!isWithInOrganisation ? [{
        key: 'organisation',
        name: t('activerecord.attributes.competence_model.organisation'),
        sortable: true,
      }] : [],
      {
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      },
    ];

    const visitCompetenceModelTemplatePath = (competenceModelTemplate) => {
      const editPath = props.organisationId
        ? editOrganisationCompetenceModelTemplatePath(props.organisationId, competenceModelTemplate)
        : editCompetenceModelTemplatePath(competenceModelTemplate);

      Turbo.visit(editPath);
    };

    return {
      columns,
      highlightedText,
      visitCompetenceModelTemplatePath,
      isWithInOrganisation,
    };
  },
};
</script>
