<template>
  <DialogModal
    :on-top="true"
    :open="showUnlockModal"
    @close="close"
  >
    <template #header>
      {{ $t('components.report_templates.webdav_session_unlock_modal.title') }}
    </template>
    <template #main>
      <div>
        <p>
          {{ message }}
        </p>
      </div>
    </template>
    <template #footer>
      <div class="bg-gray-50 w-full px-1 sm:flex sm:w-1 sm:flex-row-reverse">
        <BaseButton
          :is-loading="isLoading"
          class="mx-auto p-2 w-full justify-center rounded-md border border-transparent
             shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
          @click="forceEdit"
        >
          {{ $t('components.report_templates.webdav_session_unlock_modal.edit_continue') }}
        </BaseButton>
        <BaseButton
          :is-primary="false"
          class="mt-3 w-full justify-center rounded-md border border-gray-300
             shadow-sm p-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          @click="close"
        >
          {{ $t('defaults.cancel') }}
        </BaseButton>
      </div>
    </template>
  </DialogModal>
</template>

<script>
import {
  defineComponent, ref, watch,
} from 'vue';
import DialogModal from '@/components/generic/DialogModal/DialogModal.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import { useWebdavSessionStore } from '@/components/Templates/ReportTemplates/stores/webdavSessionStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    DialogModal,
    BaseButton,
  },
  setup() {
    const { t } = useI18n();
    const isLoading = ref(false);

    const store = useWebdavSessionStore();
    const {
      showUnlockModal,
      activeLock,
    } = storeToRefs(store);

    const close = () => {
      store.closeUnlockModal();
    };

    const forceEdit = () => {
      isLoading.value = true;
      store.continueCreateWebdavSession({ force: true });
      isLoading.value = false;
    };

    // Store message here to prevent from changing during the modal transition animation
    const message = ref('');
    watch(activeLock, () => {
      if (!activeLock.value) {
        return;
      }
      if (activeLock.value?.isCurrentUser) {
        message.value = t('components.report_templates.webdav_session_unlock_modal.message_current_user');
      } else {
        message.value = t(
          'components.report_templates.webdav_session_unlock_modal.message_other_user',
          { user: activeLock.value?.user },
        );
      }
    });

    return {
      isLoading,
      close,
      forceEdit,
      showUnlockModal,
      message,
    };
  },
});
</script>
@/components/Templates/ReportTemplates/stores/webdavSessionStore
