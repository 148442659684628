/* eslint-disable import/prefer-default-export */
import { computed } from 'vue';

export function useMultiSelectCompetenciesAndCluster({ competencyClusters, competencies, isSelectedCompetencyMap }) {
  const lengthAllSelectedCompetencies = computed(
    () => [...isSelectedCompetencyMap.value].filter(([_, value]) => value).length,
  );

  const getCluster = (clusterId) => competencyClusters.value.find((cluster) => cluster.id === clusterId);

  const lengthAllCompetencies = computed(() => competencies.value.length);
  const allCompetenciesSelected = computed(
    () => lengthAllSelectedCompetencies.value === lengthAllCompetencies.value,
  );

  const someCompetenciesSelected = computed(() => lengthAllSelectedCompetencies.value > 0
                                              && !allCompetenciesSelected.value);
  const allClusterCompetenciesSelected = (clusterId) => getCluster(clusterId).competencies.every(
    (competency) => isSelectedCompetencyMap.value.get(competency.id),
  );
  const someClusterCompetenciesSelected = (clusterId) => !allClusterCompetenciesSelected(clusterId)
  && getCluster(clusterId).competencies.some((competency) => isSelectedCompetencyMap.value.get(competency.id));

  const updateSelectAll = (isChecked) => {
    if (isChecked) {
      isSelectedCompetencyMap.value.forEach((_, competencyId, map) => {
        map.set(competencyId, true);
      });
    } else {
      isSelectedCompetencyMap.value.forEach((_, competencyId, map) => {
        map.set(competencyId, false);
      });
    }
  };

  const updateCompetency = (isChecked, competencyId) => {
    if (isChecked) {
      isSelectedCompetencyMap.value.set(competencyId, true);
    } else {
      isSelectedCompetencyMap.value.set(competencyId, false);
    }
  };

  const updateSelectCompleteCluster = (isChecked, clusterId) => {
    if (isChecked) {
      getCluster(clusterId).competencies.forEach(
        (competency) => isSelectedCompetencyMap.value.set(competency.id, true),
      );
    } else {
      getCluster(clusterId).competencies.forEach(
        (competency) => isSelectedCompetencyMap.value.set(competency.id, false),
      );
    }
  };

  return {
    lengthAllCompetencies,
    allCompetenciesSelected,
    someCompetenciesSelected,
    allClusterCompetenciesSelected,
    someClusterCompetenciesSelected,
    updateSelectAll,
    updateCompetency,
    updateSelectCompleteCluster,
  };
}
