<template>
  <Modal
    :show="open"
    :hide-overflow="false"
    @click-outside="close"
  >
    <div class="p-6 space-y-2 w-full max-w-[600px]">
      <h1 class="text-lg font-medium text-gray-900">
        {{ $t('components.organisations.merge_organisations_modal.title') }}
      </h1>

      <p class="text-sm font-normal text-gray-500">
        {{ $t('components.organisations.merge_organisations_modal.text', {name: organisation.name}) }}
      </p>

      <SearchableSelectList
        id="merge-with-organisation"
        :selected="currentMergeWithOrganisation"
        :options="mergeWithOrganisationOptions"
        :options-empty-message="$t('components.organisations.no_organisations')"
        value-prop="id"
        label-prop="displayName"
        :disabled="isLoading"
        :allow-reset="false"
        :label="$t('components.organisations.merge_organisations_modal.merge_with')"
        @update:selected="currentMergeWithOrganisation = $event"
      />

      <SelectList
        id="keep-settings-from-organisation"
        :selected="currentKeepSettingsFromOrganisation"
        :options="keepSettingsFromOrganisationsOptions"
        :options-empty-message="$t('components.organisations.no_organisations')"
        value-prop="id"
        label-prop="displayName"
        :disabled="isLoading"
        :allow-reset="false"
        :label="$t('components.organisations.merge_organisations_modal.keep_settings_from')"
        @update:selected="currentKeepSettingsFromOrganisation = $event"
      />
    </div>

    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4 rounded-b-lg">
      <BaseButton
        type="button"
        :is-primary="false"
        @click="close"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        type="button"
        :is-loading="isLoading"
        :is-disabled="mergeButtonDisabled"
        @click="mergeOrganisations"
      >
        <span>{{ $t('defaults.merge') }}</span>
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import {
  ref, toRefs, watch, computed, inject,
} from 'vue';
import Modal from '@/components/generic/Modal/Modal.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import Axios from 'axios';
import {
  mergeOrganisationPath,
} from '@/util/url-helpers';

export default {
  name: 'MergeOrganisationsModal',
  components: {
    Modal,
    SelectList,
    SearchableSelectList,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    organisation: {
      type: Object,
      required: false,
      default: null,
    },
    organisations: {
      type: Array,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { open, organisation } = toRefs(props);
    const errors = ref({});
    const isLoading = ref(false);

    const currentMergeWithOrganisation = ref(null);
    const currentKeepSettingsFromOrganisation = ref(null);

    const mergeWithOrganisationOptions = computed(() => props.organisations
      .filter((org) => org.id !== organisation.value.id));
    const keepSettingsFromOrganisationsOptions = computed(() => [organisation.value,
      currentMergeWithOrganisation.value]
      .filter((org) => org !== null));

    watch(() => open.value, (isOpen) => {
      if (isOpen) {
        currentKeepSettingsFromOrganisation.value = organisation.value;
        currentMergeWithOrganisation.value = null;
      }
    });

    watch(() => currentMergeWithOrganisation.value, (cmergeWithOrganisation) => {
      if (cmergeWithOrganisation.value !== null && currentKeepSettingsFromOrganisation.value !== organisation.value) {
        currentKeepSettingsFromOrganisation.value = null;
      }
    });

    const mergeButtonDisabled = computed(() => currentMergeWithOrganisation.value === null
    || currentKeepSettingsFromOrganisation.value === null);

    const close = () => {
      emit('close');
    };

    const toast = inject('toast');
    const mergeOrganisations = () => {
      let mergeWithOrganisationId = null;
      if (organisation.value === currentKeepSettingsFromOrganisation.value) {
        mergeWithOrganisationId = currentMergeWithOrganisation.value.id;
      } else {
        mergeWithOrganisationId = organisation.value.id;
      }

      isLoading.value = true;

      Axios.post(mergeOrganisationPath(currentKeepSettingsFromOrganisation.value), {
        merge_with_organisation_id: mergeWithOrganisationId,
      })
        .then(() => {
          isLoading.value = false;
          close();
          Turbo.visit('/organisations');
        })
        .catch((error) => {
          isLoading.value = false;
          toast.error(error.response.data.message);
        });
    };

    return {
      close,
      errors,
      isLoading,
      mergeButtonDisabled,
      mergeOrganisations,

      currentMergeWithOrganisation,
      currentKeepSettingsFromOrganisation,
      mergeWithOrganisationOptions,
      keepSettingsFromOrganisationsOptions,
    };
  },
};
</script>
