<template>
  <Disclosure
    as="nav"
    class="bg-white flex-wrap shadow z-40 border-t-4"
    :style="borderColor"
  >
    <div class="mx-auto px-4 2md:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="shrink-0 flex items-center">
          <SidebarToggle
            class="lg:hidden mr-2"
          />
          <img
            class="block h-8 w-auto"
            :src="logoUrl"
            alt="Applysia"
          >
          <span class="hidden 2md:block  ml-7 pt-px text-sm text-gray-500 font-medium">
            {{ $t('components.session.candidate_portal_header') }}
          </span>
        </div>
        <div class="flex items-center min-w-0">
          <p class="hidden sm:block ml-4 text-gray-700 font-medium text-sm leading-5 truncate">
            {{ candidate.name }}
          </p>
          <language-switch />
          <a
            :href="logoutPath"
            data-turbo-method="delete"
            rel="nofollow"
            class="flex items-center ml-2 p-2 hover:bg-gray-300 rounded-lg qa-logout"
          >
            <LogoutIcon
              class="h-6 w-6"
            />
            <span class="hidden sm:block ml-1 text-gray-700 text-sm font-medium">
              {{ $t('defaults.logout') }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </Disclosure>
</template>

<script>
import SidebarToggle from '@/components/Sidebar/SidebarToggle.vue';
import { Disclosure } from '@headlessui/vue';
import { LogoutIcon } from '@heroicons/vue/outline';
import { computed } from 'vue';

export default {
  name: 'RemoteSessionToolHeader',
  components: {
    SidebarToggle,
    Disclosure,
    LogoutIcon,
  },

  props: {
    logoutPath: {
      type: String,
      required: true,
    },
    // at least { name: string }
    candidate: {
      type: Object,
      required: true,
    },
    logoUrl: {
      type: String,
      required: true,
    },
    brandingColor: { // hex
      type: String,
      required: true,
    },
  },
  setup(props) {
    const borderColor = computed(() => `border-color: ${props.brandingColor}`);
    return {
      borderColor,
    };
  },
};
</script>
