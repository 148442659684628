<template>
  <ConfirmDialogModal
    :open="open && !disabled"
    :message="text"
    :is-loading="disabled"
    @close="$emit('close')"
    @confirm="resetCompetenceModel"
  />
</template>

<script>
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';
import { resetTemplateAssessmentCompetenceModelPath } from '@/util/url-helpers';
import Axios from 'axios';

export default {
  name: 'AssessmentCompetenceModelFormResetModal',
  components: {
    ConfirmDialogModal,
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'reset-competence-model'],
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },

    resetCompetenceModel() {
      this.disabled = true;

      Axios.post(resetTemplateAssessmentCompetenceModelPath(this.assessmentId))
        .then((response) => {
          this.$emit('reset-competence-model', response.data);
          this.$toast.success(
            this.$t('components.assessment.competence_model_form.reset_modal.reset_template.success'),
          );
          this.disabled = false;
        })
        .catch(() => {
          this.disabled = false;
          this.$toast.error(this.$t('components.assessment.competence_model_form.reset_modal.reset_template.error'));
        });
    },
  },
};
</script>
