<template>
  <ConfirmDialogModal
    :open="open && !disabled"
    :title="$t('defaults.remove')"
    :message="text"
    :listing="warningListing"
    :is-loading="disabled"
    @close="$emit('close')"
    @confirm="removeCompetenceModel"
  />
</template>

<script>
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';
import { removeAssessmentCompetenceModelPath } from '@/util/url-helpers';
import Axios from 'axios';

export default {
  name: 'AssessmentCompetenceModelFormRemoveModal',
  components: {
    ConfirmDialogModal,
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    warningListing: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: ['close', 'remove-competence-model'],
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },

    removeCompetenceModel() {
      this.disabled = true;

      Axios
        .post(removeAssessmentCompetenceModelPath(this.assessmentId))
        .then(() => {
          this.$emit('remove-competence-model');
          this.$toast.success(
            this.$t('components.assessment.competence_model_form.removal_modal.success'),
          );
          this.disabled = false;
        })
        .catch(() => {
          this.$toast.error(this.$t('components.assessment.competence_model_form.removal_modal.error'));
          this.disabled = false;
        });
    },
  },
};
</script>
