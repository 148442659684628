<template>
  <div>
    <HeadlessSwitch
      v-model="model"
      :disabled="disabled"
      :class="classes"
    >
      <span class="sr-only">{{ $t('components.toggle.use_setting') }}</span>
      <span
        :class="[
          model ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 rounded-full',
          'bg-white shadow ring-0 transition ease-in-out duration-200']"
      >
        <span
          :class="[
            model ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']"
          aria-hidden="true"
        >
          <svg
            :class="['h-3 w-3', disabled ? 'text-gray-300' : 'text-gray-400']"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span
          :class="[
            model ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']"
          aria-hidden="true"
        >
          <svg
            :class="['h-3 w-3', disabled ? 'text-gray-400' : 'text-primary-600']"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <!-- eslint-disable-next-line max-len -->
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </HeadlessSwitch>
    <input
      type="hidden"
      :name="name"
      :class="`${props.qaClass !== '' ? `qa-${props.qaClass}-value` : ''}`"
      :value="model"
    >
  </div>
</template>

<script setup>
import { Switch as HeadlessSwitch } from '@headlessui/vue';
import { computed } from 'vue';

const model = defineModel({ type: Boolean, default: false });

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: null,
    required: false,
  },
  qaClass: {
    type: String,
    default: '',
  },
});

const classes = computed(() => [
  'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer',
  'transition-colors ease-in-out duration-200',
  'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
  `${props.qaClass !== '' ? `qa-${props.qaClass}` : ''}`,
  {
    'bg-gray-400': model.value && props.disabled,
    'bg-gray-100': !model.value && props.disabled,
    'bg-primary-600': model.value && !props.disabled,
    'bg-gray-200': !model.value && !props.disabled,
  }]);
</script>
