import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import Axios from 'axios';
import { webdavSessionsPath } from '@/util/url-helpers';
import { useWebdavEditingInfoModalStore } from '@/components/generic/EditInOffice/stores/webdavEditingInfoModalStore';

// eslint-disable-next-line import/prefer-default-export
export const useWebdavSessionStore = defineStore('webdavSession', () => {
  const fileToEditInOffice = ref(null);
  const fileToEditInOfficeType = ref(null);
  const showUnlockModal = ref(false);
  const activeLock = ref(null);

  const webdavInfoModalStore = useWebdavEditingInfoModalStore();
  const { shouldShowInfoModal } = storeToRefs(webdavInfoModalStore);

  async function createWebdavSession({ file, fileType, force = false }) {
    fileToEditInOffice.value = file;
    fileToEditInOfficeType.value = fileType;

    if (shouldShowInfoModal.value) {
      webdavInfoModalStore.openInfoModal();
      return;
    }

    try {
      const response = await Axios.post(
        webdavSessionsPath(),
        {
          webdav_session: {
            file_id: fileToEditInOffice.value.id,
            file_type: fileType,
          },
          force,
        },
      );
      showUnlockModal.value = false;
      fileToEditInOffice.value = null;
      activeLock.value = null;
      window.location.href = response.data.url;
    } catch (error) {
      if (error.response.status === 423) { // LOCKED
        activeLock.value = error.response.data.activeLock;
        showUnlockModal.value = true;
      }
    }
  }

  async function continueCreateWebdavSession({ force = false } = {}) {
    await createWebdavSession({ file: fileToEditInOffice.value, fileType: fileToEditInOfficeType.value, force });
  }

  function closeUnlockModal() {
    activeLock.value = null;
    showUnlockModal.value = false;
    fileToEditInOffice.value = null;
    fileToEditInOfficeType.value = null;
  }

  return {
    createWebdavSession,
    showUnlockModal,
    closeUnlockModal,
    continueCreateWebdavSession,
    activeLock,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWebdavSessionStore, import.meta.hot));
}
