<template>
  <div class="mb-6">
    <PageHeading>
      {{ competency }}
      <template #secondary>
        {{ exercise }}
      </template>
      <template #actions>
        <div class="flex justify-end space-x-5">
          <BaseButton
            v-if="isEditable"
            :disabled="!isEditable"
            :is-primary="false"
            icon="UploadIcon"
            @click="prepareAndShowBulkAddModal"
          >
            {{ $t('components.rating_anchors_input.import') }}
          </BaseButton>
          <BaseButton
            v-if="isEditable && ratingAnchor.id"
            :disabled="!isEditable"
            :is-primary="false"
            icon="TrashIcon"
            @click="ratingAnchorStore.deleteRatingAnchor()"
          >
            {{ $t('defaults.delete') }}
          </BaseButton>
          <BaseButton
            v-if="isEditable && ratingAnchor.id"
            :disabled="!isEditable"
            icon="CheckIcon"
            :is-loading="submitting"
            @click="ratingAnchorStore.upsertRatingAnchor()"
          >
            {{ $t('defaults.save') }}
          </BaseButton>
        </div>
      </template>
    </PageHeading>
  </div>

  <div>
    <fieldset :disabled="!isEditable">
      <behavior-anchors-form-fields
        :is-editable="isEditable"
      />
    </fieldset>

    <bulk-add-modal
      :key="bulkItemsInput"
      :show="showBulkAddModal"
      :input="bulkItemsInput"
      @bulk-add="bulkAddItems"
      @close="closeBulkAddModal"
    />
  </div>

  <div
    class="flex justify-end space-x-5 mb-5"
  >
    <BaseButton
      v-if="!ratingAnchor.id"
      qa-class="save-button"
      :disabled="!isEditable"
      :is-loading="submitting"
      icon="CheckIcon"
      @click="ratingAnchorStore.upsertRatingAnchor()"
    >
      {{ $t('defaults.save') }}
    </BaseButton>
  </div>
</template>

<script>
import { BehaviorAnchorsFormFields, BulkAddModal } from '@/components/RatingAnchorsForm/index';
import { mapState } from 'pinia';
import { useRatingAnchorStore } from './RatingAnchorsForm/ratingAnchorStore';
import { useBehaviorAnchorsStore } from './RatingAnchorsForm/behaviorAnchorsStore';

export default {
  name: 'RatingAnchorsForm',
  components: {
    BehaviorAnchorsFormFields,
    BulkAddModal,
  },
  props: {
    initRatingAnchor: {
      type: Object,
      required: true,
    },
    behaviorAnchorsAttributes: {
      type: Array,
      required: true,
    },
    defaultScaleLabels: {
      type: Array,
      required: true,
    },
    competenceModel: {
      type: Object,
      required: true,
    },
    exitPath: {
      type: String,
      required: true,
    },
    competency: {
      type: String,
      required: true,
    },
    exercise: {
      type: String,
      required: true,
    },
  },
  setup() {
    const ratingAnchorStore = useRatingAnchorStore();
    const behaviorAnchorsStore = useBehaviorAnchorsStore();

    return { ratingAnchorStore, behaviorAnchorsStore };
  },
  data() {
    return {
      showBulkAddModal: false,
      bulkItemsInput: '',
    };
  },
  computed: {
    ...mapState(useRatingAnchorStore, ['ratingAnchor']),
    ...mapState(useRatingAnchorStore, ['submitting']),
    ...mapState(useBehaviorAnchorsStore, ['notDestroyedBehaviorAnchors']),
    isEditable() {
      return this.competenceModel['editable?'];
    },
  },
  created() {
    this.ratingAnchorStore.setRatingAnchor(this.initRatingAnchor, this.behaviorAnchorsAttributes);
    this.ratingAnchorStore.setExitPath(this.exitPath);
    this.ratingAnchorStore.setCompetenceModel(this.competenceModel);
    this.ratingAnchorStore.setDefaultScaleLabels(this.defaultScaleLabels);
  },
  beforeUnmount() {
    this.ratingAnchorStore.$dispose();
    this.behaviorAnchorsStore.$dispose();
  },
  methods: {
    bulkAddItems(itemsToAdd) {
      this.notDestroyedBehaviorAnchors
        .forEach((behaviorAnchor) => this.behaviorAnchorsStore.deleteBehaviorAnchor(behaviorAnchor));
      itemsToAdd.forEach((item) => this.behaviorAnchorsStore.addBehaviorAnchor({ description: item }));
    },
    prepareAndShowBulkAddModal() {
      this.bulkItemsInput = this.notDestroyedBehaviorAnchors.map((ratingAnchor) => ratingAnchor.description).join('\n');
      this.showBulkAddModal = true;
    },
    closeBulkAddModal() {
      this.showBulkAddModal = false;
      this.bulkItemsInput = '';
    },
  },
};
</script>
