<template>
  <div>
    <div class="mb-6">
      <PageHeading>
        <div v-if="tenantIsNewRecord">
          {{ $t('components.tenants.tenants_form.new_tenant') }}
        </div>
        <div v-else>
          {{ $t('components.tenants.tenants_form.edit_tenant') }}
        </div>
        <template #actions>
          <div class="flex items-center space-x-4">
            <SaveButton
              v-if="!tenantIsNewRecord"
              :is-loading="submitting"
              form="tenant-form"
            >
              {{ $t('defaults.save') }}
            </SaveButton>
          </div>
        </template>
      </PageHeading>
    </div>
    <form
      id="tenant-form"
      :action="actionUrl"
      accept-charset="UTF-8"

      method="post"
      enctype="multipart/form-data"
      @submit="submitting = true"
    >
      <input
        type="hidden"
        name="_method"
        :value="dataMethod"
      >
      <input
        type="hidden"
        name="authenticity_token"
        :value="$csrfToken"
      >
      <div class="space-y-5 mb-5">
        <tenant-fields
          :tenant-data="tenant"
          :tenant-errors="tenantErrors"
          :openid-connect-config-errors="openidConnectConfigErrors"
        />
        <div
          v-if="tenantIsNewRecord"
          class="space-y-6"
        >
          <two-column-card
            :header="$t('components.tenants.tenants_form.card.administrator.heading')"
            :helptext="$t('components.tenants.tenants_form.card.administrator.text')"
          >
            <div class="space-y-6">
              <base-input
                v-model="user.first_name"
                name="user[first_name]"
                :label="$t('activerecord.attributes.user.first_name')"
                :error="getUserError('first_name')"
              />
              <base-input
                v-model="user.last_name"
                name="user[last_name]"
                :label="$t('activerecord.attributes.user.last_name')"
                :error="getUserError('last_name')"
              />
              <base-input
                v-model="user.email"
                name="user[email]"
                :label="$t('activerecord.attributes.user.email')"
                :error="getUserError('email')"
              />
              <base-select
                v-model="initialLocale"
                name="user[locale]"
                :label="$t('activerecord.attributes.user.locale')"
                :options="localeOptions"
                :error="getUserError('locale')"
              />
            </div>
          </two-column-card>
        </div>
        <div class="flex justify-end space-x-5">
          <BaseButton
            v-if="tenantIsNewRecord"
            as="a"
            href="/admin/tenants"
            :is-primary="false"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
          <SaveButton
            v-if="tenantIsNewRecord"
            :is-loading="submitting"
            form="tenant-form"
          >
            {{ $t('components.tenants.tenants_form.create_button') }}
          </SaveButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { adminTenantsPath } from '@/util/url-helpers';
import { defineComponent, ref } from 'vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import BaseSelect from '@/components/generic/BaseSelect/BaseSelect.vue';
import TenantFields from '@/components/Tenants/TenantForm/TenantFields.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import SaveButton from '@/components/generic/SaveButton/SaveButton.vue';
import useLocaleOptions from '@/composables/localeOptions';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TenantTemplate',
  components: {
    BaseButton,
    SaveButton,
    BaseInput,
    TwoColumnCard,
    BaseSelect,
    TenantFields,
  },
  props: {
    tenantData: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    tenantErrors: {
      type: Object,
      default: () => ({}),
    },
    openidConnectConfigErrors: {
      type: Object,
      default: () => ({}),
    },
    userErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const tenant = { ...props.tenantData };
    const user = { ...props.userData };

    const getUserError = (...keys) => props.userErrors[keys.join('.')]?.[0];
    const tenantIsNewRecord = !tenant.id;
    const actionUrl = tenantIsNewRecord ? adminTenantsPath() : '/tenant';

    const dataMethod = tenantIsNewRecord ? 'post' : 'patch';

    const { availableLocales } = useI18n();
    const { localeOptions, initialLocale } = useLocaleOptions(availableLocales, user.locale);

    const submitting = ref(false);
    return {
      submitting,
      tenant,
      user,
      getUserError,
      tenantIsNewRecord,
      actionUrl,
      dataMethod,
      localeOptions,
      initialLocale,
    };
  },
});
</script>
