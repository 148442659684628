<template>
  <Modal
    :show="show"
    @click-outside="close"
  >
    <div class="p-4 w-100">
      <fieldset
        :disabled="!isEditable || isLoading"
        class="space-y-5"
      >
        <BaseInput
          v-model="competency.name"
          :disabled="!isEditable"
          :label="$t('activerecord.attributes.competency.name')"
          :error="getError('name')"
        />
        <div
          ref="descriptionTextArea"
        >
          <BaseTextarea
            v-model="competency.description"
            :disabled="!isEditable"
            :label="$t('activerecord.attributes.competency.description')"
            :error="getError('description')"
            size="large"
            @input="updateDescriptionTextAreaSize"
          />
        </div>
      </fieldset>
    </div>

    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4">
      <BaseButton
        type="button"
        :is-primary="false"
        @click="close"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        type="button"
        :is-disabled="!isEditable"
        :is-loading="isLoading"
        @click="updateCompetency"
      >
        <span>{{ $t('defaults.save') }}</span>
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import Modal from '@/components/generic/Modal/Modal.vue';
import { competencyPath } from '@/util/url-helpers';
import Axios from 'axios';

export default {
  name: 'MatrixWizardCompetencyModal',
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    competenceModelId: {
      type: [Number, String],
      required: true,
    },
    competencyData: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'competency-updated'],
  setup(props, { emit }) {
    const competency = { ...props.competencyData };
    const errors = ref({});
    const isLoading = ref(false);

    const close = () => {
      emit('close');
    };

    const getError = (...keys) => errors.value[keys.join('.')]?.[0];

    const updateCompetency = () => {
      isLoading.value = true;

      const data = {
        competency: {
          name: competency.name,
          description: competency.description || '',
        },
      };

      Axios.put(competencyPath(props.competenceModelId, competency), data)
        .then((response) => {
          errors.value = {};
          isLoading.value = false;
          emit('competency-updated', response.data);
        })
        .catch((error) => {
          errors.value = error.response.data;
          isLoading.value = false;
        });
    };

    const descriptionTextArea = ref(null);

    const autoResize = (target) => {
      /* eslint-disable no-param-reassign */
      target.style.height = 'auto';
      target.style.height = `${Math.min(target.scrollHeight, 500)}px`;
    };

    onMounted(async () => {
      await nextTick();
      descriptionTextArea.value.getElementsByTagName('textarea').forEach((el) => {
        autoResize(el);
      });
    });

    const updateDescriptionTextAreaSize = (event) => {
      autoResize(event.target);
    };

    return {
      competency,
      errors,
      isLoading,
      close,
      updateCompetency,
      getError,
      updateDescriptionTextAreaSize,
      descriptionTextArea,
    };
  },
};
</script>
