<template>
  <Modal
    :open="open"
    @close="close"
  >
    <div class="p-6">
      <h2 class="mb-4 font-bold">
        {{ $t("components.assessment.competence_model_form.creation_modal.new_competence_model") }}
      </h2>
      <p class="mb-2">
        {{ $t("components.assessment.competence_model_form.creation_modal.new_competence_model_prompt") }}
      </p>
      <form
        @keyup.enter="createCompetenceModel"
      >
        <div class="flex justify-between space-x-3">
          <BaseInput
            v-model="scaleMin"
            :label="$t('activerecord.attributes.scale.min')"
            :step="scaleStep"
            type="number"
            min="0"
            :max="SCALE_LIMIT_MAX"
            name="competence_model[scale_attributes][min]"
            :error="getError('scale', 'min')"
          />
          <BaseInput
            v-model="scaleMax"
            :label="$t('activerecord.attributes.scale.max')"
            :step="scaleStep"
            type="number"
            min="0"
            :max="SCALE_LIMIT_MAX"
            name="competence_model[scale_attributes][max]"
            :error="getError('scale', 'max')"
          />
          <BaseSelect
            v-model="selectedScaleOption"
            :label="$t('activerecord.attributes.scale.step') "
            :options="scaleOptions"
            name="competence_model[scale_attributes][step]"
          />
        </div>
      </form>
    </div>

    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4">
      <BaseButton
        type="button"
        :is-primary="false"
        :is-disabled="disabled"
        @click="close"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        type="button"
        :is-loading="disabled"
        qa-class="add-competence-model-save-button"
        @click="createCompetenceModel"
      >
        {{ $t('defaults.save') }}
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import Modal from '@/components/generic/Modal/Modal.vue';
import useScaleOptions from '@/composables/scaleOptions';
import { assessmentCompetenceModelPath } from '@/util/url-helpers';
import Axios from 'axios';
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'AssessmentCompetenceModelFormCreationModal',
  components: {
    Modal,
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'created-competence-model'],
  setup(props, { emit }) {
    const SCALE_LIMIT_MAX = 30;

    const errors = ref({});
    const disabled = ref(false);
    const scaleStep = ref(1);
    const scaleMin = ref(1);
    const scaleMax = ref(5);

    const { t } = useI18n();

    const toast = inject('toast');

    const { scaleOptions } = useScaleOptions(null);

    const selectedScaleOption = ref(scaleOptions[0].value);

    const close = () => {
      emit('close');
    };

    const getError = (...keys) => errors.value[keys.join('.')]?.[0];

    const createCompetenceModel = () => {
      disabled.value = true;
      const data = {
        competence_model: {
          scale_attributes: {
            min: scaleMin.value,
            max: scaleMax.value,
            step: selectedScaleOption.value,
          },
          customized: true,
        },
      };

      Axios
        .post(assessmentCompetenceModelPath(props.assessmentId), data)
        .then((response) => {
          disabled.value = false;
          errors.value = {};
          emit('created-competence-model', { competenceModel: response.data });
          toast.success(
            t('components.assessment.competence_model_form.creation_modal.create_competence_model.success'),
          );
        })
        .catch(({ response }) => {
          errors.value = response.data.errors;
          disabled.value = false;
          toast.error(
            t('components.assessment.competence_model_form.creation_modal.create_competence_model.error'),
          );
        });
    };

    errors.value = { sdf: 'sdfsfd' };
    return {
      errors,
      disabled,
      scaleStep,
      scaleMin,
      scaleMax,
      close,
      getError,
      createCompetenceModel,
      scaleOptions,
      selectedScaleOption,
      SCALE_LIMIT_MAX,
    };
  },
};
</script>
