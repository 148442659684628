import { locale } from '@/plugins/i18n';

export default (current) => {
  const scaleOptions = [
    { value: 1, label: 1 },
    { value: 0.5, label: locale === 'en' ? 0.5 : '0,5' },
    { value: 0.25, label: locale === 'en' ? 0.25 : '0,25' },
  ];

  const initialScaleOption = scaleOptions.find((option) => option.value === current);

  return {
    scaleOptions,
    initialScaleOption,
  };
};
