<template>
  <div class="static z-50">
    <remote-session-tool-header
      id="header"
      :candidate="candidate"
      :logo-url="logoUrl"
      :logout-path="logoutPath"
      :branding-color="brandingColor"
    />
  </div>
  <div class="flex grow bg-gray-200 h-full relative">
    <Sidebar
      :initial-resources="resourcesWrapper"
      :initial-tests="tests"
      :selected-category="selectedCategory"
      :selected-file="selectedFile"
      @select-file="selectFile"
      @select-category="selectCategory"
    />
    <div
      v-if="selectedFile"
      class="flex-1 overflow-y-auto"
    >
      <div class="flex w-full flex-initial">
        <DocumentHeader
          :selected-category="selectedCategory"
          :selected-file="selectedFile"
          @unselect-file="selectFile(null)"
          @revoke-file-on-timer-end="revokeFileOnTimerEnd"
        />
      </div>
      <div
        v-if="selectedFile"
        :style="{ height: calculatedPDFHeight }"
        class="m-auto text-center w-full"
      >
        <ResourceViewer
          :key="selectedFileData.url"
          :url="selectedFileData.url"
          :text-selectable="selectedFile.textSelectable"
          :type="selectedFileData.type"
          :class="['h-full', selectedFile.type == 'video' ? 'pb-4' : 'pt-0']"
          :pdf-watermark="watermarkText"
        />
      </div>
    </div>
    <template
      v-else-if="selectedCategory"
    >
      <CategoryOverview
        :selected-category="selectedCategory"
        @select-file="selectFile($event)"
      />
    </template>
  </div>
</template>

<script>
import {
  ref, computed, onMounted, watch, watchEffect,
} from 'vue';
import RemoteSessionToolHeader from '@/components/RemoteSessionTool/Header.vue';
import Sidebar from '@/components/RemoteSessionTool/RemoteSessionSidebar.vue';
import CategoryOverview from '@/components/RemoteSessionTool/CategoryOverview.vue';
import DocumentHeader from '@/components/RemoteSessionTool/DocumentHeader.vue';

import consumer from '@/channels/consumer';
import ResourceViewer from '@/components/Viewers/ResourceViewer.vue';

import { useCandidateSelfRatingsStore } from '@/components/RemoteSessionTool/stores/candidateSelfRatingsStore';

export default {
  name: 'RemoteSessionTool',
  components: {
    RemoteSessionToolHeader,
    ResourceViewer,
    Sidebar,
    CategoryOverview,
    DocumentHeader,
  },
  props: {
    // [{ id, type, name, files: [{ id, filename, shared, downloadable, url, type, parent: { type, id } }] }]
    initialResources: {
      type: Array,
      required: true,
    },
    // [{ name, invitation_id, status, metadata}]
    initialTests: {
      type: Array,
      required: true,
    },
    // header props
    logoutPath: {
      type: String,
      required: true,
    },
    // at least { name: string }
    candidate: {
      type: Object,
      required: true,
    },
    logoUrl: {
      type: String,
      required: false,
      default: '/applysia-logo.png',
    },
    // hex
    brandingColor: {
      type: String,
      required: true,
    },
    organisation: {
      type: String,
      required: true,
      default: '',
    },
    candidateSelfRatingConfig: {
      type: Object,
      default: null,
      required: false,
    },
    competenceModel: {
      type: Object,
      default: null,
      required: true,
    },
    sharedSelfAssessmentExerciseIds: {
      type: Array,
      default: () => [],
      required: true,
    },
    candidateSelfRatings: {
      type: Array,
      default: null,
      required: true,
    },
    watermarkText: {
      type: String,
      default: null,
      required: false,
    },
  },
  setup(props) {
    const selfRatingStore = useCandidateSelfRatingsStore();
    selfRatingStore.setConfig(props.candidateSelfRatingConfig);
    selfRatingStore.setCompetenceModel(props.competenceModel);
    selfRatingStore.setCandidate(props.candidate);
    selfRatingStore.setSharedSelfAssessmentExerciseIds(props.sharedSelfAssessmentExerciseIds);
    selfRatingStore.setCandidateSelfRatings(props.candidateSelfRatings);

    const resources = ref(props.initialResources);
    const tests = ref(props.initialTests);

    const resourcesWrapper = computed(() => {
      const array = [];
      let i = 0; const categoryLength = resources.value.length;
      while (i < categoryLength) {
        const sharedFileCount = resources.value[i].files.filter((f) => f.shared).length;
        const totalFileCount = resources.value[i].files.length;
        array[i] = {
          ...resources.value[i],
          files: resources.value[i].files,
          sharedFileCount,
          totalFileCount,
        };
        i += 1;
      }
      return array;
    });

    const calculatedPDFHeight = ref('100%');
    const showSidebar = ref(false);
    const selectedFile = ref(null);
    const selectedCategory = ref(resourcesWrapper.value.find((e) => e.files.find((f) => f.shared === true)));

    watch(resourcesWrapper, (newResourcesWrapper) => {
      const updatedCategory = newResourcesWrapper.find((e) => e.files.find((f) => f.shared === true));
      if (!selectedCategory.value
       || selectedCategory.value?.id === updatedCategory?.id) { // if selectedCategory is undefined, or changed
        selectedCategory.value = updatedCategory;
      }
    });

    function updateFile({ type: parentType, id: parentId, name: parentName }, updatedFile) {
      const resourceIndex = resources.value.findIndex(
        (resource) => resource.type === parentType && resource.id === parentId,
      );
      if (resourceIndex < 0) { // new resource
        if (parentType === 'Candidate') {
          resources.value.unshift({ id: parentId, type: parentType, name: parentName, files: [updatedFile] });
        } else {
          resources.value.push({ id: parentId, type: parentType, name: parentName, files: [updatedFile] });
        }
      } else {
        const fileIndex = resources.value[resourceIndex].files.findIndex((file) => file.id === updatedFile.id);

        if (fileIndex < 0) { // new file
          resources.value[resourceIndex].files.push(updatedFile);
        } else { // existing file
          resources.value[resourceIndex].files[fileIndex] = updatedFile;
          if ( // selected category has any shared files
            resourcesWrapper.value[resourceIndex].sharedFileCount === 0
            && selectedCategory?.value?.id === resourcesWrapper.value[resourceIndex].id
          ) {
            selectedCategory.value = resourcesWrapper.value.find((e) => e.files.find((f) => f.shared === true));
          }
        }
      }
    }

    function updateReportFile(testId, testReport) {
      const testIndex = tests.value.findIndex((test) => test.id === testId);

      const reportIndex = tests.value[testIndex].files.findIndex((file) => file.id === testReport.id);
      if (reportIndex < 0) { // new report
        tests.value[testIndex].files.push(testReport);
      } else { // update existing report
        tests.value[testIndex].files[reportIndex] = testReport;
      }
    }

    function selectFile(file) {
      selectedFile.value = selectedFile.value === file ? null : file;
    }

    const selectedFileData = computed(() => {
      if (selectedFile.value.type === 'docx' || selectedFile.value.type === 'pptx') {
        return selectedFile.value.filePdf;
      }

      return {
        url: selectedFile.value.url,
        type: selectedFile.value.type,
      };
    });

    function selectCategory(category) {
      selectedCategory.value = category;
    }

    function shareFile(parentResource, fileData) {
      if (selectedFile.value?.id === fileData.id) {
        selectedFile.value = fileData;
      }

      if (fileData.testId) {
        updateReportFile(fileData.testId, fileData);
      } else {
        updateFile(parentResource, fileData);
      }
    }

    function revokeFile(parentResource, fileData) {
      if (selectedFile.value?.id === fileData.id) {
        selectedFile.value = null;
      }

      if (fileData.testId) {
        updateReportFile(fileData.testId, fileData);
      } else {
        updateFile(parentResource, fileData);
      }
    }

    function revokeFileOnTimerEnd({ file, category }) {
      revokeFile(
        file.parent,
        {
          id: file.id,
          filename: file.filename,
          shared: false,
          testId: category.type === 'Test' ? category.id : null,
        },
      );
    }

    function updateTestStatus(updatedTest) {
      const testIndex = tests.value.findIndex((test) => test.id === updatedTest.id);

      if (testIndex < 0) { // new test
        tests.value.push(updatedTest);
      } else {
        tests.value[testIndex].status = updatedTest.status;
      }
    }

    onMounted(() => {
      // register ActionCable channel subscriptions
      consumer.subscriptions.create('RemoteSessionChannel', {
        received({ fileData, parent: parentResource, exerciseId, performedAction }) {
          if (performedAction === 'shared') {
            shareFile(parentResource, fileData);
          } else if (performedAction === 'revoked') {
            revokeFile(parentResource, fileData);
          } else if (performedAction === 'test_status') {
            updateTestStatus(fileData[0]);
          } else if (performedAction === 'share_self_assessment') {
            selfRatingStore.shareSharedSelfAssessment(exerciseId);
          } else if (performedAction === 'revoke_self_assessment') {
            selfRatingStore.revokeSharedSelfAssessment(exerciseId);
          }
        },
      });
    });

    watchEffect(() => {
      if (selectedFile.value) {
        // set height of PDFDocument component
        const headerHeight = document.querySelector('#header').offsetHeight;
        calculatedPDFHeight.value = `calc(100vh - ${headerHeight}px - 2.5rem)`;
      }
    });

    return {
      showSidebar,
      resourcesWrapper,
      selectedFile,
      selectedFileData,
      selectFile,
      revokeFileOnTimerEnd,
      calculatedPDFHeight,
      selectCategory,
      selectedCategory,
      tests,
    };
  },
};
</script>
