<template>
  <MenuItem v-slot="{ active }">
    <a
      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
      :href="href"
      :data-turbo-method="method"
      @click="onClick"
    ><slot /></a>
  </MenuItem>
</template>

<script>
import { defineComponent } from 'vue';
import { MenuItem } from '@headlessui/vue';
import { useConfirmDialogModal } from '@/composables/useConfirmDialogModal';

export default defineComponent({
  components: {
    MenuItem,
  },
  props: {
    href: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    buttonText: {
      type: String,
      default: null,
      required: false,
    },
    confirm: {
      type: String,
      default: null,
    },
    infoLink: {
      type: String,
      default: null,
    },
    challenge: {
      type: String,
      default: null,
    },
    remote: {
      type: Boolean,
      default: null,
    },
  },
  setup(props) {
    const { openConfirmModal } = useConfirmDialogModal();

    const onClick = async (event) => {
      if (props.confirm) {
        event.preventDefault();
        openConfirmModal({
          open: true,
          title: props.title,
          message: props.confirm,
          infoLink: props.infoLink,
          challenge: props.challenge,
          buttonText: props.buttonText,
          sourceElement: event.target.closest('a').cloneNode(),
        });
      }
    };

    return {
      onClick,
    };
  },
});
</script>
