<template>
  <BaseTableActions>
    <template #menu-items>
      <LinkDropdownItem
        :href="editUserPath(user)"
        class="qa-base-table-edit-action"
      >
        <PencilIcon
          class="inline-block mr-4 h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
        <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
      </LinkDropdownItem>

      <LinkDropdownItem
        :href="userDeactivationPath(user)"
        :method="user.status === 'deactivated' ? 'delete' : 'post'"
        class="qa-base-table-deactivate-action"
      >
        <component
          :is="user.status === 'deactivated' ? 'LockOpenIcon' : 'LockClosedIcon'"
          class="inline-block mr-4 h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
        <span class="text-gray-700">
          {{ deactivationText }}
        </span>
      </LinkDropdownItem>

      <LinkDropdownItem
        :href="userPath(user)"
        method="delete"
        :remote="true"
        :confirm="confirmDeletionMessage"
        :challenge="user.name"
        class="border-t border-gray-300 qa-base-table-delete-action"
      >
        <TrashIcon
          class="inline-block mr-4 h-5 w-5 text-red-500"
          aria-hidden="true"
        />
        <span class="text-red-700">{{ $t('defaults.delete') }}</span>
      </LinkDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { DotsVerticalIcon } from '@heroicons/vue/outline';
import { PencilIcon, LockOpenIcon, LockClosedIcon, TrashIcon } from '@heroicons/vue/solid';
import { MenuButton } from '@headlessui/vue';
import Dropdown from '@/components/generic/Dropdown/Dropdown.vue';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import { editUserPath, userPath, userDeactivationPath } from '@/util/url-helpers';
import { useI18n } from '@/plugins/i18n';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';

export default defineComponent({
  components: {
    DotsVerticalIcon,
    PencilIcon,
    LockOpenIcon,
    LockClosedIcon,
    TrashIcon,
    Dropdown,
    MenuButton,
    LinkDropdownItem,
    BaseTableActions,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { user } = toRefs(props);
    const { t } = useI18n();

    const deactivationText = computed(() => {
      if (user.value.status === 'deactivated') {
        return t('components.users.users_table.user_actions.reactivate');
      }
      return t('components.users.users_table.user_actions.deactivate');
    });
    const confirmDeletionMessage = computed(() => t(
      'components.users.users_table.user_actions.do_you_want_to_delete',
      { name: user.value.name },
    ));

    return {
      deactivationText,
      confirmDeletionMessage,
      userPath,
      editUserPath,
      userDeactivationPath,
    };
  },
});
</script>
