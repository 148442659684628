<template>
  <Dropdown item-classes="right-4">
    <template #button>
      <MenuButton
        class="rounded-md text-gray-400 focus:outline-none ring-primary-500 ring-offset-2
          transition-colors duration-200 hover:text-gray-500 focus:ring-2"
      >
        <DotsVerticalIcon
          class="h-6 w-6"
          aria-hidden="true"
        />
        <span class="sr-only">{{ $t('defaults.manage') }}</span>
      </MenuButton>
    </template>

    <ActionDropdownItem
      v-if="test.isEditable"
      class="border-b border-gray-300"
      @click="editTest"
    >
      <PencilIcon
        class="inline-block mr-4 h-5 w-5 text-gray-500"
        aria-hidden="true"
      />
      <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
    </ActionDropdownItem>

    <ActionDropdownItem
      v-if="test.isContinuable"
      class="border-b border-gray-300"
      @click="continueTest"
    >
      <PlayIcon
        class="inline-block mr-4 h-5 w-5 text-gray-500"
        aria-hidden="true"
      />
      <span class="text-gray-700">{{ $t('components.test_list.continue') }}</span>
    </ActionDropdownItem>

    <LinkDropdownItem
      :href="assessmentCandidateTestPath(assessmentId, candidateId, test)"
      method="delete"
      :remote="true"
      :confirm="confirmDeletionMessage"
      :challenge="test.name"
    >
      <TrashIcon
        class="inline-block mr-4 h-5 w-5 text-red-500"
        aria-hidden="true"
      />
      <span class="text-red-700">{{ $t('defaults.delete') }}</span>
    </LinkDropdownItem>
  </Dropdown>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { DotsVerticalIcon } from '@heroicons/vue/outline';
import { PencilIcon, TrashIcon, PlayIcon } from '@heroicons/vue/solid';
import { MenuButton } from '@headlessui/vue';
import Dropdown from '@/components/generic/Dropdown/Dropdown.vue';
import { useI18n } from '@/plugins/i18n';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import { assessmentCandidateTestPath } from '@/util/url-helpers';

export default defineComponent({
  components: {
    DotsVerticalIcon,
    PencilIcon,
    Dropdown,
    MenuButton,
    LinkDropdownItem,
    ActionDropdownItem,
    TrashIcon,
    PlayIcon,
  },
  props: {
    test: {
      type: Object,
      required: true,
    },
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    candidateId: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ['edit', 'continue'],
  setup(props, { emit }) {
    const { test } = toRefs(props);
    const { t } = useI18n();

    const confirmDeletionMessage = computed(() => t(
      'components.test_list.action.destroy.confirm',
      { name: test.value.name },
    ));

    const editTest = () => {
      emit('edit', test.value);
    };

    const continueTest = () => {
      emit('continue', test.value);
    };

    return {
      assessmentCandidateTestPath,
      confirmDeletionMessage,

      editTest,
      continueTest,
    };
  },
});
</script>
