<template>
  <div class="flex flex-col ">
    <div class="h-16 py-5 px-6 border-b border-solid  border-gray-200">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("components.competence_model.form.matrix") }}
      </h3>
    </div>
    <div class="flex flex-col overflow-x-auto">
      <table class="max-w-fit">
        <thead>
          <draggable
            v-model="exercises"
            item-key="id"
            tag="tr"
            :disabled="!isEditable"
            handle=".handle"
            ghost-class="dragging"
            v-bind="dragOptions"
            :move="checkMove"
            @start="drag = true"
            @end="onExerciseMove"
          >
            <template #item="{element: exercise}">
              <th
                :ref="el => exerciseRefs[exercise.id] = el"
                class="dragItem bg-white hover:bg-gray-50"
              >
                <div class="inline-flex items-center min-h-16">
                  <i
                    v-show="isEditable"
                    class="material-icons-outlined text-center handle cursor-move text-gray-400"
                  >
                    drag_indicator
                  </i>
                  <p
                    class="text-left items-center cursor-pointer text-gray-900 text-sm
                      font-medium pl-1 mr-2.5 min-w-min"
                    @click="editExercise(exercise)"
                  >
                    {{ exercise.name }}
                  </p>
                  <MatrixWizardActions
                    v-if="isEditable"
                    @edit-click="editExercise(exercise)"
                    @delete-click="showDeleteModal({ type: 'Exercise', object: exercise })"
                  />
                </div>
              </th>
            </template>
            <template #header>
              <th class="min-w-[16rem]">
                <TriStateCheckbox
                  v-if="showCompetenceModelSelect && lengthAllCompetencies > 0"
                  id="selectAllCheckbox"
                  class="py-4 pl-4"
                  :label="$t('defaults.select_all')"
                  label-classes="text-orange-600 text-sm leading-5 font-medium"
                  :is-intermediate="someCompetenciesSelected"
                  :model-value="allCompetenciesSelected"
                  @update:model-value="updateSelectAll"
                />
              </th>
            </template>
            <template #footer>
              <th
                v-if="isEditable"
                ref="addExerciseColumn"
                class="pr-2 pb-1 h-16"
              >
                <BaseButton
                  :disabled="false"
                  icon="PlusIcon"
                  :is-primary="false"
                  qa-class="add-exercise"
                  @click="createNewExercise"
                >
                  {{ $t('activerecord.models.exercise') }}
                </BaseButton>
                <div
                  v-if="addExerciseErrors.hasOwnProperty('name')"
                  class="flex flex-row flex-nowrap m-2"
                >
                  <p
                    class="text-red-500 text-base font-normal"
                  >
                    {{ $t('activerecord.attributes.exercise.name') }} {{ addExerciseErrors.name[0] }}
                  </p>
                </div>
              </th>
            </template>
          </draggable>
        </thead>
        <template
          v-for="cluster in competencyClusters"
          :key="cluster.id"
        >
          <draggable
            v-if="!cluster.default || withoutCompetencyCluster"
            :id="`competencyNameInputRow${cluster.id}`"
            v-model="cluster.competencies"
            :item-key="cluster.id.toString()"
            tag="tbody"
            :disabled="!isEditable"
            handle=".handle"
            ghost-class="dragging"
            v-bind="dragOptions"
            :move="checkMove"
            group="competencies"
            @start="drag = true"
            @end="onCompetencyMove($event, cluster.id)"
          >
            <template #item="{element: competency}">
              <tr
                class="dragItem bg-white qa-competency-or-cluster"
              >
                <td class="dragItem font-semibold hover:bg-gray-50">
                  <div v-if="showCompetenceModelSelect">
                    <BaseCheckbox
                      class="py-4 pl-4 hover:bg-gray-50"
                      label-classes="ph-no-capture text-gray-900 text-sm text-left leading-5 font-medium pr-4"
                      secondary-label-classes="text-red-500 font-normal"
                      :label="competency.name"
                      :disabled="false"
                      :model-value="isSelectedCompetencyMap.get(competency.id)"
                      @update:model-value="updateCompetency($event, competency.id)"
                    />
                  </div>
                  <div
                    v-else
                    class="inline-flex items-center w-full justify-between align-middle
                   min-h-16 pl-4 pr-1.5 py-2.5 min-w-[16rem]"
                  >
                    <div class="flex items-center space-x-1">
                      <i
                        v-show="isEditable"
                        class="material-icons-outlined text-center handle cursor-move text-gray-400"
                      >drag_indicator</i>
                      <p
                        class="flex items-center cursor-pointer text-gray-900 text-sm font-medium"
                        @click="editCompetency(competency)"
                      >
                        {{ competency.name }}
                      </p>
                    </div>
                    <MatrixWizardActions
                      v-if="isEditable"
                      @edit-click="editCompetency(competency)"
                      @delete-click="showDeleteModal({ type: 'Competency', object: competency })"
                    />
                  </div>
                </td>

                <td
                  v-for="exercise in exercisesWithRatingAnchor(competency)"
                  :key="exercise.id"
                  class="align-middle p-2"
                >
                  <MatrixWizardAnchorItem
                    v-if="exercise.ratingAnchor"
                    class="qa-anchor-button"
                    :rating-anchor="exercise.ratingAnchor"
                    :path="editRatingAnchorPath(exercise.ratingAnchor)"
                    :is-interactive="isInteractive"
                    qa-class="rating-anchor-item"
                  />
                  <MatrixWizardNewAnchorItem
                    v-else-if="isEditable && !exercise.ratingAnchor"
                    :path="createRatingAnchorPath(competency, exercise)"
                    :is-interactive="isInteractive"
                    qa-class="new-rating-anchor-item"
                    class="qa-anchor-button"
                    @anchor-dropped="copyRatingAnchor($event, exercise, competency)"
                  />
                  <div
                    v-else
                    class="border border-gray-100 rounded-lg m-2 p-2 h-12 flex"
                  />
                </td>
              </tr>
            </template>
            <template
              v-if="!cluster.default"
              #header
            >
              <tr>
                <td
                  class="py-3.5, pr-2 pl-4 h-12 bg-gray-50 min-w-[16rem]"
                >
                  <div class="inline-flex items-center w-full justify-between align-middle min-h-16 ">
                    <div
                      v-if="showCompetenceModelSelect"
                      class="-ml-4"
                    >
                      <TriStateCheckbox
                        :id="cluster.name"
                        :ref="cluster.name"
                        class="py-4 pl-4 text-sm leading-5 font-semibold text-left bg-gray-50"
                        :label="cluster.name"
                        :is-intermediate="someClusterCompetenciesSelected(cluster.id)"
                        :model-value="allClusterCompetenciesSelected(cluster.id)"
                        label-classes="ph-no-capture text-gray-900 text-sm leading-5 font-semibold pr-4"
                        @update:model-value="updateSelectCompleteCluster($event, cluster.id)"
                      />
                    </div>
                    <template v-else>
                      <p
                        class="text-sm font-semibold cursor-pointer pr-3 qa-cluster"
                        @click="editCompetencyCluster(cluster)"
                      >
                        {{ cluster.name }}
                      </p>

                      <div
                        v-if="isEditable"
                        class="flex space-x-2.5 items-center"
                      >
                        <div class="hover:bg-gray-200 rounded-full p-1.5 items-center">
                          <PlusIcon
                            class="h-5 w-5 text-gray-400 cursor-pointer"
                            aria-hidden="true"
                            @click="showAddCompetencyInput[cluster.id] = true"
                          />
                        </div>
                        <MatrixWizardActions
                          :cluster="{relativePosition: relativeClusterPosition(cluster.id)}"
                          @edit-click="editCompetencyCluster(cluster)"
                          @move-up-click="onCompetencyClusterMove(cluster, -1)"
                          @move-down-click="onCompetencyClusterMove(cluster, 1)"
                          @dissolve-clusters-click="showDissolveModal = true"
                          @delete-click="showDeleteModal({ type: 'Cluster', object: cluster })"
                        />
                      </div>
                    </template>
                  </div>
                </td>
                <td
                  v-for="exercise in exercises"
                  :key="exercise.id"
                  class="bg-gray-50"
                />
              </tr>
            </template>

            <template #footer>
              <tr
                v-if="isEditable &&(!cluster.default || withoutCompetencyCluster)"
                :ref="el => competencyInputRefs[cluster.id] = el"
              >
                <td>
                  <form
                    v-if="showAddCompetencyInput[cluster.id]"
                    :id="cluster.id.toString()"
                    class="flex flex-row flex-nowrap m-2 space-x-3 min-w-max"
                    @submit.prevent="addCompetency(cluster.id)"
                  >
                    <BaseInput
                      v-model="competencyNameInput[cluster.id]"
                      :trailing-icon-clickable="true"
                      :form="cluster.id.toString()"
                      :placeholder="$t('activerecord.models.competency')"
                      :focus="true"
                      :submit-on-enter="true"
                    >
                      <template #addon-button>
                        <BaseButton
                          type="submit"
                          :disabled="competencyNameInput[cluster.id] === ''
                            || competencyNameInput[cluster.id] === undefined"
                          :is-primary="false"
                          :form="cluster.id.toString()"
                          icon="PlusIcon"
                        />
                      </template>
                    </BaseInput>
                  </form>
                  <div
                    v-if="addCompetencyErrors[cluster.id]?.hasOwnProperty('name')"
                    class="flex flex-row flex-nowrap m-2"
                  >
                    <p
                      class="text-red-500"
                    >
                      {{ $t('activerecord.attributes.competency.name') }} {{ addCompetencyErrors[cluster.id]?.name[0] }}
                    </p>
                  </div>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
      </table>
      <div
        v-if="isEditable"
        ref="inputSelectRef"
        class="flex flex-row px-4 py-2 items-center overflow-visible min-w-max"
      >
        <MatrixWizardCompetencyInputSelect
          v-model="nameInput"
          :competence-model-id="competenceModel.id"
          :competency-cluster-id="competencyClusters[competencyClusters.length - 1].id"
          @created-competency-clusters="addCompetencyCluster($event)"
          @created-competency="propagateNewCompetency($event)"
          @error="scrollIntoViewIfNeeded(inputSelectRef, { block: 'center', inline: 'center' })"
        />
      </div>
    </div>

    <form
      id="add_exercise_form"
      @submit.prevent="addExercise"
    />

    <matrix-wizard-removal-modal
      v-if="removingObjectType && removingObject"
      :show="showRemoveModal"
      :competence-model-id="competenceModel.id"
      :reference-object-type="removingObjectType"
      :reference-object="removingObject"
      @removed="onObjectDeleted"
      @close="closeRemovalModal"
    />

    <matrix-wizard-dissolve-modal
      v-if="showDissolveModal"
      :competence-model-id="competenceModel.id"
      @dissolved="onDissolved"
      @close="showDissolveModal = false"
    />

    <matrix-wizard-exercise-modal
      v-if="modalExercise"
      :show="showExerciseModal"
      :is-editable="isEditable"
      :competence-model-id="competenceModel.id"
      :exercise-data="modalExercise"
      :show-duration-warning="competenceModel.belongsToAssessmentWithEvents"
      @exercise-saved="onExerciseSaved($event)"
      @exercise-saved-and-new="onExerciseSaved($event, 'saved-and-new')"
      @close="closeExerciseModal"
    />

    <matrix-wizard-competency-cluster-modal
      v-if="modalCompetencyCluster"
      :show="showCompetencyClusterModal"
      :is-editable="isEditable"
      :competence-model-id="competenceModel.id"
      :competency-cluster-data="modalCompetencyCluster"
      @competency-cluster-updated="onCompetencyClusterUpdated"
      @close="closeCompetencyClusterModal"
    />

    <matrix-wizard-competency-modal
      v-if="modalCompetency"
      :show="showCompetencyModal"
      :is-editable="isEditable"
      :competence-model-id="competenceModel.id"
      :competency-data="modalCompetency"
      @competency-updated="onCompetencyUpdated"
      @close="closeCompetencyModal"
    />
  </div>
</template>

<script>
import {
  computed, inject, ref, toRaw, onBeforeUpdate, watch,
} from 'vue';
import draggable from 'vuedraggable';
import { useI18n } from 'vue-i18n';
import scrollIntoViewIfNeeded from '@/util/scroll-into-view-if-needed';
import { posthog } from 'posthog-js';

import {
  competencyClusterPath,
  competenciesPath,
  competencyPath,
  exercisePath,
  exercisesPath,
  newRatingAnchorPath,
  dupRatingAnchorPath,
  ratingAnchorPath,
} from '@/util/url-helpers';
import {
  useMultiSelectCompetenciesAndCluster,
} from '@/util/multi-select-competencies-and-cluster';

import { PlusIcon } from '@heroicons/vue/outline';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import Axios from 'axios';
import {
  MatrixWizardCompetencyClusterModal,
  MatrixWizardCompetencyModal,
  MatrixWizardExerciseModal,
  MatrixWizardRemovalModal,
  MatrixWizardDissolveModal,
  MatrixWizardAnchorItem,
  MatrixWizardNewAnchorItem,
  MatrixWizardActions,
  MatrixWizardCompetencyInputSelect,
} from './MatrixWizard/index';

export default {
  name: 'CompetenceModelMatrixWizard',
  components: {
    draggable,
    MatrixWizardCompetencyClusterModal,
    MatrixWizardCompetencyModal,
    MatrixWizardExerciseModal,
    MatrixWizardRemovalModal,
    MatrixWizardDissolveModal,
    MatrixWizardAnchorItem,
    MatrixWizardNewAnchorItem,
    MatrixWizardActions,
    MatrixWizardCompetencyInputSelect,
    BaseInput,
    BaseButton,
    PlusIcon,
  },
  props: {
    competenceModelData: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    assessmentId: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCompetenceModelSelect: {
      type: Boolean,
      default: false,
    },
    selectedCompetencies: {
      type: Object,
      default: () => {},
    },
    isInteractive: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:competenceModelData', 'update:clusterModelData',
    'update:selected-competencies', 'update:rating-anchor'],

  setup(props, { emit }) {
    const toast = inject('toast');
    const { t } = useI18n();

    const competenceModel = ref(props.competenceModelData);
    const competencyClusterNameInput = ref('');
    const nameInput = ref('');
    const competencyNameInput = ref({});
    const showAddCompetencyInput = ref({});
    const modalExercise = ref(null);
    const modalCompetencyCluster = ref(null);
    const modalCompetency = ref(null);
    const showExerciseModal = ref(false);
    const showCompetencyClusterModal = ref(false);
    const showCompetencyModal = ref(false);
    const addCompetencyErrors = ref({});
    const addExerciseErrors = ref({});
    const showRemoveModal = ref(false);
    const showDissolveModal = ref(false);
    const removingObjectType = ref(null);
    const removingObject = ref(null);
    const drag = ref(false);
    const dragOptions = {
      animation: 200,
      disabled: false,
      draggable: '.dragItem',
    };
    const addExerciseColumn = ref(null);
    const inputSelectRef = ref(null);

    // created
    const exercises = ref(competenceModel.value.exercises.sort(
      (a, b) => a.position - b.position,
    ));
    const competencyClusters = ref(competenceModel.value.competencyClusters.sort(
      (a, b) => a.position - b.position,
    ));
    const competencies = ref(toRaw(competencyClusters.value).reduce(
      (accumulator, currentValue) => accumulator.concat(...currentValue.competencies),
      [],
    ));

    // computed
    const competencyBtnDisabled = computed(() => (index) => competencyNameInput.value[index] === '');

    const isEditable = computed(() => props.competenceModelData.isEditable
       && !props.disabled && !props.showCompetenceModelSelect);

    const withoutCompetencyCluster = computed(() => competencyClusters.value.filter(
      (cluster) => !cluster.default,
    ).length === 0);

    const competencyInputRefs = ref({});
    const exerciseRefs = ref({});

    onBeforeUpdate(() => {
      competencyInputRefs.value = {};
      exerciseRefs.value = {};
    });

    // methods

    // helper
    const getCompetencyClusterIndex = (competencyClusterId) => competencyClusters.value.findIndex(
      (cluster) => cluster.id === Number(competencyClusterId),
    );

    const swapArrayElements = (array, firstIndex, secondIndex) => {
      [array[firstIndex], array[secondIndex]] = [array[secondIndex], array[firstIndex]];
    };

    const relativeClusterPosition = (clusterId) => {
      const filteredList = competencyClusters.value.filter((cluster) => !cluster.default);
      const index = filteredList.findIndex((cluster) => cluster.id === Number(clusterId));
      if (index === 0) { return 'first'; }
      if (index === filteredList.length - 1) { return 'last'; }
      return index;
    };

    const removeById = (arr, id) => arr.filter((element) => element.id !== id);

    // modals
    const editCompetencyCluster = (cluster) => {
      modalCompetencyCluster.value = cluster;
      showCompetencyClusterModal.value = true;
    };

    const closeCompetencyClusterModal = () => {
      showCompetencyClusterModal.value = false;
      modalCompetencyCluster.value = null;
    };

    const editCompetency = (competency) => {
      modalCompetency.value = competency;
      showCompetencyModal.value = true;
    };
    const closeCompetencyModal = () => {
      showCompetencyModal.value = false;
      modalCompetency.value = null;
    };

    const editExercise = (exercise) => {
      modalExercise.value = exercise;
      showExerciseModal.value = true;
    };

    const closeExerciseModal = (exercise) => {
      showExerciseModal.value = false;

      if (exercise?.deletable) {
        Axios.delete(exercisePath(props.competenceModelData.id, exercise.id));
      }
      modalExercise.value = null;
    };

    const showDeleteModal = ({ type, object }) => {
      removingObjectType.value = type;
      removingObject.value = object;
      showRemoveModal.value = true;
    };
    const closeRemovalModal = () => {
      showRemoveModal.value = false;
      removingObjectType.value = null;
      removingObject.value = null;
    };

    // update state functions
    const emitCompetenceModel = () => {
      emit('update:competenceModelData', {
        ...props.competenceModelData,
        exercises: exercises.value,
        competencyClusters: competencyClusters.value,
      });
    };

    const propagateNewCompetency = (newCompetency) => {
      nameInput.value = '';

      const clusterIndex = competencyClusters.value.findIndex(
        (cluster) => cluster.id === newCompetency.competencyClusterId,
      );

      competencyClusters.value[clusterIndex].competencies.push({
        id: newCompetency.id,
        position: newCompetency.position,
        name: newCompetency.name,
        description: newCompetency.description,
        ratingAnchors: newCompetency.ratingAnchors,
        targetRange: newCompetency.targetRange,
        competencyClusterId: newCompetency.competencyClusterId,
      });
      emitCompetenceModel();
      posthog.capture('competencyCreated', { id: newCompetency.id, nameLength: newCompetency.name.length });
    };

    // cluster action functions
    const onCompetencyClusterMove = (competencyCluster, direction) => {
      const competencyClusterIndex = getCompetencyClusterIndex(competencyCluster.id);
      if (direction === -1 && competencyClusterIndex === 0) return;
      if (direction === 1 && competencyClusterIndex === competencyClusters.value.length - 1) return;

      const newPosition = competencyClusters.value[competencyClusterIndex + direction].position;
      const newCompetencyClusterIndex = competencyClusterIndex + direction;
      swapArrayElements(competencyClusters.value, competencyClusterIndex, newCompetencyClusterIndex);
      emitCompetenceModel();

      emitCompetenceModel();

      Axios.put(
        competencyClusterPath(props.competenceModelData.id, competencyCluster.id),
        {
          competency_cluster: {
            position: newPosition,
          },
        },
      );
    };

    const onDissolved = (competencyCluster) => {
      showDissolveModal.value = false;
      competencyClusters.value = competencyCluster;
      posthog.capture('competencyClusterDissolved');
    };

    // creates
    const addCompetency = (clusterId) => {
      const data = {
        competency: {
          name: competencyNameInput.value[clusterId],
          competence_model_id: props.competenceModelData.id,
          competency_cluster_id: clusterId,
        },
      };

      Axios.post(
        competenciesPath(props.competenceModelData.id),
        data,
      )
        .then((response) => {
          propagateNewCompetency(response.data);
          competencyNameInput.value[clusterId] = '';
          showAddCompetencyInput.value[clusterId] = false;
          addCompetencyErrors.value = {};

          scrollIntoViewIfNeeded(competencyInputRefs.value[clusterId], { block: 'center', inline: 'start' });
        })
        .catch((error) => {
          addCompetencyErrors.value = { [clusterId]: error.response.data };

          scrollIntoViewIfNeeded(competencyInputRefs.value[clusterId], { block: 'center', inline: 'start' });
        });
    };

    const addCompetencyCluster = (newCompetencyClusters) => {
      nameInput.value = '';
      competencyClusters.value.push(...newCompetencyClusters);
      const defaultClusterIndex = competencyClusters.value.findIndex((cluster) => cluster.default === true);
      competencyClusters.value[defaultClusterIndex].competencies = [];
      emitCompetenceModel();

      scrollIntoViewIfNeeded(inputSelectRef.value, { block: 'center', inline: 'center' });
      posthog.capture(
        'competencyClusterCreated',
        {
          id: newCompetencyClusters[newCompetencyClusters.length - 1].id,
          nameLength: newCompetencyClusters[newCompetencyClusters.length - 1].name.length,
        },
      );
    };

    const createNewExercise = () => {
      Axios.post(
        exercisesPath(props.competenceModelData.id),
        {
          exercise: {
            name: t('components.competence_model.matrix_wizard.exercise_modal.new.name'),
          },
        },
      )
        .then((response) => {
          editExercise({ ...response.data, deletable: true });
          posthog.capture('exerciseCreated', { id: response.data.id, nameLength: response.data.name.length });
        })
        .catch((error) => {
          const errorKey = Object.keys(error.response.data)[0];
          toast.error(`${errorKey} ${error.response.data[errorKey]}`);
        });
    };

    const onExerciseSaved = (exerciseData, eventType) => {
      let exerciseIndex = exercises.value.findIndex(
        (exercise) => exercise.id === exerciseData.id,
      );
      if (exerciseIndex === -1) { exerciseIndex = exercises.value.length; }

      exercises.value[exerciseIndex] = exerciseData;
      emitCompetenceModel();
      closeExerciseModal();

      if (eventType === 'saved-and-new') {
        createNewExercise();
      } else {
        scrollIntoViewIfNeeded(
          exerciseRefs.value[exerciseData.id] || addExerciseColumn.value,
          { block: 'center', inline: 'center' },
        );
      }
    };

    // updates
    const onCompetencyClusterUpdated = (clusterData) => {
      const clusterIndex = competencyClusters.value.findIndex(
        (cluster) => cluster.id === clusterData.id,
      );
      competencyClusters.value[clusterIndex] = clusterData;
      emitCompetenceModel();
      competencyClusterNameInput.value = '';
      closeCompetencyClusterModal();
      posthog.capture('competencyClusterUpdated', { id: clusterData.id, nameLength: clusterData.name.length });
    };

    const onCompetencyUpdated = (competencyData) => {
      const competencyClusterIndex = competencyClusters.value.findIndex(
        (cluster) => cluster.id === competencyData.competencyClusterId,
      );
      const competencyIndex = competencyClusters.value[competencyClusterIndex].competencies.findIndex(
        (competency) => competency.id === competencyData.id,
      );
      competencyClusters.value[competencyClusterIndex].competencies[competencyIndex] = competencyData;
      emitCompetenceModel();
      competencyNameInput.value[competencyData.competencyClusterId] = '';
      closeCompetencyModal();
    };

    const onObjectDeleted = ({ type, objectId, competencyClusterId }) => {
      if (type === 'Cluster') {
        competencyClusters.value = removeById(competencyClusters.value, objectId);
        posthog.capture('competencyClusterDeleted', { id: objectId });
      } else if (type === 'Exercise') {
        exercises.value = removeById(exercises.value, objectId);
      } else {
        const competencyClusterIndex = getCompetencyClusterIndex(competencyClusterId);

        competencyClusters.value[competencyClusterIndex]
          .competencies = removeById(competencyClusters.value[competencyClusterIndex].competencies, objectId);
      }
      emitCompetenceModel();
      closeRemovalModal();
    };

    const exercisesWithRatingAnchor = (competency) => {
      if (!competency.hasOwnProperty('ratingAnchors')) {
        return exercises;
      }
      return exercises.value.map((exercise) => ({
        ...exercise,
        ratingAnchor: competency.ratingAnchors.find(
          (ratingAnchor) => ratingAnchor.exerciseId === exercise.id,
        ),
      }));
    };

    const createRatingAnchorPath = (competency, exercise) => newRatingAnchorPath(
      props.organisationId,
      props.assessmentId,
      props.competenceModelData,
      exercise,
      competency,
    );

    const editRatingAnchorPath = (ratingAnchor) => ratingAnchorPath(
      props.organisationId,
      props.assessmentId,
      props.competenceModelData,
      ratingAnchor,
    );

    // draggable actually adjusts the newIndex by +1 if there is a header
    // slot included in <draggable/>. Since exercises have a header slot and
    // competencies don't, calculating the position here is somewhat different.
    const onExerciseMove = (e) => {
      drag.value = false;
      const { newIndex, oldIndex } = e;
      const position = newIndex;

      if (newIndex !== oldIndex) {
        const exercise = exercises.value[newIndex - 1];

        emitCompetenceModel();

        Axios.put(
          exercisePath(props.competenceModelData, exercise),
          {
            exercise: {
              position,
            },
          },
        );
      }
    };

    const onCompetencyMove = (e, oldCompetencyClusterId) => {
      drag.value = false;
      const { newDraggableIndex, oldIndex } = e;
      const position = newDraggableIndex + 1;

      // eslint-disable-next-line no-underscore-dangle
      const newCompetencyClusterId = e.to.__draggable_component__.itemKey;
      if (newDraggableIndex + 1 !== oldIndex || oldCompetencyClusterId !== newCompetencyClusterId) {
        const competencyClusterIndex = getCompetencyClusterIndex(newCompetencyClusterId);
        const competencyNew = competencyClusters.value[competencyClusterIndex].competencies[newDraggableIndex];
        competencyClusters.value[competencyClusterIndex].competencies[newDraggableIndex]
          .competencyClusterId = newCompetencyClusterId;

        const data = {
          competency: {
            competency_cluster_id: newCompetencyClusterId,
            position,
          },
        };

        emitCompetenceModel();

        Axios.put(competencyPath(props.competenceModelData.id, competencyNew), data);
      }
    };

    const checkMove = () => isEditable.value;

    const copyRatingAnchor = (sourceAnchorID, exercise, competency) => {
      Axios.post(
        dupRatingAnchorPath(props.competenceModelData.id, exercise, competency, sourceAnchorID),
      )
        .then((response) => {
          competency.ratingAnchors.push(response.data);
          emit('update:rating-anchor');
        });
    };

    // methods: selectClustersAndCompetencies
    const isSelectedCompetencyMap = ref(props.selectedCompetencies);

    const {
      lengthAllCompetencies,
      allCompetenciesSelected,
      someCompetenciesSelected,
      allClusterCompetenciesSelected,
      someClusterCompetenciesSelected,
      updateSelectAll,
      updateCompetency,
      updateSelectCompleteCluster,
    } = useMultiSelectCompetenciesAndCluster({ competencyClusters, competencies, isSelectedCompetencyMap });

    watch(isSelectedCompetencyMap, (_, changedIsSelectedCompetencyMap) => {
      emit('update:selected-competencies', changedIsSelectedCompetencyMap);
    }, { deep: true });

    return {
      competenceModel,
      competencyClusterNameInput,
      nameInput,
      competencyNameInput,
      showAddCompetencyInput,
      modalExercise,
      modalCompetencyCluster,
      modalCompetency,
      showExerciseModal,
      showCompetencyClusterModal,
      showCompetencyModal,
      addCompetencyErrors,
      addExerciseErrors,
      showRemoveModal,
      showDissolveModal,
      removingObjectType,
      removingObject,
      dragOptions,
      exercises,
      isEditable,
      competencyClusters,
      competencyInputRefs,
      exerciseRefs,
      addExerciseColumn,
      competencyBtnDisabled,
      withoutCompetencyCluster,
      inputSelectRef,

      relativeClusterPosition,
      editCompetencyCluster,
      onCompetencyClusterMove,
      onDissolved,
      addCompetency,
      addCompetencyCluster,
      createNewExercise,
      editCompetency,
      onExerciseSaved,
      onCompetencyClusterUpdated,
      onCompetencyUpdated,
      showDeleteModal,
      onObjectDeleted,
      checkMove,
      exercisesWithRatingAnchor,
      createRatingAnchorPath,
      onExerciseMove,
      onCompetencyMove,
      copyRatingAnchor,
      editRatingAnchorPath,
      closeCompetencyModal,
      closeRemovalModal,
      closeCompetencyClusterModal,
      closeExerciseModal,
      editExercise,
      propagateNewCompetency,
      scrollIntoViewIfNeeded,

      someCompetenciesSelected,
      allCompetenciesSelected,
      isSelectedCompetencyMap,
      lengthAllCompetencies,
      someClusterCompetenciesSelected,
      updateSelectAll,
      updateCompetency,
      updateSelectCompleteCluster,
      allClusterCompetenciesSelected,

    };
  },
};
</script>
