<template>
  <Card>
    <div class="p-6 border-b border-gray-100">
      <CardDescription
        :header="header"
        :helptext="helptext"
      />
    </div>
    <div class="w-full odd">
      <div
        v-for="element in elements"
        :key="element.label"
        class="w-full flex odd:bg-gray-50 p-6"
      >
        <span class="w-1/3 text-gray-500">{{ element.label }}</span>
        <span class="ph-no-capture w-2/3 whitespace-pre-wrap">{{ element.value }}</span>
      </div>
    </div>
  </Card>
</template>

<script>
import CardDescription from '@/components/generic/TwoColumnCard/CardDescription/CardDescription.vue';
import Card from '@/components/generic/Card/Card.vue';

export default {
  components: {
    Card,
    CardDescription,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    helptext: {
      type: String,
      required: true,
    },
    elements: {
      type: Array,
      required: true,
    },
  },
  setup() {

  },
};
</script>
