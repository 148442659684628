<template>
  <HeadlessMenu
    v-slot="{ open }"
    as="div"
  >
    <div>
      <slot
        name="button"
      />
    </div>
    <transition
      v-if="open"
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <MenuItems
        static
        :class="[itemClasses, withPopperOverlay ? '' :
          ['origin-top-right absolute right-0 mt-2 min-w-48 w-max rounded-md shadow-lg',
           'py-1 bg-white ring-1 ring-black/5 focus:outline-none z-20']]"
      >
        <slot />
      </MenuItems>
    </transition>
  </HeadlessMenu>
</template>

<script>
import { defineComponent } from 'vue';
import { Menu as HeadlessMenu, MenuItems } from '@headlessui/vue';

export default defineComponent({
  components: {
    HeadlessMenu,
    MenuItems,
  },
  props: {
    itemClasses: {
      type: String,
      default: '',
    },
    withPopperOverlay: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
