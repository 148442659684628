<template>
  <dropdown direction="right">
    <template #link>
      <div class="ml-4">
        <div
          class="flex hover:bg-gray-300 rounded-lg p-2 space-x-1"
        >
          <GlobeAltIcon class="h-6 w-6 text-gray-700" />
          <span class="text-justify mt-0.5 text-gray-700 text-sm font-medium">{{ $i18n.locale.toUpperCase() }}</span>
        </div>
      </div>
    </template>

    <template #dropdown-items>
      <div
        v-cloak
        class="text-center "
      >
        <a
          v-for="locale in locales"
          :key="locale"
          class="block w-full py-2 px-4 cursor-pointer hover:bg-gray-300 "
          @click="switchLanguage(locale)"
        >
          {{ getLocaleName(locale) }}
        </a>
      </div>
    </template>
  </dropdown>
</template>

<script>
import { GlobeAltIcon } from '@heroicons/vue/solid';
import dropdown from '@/components/RemoteSessionTool/RemoteSessionDropdown.vue';
import supportedLocales from '@//util/supported-locales';
import Axios from 'axios';

export default {
  name: 'LanguageSwitch',
  components: {
    dropdown,
    GlobeAltIcon,
  },
  props: {
    url: {
      type: String,
      default: '/remote/switch_locale',
    },
  },
  computed: {
    locales() {
      return [...this.$i18n.availableLocales].filter((locale) => locale !== this.$i18n.locale);
    },
  },
  methods: {
    getLocaleName(locale) {
      return supportedLocales[locale];
    },
    switchLanguage(locale) {
      Axios
        .put(this.url, { locale }, {
          withCredentials: true,
        })
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          this.$toast.error();
        });
    },
  },
};
</script>
