<template>
  <Card>
    <div :class="['flex flex-col md:flex-row p-6', customStyle]">
      <div class="w-full md:w-1/3 shrink-0 mr-4 ">
        <CardDescription
          :header="header"
          :helptext="helptext"
          :info-link="infoLink"
        />
      </div>
      <div class="grow min-w-0 mt-4 md:mt-0">
        <slot />
      </div>
    </div>
  </Card>
</template>

<script>
import { defineComponent } from 'vue';
import Card from '../Card/Card.vue';
import CardDescription from './CardDescription/CardDescription.vue';

export default defineComponent({
  name: 'TwoColumnCard',
  components: {
    Card,
    CardDescription,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    helptext: {
      type: String,
      default: '',
    },
    infoLink: {
      type: String,
      default: null,
    },
    customStyle: {
      type: String,
      required: false,
      default: '',
    },
  },
});
</script>
