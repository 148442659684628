/* eslint-disable no-underscore-dangle */
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { competenceModelTemplatePath } from '@/util/url-helpers';
import Axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const useRatingAnchorStore = defineStore('ratingAnchor', () => {
  const competenceModel = ref({});
  const ratingAnchor = ref(null);
  const submitting = ref(false);
  const errors = ref({});
  const exitPath = ref('');
  const defaultScaleLabels = ref([]);

  const setExitPath = (path) => { exitPath.value = path; };
  const setCompetenceModel = (model) => { competenceModel.value = model; };
  const setDefaultScaleLabels = (scaleLabels) => { defaultScaleLabels.value = scaleLabels; };

  const orderByValue = (scaleLabels) => scaleLabels.sort((a, b) => (a.value > b.value ? 1 : -1));

  const setRatingAnchor = (newRatingAnchor, behaviorAnchorsAttributes) => {
    ratingAnchor.value = newRatingAnchor;
    ratingAnchor.value.behavior_anchors_attributes = behaviorAnchorsAttributes;
    ratingAnchor.value.behavior_anchors_attributes.forEach((anchor) => {
      anchor._destroy = false;
      anchor.scale_labels_attributes = orderByValue(anchor.scale_labels);
      anchor.hasCustomScaleLabels = anchor.scale_labels.length > 0;
    });
  };

  const back = () => {
    if (window.history.length > 0) {
      window.history.back();
    } else {
      window.location = this.exitPath;
    }
  };

  const ratingAnchorPath = () => {
    if (ratingAnchor.value && ratingAnchor.value.id) {
      return `${competenceModelTemplatePath(competenceModel.value.id)}/rating_anchors/${ratingAnchor.value.id}`;
    }
    return `${competenceModelTemplatePath(competenceModel.value.id)}/rating_anchors/`;
  };

  const upsertRatingAnchor = () => {
    submitting.value = true;
    const data = ratingAnchor;
    const url = ratingAnchorPath(ratingAnchor);
    const method = ratingAnchor.value.id ? 'patch' : 'post';

    if (!ratingAnchor.value.id) {
      const urlParams = new URLSearchParams(window.location.search);
      data.value.competency_id = urlParams.get('competency_id');
      data.value.exercise_id = urlParams.get('exercise_id');
    }

    Axios({
      url,
      method,
      data: { rating_anchor: ratingAnchor.value },
      headers: {
        Accept: 'application/json',
      },
    })
      .then(() => {
        errors.value = {};
        back();
      }).catch((error) => {
        errors.value = {};
        errors.value = error.response.data;
        submitting.value = false;
      });
  };

  const deleteRatingAnchor = () => {
    Axios.delete(ratingAnchorPath(ratingAnchor))
      .then(() => {
        errors.value = {};
        back();
      })
      .catch(() => {
        errors.value = {};
        submitting.value = false;
      });
  };

  return {
    errors,
    submitting,
    setRatingAnchor,
    setExitPath,
    setDefaultScaleLabels,
    defaultScaleLabels,
    setCompetenceModel,
    ratingAnchor,
    upsertRatingAnchor,
    deleteRatingAnchor,
    orderByValue,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRatingAnchorStore, import.meta.hot));
}
