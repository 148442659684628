<template>
  <SearchableSelectList
    v-if="testInfo?.norms?.length > 0"
    id="test-select"
    v-model:selected="testOptions.norm"
    :options="testInfo.norms"
    :allow-reset="false"
    label-prop="name"
    value-prop="id"
    :label="$t('components.test_list.norm')"
    :disabled="testInfo.norms.length === 0"
    @update:selected="testOptions.norm = $event"
  />
  <SearchableSelectList
    v-if="testOptions?.norm != null && testInfo?.scales?.length > 0"
    id="test-select"
    v-model:selected="testOptions.scale"
    :options="testInfo.scales"
    :allow-reset="false"
    label-prop="name"
    value-prop="id"
    :label="$t('components.test_list.scale')"
    :disabled="testInfo.norms.length === 0"
    @update:selected="testOptions.scale = $event"
  />
  <div
    v-if="testInfo?.reports?.length > 0"
    class="space-y-4"
  >
    <label
      class="block text-sm font-medium text-gray-700"
    >
      Reports
    </label>
    <BaseCheckbox
      v-for="report in testInfo.reports"
      :key="report.id"
      :model-value="testOptions.selectedReportIds.includes(report.id)"
      :label="report.name"
      @update:model-value="updateReports($event, report.id)"
    />
  </div>
</template>

<script>
import BaseCheckbox from '@/components/generic/BaseCheckbox/BaseCheckbox.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import { catalogTestPath } from '@/util/url-helpers';
import Axios from 'axios';
import {
  defineComponent, inject, onMounted, ref, watch,
} from 'vue';

export default defineComponent({
  name: 'TestOptionsHogrefe',
  components: {
    SearchableSelectList,
    BaseCheckbox,
  },
  props: {
    test: {
      type: Object,
      default: null,
    },
    selectedTest: {
      type: Object,
      default: null,
    },
  },
  emits: ['isLoading', 'test-options-complete', 'update:testOptions'],
  setup(props, { emit }) {
    const toast = inject('toast');

    const testOptions = ref({
      norm: null, scale: null, selectedReportIds: [], subTestIds: [], testDuration: null,
    });
    const testInfo = ref(null);

    function updateReports(value, reportId) {
      if (value) {
        testOptions.value.selectedReportIds.push(reportId);
      } else {
        testOptions.value.selectedReportIds.splice(testOptions.value.selectedReportIds.indexOf(reportId), 1);
      }
    }

    const resetForm = () => {
      testOptions.value = {
        norm: null, scale: null, selectedReportIds: [], subTestIds: [], testDuration: null,
      };
    };

    const onTestSelected = async (testSelection) => {
      resetForm();

      if (testSelection === null) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        emit('isLoading', true);
        Axios.get(catalogTestPath(testSelection.testId, testSelection.testFormId, testSelection.testProviderName))
          .then((response) => {
            emit('isLoading', false);
            testInfo.value = response.data;
            resolve();
          })
          .catch(({ response }) => {
            toast.error(response.data.error);
            emit('isLoading', false);
            resolve();
          });
      });
    };

    watch(
      () => props.selectedTest,
      (selected, _) => {
        onTestSelected(selected);
      },
    );

    watch(
      () => testOptions,
      (updatedTestOptions) => {
        if (testOptions.value.norm != null
        && testOptions.value.scale != null
        && testOptions.value.selectedReportIds.length) {
          emit('test-options-complete', true);
        } else { emit('test-options-complete', false); }
        const options = { ...updatedTestOptions.value };

        const committedOptions = {
          norm: options.norm?.id,
          scale: options.scale?.id,
          report_ids: options.selectedReportIds,
          sub_test_ids: options.subTestIds,
          test_duration: options.testDuration,
        };

        emit('update:testOptions', committedOptions);
      },
      { deep: true },
    );

    onMounted(async () => {
      testOptions.value = {
        norm: null, scale: null, selectedReportIds: [], subTestIds: [], testDuration: null,
      };

      if (props.test) {
        onTestSelected({
          testId: props.test.metadata.testProductId,
          testFormId: props.test.metadata.testFormId,
          testProviderName: props.test.testProviderName,
        });
      } else if (props.selectedTest) { onTestSelected(props.selectedTest); }
    });

    watch(
      () => testInfo,
      (newTestInfo, _) => {
        if (props.test) { // edit test
          testOptions.value.norm = newTestInfo.value.norms.find(
            (norm) => norm.id === props.test.metadata.reports.normId,
          );
          testOptions.value.scale = newTestInfo.value.scales.find(
            (scale) => scale.id === props.test.metadata.reports.scale,
          );
          testOptions.value.selectedReportIds = [...props.test.metadata.reports.reportConfigurations];
        } else { // new test
          if (newTestInfo.value.subTests?.length > 0) {
            testOptions.value.subTestIds = newTestInfo.value.subTests.map((subTest) => subTest.id);
          }
          testOptions.value.testDuration = newTestInfo.value.testDuration;
          testOptions.value.norm = newTestInfo.value.norms.length === 1
            ? newTestInfo.value.norms[0] : null;
          testOptions.value.scale = newTestInfo.value.scales.length === 1
            ? newTestInfo.value.scales[0] : null;
          if (newTestInfo.value.reports?.length === 1) {
            updateReports(true, newTestInfo.value.reports[0].id);
          }
        }
      },
      { deep: true },
    );

    return {
      testInfo,
      testOptions,

      updateReports,
    };
  },
});
</script>
