<template>
  <div class="absolute top-0 right-0 left-0 z-10 pt-8 flex justify-center pointer-events-none">
    <div
      class="pages bg-primary-500 rounded-full px-2 py-1 text-white select-none"
    >
      {{ currentPage }} / {{ pageCount }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PDFPagination',
  props: {
    pageCount: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isScrolling: false,
  }),
  watch: {
    currentPage() {
      // fade in page counter
      const pages = this.$el.querySelector('.pages');
      if (pages === null) {
        return;
      }
      pages.classList.remove('fadeOut');
      pages.classList.add('fadeIn');
      // clear timeout when new scroll event appears
      if (this.isScrolling !== null) {
        clearTimeout(this.isScrolling);
      }
      // schedule new timeout (only happens when not cleared by new scrolling event)
      this.isScrolling = window.setTimeout(() => {
        pages.classList.add('fadeOut');
        pages.classList.remove('fadeIn');
        this.isScrolling = null;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.pages {
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.fadeIn {
  opacity: 0;
  animation-duration: 1s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeOut {
  animation-duration: 1s;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
