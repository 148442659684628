<template>
  <input
    type="hidden"
    :name="name"
    :value="selectedValue"
  >
  <RadioGroup
    v-bind="$attrs"
    v-model="selected"
    :disabled="disabled"
  >
    <RadioGroupLabel
      v-if="label"
      :class="[showLabel ? 'block text-sm font-medium text-gray-700 mb-1':'sr-only']"
    >
      {{ label }}
    </RadioGroupLabel>
    <div class="bg-white rounded-md -space-y-px">
      <RadioGroupOption
        v-for="(option, index) in options"
        :key="option[valueProp]"
        v-slot="{ checked, active }"
        as="template"
        :value="option"
        :class="qaClass !== '' ? `qa-${qaClass}-${option.value}`: ''"
      >
        <div
          :class="[
            index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
            index === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
            checked ? 'bg-primary-50 border-primary-200 z-10' : 'border-gray-200',
            'relative border p-4 flex cursor-pointer focus:outline-none']"
        >
          <span
            class="shrink-0"
            :class="[
              {
                'ring-2 ring-offset-2 ring-primary-500': active,
                'bg-primary-600 border-transparent': checked && !disabled,
                'bg-white': !checked && !disabled,
                'bg-primary-400 border-transparent': checked && disabled,
                'bg-gray-100': !checked && disabled,
              },
              checked ? 'border-transparent' : 'border-gray-300',
              'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']"
            aria-hidden="true"
          >
            <span
              v-show="checked"
              class="rounded-full bg-white w-1.5 h-1.5"
            />
          </span>
          <div class="ml-3 flex flex-col">
            <RadioGroupLabel
              as="span"
              :class="[checked ? 'text-primary-900' : 'text-gray-900', 'block text-sm font-medium']"
            >
              {{ option[labelProp] || option[fallbackLabelProp] }}
            </RadioGroupLabel>
            <RadioGroupDescription
              v-if="option[descriptionProp]"
              as="span"
              :class="[checked ? 'text-primary-700' : 'text-gray-500', 'block text-sm']"
            >
              {{ option[descriptionProp] }}
            </RadioGroupDescription>
          </div>
        </div>
      </RadioGroupOption>
    </div>
    <span
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </span>
  </RadioGroup>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
import { toRefs } from '@vueuse/core';

export default {
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  },
  props: {
    modelValue: {
      type: [Number, String],
      required: false,
      default: null,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      required: true,
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    labelProp: {
      type: String,
      default: 'name',
    },
    fallbackLabelProp: {
      type: String,
      default: 'name',
    },
    descriptionProp: {
      type: String,
      default: 'description',
    },
    name: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { valueProp, options, modelValue } = toRefs(props);

    const selectedValue = ref(modelValue.value);
    watch(modelValue, (newValue) => {
      selectedValue.value = newValue;
    });

    const selected = computed({
      get() {
        return options.value.find((option) => option[valueProp.value] === selectedValue?.value);
      },
      set(option) {
        const newValue = option[valueProp.value];
        selectedValue.value = newValue;
        emit('update:modelValue', newValue);
      },
    });

    return {
      selected,
      selectedValue,
    };
  },
};
</script>
