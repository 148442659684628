<template>
  <ConfirmDialogModal
    :open="show"
    :title="removeObjectTitle"
    :message="removeObjectText"
    :is-loading="disabled"
    :button-text="$t('defaults.delete')"
    @close="$emit('close')"
    @confirm="onRemove"
  />
</template>

<script>
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';

import { exercisePath, competencyPath, competencyClusterPath } from '@/util/url-helpers';
import Axios from 'axios';

export default {
  name: 'MatrixWizardRemovalModal',
  components: {
    ConfirmDialogModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    competenceModelId: {
      type: [Number, String],
      required: true,
    },
    referenceObject: {
      type: Object,
      required: true,
    },
    referenceObjectType: {
      type: String,
      required: true,
      validator(value) {
        return ['Exercise', 'Competency', 'Cluster'].indexOf(value) !== -1;
      },
    },
  },
  emits: ['close', 'removed'],
  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    removeObjectTitle() {
      return this.$t(
        `components.competence_model.matrix_wizard.removal_modal.title.${this.referenceObjectType.toLowerCase()}`,
        { name: this.referenceObject.name },
      );
    },

    removeObjectText() {
      return this.$t(
        `components.competence_model.matrix_wizard.removal_modal.text.${this.referenceObjectType.toLowerCase()}`,
      );
    },

    deletionPath() {
      switch (this.referenceObjectType) {
        case 'Cluster':
          return competencyClusterPath(this.competenceModelId, this.referenceObject);
        case 'Exercise':
          return exercisePath(this.competenceModelId, this.referenceObject);
        default:
          return competencyPath(this.competenceModelId, this.referenceObject);
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },

    onRemove() {
      this.disabled = true;

      Axios.delete(this.deletionPath)
        .then(() => {
          this.disabled = false;
          this.$emit('removed', {
            type: this.referenceObjectType,
            objectId: this.referenceObject.id,
            competencyClusterId: this.referenceObject.competencyClusterId,
          });
        })
        .catch((error) => {
          this.$emit('close');
          this.$toast.error(error);
        });
    },
  },
};
</script>
