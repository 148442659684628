<template>
  <CircularButton
    class="flex items-center justify-center mb-2 pointer-events-auto focus:ring-offset-neutral-500"
    :icon="icon"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </CircularButton>
</template>

<script>
import { defineComponent } from 'vue';
import CircularButton from '@/components/generic/CircularButton/CircularButton.vue';

export default defineComponent({
  name: 'ViewerControl',
  components: {
    CircularButton,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
});
</script>
