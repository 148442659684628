<!-- eslint-disable max-len -->
<template>
  <svg
    width="200"
    height="168"
    viewBox="0 0 200 168"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_630_5078)">
      <path
        d="M184.13 160H16.0226C13.1368 159.997 10.3702 158.849 8.3297 156.808C6.28918 154.768 5.14138 152.001 5.13812 149.116V17.0814C5.14047 14.9673 5.98133 12.9404 7.47622 11.4454C8.9711 9.95049 10.9979 9.10955 13.1121 9.10712H186.903C189.053 9.10957 191.115 9.96503 192.636 11.4858C194.157 13.0066 195.012 15.0685 195.015 17.2192V149.116C195.011 152.001 193.864 154.768 191.823 156.808C189.783 158.849 187.016 159.997 184.13 160V160Z"
        fill="#F2F2F2"
      />
      <path
        d="M26.5772 149.116H173.576C176.375 149.116 179.059 148.004 181.039 146.024C183.018 144.045 184.13 141.36 184.13 138.561V37.271C184.13 34.4718 183.018 31.7872 181.039 29.8078C179.059 27.8284 176.375 26.7164 173.576 26.7164H26.5772C23.7779 26.7164 21.0933 27.8284 19.114 29.8078C17.1346 31.7872 16.0226 34.4718 16.0226 37.271V138.561C16.0226 141.36 17.1346 144.045 19.114 146.024C21.0933 148.004 23.7779 149.116 26.5772 149.116Z"
        fill="white"
      />
      <path
        d="M194.863 16.4587H4.98535V10.9173C4.98953 8.02198 6.14202 5.24653 8.18996 3.19985C10.2379 1.15317 13.014 0.00240077 15.9094 0H183.939C186.834 0.00237809 189.611 1.15314 191.659 3.19983C193.706 5.24651 194.859 8.02197 194.863 10.9173V16.4587Z"
        fill="#CCCCCC"
      />
      <path
        d="M24.8382 11.0713C26.3987 11.0713 27.6638 9.80627 27.6638 8.24572C27.6638 6.68518 26.3987 5.4201 24.8382 5.4201C23.2776 5.4201 22.0126 6.68518 22.0126 8.24572C22.0126 9.80627 23.2776 11.0713 24.8382 11.0713Z"
        fill="white"
      />
      <path
        d="M35.5635 11.0713C37.1241 11.0713 38.3891 9.80627 38.3891 8.24572C38.3891 6.68518 37.1241 5.4201 35.5635 5.4201C34.003 5.4201 32.7379 6.68518 32.7379 8.24572C32.7379 9.80627 34.003 11.0713 35.5635 11.0713Z"
        fill="white"
      />
      <path
        d="M46.2889 11.0713C47.8494 11.0713 49.1145 9.80627 49.1145 8.24572C49.1145 6.68518 47.8494 5.4201 46.2889 5.4201C44.7283 5.4201 43.4633 6.68518 43.4633 8.24572C43.4633 9.80627 44.7283 11.0713 46.2889 11.0713Z"
        fill="white"
      />
      <path
        d="M37.3946 74.1832H30.1145C29.7197 74.1828 29.3412 74.0257 29.0621 73.7466C28.7829 73.4674 28.6259 73.0889 28.6254 72.6941V65.414C28.6259 65.0192 28.7829 64.6407 29.0621 64.3616C29.3412 64.0824 29.7197 63.9254 30.1145 63.9249H37.3946C37.7894 63.9254 38.1679 64.0824 38.447 64.3616C38.7262 64.6407 38.8832 65.0192 38.8837 65.414V72.6941C38.8832 73.0889 38.7262 73.4674 38.447 73.7466C38.1679 74.0257 37.7894 74.1828 37.3946 74.1832V74.1832Z"
        fill="#F97316"
      />
      <path
        d="M37.3946 93.0451H30.1145C29.7197 93.0446 29.3412 92.8876 29.0621 92.6084C28.7829 92.3293 28.6259 91.9508 28.6254 91.556V84.2759C28.6259 83.8811 28.7829 83.5026 29.0621 83.2234C29.3412 82.9443 29.7197 82.7872 30.1145 82.7868H37.3946C37.7894 82.7872 38.1679 82.9443 38.447 83.2234C38.7262 83.5026 38.8832 83.8811 38.8837 84.2759V91.556C38.8832 91.9508 38.7262 92.3293 38.447 92.6084C38.1679 92.8876 37.7894 93.0446 37.3946 93.0451Z"
        fill="#3F3D56"
      />
      <path
        d="M37.3946 111.907H30.1145C29.7197 111.907 29.3412 111.75 29.0621 111.47C28.7829 111.191 28.6259 110.813 28.6254 110.418V103.138C28.6259 102.743 28.7829 102.365 29.0621 102.085C29.3412 101.806 29.7197 101.649 30.1145 101.649H37.3946C37.7894 101.649 38.1679 101.806 38.447 102.085C38.7262 102.365 38.8832 102.743 38.8837 103.138V110.418C38.8832 110.813 38.7262 111.191 38.447 111.47C38.1679 111.75 37.7894 111.907 37.3946 111.907V111.907Z"
        fill="#CCCCCC"
      />
      <path
        d="M85.0459 70.5432H49.9692C49.5748 70.5423 49.1969 70.3851 48.9183 70.1059C48.6397 69.8267 48.4833 69.4484 48.4833 69.054C48.4833 68.6597 48.6397 68.2814 48.9183 68.0022C49.1969 67.723 49.5748 67.5658 49.9692 67.5649H85.0459C85.2417 67.5645 85.4357 67.6027 85.6167 67.6774C85.7977 67.752 85.9622 67.8617 86.1009 68C86.2395 68.1383 86.3494 68.3026 86.4244 68.4835C86.4995 68.6643 86.5381 68.8582 86.5381 69.054C86.5381 69.2499 86.4995 69.4438 86.4244 69.6246C86.3494 69.8055 86.2395 69.9698 86.1009 70.1081C85.9622 70.2464 85.7977 70.3561 85.6167 70.4307C85.4357 70.5054 85.2417 70.5436 85.0459 70.5432V70.5432Z"
        fill="#CCCCCC"
      />
      <path
        d="M85.0458 89.4051H49.9692C49.5748 89.4042 49.1969 89.247 48.9183 88.9678C48.6397 88.6887 48.4833 88.3104 48.4833 87.916C48.4833 87.5216 48.6397 87.1433 48.9183 86.8641C49.1969 86.585 49.5748 86.4277 49.9692 86.4269H85.0458C85.4408 86.4269 85.8195 86.5838 86.0988 86.863C86.3781 87.1423 86.535 87.5211 86.535 87.916C86.535 88.3109 86.3781 88.6897 86.0988 88.9689C85.8195 89.2482 85.4408 89.4051 85.0458 89.4051Z"
        fill="#CCCCCC"
      />
      <path
        d="M85.0459 108.267H49.9692C49.5748 108.266 49.1969 108.109 48.9183 107.83C48.6397 107.551 48.4833 107.172 48.4833 106.778C48.4833 106.384 48.6397 106.005 48.9183 105.726C49.1969 105.447 49.5748 105.29 49.9692 105.289H85.0459C85.2417 105.288 85.4357 105.327 85.6167 105.401C85.7977 105.476 85.9622 105.586 86.1009 105.724C86.2395 105.862 86.3494 106.027 86.4244 106.207C86.4995 106.388 86.5381 106.582 86.5381 106.778C86.5381 106.974 86.4995 107.168 86.4244 107.349C86.3494 107.529 86.2395 107.694 86.1009 107.832C85.9622 107.97 85.7977 108.08 85.6167 108.155C85.4357 108.229 85.2417 108.267 85.0459 108.267V108.267Z"
        fill="#CCCCCC"
      />
      <path
        d="M171.469 88.3889C171.506 87.7629 171.527 87.1326 171.527 86.4972C171.527 78.2188 168.239 70.2794 162.385 64.4257C156.531 58.572 148.592 55.2834 140.314 55.2834V88.3889H171.469Z"
        fill="#F97316"
      />
      <path
        d="M137.949 91.2265V58.121C131.856 58.1207 125.896 59.9037 120.804 63.2501C115.713 66.5965 111.712 71.36 109.295 76.9531C106.878 82.5462 106.151 88.7243 107.203 94.7257C108.255 100.727 111.041 106.289 115.217 110.726C119.392 115.164 124.775 118.282 130.702 119.696C136.629 121.11 142.839 120.759 148.569 118.686C154.298 116.613 159.295 112.908 162.945 108.028C166.594 103.149 168.735 97.3083 169.104 91.2265H137.949Z"
        fill="#3F3D56"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_630_5078"
        x="0.985352"
        y="0"
        width="198.029"
        height="168"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_630_5078"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_630_5078"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'AnalyticsGraphic',
  props: {
    color: {
      type: String,
      default: 'var(--color-primary-500)',
    },
  },
};
</script>
<!-- eslint-enable max-len -->
