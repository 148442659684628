<template>
  <div>
    <h3
      v-if="title"
      class="text-lg leading-6 font-medium text-gray-900"
    >
      {{ title }}
    </h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <slot />
    </dl>
  </div>
</template>

<script>
export default {
  name: 'StatsRow',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
