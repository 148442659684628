<!-- eslint-disable max-len -->
<template>
  <svg
    width="295"
    height="269"
    viewBox="0 0 295 269"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160.009 269C234.562 269 295 208.782 295 134.5C295 60.2177 234.562 0 160.009 0C85.4559 0 25.0186 60.2177 25.0186 134.5C25.0186 208.782 85.4559 269 160.009 269Z"
      fill="#F2F2F2"
    />
    <path
      d="M248.224 60.1476H0.939827C0.40957 60.1364 -0.0111173 59.6989 0.000224028 59.1706C0.0112199 58.6581 0.42553 58.2453 0.939827 58.2344H248.224C248.754 58.2457 249.175 58.6831 249.163 59.2115C249.152 59.7239 248.738 60.1367 248.224 60.1476Z"
      fill="#CACACA"
    />
    <path
      d="M163.95 92.3877H95.5908C90.4563 92.3877 86.2939 96.5349 86.2939 101.651V101.651C86.2939 106.767 90.4563 110.914 95.5908 110.914H163.95C169.084 110.914 173.247 106.767 173.247 101.651C173.247 96.5349 169.084 92.3877 163.95 92.3877Z"
      fill="white"
    />
    <path
      d="M51.4307 129.44H208.11C213.245 129.44 217.407 133.588 217.407 138.704C217.407 143.819 213.245 147.967 208.11 147.967H51.4307C46.2962 147.967 42.1338 143.819 42.1338 138.704C42.1338 133.588 46.2962 129.44 51.4307 129.44Z"
      fill="white"
    />
    <path
      d="M51.4307 166.493H208.11C213.245 166.493 217.407 170.64 217.407 175.756C217.407 180.872 213.245 185.019 208.11 185.019H51.4307C46.2962 185.019 42.1338 180.872 42.1338 175.756C42.1338 170.64 46.2962 166.493 51.4307 166.493Z"
      fill="white"
    />
    <path
      d="M72.5305 144.873C72.4235 144.877 72.3162 144.879 72.2091 144.882C72.1632 145 72.1137 145.118 72.0566 145.233L72.5305 144.873V144.873Z"
      fill="#2F2E41"
    />
    <path
      d="M76.1738 132.011C76.1947 132.141 76.2265 132.269 76.269 132.394C76.2515 132.263 76.2196 132.135 76.1738 132.011Z"
      fill="#2F2E41"
    />
    <path
      d="M159.917 153.524C161.155 138.625 150.036 125.547 135.083 124.314C120.129 123.081 107.004 134.16 105.766 149.059C104.529 163.958 115.648 177.036 130.601 178.269C145.554 179.502 158.68 168.423 159.917 153.524Z"
      :fill="color"
    />
    <path
      d="M139.978 140.012L132.842 147.122L125.705 140.012C124.55 138.861 122.677 138.861 121.522 140.012C120.366 141.163 120.366 143.029 121.522 144.18L128.658 151.291L121.522 158.401C120.368 159.553 120.369 161.42 121.526 162.57C122.68 163.718 124.55 163.718 125.705 162.57L132.842 155.459L139.978 162.57C141.135 163.72 143.008 163.718 144.162 162.566C145.315 161.415 145.315 159.552 144.162 158.401L137.025 151.291L144.162 144.18C145.317 143.029 145.317 141.163 144.162 140.012C143.007 138.861 141.134 138.861 139.978 140.012Z"
      fill="white"
    />
    <path
      d="M13.4855 48.0306C15.9677 48.0306 17.9799 45.9811 17.9799 43.4528C17.9799 40.9246 15.9677 38.875 13.4855 38.875C11.0034 38.875 8.99121 40.9246 8.99121 43.4528C8.99121 45.9811 11.0034 48.0306 13.4855 48.0306Z"
      fill="#3F3D56"
    />
    <path
      d="M29.0119 48.0306C31.4941 48.0306 33.5063 45.9811 33.5063 43.4528C33.5063 40.9246 31.4941 38.875 29.0119 38.875C26.5298 38.875 24.5176 40.9246 24.5176 43.4528C24.5176 45.9811 26.5298 48.0306 29.0119 48.0306Z"
      fill="#3F3D56"
    />
    <path
      d="M44.5373 48.0306C47.0195 48.0306 49.0316 45.9811 49.0316 43.4528C49.0316 40.9246 47.0195 38.875 44.5373 38.875C42.0552 38.875 40.043 40.9246 40.043 43.4528C40.043 45.9811 42.0552 48.0306 44.5373 48.0306Z"
      fill="#3F3D56"
    />
    <path
      d="M62.2408 47.071C62.1028 47.071 61.965 47.0177 61.8609 46.9117L58.8284 43.823C58.6262 43.6172 58.6262 43.2883 58.8284 43.0825L61.8609 39.994C62.0667 39.7851 62.4035 39.7817 62.6124 39.9857C62.8224 40.1902 62.826 40.5256 62.6207 40.7345L59.9517 43.4527L62.6207 46.1712C62.826 46.3801 62.8223 46.7155 62.6124 46.92C62.5091 47.0208 62.375 47.071 62.2408 47.071Z"
      fill="#3F3D56"
    />
    <path
      d="M67.3372 47.0714C67.203 47.0714 67.0688 47.0212 66.9655 46.9204C66.7556 46.7158 66.752 46.3808 66.9573 46.1716L69.626 43.4531L66.9573 40.7349C66.752 40.526 66.7556 40.1906 66.9655 39.9861C67.175 39.7813 67.5118 39.7852 67.7171 39.9944L70.7493 43.0828C70.9515 43.2887 70.9515 43.6175 70.7493 43.8234L67.7171 46.9121C67.613 47.0181 67.4752 47.0714 67.3372 47.0714Z"
      fill="#3F3D56"
    />
    <path
      d="M225.982 39.4053H220.344C219.701 39.4053 219.18 39.9242 219.18 40.5649V46.1885C219.18 46.8293 219.701 47.3482 220.344 47.3482H225.982C226.625 47.3482 227.152 46.8293 227.152 46.1885V40.5649C227.152 39.9242 226.625 39.4053 225.982 39.4053Z"
      fill="#3F3D56"
    />
    <path
      d="M212.165 39.4053H206.526C205.883 39.4053 205.362 39.9242 205.362 40.5649V46.1885C205.362 46.8293 205.883 47.3482 206.526 47.3482H212.165C212.808 47.3482 213.334 46.8293 213.334 46.1885V40.5649C213.334 39.9242 212.808 39.4053 212.165 39.4053Z"
      fill="#3F3D56"
    />
    <path
      d="M239.003 39.6699H233.364C232.721 39.6699 232.2 40.1888 232.2 40.8296V46.4532C232.2 47.0939 232.721 47.6128 233.364 47.6128H239.003C239.646 47.6128 240.172 47.0939 240.172 46.4532V40.8296C240.172 40.1888 239.646 39.6699 239.003 39.6699Z"
      fill="#3F3D56"
    />
    <path
      d="M154.012 41.8086H108.939C108.152 41.8086 107.52 42.444 107.52 43.2225C107.52 44.0009 108.152 44.6363 108.939 44.6363H154.012C154.793 44.6363 155.431 44.0008 155.431 43.2225C155.431 42.4441 154.793 41.8086 154.012 41.8086Z"
      fill="#3F3D56"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestAborted',
  props: {
    color: {
      type: String,
      default: 'var(--color-primary-500)',
    },
  },
};
</script>
<!-- eslint-enable max-len -->
