<template>
  <Disclosure
    v-slot="{ open }"
    as="nav"
    class="bg-white shadow z-50"
  >
    <div class="mx-auto px-4 2lg:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="shrink-0 flex items-center">
            <SidebarToggle
              v-if="hasSidebarToggle"
              class="lg:hidden mr-2"
            />
            <img
              class="block h-8 w-auto"
              :src="logoUrl"
              alt="Applysia"
            >
          </div>
          <div class="hidden 2lg:flex 2lg:ml-6 space-x-4 xl:space-x-8">
            <a
              v-for="link in navigationLinks"
              :key="`navigation-link-${link.path}`"
              :href="link.enabled ? link.path : null"
              :class="[
                'inline-flex items-center space-x-2 px-1 pt-1 border-b-2 text-sm font-medium',
                link.isCurrent
                  ? 'border-primary-500 text-gray-900'
                  : link.enabled ?
                    'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                    : 'border-transparent text-gray-300',
              ]"
              :target="link.target"
            >
              <span>{{ link.name }}</span>
              <NewFeatureBadge v-if="link.new" />
              <BetaFeatureBadge v-else-if="link.beta" />
              <Heroicon
                v-if="link.icon"
                :icon="link.icon"
                :aria-hidden="!link.icon"
                class="h-5 w-5 text-gray-500"
              />
            </a>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="hidden xl:ml-6 xl:flex xl:items-center relative">
            <UpdatesButton />
          </div>
          <div class="hidden 2lg:ml-6 2lg:flex 2lg:items-center relative">
            <Dropdown :dropdown-elements="userActions">
              <template #button>
                <MenuButton
                  class="bg-white flex  items-center text-sm rounded-full
                   2xl:rounded-md px-1 2xl:p-2 border-2 2xl:border-0 group border-gray-400 hover:border-gray-500
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 qa-user-actions"
                >
                  <span class="sr-only">
                    {{ $t('components.navbar.open_user_menu') }}
                  </span>
                  <span class="hidden 2xl:flex ph-no-capture font-medium qa-navbar-user-name">
                    {{ user.name }}
                  </span>
                  <span class="flex 2xl:hidden font-medium text-base text-gray-900">
                    {{ userInitials }}
                  </span>
                  <ChevronDownIcon class="block h-5 w-5 ml-1 text-gray-400 group-hover:text-gray-500" />
                </MenuButton>
              </template>
              <LinkDropdownItem
                v-for="(action, index) in userActions"
                :key="index"
                :href="action.path"
                :method="action.method"
                :class="action.qaClass"
                @click="resetPosthog(action.path)"
              >
                {{ action.name }}
              </LinkDropdownItem>
            </Dropdown>
          </div>
        </div>
        <div class="-mr-2 flex items-center 2lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500
              hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
          >
            <span class="sr-only">
              {{ $t('components.navbar.open_main_menu') }}
            </span>
            <MenuIcon
              v-if="!open"
              class="block h-6 w-6"
              aria-hidden="true"
            />
            <XIcon
              v-else
              class="block h-6 w-6"
              aria-hidden="true"
            />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="2lg:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <a
          v-for="link in navigationLinks"
          :key="`mobile-navigation-link-${link.path}`"
          :href="link.enabled ? link.path : ''"
          :class="[
            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
            link.isCurrent
              ? 'bg-primary-50 border-primary-500 text-primary-700'
              : link.enabled ?
                'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                : 'border-transparent text-gray-400',
          ]"
          :target="link.target"
        >
          <div class="inline-flex items-stretch space-x-2">
            <span>{{ link.name }}</span>
            <NewFeatureBadge v-if="link.new" />
            <BetaFeatureBadge v-else-if="link.beta" />
          </div>
        </a>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div class="px-4">
          <div class="text-base font-medium text-gray-800">
            {{ user.name }}
          </div>
          <div class="text-sm font-medium text-gray-500">
            {{ user.email }}
          </div>
        </div>
        <div class="mt-3 space-y-1">
          <a
            v-for="action in userActions"
            :key="`mobile-user-action-${action.path}`"
            :href="action.path"
            :data-turbo-method="action.method"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            @click="resetPosthog(action.path)"
          >{{ action.name }}</a>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import BetaFeatureBadge from '@/components/generic/FeatureBadges/BetaFeatureBadge.vue';
import NewFeatureBadge from '@/components/generic/FeatureBadges/NewFeatureBadge.vue';
import {
  adminTenantsPath,
  assessmentsPath, competenceModelTemplatesPath,
  destroyUserSessionPath,
  editUserRegistrationPath,
  insightsPath,
  organisationsPath,
  reportTemplatesPath,
  usersPath,
} from '@/util/url-helpers';
import {
  Disclosure, DisclosureButton, DisclosurePanel, MenuButton,
} from '@headlessui/vue';
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/vue/outline';
import { posthog } from 'posthog-js';
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Heroicon from '@/components/Heroicon.vue';
import SidebarToggle from './Sidebar/SidebarToggle.vue';
import UpdatesButton from './UpdatesButton.vue';
import Dropdown from './generic/Dropdown/Dropdown.vue';
import LinkDropdownItem from './generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';

export default {
  components: {
    SidebarToggle,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    MenuButton,
    XIcon,
    ChevronDownIcon,
    Dropdown,
    LinkDropdownItem,
    NewFeatureBadge,
    BetaFeatureBadge,
    UpdatesButton,
    Heroicon,
  },
  props: {
    logoUrl: {
      type: String,
      default: '/applysia-logo.png',
    },
    user: {
      type: Object,
      required: true,
    },
    hasSidebarToggle: {
      type: Boolean,
      default: false,
    },
    canSeeTenants: {
      type: Boolean,
      default: false,
    },
    insightsEnabled: {
      type: Boolean,
      default: false,
    },
    isSuperAdmin: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isObserver: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isSuperAdmin, isAdmin, isObserver } = toRefs(props);

    const updatesSlideOverIsOpen = ref(false);

    const { t } = useI18n();

    const navigationLinks = [];
    if (isAdmin.value) {
      navigationLinks.push({
        name: t('activerecord.models.assessment.other'),
        path: assessmentsPath(),
        isCurrent: window.location.pathname.startsWith(assessmentsPath()),
        enabled: true,
      });

      navigationLinks.push({
        name: t('activerecord.models.template.other'),
        path: competenceModelTemplatesPath(),
        isCurrent: new RegExp(
          `^(${reportTemplatesPath()}|${competenceModelTemplatesPath()})`
  + `(?!${assessmentsPath()}\\/\\d+${reportTemplatesPath()}$)`,
        ).test(window.location.pathname), // match templates but not assessment/templates
        enabled: true,
      });
      navigationLinks.push({
        name: 'Insights',
        path: insightsPath(),
        isCurrent: window.location.pathname.startsWith(insightsPath()),
        enabled: props.insightsEnabled,
      });
    }
    navigationLinks.push({
      name: t('activerecord.models.user.other'),
      path: usersPath(),
      isCurrent: window.location.pathname.startsWith(usersPath()),
      enabled: true,
    });
    navigationLinks.push({
      name: t('activerecord.models.organisation.other'),
      path: organisationsPath(),
      enabled: true,
      isCurrent: window.location.pathname.startsWith(organisationsPath()),
    });
    if (isSuperAdmin.value) {
      navigationLinks.push({
        name: t('activerecord.models.tenant.other'),
        path: adminTenantsPath(),
        isCurrent: window.location.pathname.startsWith(adminTenantsPath()),
        enabled: true,
      });
    }
    if (isObserver.value) {
      navigationLinks.push({
        name: t('components.navbar.observer_view'),
        path: '/observer_view',
        enabled: true,
        icon: 'ExternalLinkIcon',
        target: '_blank',
      });
    }

    const userActions = [
      {
        name: t('defaults.settings'),
        path: editUserRegistrationPath(),
        qaClass: 'qa-user-action-settings',
      },
      {
        name: t('defaults.logout'),
        path: destroyUserSessionPath(),
        method: 'delete',
        qaClass: 'qa-user-action-logout',
      },
    ];

    // reset user and deviceID(true)
    function resetPosthog(path) {
      if (path === destroyUserSessionPath() && posthog.persistence) {
        posthog.reset(true);
      }
    }

    const userInitials = computed(
      () => props.user.firstName.charAt(0).toUpperCase() + props.user.lastName.charAt(0).toUpperCase(),
    );

    return {
      navigationLinks,
      userActions,
      resetPosthog,
      updatesSlideOverIsOpen,
      userInitials,
    };
  },
};
</script>
