<template>
  <div>
    <BaseTable
      :columns="columns"
      :records="store.reportTemplates"
      :pages="pages"
      :emit-row-click="true"
      @on-row-click="showReportTemplatePreview"
    >
      <template #default="{ record: reportTemplate }">
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
          <div class="flex space-x-3">
            <p v-dompurify-html:mark="highlightedText(reportTemplate.name)" />
            <ExclamationIcon
              v-if="reportTemplate.error"
              class="h-5 w-5 text-red-500"
            />
            <Tooltip
              v-if="!reportTemplate.templateDocumentPdf && !reportTemplate.error"
            >
              <template #parent>
                <Spinner
                  size="w-5 h-5"
                />
              </template>

              {{ $t('components.report_templates.preview_tile.banner.waiting_for_file_pdf') }}
            </Tooltip>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {{ reportTemplate.namePlaceholder }}
        </td>
        <td
          v-if="!organisationId"
          v-dompurify-html:mark="highlightedText(reportTemplate?.organisation?.displayName)"
          class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm text-gray-500"
        />
        <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {{ reportTemplate.createdAt }}
        </td>
        <ReportTemplateActions
          :report-template="reportTemplate"
          :organisation-id="organisationId"
          @show-report-template-modal="showReportTemplatePreview(reportTemplate)"
          @edit-report-template-modal="$emit('edit-report-template-modal', $event)"
        />
      </template>
    </BaseTable>
    <ReportTemplatePreviewOverlay
      v-if="previewReportTemplate"
      :report-template="previewReportTemplate"
      @close="previewReportTemplateId = null"
    />
    <WebdavSessionUnlockModal />
    <WebdavEditingInfoModal />
  </div>
</template>

<script>
import ReportTemplatePreviewOverlay from '@/components/Templates/ReportTemplates/ReportTemplatePreviewOverlay/ReportTemplatePreviewOverlay.vue';
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import Spinner from '@/components/generic/Spinner/Spinner.vue';
import { useI18n } from '@/plugins/i18n';
import { ExclamationIcon } from '@heroicons/vue/outline';
import { computed, ref } from 'vue';
import { useSearchHighlighter } from '@/composables/useSearchHighlighter';
import WebdavSessionUnlockModal from '@/components/generic/EditInOffice/WebdavSessionUnlockModal.vue';
import WebdavEditingInfoModal from '@/components/generic/EditInOffice/WebdavEditingInfoModal.vue';
import { useReportTemplatesStore } from '../stores/reportTemplatesStore';
import ReportTemplateActions from './ReportTemplateActions/ReportTemplateActions.vue';

export default {
  name: 'ReportTemplatesTable',
  components: {
    BaseTable,
    ReportTemplateActions,
    ExclamationIcon,
    ReportTemplatePreviewOverlay,
    Spinner,
    WebdavSessionUnlockModal,
    WebdavEditingInfoModal,
  },
  props: {
    pages: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      default: null,
    },
  },
  emits: ['edit-report-template-modal', 'show-report-template-modal'],
  setup(props) {
    const { t } = useI18n();
    const store = useReportTemplatesStore();

    const { highlightedText } = useSearchHighlighter();

    const columns = [
      {
        key: 'name',
        name: t('activerecord.attributes.competence_model.name'),
        sortable: true,
      },
      {
        key: 'namePlaceholder',
        name: t('defaults.placeholder'),
        sortable: false,
      },
      ...(!props.organisationId ? [
        {
          key: 'organisation',
          name: t('activerecord.attributes.report_template.organisation'),
          sortable: false,
        },
      ] : []),
      {
        key: 'created_at',
        name: t('activerecord.attributes.assessment.date'),
        sortable: true,
      },
      {
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      },
    ];

    const previewReportTemplateId = ref(null);
    const previewReportTemplate = computed(() => {
      if (previewReportTemplateId.value) {
        return store.getReportTemplateById(previewReportTemplateId.value);
      }
      return null;
    });
    const showReportTemplatePreview = (reportTemplate) => {
      previewReportTemplateId.value = reportTemplate.id;
    };

    return {
      columns,
      showReportTemplatePreview,
      previewReportTemplateId,
      previewReportTemplate,
      store,
      highlightedText,
    };
  },
};
</script>
