<template>
  <BaseInput
    v-model="searchTerm"
    v-debounce:400ms="search"
    :debounce-events="['input']"
    :trailing-icon-clickable="true"
    :disabled="inputDisabled"
    leading-icon="SearchIcon"
    @keyup.exact.prevent.stop.enter="search"
    @keyup.exact.prevent.stop.esc="searchTerm = ''"
  >
    <template
      #trailing-icon
    >
      <Spinner v-if="inputDisabled" />
      <button
        v-if="hasSearchedBefore && !inputDisabled"
        class="transition-colors duration-200 cursor-pointer text-gray-300 hover:text-gray-500
          focus:outline-none focus:ring ring-primary-500 focus:text-gray-500 rounded-sm"
        @focus.stop
        @click.stop="clearSearch"
      >
        <XIcon
          class="h-5 w-5"
          aria-hidden="true"
        />
        <span class="sr-only">{{ $t('components.search_input.clear') }}</span>
      </button>
    </template>
  </BaseInput>
</template>

<script>
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import { XIcon } from '@heroicons/vue/solid';
import { onMounted, ref, toRefs } from 'vue';
import vueDebounce from 'vue-debounce';
import Spinner from '@/components/generic/Spinner/Spinner.vue';

export default {
  components: {
    BaseInput,
    XIcon,
    Spinner,
  },
  directives: {
    debounce: vueDebounce({ lock: true }),
  },
  props: {
    searchParam: {
      type: String,
      default: 'q',
    },
  },
  setup(props) {
    const { searchParam } = toRefs(props);

    const params = new URLSearchParams(window.location.search);
    const searchTerm = ref(params.get(searchParam.value) || '');

    const hasSearchedBefore = params.get(searchParam.value) !== null;
    const inputDisabled = ref(false);

    onMounted(() => {
      if (hasSearchedBefore) {
        const input = document.querySelector('input');
        input.focus();
      }
    });

    const clearSearch = () => {
      const newParams = new URLSearchParams(window.location.search);
      newParams.delete(searchParam.value);

      const url = new URL(window.location);
      url.search = newParams;

      Turbo.visit(url.toString());
    };

    const search = () => {
      inputDisabled.value = true;

      const newParams = new URLSearchParams(window.location.search);
      newParams.set(searchParam.value, searchTerm.value);

      const url = new URL(window.location);
      url.search = newParams;

      Turbo.visit(url.toString());
    };

    return {
      searchTerm,
      search,
      clearSearch,
      hasSearchedBefore,
      inputDisabled,
    };
  },
};
</script>
