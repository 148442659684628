<template>
  <Modal
    :open="open"
    outer-modal-classes="h-full px-0 pb-0 sm:px-4 sm:pb-4"
    inner-modal-classes="w-full h-full sm:min-w-3/4 md:min-w-0 md:max-w-4xl sm:h-3/4 mt-0"
    :hide-overflow="true"
    @close="close"
  >
    <div class="flex flex-col h-full">
      <div class="w-full pt-6 px-6 shrink-0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pr-3 pb-6">
          {{ $t('components.assessment_form.report_template_selection_modal.header') }}
        </h3>
        <BaseInput
          v-model="query"
          :trailing-icon-clickable="true"
          class="w-full"
          :placeholder="$t('defaults.search')"
          leading-icon="SearchIcon"
          @keyup.exact.prevent.stop.esc="query = ''"
        >
          <template #trailing-icon>
            <button
              v-if="query != ''"
              class="transition-colors duration-200 cursor-pointer text-gray-300 hover:text-gray-500
                     focus:outline-none focus:ring ring-primary-500 focus:text-gray-500 rounded-sm"
              @focus.stop
              @click.stop="query=''"
            >
              <XIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $t('components.search_input.clear') }}</span>
            </button>
          </template>
        </BaseInput>
      </div>
      <div class="flex-1 overflow-y-scroll overscroll-contain pl-6 pr-6 qa-report-template-list">
        <div
          v-for="reportTemplate in filteredReportTemplates"
          :key="reportTemplate.id"
        >
          <BaseCheckbox
            class="px-2 group hover:bg-gray-50"
            label-classes="ph-no-capture py-3.5 max-h-fit flex w-full justify-between
                 space-x-4 text-gray-900 text-sm font-normal leading-relaxed"
            secondary-label-classes="sm:flex hidden text-gray-500 text-sm font-normal leading-relaxed items-center"
            :secondary-label="reportTemplate.organisation.displayName"
            option-icon="EyeIcon"
            :disabled="false"
            :has-error="!!reportTemplate.error"
            :model-value="isSelected(reportTemplate.id)"
            qa-class="report-template-list-item"
            @update:model-value="updateReportTemplate($event, reportTemplate.id)"
            @click:option-icon="showReportTemplatePreview(reportTemplate)"
          >
            <template #label>
              <span>
                {{ `${reportTemplate.name}${reportTemplate.fileExtension}` }}
                <Badge
                  color="gray"
                  class="px-1 select-none"
                >
                  {{ reportTemplate.locale }}
                </Badge>
              </span>
            </template>
          </BaseCheckbox>
        </div>
      </div>

      <div class="bg-gray-50 py-3 px-2 flex flex-row justify-end">
        <div
          class="w-full px-1 sm:flex sm:w-1 sm:flex-row-reverse"
        >
          <BaseButton
            :is-disabled="isLoading || !isEditable"
            :is-loading="isLoading"
            class="mx-auto p-2 w-full justify-center rounded-md border border-transparent
           shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
            qa-class="save-report-template-button"
            @click="submitSelection"
          >
            {{ $t('defaults.save') }}
          </BaseButton>
          <BaseButton
            :is-primary="false"
            :disabled="isLoading || !isEditable"
            class="mt-3 w-full justify-center rounded-md border border-gray-300
           shadow-sm p-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="close"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </Modal>
  <ReportTemplatePreviewOverlay
    v-if="previewReportTemplate"
    :report-template="previewReportTemplate"
    @close="previewReportTemplate = null"
  />
</template>

<script>
import ReportTemplatePreviewOverlay from '@/components/Templates/ReportTemplates/ReportTemplatePreviewOverlay/ReportTemplatePreviewOverlay.vue';
import Modal from '@/components/generic/Modal/Modal.vue';
import { assignToAssessmentReportTemplatesPath } from '@/util/url-helpers';
import { XIcon } from '@heroicons/vue/outline';
import Axios from 'axios';
import { defineComponent, inject, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useReportTemplatesStore } from './stores/reportTemplatesStore';

export default defineComponent({
  name: 'ReportTemplateSelectionModal',
  components: {
    Modal,
    XIcon,
    ReportTemplatePreviewOverlay,
  },
  props: {
    availableReportTemplates: {
      type: Array,
      required: true,
    },
    selectedReportTemplatesList: {
      type: Array,
      default: () => [],
    },
    assessmentId: {
      type: Number,
      default: null,
      required: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'select-report-templates'],
  setup(props, { emit }) {
    const toast = inject('toast');
    const { t } = useI18n();
    const store = useReportTemplatesStore();

    const query = ref('');
    const isEditable = ref(true);

    const isLoading = ref(false);

    const filteredReportTemplates = ref(props.availableReportTemplates.map((template) => {
      template.checked = false;
      return template;
    }));

    const selectedReportTemplates = ref([...props.selectedReportTemplatesList]);

    function isSelected(id) {
      return selectedReportTemplates.value.map((templateId) => templateId).includes(id);
    }

    const filterByName = (template) => {
      const templateName = template.name.toLowerCase();
      const organisationName = template.organisation.displayName.toLowerCase();
      const queryValue = query.value.toLowerCase();
      return templateName.includes(queryValue) || organisationName.includes(queryValue);
    };

    watchEffect(() => {
      if (query.value === '') {
        filteredReportTemplates.value = props.availableReportTemplates;
      } else {
        filteredReportTemplates.value = props.availableReportTemplates.filter(filterByName);
      }
    });

    const updateReportTemplate = (isChecked, reportTemplateId) => {
      if (isChecked) {
        selectedReportTemplates.value.push(reportTemplateId);
      } else {
        const index = selectedReportTemplates.value.indexOf(reportTemplateId);
        if (index !== -1) {
          selectedReportTemplates.value.splice(index, 1);
        }
      }
    };

    const close = () => {
      selectedReportTemplates.value = [];
      isEditable.value = true;
      isLoading.value = false;
      emit('close');
    };

    const submitSelection = () => {
      if (props.assessmentId) {
        isEditable.value = false;
        isLoading.value = true;
        const data = {
          report_template_ids: selectedReportTemplates.value,
        };

        Axios.post(assignToAssessmentReportTemplatesPath(props.assessmentId), data)
          .then((response) => {
            const reportTemplates = response.data;
            store.addReportTemplates(reportTemplates);
            toast.success(t('components.report_templates.modal.add.success'));
            close();
          })
          .catch(({ response }) => {
            isEditable.value = true;
            isLoading.value = false;
            toast.error(response.data.error);
          });
      } else {
        emit('select-report-templates', selectedReportTemplates.value);
      }
    };

    const previewReportTemplate = ref(null);
    const showReportTemplatePreview = (reportTemplate) => {
      previewReportTemplate.value = reportTemplate;
    };

    return {
      query,
      isLoading,
      isEditable,
      filteredReportTemplates,
      selectedReportTemplates,
      previewReportTemplate,

      isSelected,
      updateReportTemplate,
      showReportTemplatePreview,

      close,
      submitSelection,
    };
  },
});
</script>
