// returns width of a string in pixels
export function getWidthOfText(text) {
  const tempElement = document.createElement('span');
  tempElement.style.visibility = 'hidden';
  tempElement.style.whiteSpace = 'nowrap';
  tempElement.innerText = text;

  document.body.appendChild(tempElement);
  const width = tempElement.offsetWidth;

  // remove the temp element after getting the width
  document.body.removeChild(tempElement);

  return width;
}

// returns a count of how many string elements can fit in a max width
export function countStringsToFitMaxWidth(strings, maxWidth) {
  let currentWidth = 0;
  let count = 0;

  for (let i = 0; i < strings.length; i += 1) {
    const currentString = `${strings[i]}, `;
    const stringWidth = getWidthOfText(currentString);

    if (currentWidth + stringWidth <= maxWidth) {
      currentWidth += stringWidth;
      count += 1;
    } else {
      break;
    }
  }

  return count;
}
