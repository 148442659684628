<template>
  <TwoColumnCard
    :header="$t('components.assessment.competence_model_form.categories.settings.header')"
  >
    <div
      class="flex justify-between items-center"
    >
      <div>
        <legend class="flex text-base font-medium text-gray-900 pr-4">
          <p>
            {{ $t('components.competence_model.form.settings.consolidation_in_conference.header') }}
          </p>
        </legend>
        <span class="text-sm text-gray-500">
          {{ $t('components.competence_model.form.settings.consolidation_in_conference.description') }}
          <a
            :href="$t('components.competence_model.form.settings.consolidation_in_conference.support_link')"
            class="text-primary-500 text-sm leading-5 inline-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('defaults.more_information') }}
          </a>
        </span>
      </div>
      <Toggle
        v-model="isEnabledConsolidationInConference"
        :disabled="!isEditable"
        qa-class="competence-model-consolidation"
        @update:model-value="updateCompetenceModelSettings"
      />
      <input
        type="hidden"
        name="competence_model[consolidation_in_conference_enabled]"
        :value="isEnabledConsolidationInConference"
      >
    </div>
  </TwoColumnCard>
</template>

<script>
import { defineComponent, inject, ref } from 'vue';
import { competenceModelSettingsPath } from '@/util/url-helpers';
import { useI18n } from 'vue-i18n';
import Axios from 'axios';

export default defineComponent({
  name: 'CompetenceModelSettings',
  components: {},
  props: {
    competenceModelId: {
      type: Number,
      default: undefined,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    consolidationInConferenceEnabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:competence-model-settings'],
  setup(props, { emit }) {
    const toast = inject('toast');
    const { t } = useI18n();

    const isEnabledConsolidationInConference = ref(props.consolidationInConferenceEnabled);

    const updateCompetenceModelSettings = async (value) => {
      if (props.competenceModelId) {
        const data = {
          competence_model: {
            consolidation_in_conference_enabled: isEnabledConsolidationInConference.value,
          },
        };

        try {
          const response = await Axios.put(
            competenceModelSettingsPath(props.competenceModelId),
            data,
          );

          isEnabledConsolidationInConference.value = value;
          emit('update:competence-model-settings', response.data);
        } catch (error) {
          toast.error(t('components.competence_model.form.settings.error'));
        }
      }
    };

    return {
      isEnabledConsolidationInConference,
      updateCompetenceModelSettings,
    };
  },
});
</script>
