<template>
  <template v-if="reportTemplatesFeatureEnabled">
    <page-heading>
      {{ $t('activerecord.models.report_templates.other') }}
      <template #actions>
        <div class="flex items-center space-x-4">
          <search-input />
          <UploadButton
            :accepted-mime-types="acceptedMimeTypes"
            icon="PlusIcon"
            qa-class="add-report-template-button"
            @uploaded-files="onFileUpload"
            @start-uploading-files="onFileUpload($event, 'pending')"
          />
        </div>
      </template>
    </page-heading>
    <div
      class="space-y-5"
    >
      <tab-bar
        v-if="!organisationId"
        :tabs="tabs"
      />
      <div
        :class="['relative',dragOver ? 'outline-4 outline-primary-500 rounded outline-dashed ' : '']"
        @dragover.prevent="dragOver=true"
        @dragenter.prevent="dragOver=true"
        @dragleave.prevent="dragOver=false"
        @drop.prevent="onFilesDrop"
      >
        <ReportTemplatesTable
          :pages="pages"
          :organisation-id="organisationId"
          class="mt-6"
          @edit-report-template-modal="openReportTemplateModal"
        />
        <div
          v-if="dragOver"
          class="absolute inset-0 bg-primary-200 opacity-50 rounded pointer-events-none"
        />
      </div>
    </div>
    <ReportTemplateModal
      v-if="showReportTemplateModal"
      :report-template="reportTemplate"
      :template-document="templateFile"
      :organisation-id="organisationId"
      :available-organisations="availableOrganisations"
      :upload-progress="uploadProgress"
      @close="closeReportTemplateModal"
    />
    <transition
      enter-active-class="transition-transform duration-250 ease-in-out"
      leave-active-class="transition-transform duration-250 ease-in-out"
      enter-from-class="translate-y-12"
      leave-to-class="translate-y-12"
      enter-to-class="translate-y-0"
      leave-from-class="translate-y-0"
    >
      <div
        v-show="dragOver"
        class="bg-primary-300 text-primary-800 p-2 rounded-full absolute bottom-2
           left-1/2 transform -translate-x-1/2  z-10 text-sm"
      >
        <p>{{ $t('components.report_templates.drop_to_upload') }}</p>
      </div>
    </transition>
  </template>
</template>

<script>
import { directUploadPath } from '@/util/url-helpers';
import { posthog } from 'posthog-js';
import { defineComponent, onUnmounted, ref, watch } from 'vue';

import { useI18n } from '@/plugins/i18n';
import { storeToRefs } from 'pinia';
import ReportTemplateModal from './ReportTemplateModal/ReportTemplateModal.vue';
import ReportTemplatesTable from './ReportTemplatesTable/ReportTemplatesTable.vue';
import UploadButton from './UploadButton.vue';
import { useReportTemplatesStore } from './stores/reportTemplatesStore';

export default defineComponent({
  name: 'ReportTemplates',
  components: {
    ReportTemplatesTable,
    ReportTemplateModal,
    UploadButton,
  },
  props: {
    initialReportTemplates: {
      type: Array,
      required: true,
    },
    pages: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      default: null,
    },
    availableOrganisations: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();

    const store = useReportTemplatesStore();
    store.setReportTemplates(props.initialReportTemplates);
    const { uploadProgress } = storeToRefs(store);

    const reportTemplate = ref(null);
    const templateFile = ref(null);
    const showReportTemplateModal = ref(false);
    const dragOver = ref(false);

    const { replacementReportTemplate } = storeToRefs(store);
    watch(replacementReportTemplate, (newReplacementReportTemplate, _) => {
      reportTemplate.value = newReplacementReportTemplate.reportTemplate;
      templateFile.value = newReplacementReportTemplate.replacementDocument;
      showReportTemplateModal.value = true;
    });

    store.subscribeUpdates({ organisationId: props.organisationId });

    onUnmounted(() => {
      store.unsubscribe();
    });

    const fileInputRef = ref(null);

    const tabs = [
      { name: t('activerecord.models.competence_model.one'), href: 'competence_model_templates', current: false },
      { name: t('activerecord.models.reports.one'), href: 'report_templates', current: true },
    ];
    const acceptedMimeTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ];

    const openFileExplorer = () => {
      fileInputRef.value.click();
    };

    const onFileUpload = (files, isPending = false) => {
      if (files.length > 0) {
        const [file] = files;
        templateFile.value = isPending ? { filename: file.name, content_type: file.type, isPending: true }
          : { filename: file.filename, content_type: file.content_type, signed_id: file.signed_id, isPending: false };
        showReportTemplateModal.value = true;
      }
    };

    const openReportTemplateModal = (template) => {
      reportTemplate.value = template;
      showReportTemplateModal.value = true;
    };

    const openReplaceReportTemplateModal = (template, uploadedFile) => {
      reportTemplate.value = template;
      showReportTemplateModal.value = true;
      templateFile.value = uploadedFile;
    };

    const closeReportTemplateModal = () => {
      reportTemplate.value = null;
      templateFile.value = null;
      showReportTemplateModal.value = false;
    };

    const onFilesDrop = async ({ dataTransfer }) => {
      const { files: droppedFiles } = dataTransfer;
      if (droppedFiles.length) {
        onFileUpload(droppedFiles, 'pending');
        const uploadedFiles = await store.uploadSelectedFile(
          [droppedFiles[0]],
          {
            acceptedMimeTypes,
          },
        );
        onFileUpload(uploadedFiles, false);
      }
      dragOver.value = false;
    };

    return {
      fileInputRef,
      directUploadPath,
      reportTemplate,
      showReportTemplateModal,
      acceptedMimeTypes,
      templateFile,
      tabs,

      reportTemplatesFeatureEnabled: (posthog.persistence && posthog.isFeatureEnabled('report-templates'))
      || window.moduleEnabled('reporting'),

      openFileExplorer,
      onFileUpload,
      openReportTemplateModal,
      closeReportTemplateModal,
      openReplaceReportTemplateModal,
      dragOver,
      onFilesDrop,
      uploadProgress,
    };
  },
});
</script>
