<template>
  <div class="relative">
    <div
      v-if="isLoaded"
      id="pdf"
      ref="pdf"
      class="h-full pdf-document active:cursor-grabbing qa-pdf-viewer"
    >
      <PDFPagination
        :page-count="pageCount"
        :current-page="currentPage"
        class="absolute top-0"
      />
      <div class="relative h-full w-full">
        <div
          id="viewerContainer"
          ref="container"
          class="absolute overflow-auto h-full w-full"
        >
          <div
            id="viewer"
            class="pdfViewer"
            @click.self="$emit('click-outside')"
          />
        </div>
      </div>
      <div class="absolute top-0 right-0 py-6 mr-6">
        <DownloadControl
          :is-downloadable="isDownloadable"
          :url="url"
          class=""
        />
      </div>
      <div class="absolute bottom-0 right-0 mr-6 mb-6 z-20">
        <FullscreenControl
          @fullscreen="fullscreen"
        />
        <ZoomControls
          @zoom-in="zoomIn"
          @zoom-out="zoomOut"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DownloadControl from '@/components/Viewers/Controls/DownloadControl.vue';
import FullscreenControl from '@/components/Viewers/Controls/FullscreenControl.vue';
import ZoomControls from '@/components/Viewers/Controls/ZoomControls.vue';
import PDFPagination from '@/components/Viewers/PDF/PDFPagination.vue';
import * as pdfjsLib from 'pdfjs-dist';
import { EventBus, PDFFindController, PDFLinkService, PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import { toRaw } from 'vue';

pdfjsLib.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/legacy/build/pdf.worker.min.mjs', import.meta.url)
  .toString();

export default {
  name: 'PDFDocument',
  components: {
    DownloadControl,
    ZoomControls,
    FullscreenControl,
    PDFPagination,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    isDownloadable: {
      type: Boolean,
      required: false,
      default: false,
    },
    textSelectable: {
      type: Boolean,
      required: false,
      default: true,
    },
    minScale: {
      type: Number,
      default: 0.25,
    },
    maxScale: {
      type: Number,
      default: 10.0,
    },
    defaultScale: {
      type: [String, Number],
      default: 'page-auto',
    },
    defaultScaleDelta: {
      type: Number,
      default: 1.1,
    },
    scrollPosition: {
      type: Number,
      default: 0,
    },
    watermark: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['click-outside', 'update:scrollPosition'],
  data: () => ({
    scale: 2,
    isLoaded: true,
    currentPage: 1,
    eventBus: null,
    pdfViewer: null,
    pageCount: 0,
    initialDimension: [0, 0],
  }),
  watch: {
    url() {
      this.initializePDF();
    },
  },
  created() {
    this.eventBus = new EventBus();
    toRaw(this.eventBus).on('pagechanging', (e) => {
      this.currentPage = toRaw(e).pageNumber;
    }, true);

    toRaw(this.eventBus).on('pagesinit', () => {
      if (this.defaultScale !== 'page-auto') {
        toRaw(this.pdfViewer).currentScaleValue = this.defaultScale;
      }

      this.$refs.container.scrollTop = this.scrollPosition;
    });

    if (this.watermark) {
      toRaw(this.eventBus).on('pagerendered', (event) => {
        this.addWatermark(event.source, this.watermark);
      });
    }

    this.linkService = new PDFLinkService({
      eventBus: toRaw(this.eventBus),
      externalLinkEnabled: true,
      externalLinkRel: 'noopener noreferrer nofollow',
      externalLinkTarget: 2, // Blank
    });

    this.pdfFindController = new PDFFindController({
      eventBus: toRaw(this.eventBus),
      linkService: toRaw(this.pdfLinkService),
    });
  },
  mounted() {
    this.initializePDF();
    this.$refs.container.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    this.$refs.container.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    initializePDF() {
      const { container } = this.$refs;
      this.removeHiddenCopyElement(container);

      this.pdfViewer = new PDFViewer({
        container,
        eventBus: toRaw(this.eventBus),
        textLayerMode: this.textSelectable ? 1 : 0,
        enableWebGL: true,
        linkService: toRaw(this.linkService),
      });
      toRaw(this.linkService).setViewer(toRaw(this.pdfViewer));

      const loadingTask = pdfjsLib.getDocument({
        url: this.url,
      });
      toRaw(loadingTask).promise.then((pdfDocument) => {
        this.pageCount = toRaw(pdfDocument).numPages;
        toRaw(this.pdfViewer).setDocument(toRaw(pdfDocument));
        toRaw(this.linkService).setDocument(toRaw(pdfDocument));
        toRaw(this.pdfFindController).setDocument(toRaw(pdfDocument));
      });
    },
    zoomIn() {
      let newScale = toRaw(this.pdfViewer).currentScale;
      newScale = (newScale * this.defaultScaleDelta).toFixed(2);
      newScale = Math.ceil(newScale * 10) / 10;
      newScale = Math.min(this.maxScale, newScale);
      if (newScale < this.maxScale) {
        toRaw(this.pdfViewer).currentScaleValue = newScale;
      }
    },
    zoomOut() {
      let newScale = toRaw(this.pdfViewer).currentScale;
      newScale = (newScale / this.defaultScaleDelta).toFixed(2);
      newScale = Math.floor(newScale * 10) / 10;
      newScale = Math.max(this.minScale, newScale);
      if (newScale > this.minScale) {
        toRaw(this.pdfViewer).currentScaleValue = newScale;
      }
    },
    fullscreen() {
      if (toRaw(this.pdfViewer).currentScaleValue === 'page-width') {
        toRaw(this.pdfViewer).currentScaleValue = 'page-height';
      } else {
        toRaw(this.pdfViewer).currentScaleValue = 'page-width';
      }
    },
    // Remove hiddenCopyElement from the DOM, is added each time: https://github.com/mozilla/pdf.js/pull/16298
    removeHiddenCopyElement(container) {
      const hiddenCopyElement = container.querySelector('#hiddenCopyElement');
      if (!!container && !!hiddenCopyElement) {
        container.removeChild(hiddenCopyElement);
      }
    },
    handleScroll(e) {
      this.$emit('update:scrollPosition', e.target.scrollTop);
    },
    calculateFontSize(context, text, canvasWidth) {
      const maxWidth = canvasWidth * 0.9; // Max width is 90% of canvas width
      const scale = toRaw(this.pdfViewer).currentScale;
      let fontSize = 100 * scale; // Ensure that the font size is proportional to the scale

      // Set a temporary large font size to measure the text width
      context.font = `bold ${fontSize}px sans-serif`;

      const textWidth = context.measureText(text).width;

      // Calculate the optimal font size proportionally
      if (textWidth > maxWidth) {
        fontSize *= maxWidth / textWidth;
      }

      // Ensure the font size does not fall below a minimum threshold
      return Math.max(fontSize, 10);
    },
    addWatermark(pageView, watermark) {
      const { canvas } = pageView;
      const context = canvas.getContext('2d');

      const watermarkText = watermark;

      // Calculate the appropriate font size based on the canvas width
      const fontSize = this.calculateFontSize(context, watermarkText, canvas.width);

      // Set watermark properties with the calculated font size
      context.save();
      context.font = `bold ${fontSize}px sans-serif`;
      context.fillStyle = 'rgba(100, 100, 100, 0.1)';
      context.textAlign = 'center';
      context.translate(canvas.width / 2, canvas.height / 2); // Move to center of canvas
      context.rotate(-Math.PI / 4); // Rotate 45 degrees (counter-clockwise)
      context.fillText(watermarkText, 0, 0); // Draw the watermark
      context.restore();
    },
  },
};
</script>

<style>
.pdf__spinner {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
}

.pdfViewer .page {
  margin-bottom: 0.5rem !important;
}

.annotationLayer {
  position: absolute;
  left: 0rem;
  top: 0rem;
  pointer-events: none;
}

.annotationLayer a {
    pointer-events: all;
}

.textLayer {
  max-height: 100%;
  max-width: 100%;
}

canvas {
  max-width: 100%;
}
</style>
