<template>
  <ActionDropdownItem
    :disabled="!document"
    :class="['flex flex-row', document ? 'cursor-pointer' : '']"
    @click="downloadReportExampleDocument()"
  >
    <div class="mr-4">
      <DownloadIcon
        v-if="document"
        :class="['h-5 w-5', document ? 'text-gray-500' : 'text-gray-300']"
        aria-hidden="true"
      />
      <RefreshIcon
        v-else
        :class="['h-5 w-5', 'text-gray-300 animate-spin']"
        aria-hidden="true"
      />
    </div>
    <span
      :class="[
        document ? 'text-gray-700': 'text-gray-300'
      ]"
    >
      <slot />
    </span>
  </ActionDropdownItem>
</template>
<script>
import { defineComponent, toRefs } from 'vue';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import { DownloadIcon, RefreshIcon } from '@heroicons/vue/solid';
import download from '@/util/download';

export default defineComponent({
  components: {
    ActionDropdownItem,
    DownloadIcon,
    RefreshIcon,
  },
  props: {
    document: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  setup(props) {
    const { document } = toRefs(props);

    const downloadReportExampleDocument = () => {
      if (document.value) {
        download({
          path: document.value.url,
          filename: document.value.filename,
        });
      }
    };

    return {
      downloadReportExampleDocument,
    };
  },
});
</script>
