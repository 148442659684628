<template>
  <TwoColumnCard
    :header="$t('components.edit_user_form.categories.access.header')"
    :helptext="$t('components.edit_user_form.categories.access.helptext')"
    :info-link="$t('components.edit_user_form.categories.access.info_link')"
  >
    <div class="space-y-5">
      <MultiSelectComboBox
        v-model:selected-options="organisationMemberships"
        class="sm:col-span-1"
        :options="organisations"
        :disabled="!hasAdminRoleSelected"
        :label="$t('components.edit_user_form.categories.access.list_label')"
        secondary-option-label="unit"
        @update:selected-options="organisationMemberships.value = $event"
      />
      <span
        v-if="!hasAdminRoleSelected"
        class="text-sm text-gray-500"
      >
        {{ $t('components.edit_user_form.categories.access.missing_admin_info') }}
      </span>
    </div>
    <template v-if="hasAdminRoleSelected && organisationMemberships.length >= 1">
      <input
        v-for="organisation in organisationMemberships"
        :key="organisation.id"
        type="hidden"
        name="user[organisation_ids][]"
        :value="organisation.id"
      >
    </template>
    <input
      v-else
      type="hidden"
      name="user[organisation_ids][]"
      value="_destroy"
    >
  </TwoColumnCard>
</template>

<script>
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import { ref } from 'vue';

export default {
  name: 'OrganisationAccessCard',
  components: {
    TwoColumnCard,
  },
  props: {
    organisationMembershipIds: {
      type: Array,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
    hasAdminRoleSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const organisationMemberships = ref(
      props.organisations.filter((org) => props.organisationMembershipIds.includes(org.id)) || [],
    );

    return {
      organisationMemberships,
    };
  },
};
</script>
