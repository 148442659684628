<template>
  <Badge :color="statusColor">
    {{ $t(`activerecord.enums.assessment.status.${status}`) }}
  </Badge>
</template>

<script>
import { computed, toRefs } from 'vue';
import Badge from '@/components/generic/Badge/Badge.vue';

export default {
  components: {
    Badge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { status } = toRefs(props);

    const statusColor = computed(() => {
      switch (status.value) {
        case 'active':
          return 'green';
        case 'completed':
          return 'blue';
        case 'insights_archived':
          return 'purple';
        case 'draft':
        default:
          return 'gray';
      }
    });

    return {
      statusColor,
    };
  },
};
</script>
