<template>
  <BaseTableActions>
    <template #menu-items>
      <LinkDropdownItem
        :href="deletePath"
        method="delete"
        :remote="true"
        class="flex flex-row"
      >
        <div class="mr-4">
          <TrashIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-red-700">{{ $t('defaults.delete') }}</span>
      </LinkDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import { defineComponent, toRefs } from 'vue';
import { TrashIcon } from '@heroicons/vue/solid';
import { organisationMembershipPath } from '@/util/url-helpers';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';

export default defineComponent({
  components: {
    TrashIcon,
    LinkDropdownItem,
    BaseTableActions,
  },
  props: {
    organisationMembership: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { organisationMembership } = toRefs(props);

    const deletePath = organisationMembershipPath(
      organisationMembership.value.organisationId,
      organisationMembership.value,
    );

    return {
      deletePath,
    };
  },
});
</script>
