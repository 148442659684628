<template>
  <div class="pointer-events-none flex flex-row-reverse">
    <div class="self-end">
      <ViewerControl
        v-if="fullscreen"
        @click="toggleFullscreen"
      >
        <!-- https://github.com/tailwindlabs/heroicons/pull/144/files -->
        <!-- eslint-disable -->
        <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" aria-hidden="true">
          <path d="M15.0001 19V15M15.0001 15H19.0001M15.0001 15L20.0001 20M9 19V15M9 15H5M9 15L4 20M15.0001 5V9M15.0001 9H19.0001M15.0001 9L20.0001 4M9 9L4 4M9 9V5M9 9H5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <!-- eslint-enable -->
      </ViewerControl>
      <ViewerControl
        v-else
        icon="ArrowsExpandIcon"
        @click="toggleFullscreen"
      />
    </div>
  </div>
</template>

<script>
import ViewerControl from './ViewerControl.vue';

export default {
  components: {
    ViewerControl,
  },
  emits: ['fullscreen'],
  data: () => ({
    fullscreen: false,
  }),
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.$emit('fullscreen', this.fullscreen);
    },
  },
};
</script>
