import ToastNotification from '@/components/ToastNotification.vue';
import EventBus from '@/util/event-bus';
import mount from '@/util/mount-component';
import { getCurrentVueApp } from '@/main';
import getMetaPropertyContent from '@/util/getMetaPropertyContent';

const NOTIFICATION_CONTAINER_ID = 'notification-container';

let notificationContainer;

const createNotificationContainer = () => {
  const element = document.createElement('div');
  element.id = NOTIFICATION_CONTAINER_ID;

  element.classList.add(
    'fixed',
    'right-6',
    'bottom-6',
    'p-8',
    'overflow-hidden',
    'pointer-events-none',
    'flex-col-reverse',
  );
  element.style.zIndex = '3000000000';
  document.body.appendChild(element);
  notificationContainer = element;
};

const show = (message, propsData) => {
  if (!document.querySelector(`#${NOTIFICATION_CONTAINER_ID}`)) createNotificationContainer();

  const element = document.createElement('div');
  notificationContainer.prepend(element);
  const fragment = document.createDocumentFragment();

  const props = { message, ...propsData };
  const c = mount(ToastNotification, {
    props,
    app: getCurrentVueApp(),
    element: fragment,
  });

  element.parentNode.replaceChild(fragment, element);

  return c;
};

const success = (message, props) => show(message, { ...props, type: 'success' });
const error = (message, props) => show(message, { ...props, type: 'error' });
const warn = (message, props) => show(message, { ...props, type: 'warn' });
const notice = (message, props) => show(message, { ...props, type: 'notice' });

const closeAll = () => EventBus.emit('clear-toast-notification');

export const useNotifications = (globalProps = {}) => ({
  success: (message, props) => success(message, { ...globalProps, ...props }),
  error: (message, props) => error(message, { ...globalProps, ...props }),
  warn: (message, props) => warn(message, { ...globalProps, ...props }),
  notice: (message, props) => notice(message, { ...globalProps, ...props }),
  closeAll,
});

// Load and show notifications that were set to meta tags in layouts/_notifications.html.erbn
export const loadNotificationsFromDom = () => {
  const notifications = {
    success: getMetaPropertyContent('applysia:success'),
    error: getMetaPropertyContent('applysia:error'),
    warn: getMetaPropertyContent('applysia:warn'),
    notice: getMetaPropertyContent('applysia:notice'),
  };
  if (notifications?.success) success(notifications?.success);
  if (notifications?.error) error(notifications?.error);
  if (notifications?.warn) warn(notifications?.warn);
  if (notifications?.notice) notice(notifications?.notice);
};

export default {
  install(Vue, globalOptions = {}) {
    // eslint-disable-next-line no-param-reassign
    Vue.config.globalProperties.$toast = useNotifications(globalOptions);
    Vue.provide('toast', Vue.config.globalProperties.$toast);
  },
};
