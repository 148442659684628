/* eslint-disable no-underscore-dangle */
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRatingAnchorStore } from './ratingAnchorStore';

// eslint-disable-next-line import/prefer-default-export
export const useBehaviorAnchorsStore = defineStore('behaviorAnchors', () => {
  const ratingAnchorStore = useRatingAnchorStore();
  const { ratingAnchor, defaultScaleLabels } = storeToRefs(ratingAnchorStore);

  const addBehaviorAnchor = ({ description = '' } = {}) => {
    ratingAnchor.value.behavior_anchors_attributes.push({
      description,
      rating_anchor_id: ratingAnchor.value.id,
      hasCustomScaleLabels: false,
      scale_labels_attributes: [],
    });
  };

  const notDestroyedBehaviorAnchors = computed(() => ratingAnchor.value?.behavior_anchors_attributes
    ?.filter((anchor) => !anchor._destroy)) || [];

  const deleteBehaviorAnchor = (behaviorAnchor) => {
    behaviorAnchor._destroy = true;
  };

  const groupByAdjacent = (items, key) => items.reduce((result, item) => {
    // If the last group exists and the current item has the same key value
    if (result.length > 0 && result[result.length - 1][0][key] === item[key]) {
      result[result.length - 1].push(item);
    } else {
      result.push([item]); // Start a new group
    }
    return result;
  }, []);

  const findCustomScaleLabel = (behaviorAnchor, scaleValue) => behaviorAnchor.scale_labels_attributes
    .find((label) => label.value === scaleValue);

  const findNotDestroyedCustomScaleLabel = (behaviorAnchor, scaleValue) => behaviorAnchor.scale_labels_attributes
    .find((label) => label.value === scaleValue && !label._destroy);

  const middleScaleLabelValue = () => {
    const average = defaultScaleLabels.value.slice(-1)[0].value / 2;

    return Number.isInteger(average) ? null : Math.round(average);
  };

  const customScaleLabelGroupsFor = computed(() => (behaviorAnchor) => {
    const currentBehaviorAnchor = ratingAnchor.value.behavior_anchors_attributes
      .find((anchor) => anchor === behaviorAnchor);

    const customScaleLabelItems = defaultScaleLabels.value.map((label) => {
      const customScaleLabel = findNotDestroyedCustomScaleLabel(currentBehaviorAnchor, label.value);

      const large = label.value === middleScaleLabelValue()
          || label.value === defaultScaleLabels.value[0].value
          || label.value === defaultScaleLabels.value.slice(-1)[0].value;

      return {
        scaleValue: label.value,
        exists: !!customScaleLabel,
        description: customScaleLabel?.description,
        large,
      };
    });

    const groups = groupByAdjacent(customScaleLabelItems, 'exists').map((group) => ({
      items: group,
      isLastNotExisting: false,
    }));

    const isLastNotExistingIndex = groups.findLastIndex((group) => !group.items[0].exists);
    if (isLastNotExistingIndex !== -1) {
      groups[isLastNotExistingIndex].isLastNotExisting = true;
    }

    return groups;
  });

  const showAddAllCustomScaleLabelsButton = computed(() => (behaviorAnchor) => {
    const currentBehaviorAnchor = ratingAnchor.value.behavior_anchors_attributes
      .find((anchor) => anchor === behaviorAnchor);
    return currentBehaviorAnchor.scale_labels_attributes.length < defaultScaleLabels.value.length;
  });

  const addCustomScaleLabel = (behaviorAnchor, scaleValue) => {
    const currentBehaviorAnchor = ratingAnchor.value.behavior_anchors_attributes
      .find((anchor) => anchor === behaviorAnchor);

    const customScaleLabel = findCustomScaleLabel(currentBehaviorAnchor, scaleValue);

    // If the scale label already exists, we don't add it again
    if (customScaleLabel) {
      customScaleLabel._destroy = false;
      return;
    }

    currentBehaviorAnchor.scale_labels_attributes.push({
      value: scaleValue,
      description: '',
      _destroy: false,
    });

    currentBehaviorAnchor.scale_labels_attributes = ratingAnchorStore
      .orderByValue(currentBehaviorAnchor.scale_labels_attributes);
  };

  const addAllMissingCustomScaleLabels = (behaviorAnchor) => {
    const currentBehaviorAnchor = ratingAnchor.value.behavior_anchors_attributes
      .find((anchor) => anchor === behaviorAnchor);

    defaultScaleLabels.value.forEach((label) => {
      const customScaleLabel = findNotDestroyedCustomScaleLabel(currentBehaviorAnchor, label.value);

      if (!customScaleLabel) {
        addCustomScaleLabel(behaviorAnchor, label.value);
      }
    });
  };

  const updateCustomScaleLabel = (behaviorAnchor, scaleValue, description) => {
    const currentBehaviorAnchor = ratingAnchor.value.behavior_anchors_attributes
      .find((anchor) => anchor === behaviorAnchor);

    const customLabel = currentBehaviorAnchor.scale_labels_attributes
      .find((label) => label.value === scaleValue);

    customLabel.description = description;
  };

  const deleteCustomScaleLabel = (behaviorAnchor, scaleValue) => {
    const currentBehaviorAnchor = ratingAnchor.value.behavior_anchors_attributes
      .find((anchor) => anchor === behaviorAnchor);

    const customLabel = currentBehaviorAnchor.scale_labels_attributes
      .find((label) => label.value === scaleValue);

    customLabel._destroy = true;
  };

  return {
    notDestroyedBehaviorAnchors,
    addBehaviorAnchor,
    deleteBehaviorAnchor,
    customScaleLabelGroupsFor,
    addCustomScaleLabel,
    updateCustomScaleLabel,
    deleteCustomScaleLabel,
    showAddAllCustomScaleLabelsButton,
    addAllMissingCustomScaleLabels,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBehaviorAnchorsStore, import.meta.hot));
}
