<template>
  <TwoColumnCard
    :header="$t('components.competence_model.self_rating_config.header')"
    :helptext="$t('components.competence_model.self_rating_config.helptext')"
    :info-link="$t('components.competence_model.self_rating_config.support_link')"
  >
    <div class="flex flex-row justify-end space-x-4 pb-5 border-b border-black border-opacity-10">
      <p>
        {{ $t('components.competence_model.self_rating_config.use_self_rating') }}
      </p>
      <Toggle
        :key="candidateSelfRatingEnabled"
        :model-value="candidateSelfRatingEnabled"
        :disabled="disabled"
        @update:model-value="updateCandidateSelfRatingEnabled"
      />
    </div>
    <TransitionExpand>
      <div v-if="candidateSelfRatingEnabled">
        <div class="w-full justify-start items-center inline-flex pt-8 space-x-4">
          <div class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <span class="placeholder:text-gray-900 text-sm font-medium">
              {{ $t('components.competence_model.self_rating_config.per_exercise.title') }}
            </span>
            <p class="text-gray-500 text-sm">
              {{ $t('components.competence_model.self_rating_config.per_exercise.text') }}
            </p>
          </div>
          <Toggle
            :model-value="perExercise"
            :disabled="disabled"
            @update:model-value="updatePerExercise"
          />
        </div>

        <div
          class="w-full justify-start items-center inline-flex pt-8 space-x-4 pl-4"
        >
          <div class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <span
              class="text-sm font-medium"
              :class="{'text-gray-900': perExercise, 'text-gray-400': !perExercise }"
            >
              {{ $t('components.competence_model.self_rating_config.share_self_assessment.title') }}
            </span>
            <p
              class=":text-gray-500 text-sm"
              :class="{'text-gray-500': perExercise, 'text-gray-300': !perExercise }"
            >
              {{ $t('components.competence_model.self_rating_config.share_self_assessment.text') }}
            </p>
          </div>
          <Toggle
            v-model="shareSelfAssessment"
            :disabled="disabled || !perExercise"
            @update:model-value="updateShareSelfAssessment"
          />
        </div>

        <div class="w-full justify-start items-center inline-flex pt-8 space-x-4">
          <div class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <span class="text-gray-900 text-sm font-medium">
              {{ $t('components.competence_model.self_rating_config.show_competency_description.title') }}
            </span>
            <p class="text-gray-500 text-sm">
              {{ $t('components.competence_model.self_rating_config.show_competency_description.text') }}
            </p>
          </div>
          <Toggle
            :model-value="showCompetencyDescription"
            :disabled="disabled"
            @update:model-value="updateShowCompetencyDescription"
          />
        </div>
        <div class="w-full justify-start items-center inline-flex pt-8 space-x-4">
          <div class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <span class="text-gray-900 text-sm font-medium">
              {{ $t('components.competence_model.self_rating_config.show_behavior_anchors.title') }}
            </span>
            <p class="text-gray-500 text-sm">
              {{ $t('components.competence_model.self_rating_config.show_behavior_anchors.text') }}
            </p>
          </div>
          <Toggle
            :model-value="showBehaviorAnchors"
            :disabled="disabled"
            @update:model-value="updateShowBehaviorAnchors"
          />
        </div>
        <div class="pt-8 gap-2">
          <BaseTextarea
            v-model="candidateInstructions"
            v-debounce:1500ms="updateCandidateInstructions"
            :disabled="disabled"
            name="candidateInstructions"
            :error="getError('candidateInstructions')"
            :label="$t('components.competence_model.self_rating_config.candidateInstructions.title')"
            :placeholder="$t('components.competence_model.self_rating_config.candidateInstructions.placeholder')"
            :hint="$t('defaults.optional')"
          />
          <span class="text-sm text-gray-500">
            {{ $t('components.competence_model.self_rating_config.candidateInstructions.hint') }}
          </span>
        </div>
      </div>
    </TransitionExpand>
  </TwoColumnCard>

  <!-- Modals -->
  <ConfirmDialogModal
    v-if="showWarningModal"
    :open="showWarningModal"
    :title="$t('components.competence_model.self_rating_config.disable_modal.title')"
    :message="$t('components.competence_model.self_rating_config.disable_modal.message')"
    :challenge="$t('components.competence_model.self_rating_config.disable_modal.challenge')"
    @close="abortDeactivation"
    @confirm="confirmDeactivation"
  />
</template>

<script>
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';
import TransitionExpand from '@/components/generic/TransitionExpand/TransitionExpand.vue';
import { competenceModelCandidateSelfRatingConfigPath } from '@/util/url-helpers';
import Axios from 'axios';
import { defineComponent, inject, ref } from 'vue';
import vueDebounce from 'vue-debounce';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CandidateSelfRatingConfig',
  components: {
    ConfirmDialogModal,
    TransitionExpand,
  },
  directives: {
    debounce: vueDebounce({ lock: true }),
  },
  props: {
    competenceModelId: {
      type: Number,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    isActiveAssessment: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:candidate-self-rating-config'],
  setup(props, { emit }) {
    const toast = inject('toast');
    const { t } = useI18n();
    const errors = ref({});
    const getError = (...keys) => errors.value[keys.join('.')]?.[0];

    const candidateSelfRatingEnabled = ref(props.config?.enabled);
    const perExercise = ref(props.config?.perExercise);
    const shareSelfAssessment = ref(props.config?.shareSelfAssessment);
    const showCompetencyDescription = ref(props.config?.showCompetencyDescription);
    const showBehaviorAnchors = ref(props.config?.showBehaviorAnchors);
    const candidateInstructions = ref(props.config?.candidateInstructions);

    const updateCandidateSelfRatingConfig = async () => {
      const data = {
        candidate_self_rating_config: {
          enabled: candidateSelfRatingEnabled.value,
          per_exercise: perExercise.value,
          share_self_assessment: shareSelfAssessment.value,
          show_competency_description: showCompetencyDescription.value,
          show_behavior_anchors: showBehaviorAnchors.value,
          candidate_instructions: candidateInstructions.value,
        },
      };

      try {
        const response = await Axios.put(
          competenceModelCandidateSelfRatingConfigPath(props.competenceModelId),
          data,
        );

        errors.value = {};
        candidateSelfRatingEnabled.value = response.data.enabled;
        perExercise.value = response.data.perExercise;
        shareSelfAssessment.value = response.data.shareSelfAssessment;
        showCompetencyDescription.value = response.data.showCompetencyDescription;
        candidateInstructions.value = response.data.candidateInstructions;
        emit('update:candidate-self-rating-config', response.data);
      } catch (error) {
        errors.value = error.response.data;
        toast.error(t('components.competence_model.form.settings.error'));
      }
    };

    const showWarningModal = ref(false);
    const updateCandidateSelfRatingEnabled = (enabled) => {
      if (props.isActiveAssessment && !enabled) {
        candidateSelfRatingEnabled.value = enabled;
        showWarningModal.value = true;
      } else {
        candidateSelfRatingEnabled.value = enabled;
        updateCandidateSelfRatingConfig();
      }
    };

    const updatePerExercise = (event) => {
      perExercise.value = event;
      if (!event) {
        shareSelfAssessment.value = event;
      }
      updateCandidateSelfRatingConfig();
    };

    const updateShareSelfAssessment = () => {
      updateCandidateSelfRatingConfig();
    };

    const updateShowCompetencyDescription = (event) => {
      showCompetencyDescription.value = event;
      updateCandidateSelfRatingConfig();
    };

    const updateShowBehaviorAnchors = (event) => {
      showBehaviorAnchors.value = event;
      updateCandidateSelfRatingConfig();
    };

    const updateCandidateInstructions = (event) => {
      candidateInstructions.value = event;
      updateCandidateSelfRatingConfig();
    };

    const confirmDeactivation = () => {
      candidateSelfRatingEnabled.value = false;
      showWarningModal.value = false;
      updateCandidateSelfRatingConfig();
    };

    const abortDeactivation = () => {
      candidateSelfRatingEnabled.value = true;
      showWarningModal.value = false;
    };

    return {
      candidateSelfRatingEnabled,
      perExercise,
      shareSelfAssessment,
      showCompetencyDescription,
      showBehaviorAnchors,
      candidateInstructions,
      showWarningModal,

      getError,
      updateCandidateSelfRatingEnabled,
      updatePerExercise,
      updateShareSelfAssessment,
      updateShowCompetencyDescription,
      updateShowBehaviorAnchors,
      updateCandidateInstructions,
      confirmDeactivation,
      abortDeactivation,
    };
  },
});
</script>
