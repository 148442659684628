<template>
  <chart-card
    :title="$t('components.insights.show.clusters')"
    :subtitle="$t('components.insights.show.benchmark')"
  >
    <div
      :style="{height: `${chartHeight}px`}"
      class="relative"
    >
      <canvas
        ref="chartElement"
      />
    </div>
  </chart-card>
</template>
<script>
import { ref, onMounted, toRefs, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Chart } from 'chart.js/auto';

import ChartCard from './ChartCard.vue';

export default {
  name: 'CompetencyClustersBenchmarkLineChart',
  components: {
    ChartCard,
  },
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
    competencyClusters: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const chartElement = ref(null);
    const { min, max, competencyClusters, data } = toRefs(props);

    const chartHeight = computed(() => Math.max(competencyClusters.value.length * 100, 400));

    onMounted(() => {
      const defaultColors = [
        '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6',
        '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11',
        '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#651067',
      ];

      const labels = [];
      const meanData = [];
      const lowerStdData = [];
      const higherStdData = [];

      competencyClusters.value.forEach((cluster) => {
        labels.push(cluster.name);

        const chartData = data.value.find((row) => row.clusterName === cluster.name);

        meanData.push(chartData?.avg);
        lowerStdData.push(chartData?.avgMinusStddev);
        higherStdData.push(chartData?.avgPlusStddev);
      });

      const chartData = {
        labels,
        datasets: [
          {
            borderColor: defaultColors[0],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            data: meanData,
            label: t('components.insights.show.mean'),
          },
          {
            borderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            fill: false,
            data: lowerStdData,
            label: t('components.insights.show.standard_deviation'),
          },
          {
            borderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            fill: 1,
            data: higherStdData,
          },
        ],
      };

      const config = {
        type: 'line',
        data: chartData,
        options: {
          locale: 'en',
          maintainAspectRatio: false,
          indexAxis: 'y',
          tension: 0.25,
          scales: {
            y: {
              ticks: {
                autoSkip: false,
                font: {
                  size: 14,
                },
                callback(value) {
                  const label = this.getLabelForValue(value);
                  if (label.length > 20) {
                    return `${this.getLabelForValue(value).substring(0, 20)}...`;
                  }
                  return this.getLabelForValue(value);
                },
              },
              title: {
                font: {
                  size: 14,
                },
                color: '#333',
              },
              grid: {},
              offset: true,
            },
            x: {
              min: min.value,
              max: max.value,
              grid: {
                drawOnChartArea: false,
              },
              title: {
                font: {
                  size: 14,
                },
                color: '#333',
              },
              time: {},
              ticks: {
                font: {
                  size: 14,
                },
              },
              offset: 2,
            },
          },
          animation: false,
          plugins: {
            legend: {
              labels: {
                filter(item, _) {
                  return item.text !== undefined;
                },
              },
              position: 'bottom',
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                label: (tooltipItem, _) => {
                  const clusterData = data.value.find((row) => row.clusterName === tooltipItem.label);

                  return [`${t('components.insights.show.mean')}: ${clusterData?.avg} `,
                    `${t('components.insights.show.standard_deviation')}: ${clusterData?.stddev}`];
                },
              },
            },
            title: {
              font: {
                size: 20,
              },
              color: '#333',
            },
          },
          interaction: {},
        },
      };

      // eslint-disable-next-line no-new
      new Chart(
        chartElement.value,
        config,
      );
    });

    return { chartElement, chartHeight };
  },
};
</script>
