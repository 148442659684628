<template>
  <div>
    <div class="">
      <nav
        class="flex space-x-4"
        aria-label="Tabs"
      >
        <button
          v-for="tab in tabs"
          :key="tab.name"
          :class="[
            tab.hideOnMobile ? 'hidden lg:block' : '',
            tab.name == selectedTab.name
              ? 'bg-gray-100 text-gray-700 transition-all duration-150'
              : 'bg-black text-white hover:text-gray-700',
            'px-3 py-2 font-medium text-sm rounded-md transition-all duration-150 flex',
            'qa-preview-header-tab'
          ]"
          :aria-current="tab.name == selectedTab.name ? 'page' : undefined"
          @click.prevent="selectTab(tab)"
        >
          <RefreshIcon
            v-show="!tab.filePdf && !tab.filePdfs && !tab.error"
            :class="[
              'h-5 w-5 mr-2 animate-spin',
              tab.name == selectedTab.name ? 'text-gray-700' : 'text-white',
              'qa-preview-loading-spinner'
            ]"
            :aria-hidden="!tab.filePdf"
          />
          <ExclamationIcon
            v-if="tab.error"
            :class="['h-5 w-5 mr-2', tab.name == selectedTab.name ? 'text-gray-700' : 'text-white']"
            :aria-hidden="!tab.error"
          />
          {{ tab.name }}
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { RefreshIcon, ExclamationIcon } from '@heroicons/vue/solid';

export default defineComponent({
  components: {
    RefreshIcon,
    ExclamationIcon,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedTab = ref(props.modelValue);

    const selectTab = (tab) => {
      selectedTab.value = tab;
      emit('update:modelValue', tab);
    };

    return {
      selectedTab,
      selectTab,
    };
  },
});
</script>
