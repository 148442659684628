<template>
  <span
    :class="['inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
             customColor ? 'text-white' : colorClasses]"
    :style="customColor ? `background-color: ${customColor}` : ''"
  >
    <slot />
  </span>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';

const AVAILABLE_COLORS = [
  'primary', 'slate', 'gray', 'gray-text', 'neutral', 'stone', 'white',
  'red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan',
  'sky', 'blue', 'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose',
];

export default defineComponent({
  name: 'Badge',
  props: {
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return AVAILABLE_COLORS.includes(value);
      },
    },
    customColor: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { color } = toRefs(props);

    const colorClasses = computed(() => ({
      'bg-primary-100 text-primary-800': color.value === 'primary',
      'bg-slate-100 text-slate-800': color.value === 'slate',
      'bg-gray-100 text-gray-800': color.value === 'gray',
      'bg-gray-100 text-gray-600': color.value === 'gray-text',
      'bg-neutral-100 text-neutral-800': color.value === 'neutral',
      'bg-stone-100 text-stone-800': color.value === 'stone',
      'bg-white text-gray-900': color.value === 'white',
      'bg-red-100 text-red-800': color.value === 'red',
      'bg-orange-100 text-orange-800': color.value === 'orange',
      'bg-amber-100 text-amber-800': color.value === 'amber',
      'bg-yellow-100 text-yellow-800': color.value === 'yellow',
      'bg-lime-100 text-lime-800': color.value === 'lime',
      'bg-green-100 text-green-800': color.value === 'green',
      'bg-emerald-100 text-emerald-800': color.value === 'emerald',
      'bg-teal-100 text-teal-800': color.value === 'teal',
      'bg-cyan-100 text-cyan-800': color.value === 'cyan',
      'bg-sky-100 text-sky-800': color.value === 'sky',
      'bg-blue-100 text-blue-800': color.value === 'blue',
      'bg-indigo-100 text-indigo-800': color.value === 'indigo',
      'bg-violet-100 text-violet-800': color.value === 'violet',
      'bg-purple-100 text-purple-800': color.value === 'purple',
      'bg-fuchsia-100 text-fuchsia-800': color.value === 'fuchsia',
      'bg-pink-100 text-pink-800': color.value === 'pink',
      'bg-rose-100 text-rose-800': color.value === 'rose',
    }));

    return {
      colorClasses,
    };
  },
});
</script>
