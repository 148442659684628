import Clipboard from 'clipboard';

const ClipboardConfig = {
  appendToBody: true,
};

const copyText = (text, container) => new Promise((resolve, reject) => {
  const fakeElement = document.createElement('button');
  const clipboard = new Clipboard(fakeElement, {
    text() {
      return text;
    },
    action() {
      return 'copy';
    },
    container: typeof container === 'object' ? container : document.body,
  });
  clipboard.on('success', (e) => {
    clipboard.destroy();
    resolve(e);
  });
  clipboard.on('error', (e) => {
    clipboard.destroy();
    reject(e);
  });
  if (ClipboardConfig.appendToBody) document.body.appendChild(fakeElement);
  fakeElement.click();
  if (ClipboardConfig.appendToBody) document.body.removeChild(fakeElement);
});

export {
  // eslint-disable-next-line import/prefer-default-export
  copyText,
};
