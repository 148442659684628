<template>
  <Modal
    :show="true"
    inner-modal-classes="w-full sm:min-w-3/4 md:min-w-0 md:max-w-4xl"
    :hide-overflow="false"
    @click-outside="close"
  >
    <div class="flex flex-col p-6 w-full">
      <h2 class="text-gray-900 text-lg font-medium leading-normal mb-5">
        {{ $t('components.assessments.assessments_table.duplicate_assessment_modal.title') }}
      </h2>
      <form
        :disabled="isLoading"
        class="space-y-6"
        @keydown.enter="duplicateAssessment"
      >
        <BaseInput
          v-model="assessmentName"
          :label="$t('activerecord.attributes.assessment.name')"
          :required="true"
          :disabled="isLoading"
          :error="getError('name')"
        />

        <SearchableSelectList
          id="organisation-model-select"
          :selected="currentSelectedOrganisation"
          :options="availableOrganisations"
          :disabled="organisationId || isLoading"
          value-prop="id"
          label-prop="displayName"
          name="competence_model[organisation_id]"
          :allow-reset="false"
          :error="getError('organisation_id')"
          :label="$t('activerecord.attributes.assessment.organisation')"
          @update:selected="currentSelectedOrganisation = $event"
        />

        <div class="grow z-20 space-y-2">
          <DatePicker
            v-model.string="startDate"
            :locale="$i18n.locale"
            mode="dateTime"
            color="gray"
            :masks="masks"
            :minute-increment="5"
            is24hr
            is-required
          >
            <template #default="{ inputValue, inputEvents }">
              <div>
                <BaseInput
                  id="assessment_schedule_attributes_start_date"
                  type="text"
                  leading-icon="ClockIcon"
                  readonly
                  :disabled="isLoading"
                  :model-value="inputValue"
                  :label="$t('activerecord.attributes.schedule.start_date')"
                  :error="getError('schedule') ?
                    `${$t('activerecord.attributes.assessment.date')} ${getError('schedule')}`
                    : ''
                  "
                  v-on="isLoading ? {} : { ...inputEvents }"
                />
              </div>
            </template>
          </DatePicker>

          <div class="text-gray-500">
            <div
              v-if="assessment.hasAssessmentEvents"
            >
              {{ $t('components.assessments.assessments_table.duplicate_assessment_modal.warning.scheduler') }}
            </div>
            <div
              v-if="disableAutoActivation"
            >
              {{ $t('components.assessments.assessments_table.duplicate_assessment_modal.warning.workflow_setting') }}
            </div>
          </div>
        </div>
      </form>
    </div>
    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4 rounded-b-lg">
      <BaseButton
        type="button"
        :is-primary="false"
        @click="close"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        type="button"
        :is-loading="isLoading"
        @click="duplicateAssessment"
      >
        <span>{{ $t('components.assessments.assessments_table.assessment_actions.duplicate') }}</span>
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import Modal from '@/components/generic/Modal/Modal.vue';
import { duplicateAssessmentPath, duplicateAssessmentNewPath, duplicateOrganisationAssessmentPath, duplicateOrganisationAssessmentNewPath } from '@/util/url-helpers';
import Axios from 'axios';
import {
  computed, defineComponent, inject, onMounted, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { DatePicker } from 'v-calendar';

export default defineComponent({
  name: 'DuplicateAssessmentModal',
  components: {
    Modal,
    DatePicker,
  },
  props: {
    assessment: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const toast = inject('toast');
    const { t } = useI18n();
    const isLoading = ref(false);
    const errors = ref({});
    const getError = (...keys) => errors.value[keys.join('.')]?.[0];

    const assessmentName = ref(props.assessment.name);

    // onMount query extra data available organisation
    const availableOrganisations = ref([]);
    onMounted(async () => {
      try {
        const url = props.organisationId
          ? duplicateOrganisationAssessmentNewPath(props.organisationId, props.assessment.id)
          : duplicateAssessmentNewPath(props.assessment.id);
        const response = await Axios({
          url,
          method: 'GET',
        });

        availableOrganisations.value = response.data.organisations;
      } catch (error) {
        toast.error(
          t('components.assessments.assessments_table.duplicate_assessment_modal.error.initialize'),
        );
      }
    });

    const initialOrganisationId = props.organisationId;
    const currentSelectedOrganisation = ref(props.assessment.organisation);
    const getInitialStartDate = () => {
      const today = new Date();
      const [hours, minutes] = props.assessment.schedule.startDate.split(' ')[1].split(':');

      today.setHours(parseInt(hours, 10));
      today.setMinutes(parseInt(minutes, 10));
      today.setSeconds(0);
      today.setMilliseconds(0);

      const now = new Date();
      if (today <= now) {
        today.setDate(today.getDate() + 1);
      }

      return today;
    };
    const startDate = ref(getInitialStartDate());

    // disable auto_activation when less than 1 day is left to activate
    const disableAutoActivation = computed(() => {
      const { intervalToActive } = props.assessment.assessmentWorkflowSetting; // in hours

      const activationDate = new Date(startDate.value).getTime() - intervalToActive * 60 * 60 * 1000;
      const today = new Date();
      const oneDayFromNow = today.getTime() + 24 * 60 * 60 * 1000;

      return props.assessment.assessmentWorkflowSetting.autoActivation && activationDate < oneDayFromNow;
    });

    const close = () => {
      emit('close');
    };

    const duplicateAssessment = () => {
      isLoading.value = true;

      const data = {
        assessment: {
          name: assessmentName.value,
          organisation_id: currentSelectedOrganisation.value.id,
          schedule: { start_date: startDate.value },
          disable_auto_auto_activation: disableAutoActivation.value,
        },
      };

      const url = props.organisationId
        ? duplicateOrganisationAssessmentPath(props.organisationId, props.assessment.id)
        : duplicateAssessmentPath(props.assessment.id);
      Axios({
        url,
        data,
        method: 'POST',
      })
        .then((response) => {
          toast.success(response.data.message);
          Turbo.visit(response.data.path);
          close();
        })
        .catch(({ response }) => {
          isLoading.value = false;
          errors.value = response?.data?.errors;

          // show errors unrelated to the modal's input fields
          const excludedKeys = ['name', 'organisation_id', 'schedule'];
          if (errors.value && Object.keys(errors.value).some((key) => !excludedKeys.includes(key))) {
            toast.error(t('components.assessments.assessments_table.duplicate_assessment_modal.error.duplicate'));
          }
        });
    };

    return {
      assessmentName,
      currentSelectedOrganisation,
      initialOrganisationId,
      availableOrganisations,
      startDate,
      disableAutoActivation,
      isLoading,
      masks: { modelValue: 'YYYY-MM-DD HH:mm' },

      getError,
      close,
      duplicateAssessment,

    };
  },
});
</script>
