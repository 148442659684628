<template>
  <div class="h-14 bg-black w-full flex items-center text-white">
    <button
      class="h-full"
      @click="$emit('close')"
    >
      <heroicon
        class="h-6 w-14"
        icon="ArrowSmLeftIcon"
      />
    </button>

    <FileTypeHeroIcon
      class="h-6"
      :file-type="file.type"
    />
    <span class="ph-no-capture ml-2 max-w-1/2 truncate">
      {{ file.filename }}
    </span>

    <div class="ml-auto h-full flex items-center">
      <EditInOfficePreviewOverlayButton
        v-if="file.type === 'docx' || file.type === 'pptx'"
        class="mr-2"
        :file="file"
        file-type="ShareableFile"
      />
      <button
        v-if="file.type === 'pdf'"
        class="h-full"
        @click.prevent="$emit('print')"
      >
        <heroicon
          class="h-6 w-14"
          icon="PrinterIcon"
        />
      </button>
      <button
        class="h-full"
        @click.prevent="$emit('download')"
      >
        <heroicon
          class="h-6 w-14"
          icon="DownloadIcon"
        />
      </button>
      <a
        :href="file.url"
        target="_blank"
        rel="noopener noreferrer"
        class="h-full flex items-center"
      >
        <heroicon
          class="h-6 w-14"
          icon="ExternalLinkIcon"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Heroicon from '@/components/Heroicon.vue';
import FileTypeHeroIcon from '@/components/FileTypeHeroIcon.vue';
import EditInOfficePreviewOverlayButton from '../generic/EditInOffice/EditInOfficePreviewOverlayButton.vue';

export default defineComponent({
  components: {
    Heroicon,
    FileTypeHeroIcon,
    EditInOfficePreviewOverlayButton,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'download', 'print'],
  setup() {

  },
});
</script>
