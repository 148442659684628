<template>
  <div>
    <div
      v-if="label || hint"
      :class="['flex', label ? 'justify-between' : 'justify-end' ]"
    >
      <label
        v-if="label"
        :for="id"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </label>
      <span
        v-if="hint"
        class="text-sm text-gray-500"
      >
        {{ hint }}
      </span>
    </div>

    <div :class="{ 'mt-1': label || hint }">
      <div class="w-full flex shadow-sm relative h-10">
        <div
          :class="[
            hidePicker ? 'rounded' : 'rounded-l-md grow border',
            error ? 'border-red-300' : 'border-gray-300 border-r-0',
            'h-10', 'w-full', `${qaClass !== '' ? `qa-${qaClass}-color-picker-display` : ''}`
          ]"
          :style="{ 'background-color': selectedColor || modelValue }"
        />

        <template v-if="usesNativeColorPicker">
          <BaseButton
            v-if="!hidePicker"
            :is-primary="false"
            :is-disabled="disabled"
            icon="ColorSwatchIcon"
            class="color-picker-button"
            :qa-class="`${qaClass}-color-picker-button`"
            @click="openNativeColorPicker"
          >
            {{ $t('components.color_picker.pick') }}
          </BaseButton>

          <input
            :id="id"
            ref="nativeColorInput"
            type="color"
            :value="selectedColor || modelValue"
            :name="name"
            :form="form"
            :disabled="disabled"
            :class="`absolute -bottom-1 right-0 h-10 w-1 invisible
              ${qaClass !== '' ? `qa-${qaClass}-color-picker-input` : ''}`"
            @input="onNativeColorPickerInput"
          >
        </template>

        <HeadlessMenu
          v-else
          as="div"
          class="relative"
        >
          <div>
            <BaseButton
              as="MenuButton"
              :is-primary="false"
              :is-disabled="disabled"
              icon="ColorSwatchIcon"
              :class="`qa-${qaClass}-color-picker-button`"
            >
              {{ $t('components.color_picker.pick') }}
            </BaseButton>

            <input
              :id="id"
              type="color"
              :value="selectedColor"
              :name="name"
              :form="form"
              :disabled="disabled"
              class="hidden"
            >
          </div>
          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
          >
            <MenuItems
              role="listbox"
              class="origin-top-right absolute right-0 z-10 mt-2 w-56
                flex flex-wrap items-center rounded-md shadow-lg p-1
                bg-white ring-1 ring-black/5 focus:outline-none"
            >
              <MenuItem
                v-for="(color, index) in colors"
                :key="index"
                v-slot="{ active }"
              >
                <div
                  :aria-label="`${$t('components.color_picker.color')}: ${color}`"
                  :aria-selected="color === selectedColor"
                  :class="[
                    'h-7 w-7 m-1 cursor-pointer rounded-sm inline-flex items-center justify-center',
                    active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
                  ]"
                  :style="{ 'background-color': color }"
                  @click="selectColor(color)"
                >
                  <span
                    v-show="color === selectedColor"
                    class="rounded-full bg-white w-3 h-3"
                  />
                </div>
              </MenuItem>
            </MenuItems>
          </transition>
        </HeadlessMenu>
      </div>
    </div>

    <p
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import {
  defineComponent, ref, toRefs, computed, watch,
} from 'vue';
import { Menu as HeadlessMenu, MenuItems, MenuItem } from '@headlessui/vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';

const DEFAULT_COLORS = [
  '#F43F5E', '#EC4899',
  '#D946EF', '#A855F7',
  '#8B5CF6', '#6366F1',
  '#3B82F6', '#0EA5E9',
  '#06B6D4', '#14B8A6',
  '#10B981', '#22C55E',
  '#84CC16', '#EAB308',
  '#F59E0B', '#F97316',
  '#EF4444',
];

export default defineComponent({
  name: 'ColorPicker',
  components: {
    BaseButton,
    HeadlessMenu,
    MenuItems,
    MenuItem,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
      required: false,
    },

    mode: {
      type: String,
      default: null,
      validator(value) {
        return [null, 'native', 'palette'].includes(value);
      },
    },
    customPalette: {
      type: Array,
      default: () => [],
    },

    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    form: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    hidePicker: {
      type: Boolean,
      default: false,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, mode, customPalette } = toRefs(props);
    const selectedColor = ref(modelValue.value);

    const nativeColorInput = ref(null);
    const openNativeColorPicker = () => {
      nativeColorInput.value.click();
    };
    const onNativeColorPickerInput = ({ target }) => {
      selectedColor.value = target.value;
      emit('update:modelValue', target.value);
    };

    const usesNativeColorPicker = computed(
      () => mode.value === 'native' || (customPalette.value.length === 0 && mode.value !== 'palette'),
    );
    const colors = computed(() => {
      if (customPalette.value.length > 0) return customPalette.value;
      if (mode.value === 'palette') return DEFAULT_COLORS;

      return [];
    });

    watch(
      () => modelValue.value,
      (color, _) => {
        selectedColor.value = color;
      },
    );

    const selectColor = (newColor) => {
      selectedColor.value = newColor;
      emit('update:modelValue', newColor);
    };

    return {
      selectedColor,

      nativeColorInput,
      usesNativeColorPicker,
      openNativeColorPicker,
      onNativeColorPickerInput,

      colors,
      selectColor,
    };
  },
});
</script>

<style lang="scss" scoped>
.color-picker-button {
  @apply rounded-l-none shadow-none ring-offset-0 #{!important};
}
</style>
