import { DateHelper, LocaleManager } from '@bryntum/scheduler';
import { truncate, difference } from 'lodash';
import { useI18n } from '@/plugins/i18n';
import { useNotifications } from '@/plugins/toast';
import deDE from '@/lib/Scheduler/locales/custom.locale.De';
import { stores } from '@/lib/Scheduler/stores';
import eventTooltip from './eventTooltip';
import timeAxis from './timeAxis';

const { error } = useNotifications();
const i18n = useI18n();
const { t } = i18n;

export const setLocale = () => {
  let locale = 'En';
  switch (i18n.locale.value) {
    case 'de':
      locale = deDE;
      break;
    case 'En':
      locale = 'En';
      break;
    default:
      locale = 'En';
  }
  LocaleManager.locale = locale;
};

export const configureScheduler = ({
  startDate, endDate, onChangeStartDate, onChangeEndDate, onZoom, config = {},
}) => ({
  schedulerFeatures: {
    ...eventTooltip,
    scheduleMenuFeature: false,
    timeRangesFeature: {
      showHeaderElements: false,
      timeRanges: [
        {
          startDate: DateHelper.add(startDate, -2, 'h'),
          duration: 2,
          style: `
          background : repeating-linear-gradient(45deg, rgba(155,155,155,0.5),
          rgba(155,155,155,0.5) 8px, transparent 8px, transparent 16px);
          color: #666`,
          durationUnit: 'h',
        },
        {
          startDate: endDate,
          duration: 2,
          style: `
          background : repeating-linear-gradient(45deg, rgba(155,155,155,0.5),
          rgba(155,155,155,0.5) 8px, transparent 8px, transparent 16px);
          color: #666`,
          durationUnit: 'h',
        },
      ],
    },
    stripeFeature: true,
    panFeature: false,
    scheduleTooltipFeature: false,
    eventResizeFeature: false,
    eventDragSelectFeature: true,
    eventDragCreateFeature: false,
    eventDragFeature: {
      disabled: false,
      validatorFn: ({ startDate: newStartDate, endDate: newEndDate }) => {
        const isValid = DateHelper.betweenLesser(newStartDate, startDate, endDate)
          && (DateHelper.isBefore(newEndDate, endDate)
          || DateHelper.isEqual(newEndDate, endDate));
        return {
          valid: isValid,
        };
      },
    },
    eventEditFeature: {
      showNameField: false,
      items: {
        resourceField: false,
        observers: {
          type: 'combo',
          multiSelect: true,
          store: stores.observerStore,
          name: 'observers',
          valueField: 'id',
          displayField: 'name',
          label: t('activerecord.models.observer_assignment.other'),
          weight: 160,
          editable: true,
          filterSelected: false,
          clearable: true,
          listeners: {
            change({ source: combo, value }) {
              combo.input.value = null;
              value = value || [];
              const leadObserverCombo = combo.owner.widgetMap.leadObserverId;

              // Set possible leadObserver values to assigned observers
              leadObserverCombo.store.clearFilters();
              leadObserverCombo.store.filter((observer) => value.includes(observer.id));

              if (leadObserverCombo.record) {
                const index = leadObserverCombo.store.indexOf(
                  leadObserverCombo.record,
                );

                // Remove value if leadObserver is no longer assigned to event
                if (index === -1 || index == null) {
                  leadObserverCombo.value = null;
                }
              }
            },
          },
        },
        candidates: {
          type: 'combo',
          multiSelect: true,
          name: 'candidates',
          valueField: 'id',
          displayField: 'name',
          store: stores.candidateStore,
          label: t('activerecord.models.candidate.other'),
          weight: 180,
          editable: true,
          filterSelected: true,
          clearable: true,
        },
        leadObserverId: {
          type: 'combo',
          store: stores.observerStore.chain(),
          name: 'leadObserverId',
          label: 'Lead',
          displayField: 'name',
          weight: 200,
          editable: true,
          clearable: true,
        },
        endDateField: {
          label: t('activerecord.attributes.assessment_event.end_date'),
          disabled: true,
        },
        endTimeField: {
          disabled: true,
        },
        room: {
          type: 'text',
          name: 'room',
          label: t('activerecord.attributes.assessment_event.room'),
          weight: 210,
        },
      },
      editorConfig: {
        title: t('components.assessment_scheduler.edit_event.title'),
        bbar: {
          items: {
            deleteButton: {
              color: 'b-red',
              text: t('defaults.delete'),
            },
            saveButton: {
              text: t('defaults.save'),
            },
            cancelButton: {
              text: t('defaults.cancel'),
            },
          },
        },
      },
    },
    eventMenuFeature: {
      items: {
        // custom item with inline handler
        cutEvent: false,
        copyEvent: false,
        deleteEvent: false,
        unassignEvent: false,
        unassign: {
          text: t('components.assessment_scheduler.unassign'),
          icon: 'b-fa b-fa-user-times',
          weight: 200,
          onItem: ({ source: scheduler, eventRecord, resourceRecord }) => {
            const { selectedEvents } = scheduler;
            if (selectedEvents.length > 1) {
              scheduler.eventStore.remove(selectedEvents);
            } else {
              scheduler.assignmentStore.unassignEventFromResource(
                eventRecord,
                resourceRecord,
              );
            }
          },
        },
      },
    },
  },

  config: {
    eventColor: 'red',
    width: '75%',
    eventBodyTemplate: (data) => `
      <div class="w-full text-xs">
        <div class="w-full flex items-start mb-2">
          <span class="font-bold">${DateHelper.format(data.startDate, 'HH:mm')}</span>
          <div class="w-full flex justify-between ml-1">
            <div>${data.name}</div>
          <span>${data.leadIcon}</span>
          </div>
        </div>
        <div class="w-full flex items-end">
          <span>${data.groupExerciseIcon}</span>
          <span class="ph-no-capture font-bold">${data.candidateNames.join(', ')}</span>
        </div>
      </div>
    `,
    eventRenderer: ({ eventRecord, resourceRecord, renderData }) => {
      let leadIcon = '';
      const { leadObserverId } = eventRecord;
      if (resourceRecord.id.toString() === leadObserverId) {
        // eslint-disable-next-line max-len
        leadIcon = '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" /></svg>';
      } else if (leadObserverId === undefined || leadObserverId === null) {
        // eslint-disable-next-line max-len
        leadIcon = '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd"d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" /></svg>';
      }
      renderData.eventStyle = 'colored';
      const candidateNames = eventRecord.getCandidates().map((candidate) => {
        const abbreviatedName = `${candidate.originalData.firstName[0]}. ${candidate.originalData.lastName}`;
        return truncate(abbreviatedName, { length: 20 });
      });

      renderData.eventStyle = candidateNames.length > 0 ? 'colored' : 'hollow';

      const groupExerciseIcon = candidateNames.length > 1
        // eslint-disable-next-line max-len
        ? '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor"><path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" /></svg>'
        : '';

      return {
        startDate: eventRecord.startDate,
        candidateNames,
        name: eventRecord.name,
        leadIcon,
        groupExerciseIcon,
      };
    },
    minHeight: '100%',
    ...timeAxis,
    rowHeight: 50,
    barMargin: 5,
    resourceMargin: 5,
    multiEventSelect: true,
    createEventOnDblClick: false,
    allowOverlap: true,
    mode: 'vertical',
    resourceColumns: {
      showAvatars: false,
      fillWidth: true,
      fitWidth: true,
    },
    tbar: {
      items: {
        startDate: {
          type: 'date',
          value: startDate,
          min: startDate,
          max: endDate,
          step: '1d',
          onChange: onChangeStartDate,
        },
        endDate: {
          type: 'date',
          value: endDate,
          min: startDate,
          max: endDate,
          step: '1d',
          onChange: onChangeEndDate,
        },
        spacer: '->',
        zoom: {
          type: 'slider',
          ref: 'zoom',
          width: 130,
          text: 'Zoom',
          showValue: true,
          min: 0,
          max: 4,
          value: 2,
          onInput: onZoom,
        },
      },
    },
    listeners: {
      eventSelectionChange({ source: scheduler }) {
        // prevent candidate assignments of being updated when multiple events are dragged
        const candidateAssignments = scheduler.selectedAssignments.filter(
          (assignment) => assignment.resource.type === 'candidate',
        );
        scheduler.deselectAssignments(candidateAssignments);
      },

      beforeEventEditShow({ source: scheduler, eventRecord, editor }) {
        stores.candidateStore.clearFilters();

        const { candidates, observers } = editor.widgetMap;
        candidates.value = eventRecord.getCandidates();
        observers.value = eventRecord.getObservers();

        stores.candidateStore.filterBy((candidate) => {
          const candidateIdsNotAssignedToExercise = scheduler.assignmentStore.query(
            (assignment) => (
              assignment.resource.type === 'candidate'
              && assignment.event?.exerciseId === eventRecord.exerciseId
            ),
          ).map((assignment) => assignment.resource.id);

          return (
            !candidateIdsNotAssignedToExercise.includes(candidate.id)
            || eventRecord.getCandidates().map((c) => c.id).includes(candidate.id)
          );
        });

        editor.widgetMap.leadObserverId.store.clearFilters();
        const assignedResources = scheduler.assignmentStore.getResourcesForEvent(
          eventRecord,
        );
        editor.widgetMap.leadObserverId.store.filterBy(
          (record) => assignedResources.filter((resource) => resource.type === 'observer').includes(record),
        );
        editor.widgetMap.leadObserverId.value = editor.widgetMap.leadObserverId.store.findRecord(
          'id',
          eventRecord.leadObserverId,
        );
      },
      beforeEventSave({ source: scheduler, values, eventRecord }) {
        // Assigning resources to event does not work here,
        // because event editor reassigns the resources internally.
        // Therefore mutate the resourceRecords param which is used internally.
        const selectedCandidates = stores.candidateStore.allRecords.filter(
          (candidate) => values.candidates.includes(candidate.id),
        );
        const selectedObservers = stores.observerStore.allRecords.filter(
          (observer) => values.observers.includes(observer.id),
        );
        const selectedResources = [...selectedCandidates, ...selectedObservers];
        const addedResources = difference(selectedResources, eventRecord.resources);
        const removedResources = difference(eventRecord.resources, selectedResources);
        removedResources.forEach((resource) => {
          const [assignment] = scheduler.assignmentStore.query(
            (a) => a.eventId === eventRecord.id && a.resourceId === resource.id,
          );
          scheduler.assignmentStore.remove(assignment);
        });
        addedResources.forEach((resource) => {
          scheduler.assignmentStore.add({
            eventId: eventRecord.id,
            resourceId: resource.id,
          });
        });
      },

      beforeEventDropFinalize({ context, source: scheduler }) {
        const { targetEventRecord: otherEvent, eventRecords: [currentEvent] } = context;
        if (!otherEvent) return true;
        if (otherEvent.id === currentEvent.id) return true;
        if (otherEvent.exerciseId !== currentEvent.exerciseId) {
          error(t('components.assessment_scheduler.exercises_must_match'));
          return false;
        }

        const resources = [...currentEvent.resources];
        currentEvent.remove();

        resources.forEach((resource) => {
          if (otherEvent.isAssignedTo(resource)) return;
          scheduler.assignmentStore.add({
            eventId: otherEvent.id,
            resourceId: resource.id,
          });
        });

        return true;
      },
    },
    columns: [
      {
        type: 'resourceInfo',
        text: 'Name',
        field: 'name',
      },
    ],
    loadMaskError: {
      // Only option to "disable" loadMaskError
      autoClose: 1,
    },
    ...config,
  },
});
