<template>
  <div>
    <input
      v-if="schedule.id"
      name="assessment[schedule_attributes][id]"
      :value="schedule.id"
      type="hidden"
    >
    <div class="flex space-x-6">
      <div class="grow z-20">
        <date-picker
          v-model.string="startDate"
          :locale="$i18n.locale"
          mode="dateTime"
          color="gray"
          :masks="masks"
          :minute-increment="5"
          is24hr
          is-required
        >
          <template #default="{ inputValue, inputEvents }">
            <div>
              <BaseInput
                id="assessment_schedule_attributes_start_date"
                type="text"
                leading-icon="ClockIcon"
                readonly
                :disabled="disabled"
                :model-value="inputValue"
                :label="$t('activerecord.attributes.schedule.start_date')"
                :error="errors['schedule.start_date'] ?
                  `${$t('activerecord.attributes.assessment.date')} ${errors['schedule.start_date']}`
                  : ''
                "
                v-on="disabled ? {} : { ...inputEvents }"
              />
              <input
                v-model="startDate"
                type="hidden"
                :disabled="disabled"
                name="assessment[schedule_attributes][start_date]"
              >
            </div>
          </template>
        </date-picker>
      </div>
      <div class="grow z-20">
        <date-picker
          v-model.string="endDate"
          :locale="$i18n.locale"
          mode="dateTime"
          color="gray"
          :masks="masks"
          :minute-increment="5"
          is24hr
          is-required
        >
          <template #default="{ inputValue, inputEvents }">
            <div>
              <BaseInput
                id="assessment_schedule_attributes_end_date"
                type="text"
                leading-icon="ClockIcon"
                readonly
                :disabled="disabled"
                :model-value="inputValue"
                :label="$t('activerecord.attributes.schedule.end_date')"
                :error="errors['schedule.end_date'] ?
                  `${$t('activerecord.attributes.assessment.date')} ${errors['schedule.end_date']}` : ''"
                v-on="disabled ? {} : { ...inputEvents }"
              />
              <input
                v-model="endDate"
                type="hidden"
                :disabled="disabled"
                name="assessment[schedule_attributes][end_date]"
              >
            </div>
          </template>
        </date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';

export default {
  name: 'ScheduleFields',
  components: {
    DatePicker,
    BaseInput,
  },
  props: {
    scheduleData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:scheduleData'],
  data() {
    return {
      startDate: this.scheduleData.startDate,
      endDate: this.scheduleData.endDate,
      masks: { modelValue: 'YYYY-MM-DD HH:mm' },
      schedule: { ...this.scheduleData },
      initialStartDate: this.scheduleData.startDate,
      initialEndDate: this.scheduleData.endDate,
    };
  },
  watch: {
    schedule: {
      deep: true,
      handler() {
        this.emitSchedule();
      },
    },
    startDate() {
      if (this.startDate > this.endDate) {
        this.endDate = this.createCombinedDateTimeString(this.endDate, this.startDate);
      }
      this.emitSchedule();
    },
    endDate() {
      if (this.startDate > this.endDate) {
        this.startDate = this.createCombinedDateTimeString(this.startDate, this.endDate);
      }
      this.emitSchedule();
    },
  },
  methods: {
    emitSchedule() {
      this.$emit('update:scheduleData', {
        id: this.schedule.id,
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
    createCombinedDateTimeString(dateTimeToChange, dateTimeToGetDateFrom) {
      const newDateTime = new Date(dateTimeToChange);
      const newDate = new Date(dateTimeToGetDateFrom).getDate();
      const newMonth = new Date(dateTimeToGetDateFrom).getMonth();
      const newYear = new Date(dateTimeToGetDateFrom).getFullYear();

      newDateTime.setFullYear(newYear);
      newDateTime.setMonth(newMonth);
      newDateTime.setDate(newDate);

      const dateString = newDateTime.toISOString().replace(/T.*/, '');
      const timeString = newDateTime.toLocaleString('de', { hour: '2-digit', minute: '2-digit' });
      return `${dateString} ${timeString}`;
    },
  },
};
</script>

<style lang="scss">
.vc-select select  {
  background-image: none;
}

.vc-select select:focus  {
  background-image: none;
}

.datetimepicker.visible {
  z-index: 49 !important;
}

.vc-base-select select {
  border: 0 !important;
}
</style>
