<template>
  <thead class="bg-gray-50">
    <tr>
      <th
        v-for="column in columns"
        :key="column.key"
        scope="col"
        :class="[
          'px-6 py-3',
          column.hidden ? 'sticky right-[-1px] bg-gray-50' :
          'text-left text-xs font-medium text-gray-500 uppercase tracking-wider']"
      >
        <span
          v-if="column.hidden"
          class="sr-only"
        >
          {{ column.name }}
        </span>

        <a
          v-else-if="column.sortable && !preview"
          :href="sortLink(column)"
          :class="[
            'inline-flex items-center space-x-2',
            isColumnSorted(column) ? 'text-gray-700' : 'hover:text-gray-700']"
        >
          <span class="truncate">{{ column.name }}</span>
          <Heroicon
            :icon="sortIcon(column)"
            aria-hidden="true"
            class="h-5 w-5"
          />
        </a>

        <span
          v-else
          class="truncate"
        >
          {{ column.name }}
        </span>
      </th>
    </tr>
  </thead>
</template>

<script>
import { defineComponent, toRefs } from 'vue';
import Heroicon from '@/components/Heroicon.vue';

export default defineComponent({
  components: {
    Heroicon,
  },
  props: {
    /**
     * { key: string, association?: string, name: string, sortable?: boolean, hidden?: boolean }[]
     */
    columns: {
      type: Array,
      required: true,
    },

    sortParam: {
      type: String,
      required: true,
    },
    sortDirectionParam: {
      type: String,
      required: true,
    },
    sortAssociationParam: {
      type: String,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { sortParam, sortDirectionParam, sortAssociationParam } = toRefs(props);

    const isColumnSorted = ({ key, association }) => {
      const params = new URLSearchParams(window.location.search);

      return (
        params.get(sortParam.value) === key
        && (!association || params.get(sortAssociationParam.value) === association)
      );
    };

    const sortLink = ({ key, association }) => {
      const params = new URLSearchParams(window.location.search);
      const newSortDirection = isColumnSorted({ key, association }) && params.get(sortDirectionParam.value) === 'asc'
        ? 'desc'
        : 'asc';

      params.set(sortParam.value, key);
      params.set(sortDirectionParam.value, newSortDirection);
      if (association) {
        params.set(sortAssociationParam.value, association);
      } else {
        params.delete(sortAssociationParam.value);
      }

      return `${window.location.pathname}?${params.toString()}`;
    };

    const sortIcon = (column) => {
      const params = new URLSearchParams(window.location.search);
      if (!(params.has(sortDirectionParam.value) && isColumnSorted(column))) return 'SwitchVerticalIcon';

      return params.get(sortDirectionParam.value) === 'asc'
        ? 'ArrowSmUpIcon'
        : 'ArrowSmDownIcon';
    };

    return {
      sortIcon,
      isColumnSorted,
      sortLink,
    };
  },
});
</script>
