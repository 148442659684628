<template>
  <div class="space-y-8 overflow-auto py-4">
    <div class="sm:mx-auto sm:w-full sm:max-w-md text-center object-center content-center justify-center space-y-6">
      <img
        class="mx-auto w-48 h-auto"
        :src="logoUrl"
        alt="Applysia"
      >
      <h2
        class="text-gray-900 text-3xl leading-9 font-extrabold"
      >
        {{ $t('components.workspace_forwarding.header') }}
      </h2>

      <p class="text-sm text-gray-500">
        {{ $t('components.workspace_forwarding.text') }}
      </p>
    </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <Card
        class="py-8 px-5 sm:px-10"
      >
        <div class="space-y-6">
          <BaseInput
            :model-value="workspace"
            :label="$t('defaults.workspace')"
            qa-class="workspace"
            @update:model-value="workspace = $event"
            @keydown.enter="forwardingToWorkspace"
          >
            <template #trailing-addon>
              <span class="px-4">
                .applysia.app
              </span>
            </template>
          </BaseInput>
          <div class="flex flex-col space-y-6">
            <BaseButton
              as="a"
              type="href"
              qa-class="workspace-button"
              @click="forwardingToWorkspace"
            >
              {{ $t('defaults.continue') }}
            </BaseButton>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Axios from 'axios';
import Card from '@/components/generic/Card/Card.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';

export default defineComponent({
  name: 'WorkspaceForwarding',
  components: {
    Card,
    BaseInput,
    BaseButton,
  },
  setup() {
    const toast = inject('toast');
    const { t } = useI18n();
    const workspace = ref('');

    const forwardingToWorkspace = () => {
      if (workspace.value?.length < 1) return;

      Axios.post(
        '/',
        { subdomain: workspace.value },
      )
        .then((response) => {
          window.location.href = response.data.url;
        })
        .catch((_) => {
          toast.error(t('components.workspace_forwarding.error'));
        });
    };

    return {
      logoUrl: '/applysia-logo.png',
      workspace,
      forwardingToWorkspace,
    };
  },
});
</script>
