<template>
  <Modal
    :open="open"
    :hide-overflow="true"
    @close="close"
  >
    <div class="max-w-4xl min-w-[360px] sm:min-w-[600px] py-2 pl-3 sm:py-6 sm:pl-6">
      <div class="sm:flex sm:items-start">
        <div class="mt-2 text-center sm:mt-0 sm:text-left w-full">
          <h3 class="text-lg leading-6 font-medium text-gray-900 pr-3 sm:pr-6">
            {{ $t('components.assessment_form.competency_selection_modal.header') }}
          </h3>
          <div
            class="mt-6 max-h-96 overflow-y-scroll overscroll-contain scroll-list-shadow"
          >
            <div class="border-b border-gray-200">
              <TriStateCheckbox
                id="selectAllCheckbox"
                class="py-4 pl-4"
                :label="$t('defaults.select_all')"
                label-classes="text-orange-600 text-sm leading-5 font-medium"
                :is-intermediate="someCompetenciesSelected"
                :model-value="allCompetenciesSelected"
                @update:model-value="updateSelectAll"
              />
            </div>
            <div
              v-for="cluster in competencyClusters"
              :key="cluster.id"
            >
              <TriStateCheckbox
                v-if="!cluster.default"
                :id="cluster.id"
                class="py-4 pl-4 text-sm leading-5 font-semibold text-left bg-gray-50"
                :label="cluster.name"
                :is-intermediate="someClusterCompetenciesSelected(cluster.id)"
                :model-value="allClusterCompetenciesSelected(cluster.id)"
                label-classes="ph-no-capture text-gray-900 text-sm leading-5 font-semibold pr-4"
                @update:model-value="updateSelectCompleteCluster($event, cluster.id)"
              />
              <div
                v-for="competency in cluster.competencies"
                :key="competency.id"
              >
                <BaseCheckbox
                  class="pl-4 hover:bg-gray-50"
                  label-classes="ph-no-capture flex cursor-pointer h-13
                  text-gray-900 text-sm text-left leading-5 font-medium pr-4"
                  secondary-label-classes="text-red-500 font-normal"
                  :label="competency.name"
                  :disabled="false"
                  :model-value="isSelectedCompetencyMap.get(competency.id)"
                  @update:model-value="updateCompetency($event, competency.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 py-3 px-2 flex flex-row justify-end">
      <div class="bg-gray-50 w-full px-1 sm:flex sm:w-1 sm:flex-row-reverse">
        <BaseButton
          :is-disabled="!(someCompetenciesSelected || allCompetenciesSelected)"
          class="mx-auto p-2 w-full justify-center rounded-md border border-transparent
           shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
          @click="saveSelection"
        >
          {{ $t('defaults.save') }}
        </BaseButton>
        <BaseButton
          :is-primary="false"
          class="mt-3 w-full justify-center rounded-md border border-gray-300
           shadow-sm p-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          @click="close"
        >
          {{ $t('defaults.cancel') }}
        </BaseButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/generic/BaseCheckbox/BaseCheckbox.vue';
import Modal from '@/components/generic/Modal/Modal.vue';
import { useMultiSelectCompetenciesAndCluster } from '@/util/multi-select-competencies-and-cluster';
import { ref, toRaw } from 'vue';

export default {
  name: 'AssessmentCompetenceModelCompetencySelectionModal',
  components: {
    Modal,
    BaseButton,
    BaseCheckbox,
  },
  props: {
    competencyClusters: {
      type: Array,
      required: true,
    },
    selectedCompetencies: {
      type: Array,
      default: () => [],
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'save-selection'],
  setup(props, { emit }) {
    const competencyClusters = ref(props.competencyClusters);
    const competencies = ref(toRaw(competencyClusters.value).reduce(
      (accumulator, currentValue) => accumulator.concat(...currentValue.competencies),
      [],
    ));

    const disabled = ref(false);

    const isSelectedCompetencyMap = ref(new Map(
      competencies.value.map((competency) => [competency.id,
        props.selectedCompetencies.length === 0 || props.selectedCompetencies.includes(competency.id)]),
    ));

    const {
      allCompetenciesSelected,
      someCompetenciesSelected,
      allClusterCompetenciesSelected,
      someClusterCompetenciesSelected,
      updateSelectAll,
      updateCompetency,
      updateSelectCompleteCluster,
    } = useMultiSelectCompetenciesAndCluster({ competencyClusters, competencies, isSelectedCompetencyMap });

    const saveSelection = () => {
      const selection = [];
      isSelectedCompetencyMap.value.forEach((value, key) => {
        if (value) {
          selection.push(key);
        }
      });
      emit('save-selection', selection);
    };

    const close = () => {
      emit('close');
    };

    return {
      disabled,
      close,
      allClusterCompetenciesSelected,
      someClusterCompetenciesSelected,
      allCompetenciesSelected,
      someCompetenciesSelected,
      updateSelectAll,
      isSelectedCompetencyMap,
      updateCompetency,
      updateSelectCompleteCluster,
      saveSelection,
    };
  },
};
</script>
