/* eslint-disable import/prefer-default-export */
import { useI18n } from '@/plugins/i18n';
import UnplannedAssessmentEvent from '@/lib/Scheduler/UnplannedAssessmentEvent';

const { t } = useI18n();

export const configureGrid = ({ eventStore, assignmentStore, assessmentId, config = {} }) => ({
  eventStore,
  assignmentStore,
  store: {
    modelClass: UnplannedAssessmentEvent,
    readUrl: `/assessments/${assessmentId}/schedule/unplanned`,
    autoLoad: true,
  },
  emptyText: t('components.grid.empty_event_list'),
  columns: [{
    text: t('activerecord.attributes.candidate.first_name'),
    flex: 1,
    field: 'candidateFirstName',
    htmlEncode: false,
    cellCls: 'ph-no-capture',
  },
  {
    text: t('activerecord.attributes.candidate.last_name'),
    flex: 1,
    field: 'candidateLastName',
    htmlEncode: false,
    cellCls: 'ph-no-capture',
  },
  {
    text: t('activerecord.models.exercise'),
    flex: 1,
    field: 'name',
    htmlEncode: false,
  },
  {
    text: t('activerecord.attributes.exercise.duration'),
    flex: 1,
    align: 'right',
    editor: false,
    field: 'duration',
    renderer: (data) => `${data.record.duration} ${data.record.durationUnit}`,
  }],
  tbar: [
    {
      type: 'combo',
      ref: 'groupBy',
      label: t('components.grid.group_by'),
      placeholder: t('components.grid.none'),
      editable: false,
      clearable: true,
      flex: 1,
      items: [
        { value: 'candidateName', text: t('activerecord.models.candidate.one') },
        { value: 'name', text: t('activerecord.models.exercise') },
      ],
      value: null,
      onSelect: ({ source: combo, record }) => {
        const { parent: gridInstance } = combo.owner;
        if (combo.selecting) {
          return;
        }
        if (record) {
          // Change grouping but prevent change from triggering yet another group
          combo.selecting = true;
          gridInstance.store.group(record.value);
          combo.selecting = false;
        } else {
          gridInstance.store.clearGroupers();
        }
      },
    },
  ],
  minHeight: '100%',
  width: '25%',
  features: {
    cellMenu: false,
    cellEdit: false,
  },
  ...config,
});
