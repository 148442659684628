<template>
  <fieldset :disabled="!isEditable">
    <input
      v-if="competenceModel.id"
      :value="competenceModel.id"
      name="competence_model[id]"
      type="hidden"
    >

    <div class="space-y-6">
      <template v-if="competenceModel.template && showName">
        <TwoColumnCard
          :header="$t('components.assessment.competence_model_form.categories.general.header')"
          :helptext="$t('components.assessment.competence_model_form.categories.general.helptext')"
        >
          <div class="space-y-6">
            <BaseInput
              :model-value="competenceModel.name"
              name="competence_model[name]"
              type="text"
              :disabled="!isEditable"
              :label="$t('activerecord.attributes.competence_model.name')"
              :error="getError('name')"
              qa-class="competence-model-name"
            />
            <SearchableSelectList
              id="organisation-model-select"
              :selected="currentSelectedOrganisation"
              :options="availableOrganisations"
              :options-empty-message="$t('components.organisations.no_organisations')"
              value-prop="id"
              label-prop="displayName"
              name="competence_model[organisation_id]"
              qa-class="competence-model-organisation"
              :disabled="!isEditable || !!organisation"
              :allow-reset="false"
              :error="getError('organisation_id')"
              :label="$t('activerecord.attributes.assessment.organisation')"
              @update:selected="currentSelectedOrganisation = $event"
            />
            <input
              v-for="template in selectedReportTemplates"
              :key="template"
              type="hidden"
              name="competence_model[report_template_ids][]"
              :value="template"
            >
            <BaseInput
              v-if="reportTemplatesFeatureEnabled"
              v-model="selectedReportTemplateNames"
              :disabled="false"
              type="text"
              :label="$t('activerecord.attributes.assessment.report')"
              trailing-icon="SelectorIcon"
              :readonly="true"
              :as-button="true"
              :hint="$t('defaults.optional')"
              @click="showReportTemplateSelectionModal = true"
            />
          </div>
        </TwoColumnCard>
      </template>

      <CompetenceModelSettings
        :competence-model-id="competenceModel.id"
        :is-editable="isEditable || !competenceModel.id"
        :consolidation-in-conference-enabled="competenceModel.consolidationInConferenceEnabled"
        @update:competence-model-settings="$emit('update:competence-model-settings')"
      />

      <competence-model-scale-input
        :competence-model-id="competenceModel.id"
        :initial-scale="competenceModel.scale"
        :initial-scale-labels="competenceModel.scaleLabels"
        :errors="errors"
        :disable-scale-editing="!scaleEditable"
        :disable-scale-label-editing="!isEditable"
        @update:scale-labels="$emit('update:scale-labels')"
      />
    </div>
  </fieldset>
  <ReportTemplateSelectionModal
    v-if="showReportTemplateSelectionModal"
    :available-report-templates="availableReportTemplates"
    :selected-report-templates-list="selectedReportTemplates"
    :open="showReportTemplateSelectionModal"
    @close="showReportTemplateSelectionModal = false"
    @select-report-templates="selectReportTemplates"
  />
</template>

<script>
import { toRefs, ref, computed } from 'vue';

import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import CompetenceModelSettings from '@/components/CompetenceModel/CompetenceModelSettings.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import ReportTemplateSelectionModal from '@/components/Templates/ReportTemplates/ReportTemplateSelectionModal.vue';
import { posthog } from 'posthog-js';

export default {
  name: 'CompetenceModelFormFields',
  components: {
    BaseInput,
    CompetenceModelSettings,
    SearchableSelectList,
    TwoColumnCard,
    ReportTemplateSelectionModal,
  },
  props: {
    scaleEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    showName: {
      type: Boolean,
      required: false,
      default: true,
    },
    organisation: {
      type: Object,
      required: false,
      default: null,
    },
    availableOrganisations: {
      type: Array,
      default: () => [],
    },
    competenceModel: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    availableReportTemplates: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  emits: ['update:competence-model-settings', 'update:scale-labels'],
  setup(props) {
    const { errors, competenceModel } = toRefs(props);

    const getError = (key) => errors.value[key]?.[0];

    const currentSelectedOrganisation = ref(
      competenceModel.value.organisation || props.organisation,
    );

    const showReportTemplateSelectionModal = ref(false);
    const selectedReportTemplates = ref(competenceModel.value.reportTemplates.map((template) => template.id));
    const selectReportTemplates = (templates) => {
      selectedReportTemplates.value = templates;
      showReportTemplateSelectionModal.value = false;
    };

    const selectedReportTemplateNames = computed(() => {
      const namesArray = selectedReportTemplates.value.map((id) => {
        const reportTemplate = props.availableReportTemplates.find((template) => template.id === id);
        return reportTemplate ? reportTemplate.name : '';
      });

      return namesArray.join(', ');
    });

    return {
      getError,
      currentSelectedOrganisation,
      showReportTemplateSelectionModal,
      selectReportTemplates,
      selectedReportTemplates,
      selectedReportTemplateNames,

      reportTemplatesFeatureEnabled: (posthog.persistence && posthog.isFeatureEnabled('report-templates'))
      || window.moduleEnabled('reporting'),
    };
  },
};
</script>
