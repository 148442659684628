<template>
  <Card>
    <div class="w-full flex justify-between border-b">
      <h2 class="font-bold text-xl ml-5 my-5">
        <span class="mr-1">{{ $t('components.candidate_import.preview_table.preview') }}</span>
        <span v-if="previewData">
          ({{ $t('components.candidate_import.preview_table.candidate', previewData.length) }})
        </span>
      </h2>
    </div>

    <div
      v-if="loading"
      class="w-full h-20 relative p-16"
    >
      <div class="spinner lg dark" />
    </div>

    <div
      v-else-if="previewData"
      class="w-full mb-4 overflow-x-auto"
    >
      <table class="table-auto text-left w-full mb-2">
        <thead class="border-b-2">
          <tr class="children:px-5 children:py-4">
            <th>{{ $t('activerecord.attributes.candidate.first_name') }}</th>
            <th>{{ $t('activerecord.attributes.candidate.last_name') }}</th>
            <th>{{ $t('activerecord.attributes.candidate.email') }}</th>
            <th>{{ $t('activerecord.attributes.candidate.locale') }}</th>
            <th>{{ $t('activerecord.attributes.candidate.sex') }}</th>
            <th>{{ $t('activerecord.attributes.candidate.birthdate') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(candidate, index) in previewCandidates"
            :key="`preview-candidate-${index}`"
            class="ph-no-capture children:border-b children:py-4 children:px-5 text-lg align-middle"
          >
            <td>{{ candidate.firstName }}</td>
            <td>{{ candidate.lastName }}</td>
            <td class="text-left">
              {{ candidate.email }}
            </td>
            <td>{{ candidate.locale }}</td>
            <td>{{ candidate.sex }}</td>
            <td>{{ candidate.birthdate }}</td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="previewData.length - 5 > 0"
        class="italic pl-5 pb-2"
      >
        ... {{ $t('components.candidate_import.preview_table.and_some_more', { count: previewData.length - 5 }) }}
      </div>
    </div>

    <div
      v-else
      class="w-full text-center align-middle p-6 italic"
    >
      {{ $t('components.candidate_import.preview_table.no_candidates') }}
    </div>
  </Card>
</template>

<script>
function renameKeys(keyMapping, object) {
  return Object.keys(keyMapping).reduce((renamedObject, renamedKey) => {
    const renamedProp = { [renamedKey]: object[keyMapping[renamedKey]] };

    return {
      ...renamedObject,
      ...renamedProp,
    };
  }, {});
}

export default {
  name: 'PreviewTable',
  props: {
    previewData: {
      type: Array,
      default: () => [],
    },
    columnMapping: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    previewCandidates() {
      if (this.previewData.length === 0) {
        return [];
      }

      return this.previewData.slice(0, 5).map((candidate) => renameKeys(this.columnMapping, candidate));
    },
  },
};
</script>
