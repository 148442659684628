<template>
  <!-- from https://undraw.co/ -->

  <!-- eslint-disable max-len, vue/max-attributes-per-line -->
  <svg id="3cd38589-f6b5-4ced-9f2c-9a4b79f01fae" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="835.61" height="660.05" viewBox="0 0 835.61 660.05">
    <defs>
      <linearGradient id="459c4fc8-d836-433f-a197-0a2a91cd58e2" x1="435.61" y1="529" x2="435.61" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="gray" stop-opacity="0.25" />
        <stop offset="0.54" stop-color="gray" stop-opacity="0.12" />
        <stop offset="1" stop-color="gray" stop-opacity="0.1" />
      </linearGradient>
      <linearGradient id="342ea0e6-6062-49b7-bc20-c734cb7a73a4" x1="435.61" y1="488" x2="435.61" y2="95" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="763d00b7-5a07-4818-8ebb-d18341e18e84" x1="171.55" y1="660.05" x2="171.55" y2="316.95" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="92069d5c-e5f8-43fe-b38d-e54af900eab7" x1="171.55" y1="496.61" x2="171.55" y2="480.38" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="cc2cb1f8-9965-47fb-9589-218376181648" x1="194.61" y1="489.5" x2="194.61" y2="382.5" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="21b9629b-50a9-4ebb-aca6-e4e0185f53fc" x1="187.61" y1="544.5" x2="187.61" y2="488.5" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="fcd1d80a-fd87-48d8-ac9f-c26a469979c7" x1="509.54" y1="778.88" x2="509.54" y2="769.85" gradientTransform="translate(1170.3 491.9) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="103b5314-699c-4416-ba96-f0315aa2be6d" x1="246.07" y1="679.17" x2="246.07" y2="670.14" gradientTransform="translate(370.3 -32.65) rotate(30)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="f7eccc56-af47-4388-bae2-3610c4149eb5" x1="364.44" y1="766.03" x2="364.44" y2="756.99" gradientTransform="translate(110.34 -349.73) rotate(30)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="bcd8726a-d7c5-49b1-9a44-0b3978539435" x1="643.63" y1="719.48" x2="643.63" y2="710.44" gradientTransform="translate(1232.03 299.71) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="391a46f9-b208-48d2-be26-d2096ab99e61" x1="627.41" y1="656.89" x2="627.41" y2="647.85" gradientTransform="translate(1232.3 266.24) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="83f8f054-eb9c-442d-b51f-167d68b16295" x1="643.63" y1="594.29" x2="643.63" y2="585.26" gradientTransform="translate(1248.8 237.12) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="ae1ae34d-eb1b-4711-a510-1c391231e596" x1="690" y1="547.93" x2="690" y2="538.89" gradientTransform="translate(1278.2 220.15) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="9891a10d-f6cd-4be8-8439-1a188b457eca" x1="818.66" y1="549.09" x2="818.66" y2="540.05" gradientTransform="translate(1342.37 237.96) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="fc0ab291-1c38-49b0-9137-a4534010b0cb" x1="863.87" y1="596.61" x2="863.87" y2="587.58" gradientTransform="translate(1358.61 267.78) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="28dee737-f47b-4c6f-813e-2c0be0b581eb" x1="877.78" y1="656.89" x2="877.78" y2="647.85" gradientTransform="translate(1357.49 299.78) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
      <linearGradient id="228de15d-ea7a-43bb-9b48-ae33db5c4fe2" x1="863.87" y1="717.16" x2="863.87" y2="708.13" gradientTransform="translate(1342.46 328.06) rotate(120)" xlink:href="#459c4fc8-d836-433f-a197-0a2a91cd58e2" />
    </defs>
    <title>calendar</title>
    <rect x="35.61" width="800" height="529" fill="url(#459c4fc8-d836-433f-a197-0a2a91cd58e2)" />
    <rect x="42.61" y="62.5" width="784" height="450.5" fill="#fff" />
    <rect x="42.61" y="11" width="784" height="51.5" :fill="color" />
    <rect x="53.61" y="95" width="764" height="393" fill="url(#342ea0e6-6062-49b7-bc20-c734cb7a73a4)" />
    <rect x="63.11" y="102" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="156.11" y="102" width="92" height="73.5" fill="#fff" />
    <rect x="249.11" y="102" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="342.11" y="102" width="92" height="73.5" fill="#fff" />
    <rect x="435.11" y="102" width="92" height="73.5" fill="#fff" />
    <rect x="528.11" y="102" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="621.11" y="102" width="92" height="73.5" fill="#fff" />
    <rect x="714.11" y="102" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="63.11" y="177" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="156.11" y="177" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="249.11" y="177" width="92" height="73.5" fill="#fff" />
    <rect x="342.11" y="177" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="435.11" y="177" width="92" height="73.5" fill="#fff" />
    <rect x="528.11" y="177" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="621.11" y="177" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="714.11" y="177" width="92" height="73.5" fill="#fff" />
    <rect x="63.11" y="251" width="92" height="73.5" fill="#fff" />
    <rect x="156.11" y="251" width="92" height="73.5" fill="#fff" />
    <rect x="249.11" y="251" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="342.11" y="251" width="92" height="73.5" fill="#fff" />
    <rect x="435.11" y="251" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="528.11" y="251" width="92" height="73.5" fill="#fff" />
    <rect x="621.11" y="251" width="92" height="73.5" fill="#fff" />
    <rect x="714.11" y="251" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="63.11" y="325" width="92" height="73.5" fill="#fff" />
    <rect x="156.11" y="325" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="249.11" y="325" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="342.11" y="325" width="92" height="73.5" fill="#fff" />
    <rect x="435.11" y="325" width="92" height="73.5" fill="#fff" />
    <rect x="528.11" y="325" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="621.11" y="325" width="92" height="73.5" fill="#fff" />
    <rect x="714.11" y="325" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="63.11" y="400" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="156.11" y="400" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="249.11" y="400" width="92" height="73.5" fill="#fff" />
    <rect x="342.11" y="400" width="92" height="73.5" :fill="color" opacity="0.5" />
    <rect x="435.11" y="400" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="528.11" y="400" width="92" height="73.5" fill="#fff" />
    <rect x="621.11" y="400" width="92" height="73.5" :fill="color" opacity="0.2" />
    <rect x="714.11" y="400" width="92" height="73.5" fill="#fff" />
    <rect x="70.61" y="258" width="28" height="28" fill="#69f0ae" />
    <rect x="348.61" y="110" width="28" height="28" fill="#69f0ae" />
    <rect x="345.61" y="258" width="28" height="28" fill="#69f0ae" />
    <rect x="440.61" y="329" width="28" height="28" fill="#69f0ae" />
    <rect x="625.61" y="258" width="28" height="28" fill="#69f0ae" />
    <circle cx="171.55" cy="488.5" r="171.55" fill="url(#763d00b7-5a07-4818-8ebb-d18341e18e84)" />
    <circle cx="171.55" cy="488.5" r="166.92" :fill="color" />
    <circle cx="171.55" cy="488.5" r="135.62" fill="#fff" />
    <circle cx="171.55" cy="488.5" r="8.11" fill="url(#92069d5c-e5f8-43fe-b38d-e54af900eab7)" />
    <line x1="216.76" y1="384.18" x2="172.71" y2="488.5" :fill="color" />
    <polygon points="174.61 489.5 169.61 487.82 214.61 382.5 219.61 384.85 174.61 489.5" fill="url(#cc2cb1f8-9965-47fb-9589-218376181648)" />
    <rect x="320.31" y="554.57" width="113.24" height="3.48" transform="translate(-464.39 567.17) rotate(-67.11)" fill="#2196f3" />
    <line x1="172.71" y1="489.66" x2="201.69" y2="542.98" :fill="color" />
    <polygon points="199.61 544.5 170.61 490.5 174.61 488.5 204.61 541.5 199.61 544.5" fill="url(#21b9629b-50a9-4ebb-aca6-e4e0185f53fc)" />
    <rect x="367.66" y="605.95" width="3.48" height="60.69" transform="translate(-441.2 133.65) rotate(-28.52)" fill="#2196f3" />
    <circle cx="297.9" cy="491.98" r="3.48" :fill="color" />
    <circle cx="244.91" cy="545.99" r="4.52" transform="translate(-532.58 365.12) rotate(-60)" fill="url(#fcd1d80a-fd87-48d8-ac9f-c26a469979c7)" />
    <circle cx="246.07" cy="674.66" r="4.52" transform="translate(-486.56 93.44) rotate(-30)" fill="url(#103b5314-699c-4416-ba96-f0315aa2be6d)" />
    <circle cx="45.21" cy="491.98" r="4.52" fill="url(#f7eccc56-af47-4388-bae2-3610c4149eb5)" />
    <circle cx="291.04" cy="499.63" r="4.52" transform="translate(-393.02 92.48) rotate(-30)" fill="url(#bcd8726a-d7c5-49b1-9a44-0b3978539435)" />
    <circle cx="353.63" cy="483.41" r="4.52" transform="translate(-376.52 121.6) rotate(-30)" fill="url(#391a46f9-b208-48d2-be26-d2096ab99e61)" />
    <circle cx="416.23" cy="499.63" r="4.52" transform="translate(-376.25 155.08) rotate(-30)" fill="url(#83f8f054-eb9c-442d-b51f-167d68b16295)" />
    <circle cx="462.59" cy="546" r="4.52" transform="translate(-393.22 184.47) rotate(-30)" fill="url(#ae1ae34d-eb1b-4711-a510-1c391231e596)" />
    <circle cx="461.43" cy="674.66" r="4.52" transform="translate(-457.7 201.13) rotate(-30)" fill="url(#9891a10d-f6cd-4be8-8439-1a188b457eca)" />
    <circle cx="413.91" cy="719.87" r="4.52" transform="translate(-486.68 183.42) rotate(-30)" fill="url(#fc0ab291-1c38-49b0-9137-a4534010b0cb)" />
    <circle cx="353.63" cy="733.78" r="4.52" transform="translate(-501.71 155.15) rotate(-30)" fill="url(#28dee737-f47b-4c6f-813e-2c0be0b581eb)" />
    <circle cx="293.36" cy="719.87" r="4.52" transform="translate(-502.83 123.15) rotate(-30)" fill="url(#228de15d-ea7a-43bb-9b48-ae33db5c4fe2)" />
    <circle cx="171.55" cy="363.31" r="3.48" fill="#69f0ae" />
    <circle cx="416.34" cy="500.06" r="3.48" transform="translate(-407.09 490.61) rotate(-60)" fill="#69f0ae" />
    <circle cx="462.16" cy="545.88" r="3.48" transform="translate(-393.22 184.24) rotate(-30)" fill="#69f0ae" />
    <circle cx="461.43" cy="674.66" r="3.48" transform="translate(-535.75 616.96) rotate(-60)" fill="#69f0ae" />
    <circle cx="413.91" cy="719.63" r="3.48" transform="translate(-486.56 183.39) rotate(-30)" fill="#69f0ae" />
    <circle cx="291.15" cy="500.06" r="3.48" transform="translate(-393.22 92.6) rotate(-30)" fill="#69f0ae" />
    <circle cx="245.33" cy="545.88" r="3.48" transform="translate(-532.27 365.43) rotate(-60)" fill="#69f0ae" />
    <circle cx="479.98" cy="612.07" r="4.52" transform="translate(-423.92 202.02) rotate(-30)" fill="#69f0ae" />
    <circle cx="246.07" cy="674.66" r="3.48" transform="translate(-486.56 93.44) rotate(-30)" fill="#69f0ae" />
    <circle cx="293.58" cy="719.63" r="3.48" transform="translate(-658.62 494.09) rotate(-60)" fill="#69f0ae" />
    <circle cx="45.21" cy="491.98" r="3.48" fill="#69f0ae" />
    <circle cx="171.55" cy="613.68" r="3.48" fill="#69f0ae" />
    <path d="M225.08,621.22a135.64,135.64,0,0,1,253.28-67.48A135.64,135.64,0,1,0,236.08,674.8,135.16,135.16,0,0,1,225.08,621.22Z" transform="translate(-182.19 -119.97)" :fill="color" opacity="0.2" />
    <circle cx="171.55" cy="488.5" r="5.8" fill="#69f0ae" />
  </svg>
  <!-- eslint-enable max-len, vue/max-attributes-per-line -->
</template>

<script>
export default {
  name: 'CalendarIcon',
  props: {
    color: {
      type: String,
      default: 'var(--color-primary-500)',
    },
  },
};
</script>
