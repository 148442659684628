<template>
  <div>
    <div class="mb-6">
      <PageHeading>
        <div>
          {{ tenant.name }}
        </div>
        <template #actions>
          <div class="flex items-center space-x-4">
            <SaveButton
              :is-loading="submitting"
              form="tenant-form"
            >
              {{ $t('defaults.save') }}
            </SaveButton>
          </div>
        </template>
      </PageHeading>
    </div>
    <form
      id="tenant-form"
      :action="actionUrl"
      accept-charset="UTF-8"

      method="post"
      enctype="multipart/form-data"
      @submit="submitting = true"
    >
      <input
        type="hidden"
        name="_method"
        :value="dataMethod"
      >
      <input
        type="hidden"
        name="authenticity_token"
        :value="$csrfToken"
      >
      <div class="space-y-5 mb-5">
        <tenant-fields
          :tenant-data="tenant"
          :tenant-errors="tenantErrors"
        />
        <TwoColumnCard
          :header="$t('components.tenants.tenants_form.card.modules.heading')"
          :helptext="$t('components.tenants.tenants_form.card.modules.text')"
        >
          <div class="space-y-6">
            <div class="flex flex-row justify-between ">
              <div>
                {{ $t('components.tenants.tenants_form.card.modules.insights') }}
              </div>
              <Toggle
                v-model="tenant.insightsModuleEnabled"
              />
              <input
                type="hidden"
                name="tenant[insights_module_enabled]"
                :value="tenant.insightsModuleEnabled"
              >
            </div>
            <div class="flex flex-row justify-between ">
              <div>
                {{ $t('components.tenants.tenants_form.card.modules.reporting') }}
              </div>
              <Toggle
                v-model="tenant.reportingModuleEnabled"
              />
              <input
                type="hidden"
                name="tenant[reporting_module_enabled]"
                :value="tenant.reportingModuleEnabled"
              >
            </div>
            <div class="flex flex-row justify-between ">
              <div>
                {{ $t('components.tenants.tenants_form.card.modules.basic_mode') }}
              </div>
              <Toggle
                v-model="tenant.basicModeEnabled"
              />
              <input
                type="hidden"
                name="tenant[basic_mode_enabled]"
                :value="tenant.basicModeEnabled"
              >
            </div>

            <div class="flex flex-row justify-between ">
              <div>
                {{ $t('components.tenants.tenants_form.card.modules.session_timeout') }}
              </div>
              <Toggle
                v-model="tenant.sessionTimeoutEnabled"
              />
              <input
                type="hidden"
                name="tenant[session_timeout_enabled]"
                :value="tenant.sessionTimeoutEnabled"
              >
            </div>
          </div>
        </TwoColumnCard>
      </div>
      <div class="space-y-5 mb-5">
        <hogrefe-integration-fields
          :hogrefe-integration-data="hogrefeIntegrationData"
          :hogrefe-integration-errors="hogrefeIntegrationErrors"
        />
      </div>
      <div class="space-y-5 mb-5">
        <iona-integration-fields
          :iona-integration-data="ionaIntegrationData"
          :iona-integration-errors="ionaIntegrationErrors"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { adminTenantPath } from '@/util/url-helpers';
import { defineComponent, ref } from 'vue';
import TenantFields from '@/components/Tenants/TenantForm/TenantFields.vue';
import HogrefeIntegrationFields from '@/components/Tenants/TenantForm/HogrefeIntegrationFields.vue';
import IonaIntegrationFields from '@/components/Tenants/TenantForm/IonaIntegrationFields.vue';
import SaveButton from '@/components/generic/SaveButton/SaveButton.vue';
import useLocaleOptions from '@/composables/localeOptions';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TenantTemplate',
  components: {
    SaveButton,
    TenantFields,
    HogrefeIntegrationFields,
    IonaIntegrationFields,
  },
  props: {
    tenantData: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    tenantErrors: {
      type: Object,
      default: () => ({}),
    },
    userErrors: {
      type: Object,
      default: () => ({}),
    },
    hogrefeIntegrationData: {
      type: Object,
      required: false,
      default: null,
    },
    hogrefeIntegrationErrors: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    ionaIntegrationData: {
      type: Object,
      required: false,
      default: null,
    },
    ionaIntegrationErrors: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup(props) {
    const tenant = ref({ ...props.tenantData });

    const user = { ...props.userData };

    const getUserError = (...keys) => props.userErrors[keys.join('.')]?.[0];
    const actionUrl = adminTenantPath(tenant.value);

    const dataMethod = 'patch';

    const { availableLocales } = useI18n();
    const { localeOptions, initialLocale } = useLocaleOptions(availableLocales, user.locale);

    const submitting = ref(false);
    return {
      submitting,
      tenant,
      user,
      getUserError,
      actionUrl,
      dataMethod,
      localeOptions,
      initialLocale,
    };
  },
});
</script>
