<template>
  <div class="flex-1 overview-page overflow-y-scroll overscroll-none bg-gray-50">
    <TestView
      v-if="selectedCategory.type === 'Test'"
      :test="selectedCategory"
      @select-file="$emit('select-file', $event)"
    />
    <CandidateSelfRatingView
      v-else-if="selectedCategory.type === 'SelfRating'"
    />
    <FileOverview
      v-else
      :category="selectedCategory"
      @select-file="$emit('select-file', $event)"
    />
  </div>
</template>

<script>
import TestView from '@/components/RemoteSessionTool/TestView.vue';
import CandidateSelfRatingView from '@/components/RemoteSessionTool/CandidateSelfRating/CandidateSelfRatingView.vue';
import FileOverview from '@/components/RemoteSessionTool/FileOverview.vue';

export default {
  name: 'CategoryOverview',
  components: {
    TestView,
    CandidateSelfRatingView,
    FileOverview,
  },
  props: {
    selectedCategory: {
      type: Object,
      required: true,
    },
  },
  emits: ['select-file'],
};
</script>
<style scoped>
.overview-page {
  height: calc(100vh - 3.5rem);
}

@media only screen and (max-width: 640px) {
  .width-mobile {
    width: 370px;
  }
}
</style>
