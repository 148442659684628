<template>
  <div class="flex justify-center">
    <div
      :class="[
        'rounded-lg relative shadow overflow-hidden bg-gray-200 max-w-sm min-w-md min-h-fit tile divide-y',
        file.shared ? 'cursor-pointer' : 'cursor-default'
      ]"
      @click="file.shared ? $emit('select-file', file) : ''"
    >
      <div class="relative h-4/5 overflow-hidden">
        <div
          v-if="file.shared && file.type=='video'"
          class="w-full h-full flex items-center justify-center"
        >
          <PlayIcon class="text-gray-400 h-14 w-14" />
        </div>
        <div
          v-else-if="file.shared && file.type =='pdf'"
          class="w-full min-h-full px-4 pt-4 flex items-center"
        >
          <img

            :src="file.previewUrl"
            class="w-full object-top "
            onerror="this.style.display='none'"
          >
        </div>
        <div
          v-else-if="file.shared"
          class="w-full min-h-full px-4 pt-4 flex items-center"
        >
          <img
            :src="file.previewUrl"
            class="w-full"
            onerror="this.style.display='none'"
          >
        </div>
        <div
          v-if="!file.shared"
          class="w-full h-full flex items-center justify-center"
        >
          <LockClosedIcon class="text-gray-400 h-14 w-14" />
        </div>
        <div class="absolute bottom-5 left-5">
          <Badge
            v-if="file.shared"
            color="green"
            class="left-2 p-3"
          >
            {{ $t('components.remote_session_tool.overview.shared') }}
          </Badge>
          <Badge
            v-else
            color="gray-text"
            class="left-2 p-3"
          >
            {{ $t('components.remote_session_tool.overview.not_shared') }}
          </Badge>
        </div>
      </div>
      <div class="h-1/5 pl-5 py-4 pr-3 bg-white rounded-b-lg flex justify-between">
        <p
          v-if="file.shared"
          class="pr-1 line-clamp-2 break-all text-left text-sm leading-5 font-medium text-gray-700'"
        >
          {{ file.filename }}
        </p>
        <p
          v-else
          class="bg-gray-100 h-5 rounded w-40"
        />
        <div
          :class="[
            file.shared && file.downloadable ? 'cursor-pointer visible' : 'cursor-default invisible'
          ]"
          @click.stop.prevent="downloadFile"
        >
          <div class="relative h-6 w-6 p-1 right-3 top-1">
            <DownloadIcon
              class="absolute z-10 text-gray-400 hover:text-gray-500
            focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-orange-500"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div
        v-if="file.shared"
        class="overlay absolute inset-0"
      />
    </div>
  </div>
</template>

<script>
import { DownloadIcon, PlayIcon, LockClosedIcon } from '@heroicons/vue/outline';
import download from '@/util/download';

export default {
  name: 'PreviewTile',
  components: {
    DownloadIcon,
    PlayIcon,
    LockClosedIcon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  emits: ['select-file'],
  setup(props) {
    function downloadFile() {
      download({
        path: props.file.url,
        filename: props.file.filename,
      });
    }

    return {
      downloadFile,
    };
  },
};
</script>
<style scoped>
.tile {
  height: 384px;
  width: 370px;
}
.overlay:hover {
  background-color: rgba(24, 24, 27, 0.05);
}
</style>
