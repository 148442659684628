<template>
  <div class="pointer-events-none flex flex-row-reverse">
    <div class="self-end flex flex-col">
      <ViewerControl
        icon="PlusIcon"
        @click="zoomIn"
      />
      <ViewerControl
        :disabled="zoomOutDisabled"
        icon="MinusIcon"
        @click="zoomOut"
      />
    </div>
  </div>
</template>

<script>
import ViewerControl from './ViewerControl.vue';

export default {
  components: {
    ViewerControl,
  },
  props: {
    zoomOutDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['zoom-in', 'zoom-out'],
  data: () => ({
    fullscreen: true,
  }),
  methods: {
    zoomIn() {
      this.$emit('zoom-in');
    },
    zoomOut() {
      this.$emit('zoom-out');
    },
  },
};
</script>
