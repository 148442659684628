/* eslint-disable max-len */
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

// eslint-disable-next-line import/prefer-default-export
export const useAssessmentWorkflowSettingsStore = defineStore('assessmentWorkflowSettingsStore', () => {
  const { t } = useI18n();
  const assessmentCompletedAt = ref(null);
  const activationTransition = ref({ automatic: null, interval: null });
  const completionTransition = ref({ automatic: null, interval: null });
  const finalizationTransition = ref({ automatic: null, interval: null });
  const finalStatus = ref(null);

  // init
  const initAssessmentWorkflowSettings = (settings, completedAt = null) => {
    activationTransition.value = { automatic: settings.autoActivation, interval: settings.intervalToActive };
    completionTransition.value = { automatic: settings.autoCompletion, interval: settings.intervalToCompleted };
    finalizationTransition.value = { automatic: settings.autoFinalization, interval: settings.intervalToFinalStatus };
    finalStatus.value = settings.finalStatus;
    assessmentCompletedAt.value = completedAt;
  };

  // actions
  const getFinalStatusColor = (status) => {
    if (status === 'insights') return 'purple';
    return 'red';
  };

  const durationInHours = (interval) => (interval !== null ? `PT${interval}H` : null);
  const durationInDays = (interval) => (interval !== null ? `P${interval}D` : null);
  const durationInWeeks = (interval) => (interval !== null ? `P${interval}W` : null);

  // validations
  const validateDuration = (value, field) => {
    const minValue = field === 'finalizationTransition' ? 1 : 0;
    if (value === null || !(Number.isInteger(value) && value >= minValue)) {
      return { valid: false, error: t('components.assessment_workflow_setting.errors.interval.no_negative_numbers') };
    }

    if (assessmentCompletedAt.value && field === 'finalizationTransition') {
      const now = new Date();
      const timePast = now - new Date(assessmentCompletedAt.value);
      const intervalTooShort = timePast >= (value - 1) * 604800000; // * 1 week in milliseconds

      if (intervalTooShort) {
        return { valid: false, error: t('components.assessment_workflow_setting.errors.interval.remaining_time') };
      }
    }

    return { valid: true, error: '' };
  };

  return {
    activationTransition,
    completionTransition,
    finalizationTransition,
    finalStatus,
    initAssessmentWorkflowSettings,
    getFinalStatusColor,
    durationInHours,
    durationInDays,
    durationInWeeks,
    validateDuration,
  };
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAssessmentWorkflowSettingsStore, import.meta.hot));
}
