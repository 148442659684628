import { ResourceStore } from '@bryntum/scheduler';

export const stores = {
  resourceStore: undefined,
  candidateStore: undefined,
  observerStore: undefined,
};

export const initializeStores = () => {
  stores.resourceStore = new ResourceStore();
  stores.candidateStore = stores.resourceStore.chain((resource) => resource.type === 'candidate');
  stores.observerStore = stores.resourceStore.chain((resource) => resource.type === 'observer');
};

export const destroyStores = () => {
  Object.values(stores).forEach((store) => store.destroy);
};
