/**
 *- Custom Event model
 *
 * Taken from the vanilla dragfromgrid example
 */
import { EventModel } from '@bryntum/scheduler';

export default class AssessmentEvent extends EventModel {
  static get defaults() {
    return {
      durationUnit: 'm',
      leadObserverId: null,
      room: null,
    };
  }

  static get fields() {
    return [
      { name: 'endDate', persist: false },
      { name: 'exerciseId', type: 'string', persist: true },
      { name: 'leadObserverId', type: 'string', persist: true },
      { name: 'room', type: 'string', persist: true },
    ];
  }

  get leadObserverName() {
    const leadObserver = this.resources.find((resource) => resource.id === this.leadObserverId);
    if (leadObserver !== undefined && leadObserver !== null) {
      return leadObserver.name;
    }
    return '-';
  }

  getCandidates() {
    return this.resources.filter((resource) => resource.type === 'candidate');
  }

  get eventColor() {
    return this.getCandidates()?.[0]?.eventColor;
  }

  getObservers() {
    return this.resources.filter((resource) => resource.type === 'observer');
  }
}
