/**
 *- Custom drag implementation
 *
 * Taken from the vanilla dragfromgrid example
 */
import {
  DomHelper, DragHelper, Rectangle, DateHelper,
} from '@bryntum/scheduler';
import { useNotifications } from '@/plugins/toast';
import { useI18n } from '@/plugins/i18n';
import { stores } from './stores';

const { error } = useNotifications();
const { t } = useI18n();

export default class Drag extends DragHelper {
  static get defaultConfig() {
    return {
      // Don't drag the actual row element, clone it
      cloneTarget: true,
      mode: 'translateXY',
      // Only allow drops on the schedule area
      dropTargetSelector: '.b-timeline-subgrid',

      // Only allow drag of row elements inside on the unplanned grid
      targetSelector: '.b-grid-row:not(.b-group-row)',
    };
  }

  construct(config) {
    const me = this;

    super.construct(config);

    me.on({
      dragstart: me.onTaskDragStart,
      drag: me.onTaskDrag,
      drop: me.onTaskDrop,
      thisObj: me,
    });
  }

  onTaskDragStart({ context }) {
    const
      me = this;
    const { schedule } = me;
    const mouseX = context.clientX;
    const mouseY = context.clientY;
    const proxy = context.element;
    const task = me.grid.getRecordFromElement(context.grabbed);
    const newSize = me.schedule.timeAxisViewModel.getDistanceForDuration(task.durationMS);

    // save a reference to the task so we can access it later
    context.task = task;

    // Mutate dragged element (grid row) into an event bar
    proxy.classList.remove('b-grid-row');
    proxy.classList.add(`b-sch-${schedule.mode}`);

    // eslint-disable-next-line max-len
    proxy.innerHTML = `<div class="b-sch-event-wrap b-sch-style-colored b-sch-custom-color" style="height: 100%; width: 100%; pointer-events: none;">
      <div class="b-sch-event" data-task-feature="event" style="color: ${task.eventColor};">
        <div class="b-sch-event-content">
          <div class="block text-xs">
            <div class="w-full flex items-center justify-between mb-2">
              <span id="time"></span>
            </div>
            <div class="w-full">
              <span class="font-bold">${task.candidateFirstName[0]}. ${task.candidateLastName}</span>
            </div>
            <div class="mt-1">${task.name}</div>
          </div>
        </div>
      </div>
    </div>`;

    me.schedule.enableScrollingCloseToEdges(me.schedule.timeAxisSubGrid);

    if (schedule.isHorizontal) {
      // If the new width is narrower than the grabbed element...
      if (context.grabbed.offsetWidth > newSize) {
        const proxyRect = Rectangle.from(context.grabbed);

        // If the mouse is off (nearly or) the end, centre the element on the mouse
        if (mouseX > proxyRect.x + newSize - 20) {
          // eslint-disable-next-line no-multi-assign
          context.newX = context.elementStartX = context.elementX = mouseX - newSize / 2;
          DomHelper.setTranslateX(proxy, context.newX);
        }
      }

      proxy.style.width = `${newSize}px`;
    } else {
      const width = schedule.resourceColumns.columnWidth;

      // Always center horizontal under mouse for vertical mode
      // eslint-disable-next-line no-multi-assign
      context.newX = context.elementStartX = context.elementX = mouseX - width / 2;
      // eslint-disable-next-line no-multi-assign
      context.newY = context.elementStartY = context.elementY = mouseY - newSize / 2;
      DomHelper.setTranslateX(proxy, context.newX);
      DomHelper.setTranslateY(proxy, context.newY);

      proxy.style.width = `${width}px`;
      proxy.style.height = `${newSize}px`;
    }

    // Prevent tooltips from showing while dragging
    schedule.element.classList.add('b-dragging-event');
  }

  onTaskDrag({ context, event }) {
    const
      me = this;
    const
      coordinate = DomHelper[`getTranslate${me.schedule.isHorizontal ? 'X' : 'Y'}`](context.element);
    const date = me.schedule.getDateFromCoordinate(coordinate, 'round', false);
    const observer = context.target && me.schedule.resolveResourceRecord(event);

    context.element.querySelector('#time').innerHTML = DateHelper.format(date, 'LT');

    // Don't allow drops anywhere, only allow drops if the drop is on the timeaxis and on top of a Resource
    context.valid = context.valid && Boolean(date && observer);

    // Save reference to observer so we can use it in onTaskDrop
    context.observer = observer;
  }

  // Drop callback after a mouse up, take action and
  // transfer the unplanned task to the real SchedulerEventStore (if it's valid)
  onTaskDrop({ context }) {
    const
      me = this;
    const { task } = context;
    const { target } = context;

    me.schedule.disableScrollingCloseToEdges(me.schedule.timeAxisSubGrid);

    // If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
    if (context.valid && target) {
      const otherEvent = me.schedule.resolveEventRecord(target);

      if (otherEvent) {
        if (otherEvent.exerciseId === task.exerciseId) {
          me.joinEvents({ unplannedEvent: task, otherEvent });
        } else {
          error(t('components.assessment_scheduler.exercises_must_match'));
        }
      } else {
        const coordinate = DomHelper[`getTranslate${me.schedule.isHorizontal ? 'X' : 'Y'}`](context.element);
        const date = me.schedule.getDateFromCoordinate(coordinate, 'round', false);

        if (date) {
          me.createEvent({ task, startDate: date, observer: context.observer });
        }
      }

      me.context.finalize();
    } else {
      me.abort();
    }

    me.schedule.element.classList.remove('b-dragging-event');
  }

  createEvent({ task, startDate, observer }) {
    const me = this;

    // Remove from grid first so that the data change
    // below does not fire events into the grid.
    me.grid.store.remove(task);

    const candidate = stores.candidateStore.allRecords.find((resource) => resource.id === task.candidateId);

    const [event] = me.schedule.eventStore.add({
      id: task.id,
      name: task.name,
      duration: task.duration,
      durationUnit: task.durationUnit,
      exerciseId: task.exerciseId,
      startDate,
    });

    me.schedule.eventStore.add(event);
    me.schedule.eventStore.assignmentStore.add({
      eventId: event.id,
      resourceId: candidate.id,
    });
    me.schedule.eventStore.assignmentStore.add({
      eventId: event.id,
      resourceId: observer.id,
    });
  }

  joinEvents({ unplannedEvent, otherEvent }) {
    const me = this;

    // Remove from grid first so that the data change
    // below does not fire events into the grid.
    me.grid.store.remove(unplannedEvent);

    const candidate = stores.candidateStore.allRecords.find((resource) => resource.id === unplannedEvent.candidateId);

    me.schedule.eventStore.assignmentStore.add({
      eventId: otherEvent.id,
      resourceId: candidate.id,
    });
  }
}
