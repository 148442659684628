<template>
  <ul class="list-none !mt-2.5">
    <li
      v-for="rule in rules"
      :key="rule.key"
      class="mb-1"
    >
      <span
        class="flex items-center leading-5"
        :class="{
          'text-gray-400':
            !rule.result.value,
          'text-green-500':
            rule.result.value
        }"
      >
        <CheckIcon
          v-if="rule.result.value"
          class="h-5 w-5"
          aria-hidden="true"
        />
        <XIcon
          v-if="!rule.result.value"
          class="h-5 w-5 fill-gray-300"
          aria-hidden="true"
        />
        <span class="ml-1 text-sm">
          {{ $t(rule.messageKey) }}
        </span>
      </span>
    </li>
  </ul>
</template>

<script setup>
import { CheckIcon, XIcon } from '@heroicons/vue/solid';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
});

const rules = [
  {
    key: 'characters',
    result: computed(() => props.modelValue.length >= 8),
    messageKey: 'components.password_requirements.characters',
  },
  {
    key: 'case',
    result: computed(() => {
      const hasLowerCaseCharacter = /[a-z]/.test(props.modelValue);
      const hasUpperCaseCharacter = /[A-Z]/.test(props.modelValue);
      return hasLowerCaseCharacter && hasUpperCaseCharacter;
    }),
    messageKey: 'components.password_requirements.case',
  },
  {
    key: 'special',
    result: computed(() => /[#?!@$%^&*-]/.test(props.modelValue)),
    messageKey: 'components.password_requirements.special',
  },
  {
    key: 'number',
    result: computed(() => /(?=.*\d)/.test(props.modelValue)),
    messageKey: 'components.password_requirements.number',
  },
];
</script>
