<template>
  <div class="flex flex-row items-center">
    <progress-circle
      :progress="circleDisplayValue*100"
      :radius="65"
      :stroke-width="10"
      :color-class="colorClass"
    >
      <heroicon
        v-if="iconName"
        :icon="iconName"
        :class="[colorClass, 'h-full']"
      />
    </progress-circle>
    <div class="ml-4">
      <p
        :class="[colorClass, 'text-3xl', 'font-bold']"
      >
        <slot :value="value">
          <span v-if="value !== null && !percentage">{{ value }}</span>
          <span v-else-if="value !== null && percentage">{{ Math.round(value * 100, 2) }}%</span>
          <span
            v-else
            class="text-lg"
          >{{ $t('components.insights.competencies.show.calculation_impossible') }}</span>
        </slot>
      </p>
      <label
        class="font-medium"
      >
        {{ name }}
      </label>
    </div>
    <tooltip
      v-if="description"
      class="inline-flex self-start justify-self-end"
    >
      {{ description }}
    </tooltip>
  </div>
</template>

<script>
import * as Icons from '@heroicons/vue/solid';
import Heroicon from '@/components/Heroicon.vue';
import ProgressCircle from '@/components/Insights/ProgressCircle.vue';
import { computed, toRefs } from 'vue';

export default {
  name: 'CompetencyStat',
  components: {
    Heroicon,
    ProgressCircle,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      required: false,
      default: null,
      validator(value) {
        return (value <= 1);
      },
    },
    iconName: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return Object.keys(Icons).includes(value);
      },
    },
    colorClass: {
      type: String,
      default: 'text-green-400',
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { value } = toRefs(props);

    const circleDisplayValue = computed(() => Math.max(value.value, 0));

    return {
      circleDisplayValue,
    };
  },
};
</script>
