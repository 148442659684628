<template>
  <PageHeading>
    Insights
    <template #actions>
      <BaseButton
        v-if="!data.error"
        class="mt-5"
        icon="FilterIcon"
        @click="openFilterModal"
      >
        {{ $t('components.insights.filters.title') }}
      </BaseButton>
    </template>
  </PageHeading>
  <div
    v-if="!data.error"
    class="mt-2 text-gray-500 text-sm font-medium gap-6 flex"
  >
    <div
      v-if="filterData.selected.organisations"
      class="flex"
    >
      <OfficeBuildingIcon class="h-5 w-5 mr-2" /><span>{{ selectedOrganisationNames }}</span>
    </div>
    <div
      v-if="filterData.selected.positions"
      class="flex"
    >
      <UserIcon class="h-5 w-5 mr-2" /><span>{{ filterData.selected.positions.join(", ") }}</span>
    </div>
    <div
      class="flex"
    >
      <CalendarIcon class="h-5 w-5 mr-2" />
      <span>{{ $d(filterData.selected.startDate) }} - {{ $d(filterData.selected.endDate) }}</span>
    </div>
    <div
      class="flex"
    >
      <ChartBarIcon class="h-5 w-5 mr-2" />
      <span>
        {{ $t('components.insights.filters.scale') }}
        {{ filterData.selected.scale.min }} - {{ filterData.selected.scale.max }} ({{ filterData.selected.scale.step }})
      </span>
    </div>
  </div>
  <div v-if="data.error">
    <TwoColumnCard
      class="mt-6"
      :header="$t('components.insights.filters.title')"
      :helptext="$t('components.insights.filters.helptext')"
    >
      <Filters
        v-model="selectedFilters"
        :initial-organisations="filterData.options.organisations"
        :initial-positions="filterData.options.positions"
        :initial-start-date="filterData.options.startDate"
        :initial-end-date="filterData.options.endDate"
        :initial-scales="filterData.options.scales"
      />
    </TwoColumnCard>
    <div
      class="flex justify-end"
    >
      <BaseButton
        class="mt-5"
        :is-loading="isLoading"
        @click="applyFilters"
      >
        {{ $t("defaults.apply") }}
      </BaseButton>
    </div>
    <div
      v-if="requiredFiltersPresent"
      class="m-auto mt-28"
    >
      <div class="mb-6 flex justify-center">
        <data-report-icon class="w-auto h-72" />
      </div>

      <div class="mb-4 font-bold text-3xl text-gray-900 px-4 text-center">
        {{ $t('components.insights.show.error') }}
      </div>
      <div class="mb-2 font-medium text-lg text-gray-700 px-4 text-center">
        {{ $t(`components.insights.show.${data.error}`) }}
      </div>
    </div>
  </div>
  <div v-else>
    <stats-row>
      <stats-card
        name="Assessments"
        :stat="data.assessmentsCount"
        icon-name="ChartSquareBarIcon"
      />
      <stats-card
        :name="$t('components.insights.show.candidates')"
        :stat="data.candidatesCount"
        icon-name="UserGroupIcon"
      />
      <stats-card
        :name="$t('components.insights.show.observers')"
        :stat="data.observersCount"
        icon-name="UsersIcon"
      />
    </stats-row>
    <div class="grid grid-cols-1 gap-5 lg:grid-cols-6 mt-5">
      <competency-clusters-benchmark-line-chart
        v-if="showCompetencyClusterDiagrams && showLineChartForClusterBenchmark"
        class="lg:col-span-3"
        :min="parseInt(data.competenceModel.scale.min)"
        :max="parseInt(data.competenceModel.scale.max)"
        :competency-clusters="data.competenceModel.competencyClusters"
        :data="data.competencyClustersBenchmark"
      />
      <competency-clusters-benchmark-radar-chart
        v-if="showCompetencyClusterDiagrams && !showLineChartForClusterBenchmark"
        class="lg:col-span-3"
        :min="parseInt(data.competenceModel.scale.min)"
        :max="parseInt(data.competenceModel.scale.max)"
        :competency-clusters="data.competenceModel.competencyClusters"
        :data="data.competencyClustersBenchmark"
      />
      <distribution-chart
        v-if="showCompetencyClusterDiagrams"
        class="lg:col-span-3"
        :options="data.competenceModel.competencyClusters"
        :data="data.competencyClustersAnswersFrequency"
        :title="$t('components.insights.show.clusters')"
        :subtitle="$t('components.insights.show.distribution')"
        :options-label="$t('components.insights.show.cluster')"
        :compute-chart-height="showLineChartForClusterBenchmark"
      />
      <competencies-benchmark
        class="lg:col-span-3"
        :min="parseInt(data.competenceModel.scale.min)"
        :max="parseInt(data.competenceModel.scale.max)"
        :competencies="data.competenceModel.competencies"
        :data="data.competenciesBenchmark"
      />
      <distribution-chart
        class="lg:col-span-3"
        :options="data.competenceModel.competencies"
        :data="data.competenciesAnswersFrequency"
        :title="$t('components.insights.show.competencies')"
        :subtitle="$t('components.insights.show.distribution')"
        :options-label="$t('components.insights.show.competency')"
        :compute-chart-height="true"
      />
    </div>
    <competency-details
      :data="data.competenciesByExerciseBenchmark"
      :competence-model="data.competenceModel"
      class="mt-5 mb-5"
    />
  </div>
  <Modal
    :open="filterModalOpen"
    :hide-overflow="false"
    @click-outside="closeFilterModal"
  >
    <div class="p-6">
      <CardDescription
        :header="$t('components.insights.filters.title')"
        :helptext="$t('components.insights.filters.helptext')"
      />

      <Filters
        v-model="selectedFilters"
        class="mt-6"
        :initial-organisations="filterData.options.organisations"
        :initial-positions="filterData.options.positions"
        :initial-start-date="filterData.options.startDate"
        :initial-end-date="filterData.options.endDate"
        :initial-scales="filterData.options.scales"
      />
    </div>
    <div
      class="flex justify-end"
    >
      <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4 w-full rounded-b-lg">
        <BaseButton
          type="button"
          :is-primary="false"
          @click="closeFilterModal"
        >
          {{ $t('defaults.cancel') }}
        </BaseButton>
        <BaseButton
          type="button"
          :is-loading="isLoading"
          @click="applyFilters"
        >
          {{ $t("defaults.apply") }}
        </BaseButton>
      </footer>
    </div>
  </Modal>
</template>
<script>
import StatsRow from '@/components/Insights/StatsRow.vue';
import StatsCard from '@/components/Insights/StatsCard.vue';
import CompetenciesBenchmark from '@/components/Insights/CompetenciesBenchmark.vue';
import DistributionChart from '@/components/Insights/DistributionChart.vue';
import CompetencyDetails from '@/components/Insights/CompetencyDetails.vue';
import { ref, toRefs, computed, watch } from 'vue';
import { OfficeBuildingIcon, UserIcon, CalendarIcon, ChartBarIcon } from '@heroicons/vue/solid';
import CardDescription from '@/components/generic/TwoColumnCard/CardDescription/CardDescription.vue';
import CompetencyClustersBenchmarkRadarChart from '@/components/Insights/CompetencyClustersBenchmarkRadarChart.vue';
import CompetencyClustersBenchmarkLineChart from '@/components/Insights/CompetencyClustersBenchmarkLineChart.vue';
import Filters from './Filters.vue';
import BaseButton from '../generic/BaseButton/BaseButton.vue';
import Modal from '../generic/Modal/Modal.vue';
import TwoColumnCard from '../generic/TwoColumnCard/TwoColumnCard.vue';

export default {
  name: 'InsightsLayout',
  components: {
    StatsRow,
    StatsCard,
    CompetenciesBenchmark,
    CompetencyDetails,
    DistributionChart,
    Filters,
    BaseButton,
    OfficeBuildingIcon,
    UserIcon,
    CalendarIcon,
    ChartBarIcon,
    Modal,
    TwoColumnCard,
    CardDescription,
    CompetencyClustersBenchmarkRadarChart,
    CompetencyClustersBenchmarkLineChart,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    filterData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { filterData, data } = toRefs(props);
    const isLoading = ref(false);

    const selectedFilters = ref({
      organisations: filterData.value.selected.organisations
        ?.map((organisation) => filterData.value.options.organisations
          .find((org) => String(org.id) === organisation)) || [],
      positions: filterData.value.selected.positions?.map((position) => ({ id: position, name: position })) || [],
      startDate: filterData.value.selected.startDate || filterData.value.options.startDate,
      endDate: filterData.value.selected.endDate || filterData.value.options.endDate,
      scale: filterData.value.selected.scale.min ? {
        label: `${filterData.value.selected.scale.min}-${filterData.value.selected.scale.max}`,
        secondaryLabel: `(${filterData.value.selected.scale.step})`,
        value: filterData.value.selected.scale,
      } : null,
    });

    const selectedOrganisationNames = computed(() => selectedFilters.value.organisations
      .map((organisation) => organisation.name).join(', '));

    const applyFilters = () => {
      isLoading.value = true;
      const urlParams = new URLSearchParams();
      urlParams.set('start_date', selectedFilters.value.startDate);
      urlParams.set('end_date', selectedFilters.value.endDate);
      urlParams.set('scale_min', selectedFilters.value.scale.value.min);
      urlParams.set('scale_max', selectedFilters.value.scale.value.max);
      urlParams.set('scale_step', selectedFilters.value.scale.value.step);
      selectedFilters.value.organisations.forEach(
        (organisation) => urlParams.append('organisations[]', organisation.id),
      );
      selectedFilters.value.positions.forEach(
        (position) => urlParams.append('target_positions[]', position.name),
      );

      window.location.search = urlParams;
    };

    const requiredFiltersPresent = computed(() => data.value.error !== 'filters_invalid');

    const filterModalOpen = ref(false);
    const openFilterModal = () => { filterModalOpen.value = true; };
    const closeFilterModal = () => { filterModalOpen.value = false; };

    watch(data, async (newData, _) => {
      if (newData.error) {
        isLoading.value = false;
      }
    });

    const showCompetencyClusterDiagrams = computed(() => data.value.competenceModel.competencyClusters.length > 1
    && data.value.competenceModel.competencyClusters.some((cluster) => cluster.isDefault === false));

    const showLineChartForClusterBenchmark = computed(() => data.value.competenceModel.competencyClusters.length < 3
    || data.value.competenceModel.competencyClusters.length > 8);

    return {
      selectedFilters,
      selectedOrganisationNames,
      applyFilters,
      openFilterModal,
      closeFilterModal,
      filterModalOpen,
      showCompetencyClusterDiagrams,
      showLineChartForClusterBenchmark,

      requiredFiltersPresent,
      isLoading,
    };
  },
};
</script>
