import Axios from 'axios';

export default function download({ path, filename, withCache = false }) {
  const headers = withCache ? {} : { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0', mode: 'cors' };

  Axios.get(`${path}?no-cache`, { responseType: 'blob', headers }).then((response) => {
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  });
}
