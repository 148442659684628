import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

// Convert to a date string in the format of the current locale
// withYear: if true, the year will be included in the string
// date string format: de: 16. Jan. 2024, en: Jan 16, 2024
export const convertToIsoDateString = (dateString, withYear) => {
  const locale = useI18n().locale.value;

  const options = {
    year: withYear ? 'numeric' : undefined,
    month: 'short',
    day: '2-digit',
  };

  return new Date(dateString).toLocaleDateString(locale, options);
};

// Date range representation
// Date range can be represented in 3 ways:
// 1. A date range with end and start date: '24. Dez. 2023 - 16. Jan. 2024'
// 2. A range within the the current year: '16. Jan. - 18. Feb.'
// 3. A range with only the start or end date or same day: '16. Jan. 2023'
export const computeDateRangeString = (selectedFilters) => computed(() => {
  const startDate = selectedFilters.value.startDate ? new Date(selectedFilters.value.startDate) : null;
  const endDate = selectedFilters.value.endDate ? new Date(selectedFilters.value.endDate) : null;

  if (startDate && endDate) {
    const thisYear = new Date().getFullYear();
    const withYear = !(startDate.getFullYear() === thisYear && endDate.getFullYear() === thisYear);

    if (startDate.getTime() === endDate.getTime()) { // same day
      return convertToIsoDateString(startDate, withYear);
    }

    return `${convertToIsoDateString(startDate, withYear)} - ${convertToIsoDateString(endDate, withYear)}`;
  }
  const { t } = useI18n();

  if (startDate) {
    return `${t('components.date_range.from')} ${convertToIsoDateString(startDate, true)}`; // show only start date
  } if (endDate) {
    return `${t('components.date_range.until')} ${convertToIsoDateString(endDate, true)}`; // show only end date
  }

  return null; // Both startDate and endDate are null
});
