<template>
  <div class="min-h-full flex flex-col justify-center bg-gray-50 h-screen">
    <div class="space-y-8 overflow-auto py-4">
      <div class="sm:mx-auto sm:w-full sm:max-w-md text-center object-center content-center justify-center space-y-6">
        <img
          class="mx-auto w-48 h-auto"
          :src="logoUrl"
          alt="Applysia"
        >
        <h2
          class="text-gray-900 text-3xl leading-9 font-extrabold"
        >
          {{ $t('components.session.reset_password_header') }}
        </h2>
      </div>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <Card
          class="py-8 px-5 sm:px-10"
        >
          <form
            id="reset-password-form"
            :action="actionUrl"
            accept-charset="UTF-8"
            method="post"
            enctype="multipart/form-data"
            @submit="loading = true"
          >
            <input
              type="hidden"
              name="_method"
              value="put"
            >
            <input
              type="hidden"
              name="authenticity_token"
              :value="$csrfToken"
            >
            <input
              type="hidden"
              name="user[reset_password_token]"
              :value="user.resetPasswordToken"
            >
            <input
              type="hidden"
              name="user[email]"
              :value="user.email"
            >
            <div class="space-y-6">
              <base-input
                v-model="password"
                name="user[password]"
                :label="$t('components.session.fields.new_password')"
                :type="'password'"
                :error="getError('password')"
                qa-class="new-password"
              />
              <password-requirements v-model="password" />
              <base-input
                name="user[password_confirmation]"
                :label="$t('components.session.fields.new_password_confirmation')"
                :type="'password'"
                :error="getError('password_confirmation')"
                qa-class="new-password-confirmation"
              />
              <div class="flex flex-col space-y-6">
                <base-button
                  class="btn"
                  type="submit"
                  :is-loading="loading"
                  form="reset-password-form"
                  qa-class="new-password-save"
                >
                  {{ $t('components.session.buttons.change_password') }}
                </base-button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { resetPasswordPath } from '@/util/url-helpers';
import { defineComponent, ref, inject } from 'vue';
import Card from '@/components/generic/Card/Card.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import PasswordRequirements from '@/components/generic/PasswordRequirements/PasswordRequirements.vue';

export default defineComponent({
  name: 'ResetPasswordForm',
  components: {
    Card,
    BaseInput,
    BaseButton,
    PasswordRequirements,
  },
  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    logoUrl: {
      type: String,
      default: '/applysia-logo.png',
    },
  },
  setup(props) {
    const user = { ...props.userData };

    const actionUrl = resetPasswordPath();
    const loading = ref(false);
    const password = ref('');

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    const tokenError = getError('reset_password_token');

    if (tokenError) {
      const toast = inject('toast');
      toast.error(tokenError);
    }

    return {
      user,
      getError,
      actionUrl,
      loading,
      password,
    };
  },
});
</script>
