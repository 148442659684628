<template>
  <div class="flex flex-col justify-center items-center relative">
    <VideoPlayer :options="videoPlayerOptions" />

    <div class="absolute top-0 right-0 py-6 mr-6">
      <DownloadControl
        :is-downloadable="isDownloadable"
        :url="url"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import DownloadControl from '@/components/Viewers/Controls/DownloadControl.vue';
import VideoPlayer from './VideoPlayer.vue';

export default defineComponent({
  name: 'VideoViewer',
  components: {
    VideoPlayer,
    DownloadControl,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    isDownloadable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const videoPlayerOptions = {
      controls: true,
      sources: [
        {
          src: props.url,
        },
      ],
    };

    return { videoPlayerOptions };
  },
});
</script>
