<template>
  <div class="relative">
    <div
      :class="[
        'h-36 flex justify-center px-6 pt-5 pb-6 border-gray-300 rounded-md border-2'
      ]"
    >
      <div
        v-if="preview.url"
        :class="[
          'w-full h-full rounded-md flex justify-center items-center absolute inset-0',
          'bg-black border-2 border-black opacity-0 bg-opacity-0 transition-all duration-200',
          'border-opacity-5 hover:bg-opacity-50 hover:opacity-100 space-x-4',
        ]"
        @click.stop.prevent
      >
        <button
          class="
                rounded-full
                transition-colors
                duration-200
                cursor-pointer
                text-gray-300
                focus:outline-none focus:ring-1
                ring-offset-2 ring-white
                hover:text-white
              "
          @click="showFilePreview"
        >
          <EyeIcon class="h-6 w-6" />
          <span class="sr-only">
            {{ $t("components.single_file_upload.view_file") }}
          </span>
        </button>
      </div>

      <div class="w-full h-full text-center flex flex-col">
        <div class="w-fit mx-auto flex flex-auto min-h-0 justify-center">
          <img
            v-if="preview.url"
            :src="preview.url"
            :alt="preview.text"
            class="ph-no-capture h-full rounded-md"
          >
          <div
            v-else
            class="flex flex-col justify-center"
          >
            <strong class="text-sm text-gray-600">
              {{ noLogoText }}
            </strong>
            <p class="text-gray-500 text-xs leading-4">
              {{ noLogoHint }}
            </p>
          </div>
        </div>
        <span
          v-if="preview.text"
          class="ph-no-capture mt-1 font-medium text-sm text-gray-700 text-ellipsis overflow-x-hidden shrink-0"
        >
          {{ preview.text }}
        </span>
      </div>
      <PreviewOverlay
        v-if="previewFile"
        :file="previewFile"
        @close="previewFile = null"
      />
    </div>
  </div>
</template>

<script>
import PreviewOverlay from '@/components/PreviewOverlay/PreviewOverlay.vue';
import { EyeIcon } from '@heroicons/vue/outline';
import { defineComponent, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    EyeIcon,
    PreviewOverlay,
  },
  props: {
    logo: {
      type: Object,
      default: null,
    },
    source: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const file = ref(props.logo);
    const preview = ref({
      url: file.value?.previewUrl,
      text: file.value?.filename,
    });
    const previewFile = ref(null);
    const showFilePreview = () => {
      previewFile.value = {
        url: file.value?.url,
        type: file.value?.type,
        text: file.value?.name,
        filename: file.value?.filename,
      };
    };

    watch(() => props.logo, (newValue) => {
      file.value = newValue;
      preview.value = {
        url: file.value?.previewUrl,
        text: file.value?.filename,
      };
    });

    const keyPrefix = 'components.organisation_form.categories.branding';

    const noLogoText = computed(() => {
      const key = props.source === 'organisation'
        ? `${keyPrefix}.organisation.no_logo`
        : `${keyPrefix}.workspace.no_logo`;
      return t(key);
    });

    const noLogoHint = computed(() => {
      const key = props.source === 'organisation'
        ? `${keyPrefix}.organisation.no_logo_hint`
        : `${keyPrefix}.workspace.no_logo_hint`;
      return t(key);
    });

    return {
      preview,
      previewFile,
      noLogoText,
      noLogoHint,
      showFilePreview,
    };
  },
});
</script>
