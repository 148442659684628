import { posthog } from 'posthog-js';
import getMetaPropertyContent from './getMetaPropertyContent';

// https://medium.com/codex/user-session-inactivity-timeout-with-rails-and-devise-7269ac3a8213
let heartBeatActivated = false;
class HeartBeat {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.initHeartBeat();
    });
  }

  initHeartBeat() {
    this.lastActive = new Date().valueOf();
    if (!heartBeatActivated) {
      ['mousemove', 'scroll', 'click', 'keydown'].forEach((activity) => {
        document.addEventListener(activity, (ev) => {
          this.lastActive = ev.timeStamp + performance.timeOrigin;
        }, false);
      });
      heartBeatActivated = true;
    }
  }
}

window.heartBeat = new HeartBeat();

const sessionTimeoutPollFrequency = 60;
function pollForSessionTimeout() {
  setTimeout(pollForSessionTimeout, (sessionTimeoutPollFrequency * 1000));

  const userData = getMetaPropertyContent('applysia:user');
  const userUnauthenticated = !userData;
  const userActive = (Date.now() - window.heartBeat.lastActive) < (sessionTimeoutPollFrequency * 1000);

  if (userUnauthenticated || userActive) {
    return;
  }

  const { sessionTimeoutEnabled } = JSON.parse(userData);
  if (!sessionTimeoutEnabled) {
    return;
  }

  const request = new XMLHttpRequest();
  request.onload = (event) => {
    const { status } = event.target;
    const { response } = event.target;

    // if the remaining valid time for the current user session is less than or equals to 0 seconds.
    if (status === 200 && (response <= 0)) {
      // reset posthog user
      if (posthog.persistence) { posthog.reset(true); }
      // Just reload the page instead of redirecting to login page.
      // This way the page gets stored by devise and the user gets redirected to this page by devise.
      window.location.reload();
    }
  };
  request.open('GET', '/session_timeout', true);
  request.responseType = 'json';
  request.send();
}

window.setTimeout(pollForSessionTimeout, (sessionTimeoutPollFrequency * 1000));
