<template>
  <div class="mb-6">
    <div class="mb-6 space-y-6">
      <AssessmentCompetenceModelFormHeader
        :title="title"
        :competence-model="persistentCompetenceModel"
        :selected-competence-model="selectedCompetenceModel"
        :model-is-selected="modelAlreadySelected"
        :is-editable="isEditable"
        :is-customized="isCustomized"
        :form-disabled="formDisabled"
        :show-replace-competence-model="showReplaceCompetenceModel"

        @open-parent-template="openParentTemplate"
        @show-creation-modal="showCreationModal = true"
        @show-save-as-template-modal="showSaveAsTemplateModal = true"
        @show-template-reset-modal="showTemplateResetModal = true"
        @show-customized-template-remove-modal="showCustomizedTemplateRemoveModal = true"
        @replace-competence-model="replaceCompetenceModel"
        @customize-competence-model="onCustomizeCompetenceModel"
        @on-competence-model-remove="onCompetenceModelRemove"
        @save-selected-template="onSaveSelectedTemplate"
      />
      <Warnings
        v-if="isActiveAssessment && (isCustomized || (!!selectedCompetenceModel && !!persistentCompetenceModel))"
        :warnings="isActiveWarning"
        :custom-header="
          $t('components.assessment.competence_model_form.update_competence_model.warning_active_assessment_header')"
      />

      <div class="space-y-6">
        <TwoColumnCard
          v-if="isSelecting"
          :header="$t('components.assessment.competence_model_form.categories.model_selection.header')"
        >
          <assessment-competence-model-form-select
            v-model:competence-model-data="selectedCompetenceModel"
            :assessment-id="assessmentId"
            :is-editable="isEditable"
            :available-competence-models="availableCompetenceModelsList"
            @update:competence-model-data="onTemplateSelect($event)"
          />
        </TwoColumnCard>
        <div
          v-if="competenceModel?.scale && competenceModel?.scaleLabels"
          class="space-y-6"
        >
          <form
            v-if="competenceModel.id"
            ref="form"
            :key="competenceModel.id"
            :disabled="formDisabled"
          >
            <CompetenceModelFormFields
              :competence-model="competenceModel"
              :errors="competenceModelErrors"
              :show-name="false"
              :is-editable="isEditable && isCustomized && !competenceModel.template"
              @update:scale-labels="saveCompetenceModel"
              @update:competence-model-settings="showUpdateNotificationToolTip"
            />
          </form>

          <Card
            v-if="!!competenceModel.id"
            :key="competenceModel.id"
          >
            <CompetenceModelMatrixWizard
              :competence-model-data="competenceModel"
              :organisation-id="inOrganisationContext ? assessmentOrganisationId : null"
              :assessment-id="assessmentId"
              :selected-competencies="selectedCompetencies"
              :disabled="!isEditable || competenceModel.template || !isCustomized || formDisabled"
              :show-competence-model-select="isSelecting"
              @update:competence-model-data="updatePersistentCompetenceModel"
              @update:selected-competencies="selectedCompetencies = $event;"
              @update:rating-anchor="showUpdateNotificationToolTip"
            />
          </Card>
          <CandidateSelfRatingConfig
            v-if="!!competenceModel"
            :key="competenceModel.id"
            :competence-model-id="competenceModel.id"
            :config="competenceModel.candidateSelfRatingConfig"
            :is-active-assessment="isActiveAssessment"
            :disabled="!isEditable || competenceModel.template || !isCustomized || formDisabled"
            @update:candidate-self-rating-config="showUpdateNotificationToolTip"
          />
          <TargetProfile
            v-if="!!competenceModel"
            :key="competenceModel.id"
            :competence-model="competenceModel"
            :disabled="!isEditable || competenceModel.template || !isCustomized || formDisabled"
            @update:competence-model="updatePersistentCompetenceModel"
          />
        </div>
      </div>
      <div>
        <!-- modals -->
        <AssessmentCompetenceModelFormSaveAsTemplateModal
          v-if="showSaveAsTemplateModal"
          :open="showSaveAsTemplateModal"
          :assessment-id="assessmentId"
          :name="persistentCompetenceModel.parentTemplate ? persistentCompetenceModel.parentTemplate.name : null"
          :organisation-id="assessmentOrganisationId"
          :available-organisations="availableOrganisations"
          @close="showSaveAsTemplateModal = false"
          @save-competence-model="onCompetenceModelSaveAsTemplate"
        />

        <!-- remove selected or persistent model -->
        <AssessmentCompetenceModelFormRemoveModal
          :open="showCustomizedTemplateRemoveModal"
          :assessment-id="assessmentId"
          :text="$t('components.assessment.competence_model_form.remove_template_confirmation_text.base')"
          :warning-listing="warningListing"
          @close="showCustomizedTemplateRemoveModal = false"
          @remove-competence-model="onCompetenceModelRemove"
        />

        <!-- reset to template -->
        <AssessmentCompetenceModelFormResetModal
          :open="showTemplateResetModal"
          :assessment-id="assessmentId"
          :title="$t('defaults.reset')"
          :text="resetTemplateText"
          @close="showTemplateResetModal = false"
          @reset-competence-model="onTemplateModelReset"
        />

        <AssessmentCompetenceModelFormCreationModal
          v-if="showCreationModal"
          :open="showCreationModal"
          :assessment-id="assessmentId"
          @close="showCreationModal = false"
          @created-competence-model="onCompetenceModelCreated"
        />

        <WarningModal
          :open="showWarningModal"
          :title="$t('components.assessment.competence_model_form.warning_modal.title')"
          :message="$t('components.assessment.competence_model_form.warning_modal.message')"
          :confirm-button-text="$t('defaults.customize')"
          @close="showWarningModal = false"
          @confirm="customizeCompetenceModel"
        />

        <WarningModal
          :open="showEmptyCompetenciesWarning"
          :title="$t('components.assessment.competence_model_form.emptyCompetenciesWarningModal.title')"
          :message="$t('components.assessment.competence_model_form.emptyCompetenciesWarningModal.message')"
          @close="showEmptyCompetenciesWarning = false"
        />
      </div>
    </div>
  </div>
  <NotificationToolTip
    v-show="isVisibleUpdateNotificationToolTip"
    ref="notificationToolTip"
    :message="$t('defaults.updated')"
  />
</template>

<script>
import {
  adoptTemplateAssessmentCompetenceModelPath,
  assessmentCompetenceModelPath,
  editCompetenceModelTemplatePath,
} from '@/util/url-helpers';

import CandidateSelfRatingConfig from '@/components/CompetenceModel/CandidateSelfRatingConfig.vue';
import TargetProfile from '@/components/CompetenceModel/TargetProfile/TargetProfile.vue';
import NotificationToolTip from '@/components/NotificationToolTip.vue';
import Card from '@/components/generic/Card/Card.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import WarningModal from '@/components/generic/WarningModal/WarningModal.vue';
import Axios from 'axios';
import {
  AssessmentCompetenceModelFormCreationModal,
  AssessmentCompetenceModelFormHeader,
  AssessmentCompetenceModelFormRemoveModal,
  AssessmentCompetenceModelFormResetModal,
  AssessmentCompetenceModelFormSaveAsTemplateModal,
  AssessmentCompetenceModelFormSelect,
} from './CompetenceModelForm/index';

export default {
  name: 'AssessmentCompetenceModelForm',
  components: {
    AssessmentCompetenceModelFormCreationModal,
    AssessmentCompetenceModelFormRemoveModal,
    AssessmentCompetenceModelFormSaveAsTemplateModal,
    AssessmentCompetenceModelFormSelect,
    AssessmentCompetenceModelFormResetModal,
    AssessmentCompetenceModelFormHeader,
    WarningModal,
    Card,
    TwoColumnCard,
    TargetProfile,
    NotificationToolTip,
    CandidateSelfRatingConfig,
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    assessmentOrganisationId: {
      type: Number,
      required: true,
    },
    inOrganisationContext: {
      type: Boolean,
      required: true,
    },
    isActiveAssessment: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    competenceModelData: {
      type: Object,
      default: null,
    },
    availableOrganisations: {
      type: Array,
      required: true,
    },
    availableCompetenceModels: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedCompetenceModel: null,
      persistentCompetenceModel: this.competenceModelData ? { ...this.competenceModelData } : null,

      availableCompetenceModelsList: [...this.availableCompetenceModels],

      belongsToAssessmentWithEvents: this.competenceModelData?.belongsToAssessmentWithEvents,

      formDisabled: false,
      competenceModelErrors: {},

      showCreationModal: false,
      showSaveAsTemplateModal: false,
      showCustomizedTemplateRemoveModal: false,
      showTemplateResetModal: false,
      showWarningModal: false,
      showEmptyCompetenciesWarning: false,
      selectedCompetencies: {},
      isVisibleUpdateNotificationToolTip: false,
      showReplaceCompetenceModel: false,
    };
  },
  computed: {
    resetModalText() {
      const textParts = [];

      textParts.push(this.$t('components.assessment.competence_model_form.reset_template_confirmation_text.base'));

      if (this.belongsToAssessmentWithEvents) {
        // eslint-disable-next-line max-len
        textParts.push(this.$t('components.assessment.competence_model_form.reset_template_confirmation_text.assessment_events_will_be_deleted'));
      }

      return textParts.join(' ');
    },
    warningListing() {
      const listing = [];
      if (this.belongsToAssessmentWithEvents) {
        listing.push(
          this.$t('components.assessment.competence_model_form.remove_template_confirmation_text.active_schedule'),
        );
      }
      listing.push(
        this.$t('components.assessment.competence_model_form.remove_template_confirmation_text.existing_ratings'),
      );

      return listing;
    },

    resetTemplateText() {
      const textParts = [];

      /* eslint-disable max-len */
      textParts.push(`
          ${this.$t('components.assessment.competence_model_form.reset_template_confirmation_text.base')}
          ${this.$t('components.assessment.competence_model_form.reset_template_confirmation_text.individual_competence_model_addition')}
        `);
      /* eslint-enable max-len */

      if (this.belongsToAssessmentWithEvents) {
        // eslint-disable-next-line max-len
        textParts.push(this.$t('components.assessment.competence_model_form.reset_template_confirmation_text.assessment_events_will_be_deleted'));
      }

      return textParts.join(' ');
    },

    isActiveWarning() {
      let textParts = '';
      if (this.isSelecting) {
        textParts = (this.$t(
          'components.assessment.competence_model_form.update_competence_model.warning_active_assessment_select',
        ));
      } else {
        textParts = (this.$t(
          'components.assessment.competence_model_form.update_competence_model.warning_active_assessment_customize',
        ));
      }
      if (this.belongsToAssessmentWithEvents) {
        textParts = textParts.concat(
          ' ',
          this.$t('components.assessment.competence_model_form.update_competence_model.warning_active_schedule'),
        );
      }
      return [textParts];
    },

    hasParentTemplate() {
      return !!this.persistentCompetenceModel?.parentTemplate && !this.selectedCompetenceModel;
    },

    isCustomized() {
      return this.persistentCompetenceModel?.customized;
    },

    isSelecting() {
      const isSelecting = !this.persistentCompetenceModel
       || this.persistentCompetenceModel?.template
       || this.selectedCompetenceModel
       || this.showReplaceCompetenceModel;
      return !!isSelecting;
    },

    competenceModel() {
      return this.selectedCompetenceModel || this.persistentCompetenceModel;
    },

    modelAlreadySelected() {
      return this.persistentCompetenceModel?.parentTemplate?.id === this.selectedCompetenceModel?.id;
    },
  },
  methods: {
    onCompetenceModelRemove() {
      this.showCustomizedTemplateRemoveModal = false;
      if (this.selectedCompetenceModel == null && !this.showReplaceCompetenceModel) {
        this.persistentCompetenceModel = null;
      } else {
        this.selectedCompetenceModel = null;
      }
      this.showReplaceCompetenceModel = false;
    },
    onCompetenceModelCancelReset() {
      this.persistentCompetenceModel = this.selectedCompetenceModel;
    },
    onCompetenceModelCreated({ competenceModel }) {
      this.showCreationModal = false;
      this.persistentCompetenceModel = competenceModel;
    },
    onCompetenceModelSaveAsTemplate(event) {
      this.persistentCompetenceModel = event.model;

      this.availableCompetenceModelsList = JSON.parse(event.availableCompetenceModels);
    },
    replaceCompetenceModel() {
      this.selectedCompetenceModel = this.availableCompetenceModelsList.find(
        (model) => model.id === this.persistentCompetenceModel.parentTemplate?.id,
      );
      this.showReplaceCompetenceModel = true;
    },
    onTemplateModelReset(competenceModel) {
      this.showTemplateResetModal = false;
      this.persistentCompetenceModel = competenceModel;
    },

    onTemplateSelect(event) {
      this.selectedCompetenceModel = event;
      const competencies = this.selectedCompetenceModel.competencyClusters.reduce(
        (accumulator, currentValue) => accumulator.concat(...currentValue.competencies),
        [],
      );
      this.selectedCompetencies = new Map( // select all competencies
        competencies.map((competency) => [competency.id, true]),
      );
    },

    saveSelectedTemplate() {
      this.formDisabled = true;
      const selectedCompetencyIds = [];

      this.selectedCompetencies.forEach((value, key) => {
        if (value === true) {
          selectedCompetencyIds.push(key);
        }
      });
      const data = {
        competence_model_id: this.competenceModel.id,
        selected_competencies: selectedCompetencyIds,
      };

      Axios.post(adoptTemplateAssessmentCompetenceModelPath(this.assessmentId), data)
        .then((response) => {
          this.formDisabled = false;
          this.persistentCompetenceModel = response.data;
          this.selectedCompetenceModel = null;
          this.$toast.success(this.$t('components.assessment.competence_model_form.select.adopt_template.success'));
        })
        .catch(() => {
          this.formDisabled = false;
          this.$toast.error(this.$t('components.assessment.competence_model_form.select.adopt_template.error'));
        });
    },

    onSaveSelectedTemplate() {
      const competencies = this.selectedCompetenceModel.competencyClusters.reduce(
        (accumulator, currentValue) => accumulator.concat(...currentValue.competencies),
        [],
      );
      if (competencies.length > 0 && [...this.selectedCompetencies].filter(([_, v]) => v).length === 0) {
        this.showEmptyCompetenciesWarning = true;
      } else {
        this.saveSelectedTemplate();
      }
      this.showReplaceCompetenceModel = false;
    },

    customizeCompetenceModel() {
      this.persistentCompetenceModel.customized = true;
      this.formDisabled = true;
      const data = {
        competence_model: {
          customized: true,
        },
      };

      Axios.put(assessmentCompetenceModelPath(this.assessmentId), data)
        .then((response) => {
          this.showWarningModal = false;
          this.formDisabled = false;
          this.persistentCompetenceModel = response.data;
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            this.persistentCompetenceModel = null;
            this.$toast.error(response.data.error);
          } else {
            this.showWarningModal = false;
            this.formDisabled = false;
            this.competenceModelErrors = response.data.errors;
            this.$toast.error(this.$t('components.assessment.competence_model_form.update_competence_model.error'));
          }
        });
    },
    onCustomizeCompetenceModel() {
      if (this.isActiveAssessment) {
        this.showWarningModal = true;
      } else {
        this.customizeCompetenceModel();
      }
    },

    showUpdateNotificationToolTip() {
      this.isVisibleUpdateNotificationToolTip = true;
      this.$refs.notificationToolTip.triggerNotification();
    },

    updatePersistentCompetenceModel(model) {
      this.persistentCompetenceModel = model;
      this.showUpdateNotificationToolTip();
    },

    saveCompetenceModel() {
      const data = new FormData(this.$refs.form);
      Axios.put(assessmentCompetenceModelPath(this.assessmentId), data)
        .then((response) => {
          this.persistentCompetenceModel = response.data;
          this.competenceModelErrors = {};
          this.showUpdateNotificationToolTip();
        })
        .catch(({ response }) => {
          this.competenceModelErrors = response.data.errors;
          this.$toast.error(this.$t('components.assessment.competence_model_form.update_competence_model.error'));
        });
    },

    openParentTemplate() {
      window.open(editCompetenceModelTemplatePath(this.persistentCompetenceModel.parentTemplate.id), '_blank');
    },
  },
};
</script>
