<template>
  <Sidebar>
    <div class="h-max scrolling-touch">
      <div
        v-for="(resource, resource_index) in initialResources"
        :key="`resource-${resource_index}`"
        class="mb-1"
      >
        <div
          :class="[
            isSelectedCategory(resource) && !selectedFile ? 'bg-gray-100' : 'bg-white',
            'flex justify-between items-center px-3 py-2',
            'rounded-md hover:bg-gray-50',
            resource.sharedFileCount ? 'cursor-pointer' : 'cursor-default' ]"
          @click.prevent="resource.sharedFileCount ? selectCategory(resource) : ''"
        >
          <OverflowTextTooltip :text-classes="resource.sharedFileCount ? 'text-gray-900' : 'text-gray-400'">
            <template #tooltip>
              <p>
                {{ resource.name }}
              </p>
            </template>
            <span class="mr-1 text-sm font-medium ">
              {{ resource.name }}
            </span>
          </OverflowTextTooltip>
          <Badge
            v-if="resource.sharedFileCount"
            :color="isSelectedCategory(resource) && !selectedFile ? 'white' : 'gray-text'"
            class="inline-block py-0.5 px-3"
          >
            {{ resource.sharedFileCount + '/' + resource.totalFileCount }}
          </Badge>
          <div
            v-else
          >
            <LockClosedIconSolid
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
        <TransitionExpand>
          <ul
            v-show="isSelectedCategory(resource)"
          >
            <li
              v-for="file in resource.files"
              :key="`resource-${resource_index}-file-${file.id}`"
              class="flex justify-between items-center pr-3 pl-10 py-2 mt-1 rounded-md
              bg-white hover:bg-gray-50 cursor-pointer min-h-10"
              :class="selectedFile == file ? ['bg-gray-100'] : []"
              @click="file.shared ? selectFile(file) : ''"
            >
              <OverflowTextTooltip :text-classes="file.shared ?'text-gray-600' : 'text-gray-400'">
                <template
                  v-if="file.shared"
                  #tooltip
                >
                  <p>
                    {{ file.filename }}
                  </p>
                </template>
                <span
                  v-if="file.shared"
                  class="text-sm font-medium mr-1"
                >
                  {{ file.filename }}
                </span>
                <div
                  v-else
                  class="bg-gray-100 h-5 rounded w-40"
                />
              </OverflowTextTooltip>
              <div>
                <LockOpenIcon
                  v-if="file.shared"
                  class="h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
                <LockClosedIcon
                  v-if="!file.shared"
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </li>
          </ul>
        </TransitionExpand>
      </div>
      <div
        v-if="initialTests.length > 0 || selfRatingEnabled"
        class="mt-7 mb-1 pl-2"
      >
        <h1 class="text-xs leading-4 font-semibold tracking-wider text-gray-500 uppercase">
          {{ $t('components.remote_session_tool.tests') }}
        </h1>
      </div>
      <ul>
        <li
          v-if="selfRatingEnabled"
          class="mb-1"
        >
          <div
            :class="[
              isSelectedCategory({id: 'selfAssessment', name: 'selfAssessment'}) ? 'bg-gray-100' : 'bg-white',
              'flex justify-between items-center p-2 cursor-pointer',
              'rounded-md hover:bg-gray-50']"
            @click.prevent="selectSelfAssessmentCategory"
          >
            <span class="mr-1 text-sm font-medium text-gray-600">
              {{ $t('components.candidate_self_rating.self_assessment') }}
            </span>
            <div class="mr-1">
              <ClipboardCheckIcon
                v-if="selfRatingCompleted"
                class="h-6 w-6 text-green-500"
                aria-hidden="true"
              />
              <LockClosedIcon
                v-else-if="selfRatingLocked"
                class="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
              <ClipboardListIcon
                v-else
                class="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            </div>
          </div>
        </li>
        <li
          v-for="(test, test_index) in initialTests"
          :key="`test-${test_index}`"
          class="mb-1"
        >
          <div
            :class="[
              isSelectedCategory(test) ? 'bg-gray-100' : 'bg-white',
              'flex justify-between items-center p-2 cursor-pointer',
              'rounded-md hover:bg-gray-50']"
            @click.prevent="selectCategory(test)"
          >
            <OverflowTextTooltip text-classes="text-gray-600">
              <template #tooltip>
                <p>
                  {{ test.name }}
                </p>
              </template>

              <span class="mr-1 text-sm font-medium">
                {{ test.name }}
              </span>
            </OverflowTextTooltip>
            <div class="mr-1">
              <DotsHorizontalIcon
                v-if="test.status === 'started'"
                class="h-6 w-6 text-blue-500"
                aria-hidden="true"
              />
              <ClipboardCheckIcon
                v-else-if="test.status === 'completed'"
                class="h-6 w-6 text-green-500"
                aria-hidden="true"
              />
              <ExclamationCircleIcon
                v-else-if="test.status === 'aborted'"
                class="h-6 w-6 text-yellow-500"
                aria-hidden="true"
              />
              <ClipboardListIcon
                v-else
                class="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </Sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import {
  LockClosedIcon, LockOpenIcon, ClipboardListIcon, DotsHorizontalIcon, ExclamationCircleIcon, ClipboardCheckIcon
  ,
} from '@heroicons/vue/outline';
import { LockClosedIcon as LockClosedIconSolid } from '@heroicons/vue/solid';
import TransitionExpand from '@/components/generic/TransitionExpand/TransitionExpand.vue';
import { useCandidateSelfRatingsStore } from '@/components/RemoteSessionTool/stores/candidateSelfRatingsStore';
import { storeToRefs } from 'pinia';
import OverflowTextTooltip from './OverflowTextTooltip.vue';

export default {
  name: 'RemoteSessionToolSidebar',
  components: {
    Sidebar,
    LockClosedIcon,
    LockOpenIcon,
    LockClosedIconSolid,
    ClipboardListIcon,
    DotsHorizontalIcon,
    ExclamationCircleIcon,
    ClipboardCheckIcon,
    TransitionExpand,
    OverflowTextTooltip,
  },

  props: {
    initialResources: {
      type: Array,
      required: true,
    },
    initialTests: {
      type: Array,
      required: true,
    },
    selectedCategory: {
      type: Object,
      default: null,
      required: false,
    },
    selectedFile: {
      type: Object,
      default: null,
      required: false,
    },
  },
  emits: ['select-file', 'select-category'],
  setup(props, { emit }) {
    const selfRatingStore = useCandidateSelfRatingsStore();
    const {
      selfRatingEnabled,
      ratingCompleted: selfRatingCompleted,
      ratingLocked: selfRatingLocked,
    } = storeToRefs(selfRatingStore);

    function selectFile(file) {
      emit('select-file', file);
    }

    function isSelectedCategory(category) {
      return props.selectedCategory?.id === category.id && props.selectedCategory?.name === category.name;
    }

    function selectCategory(category) {
      selectFile(null);
      emit('select-category', category);
    }

    function selectSelfAssessmentCategory() {
      if (selfRatingStore.currentExercise) {
        selfRatingStore.setCurrentExercise(null);
        selfRatingStore.fetchRatings();
      }
      selectCategory({ id: 'selfAssessment', name: 'selfAssessment', type: 'SelfRating' });
    }

    return {
      isSelectedCategory,
      selectCategory,
      selectFile,
      selfRatingEnabled,
      selfRatingCompleted,
      selfRatingLocked,
      selectSelfAssessmentCategory,
    };
  },
};
</script>
