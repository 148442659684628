<template>
  <div
    class="min-w-0"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div
      ref="tooltip"
      :style="{visibility: tooltipVisible ? 'visible' : 'hidden'}"
      :class="['transition-all z-40 overflow-visible whitespace-nowrap absolute',
               '-ml-2 px-2 py-1 rounded-md text-white bg-gray-600 text-sm font-medium',
               tooltipVisible ? 'delay-1000' : '']"
    >
      <slot
        name="tooltip"
      />
    </div>
    <p
      ref="text"
      :class="['truncate', textClasses]"
    >
      <slot />
    </p>
  </div>
</template>
<script>
import { ref } from 'vue';

export default {
  name: 'OverflowTextTooltip',
  props: {
    textClasses: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    /*
     * gets the next scrollable parent element in DOM
     */
    function getScrollParent(element, includeHidden) {
      let style = getComputedStyle(element);
      const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

      if (style.position === 'fixed') return document.body;
      let parent = element.parentElement;
      for (let i = 0; i < 10; i + 1) {
        parent = parent.parentElement;
        style = getComputedStyle(parent);

        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
      }

      return document.body;
    }

    const tooltipVisible = ref(false);
    const text = ref(null);
    const tooltip = ref(null);

    function onMouseEnter() {
      const sidebar = getScrollParent(this.text, true);
      const offset = this.text.offsetTop - sidebar.scrollTop;
      this.tooltip.style.top = `${offset}px`;

      if (text.value?.offsetWidth < text.value?.scrollWidth) {
        tooltipVisible.value = true;
      }
    }

    function onMouseLeave() {
      tooltipVisible.value = false;
    }

    return {
      text,
      tooltipVisible,
      tooltip,

      onMouseEnter,
      onMouseLeave,
    };
  },
};
</script>
