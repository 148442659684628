<template>
  <Badge color="green">
    {{ $t('defaults.new') }}
  </Badge>
</template>

<script>
import Badge from '@/components/generic/Badge/Badge.vue';

export default {
  components: { Badge },
};
</script>
