<template>
  <Sidebar>
    <div class="flex flex-col justify-between h-full">
      <div>
        <div class="flex flex-col items-center px-2 pb-6">
          <div
            v-if="logoUrl"
            class="flex space-x-2 items-center pb-3"
          >
            <img
              ref="image"
              :class="[logoSize, 'block w-auto']"
              :src="logoUrl"
            >
          </div>
          <div class="flex flex-col px-2 items-center">
            <p class="text-gray-900 text-sm font-semibold max-w-64 truncate">
              {{ organisation.name }}
            </p>
            <p class="text-gray-500 text-xs font-medium max-w-64 truncate">
              {{ organisation.unit }}
            </p>
          </div>
        </div>
        <a
          :href="userHasOrganisationAccess ? assessments.href : null"
          :class="[
            assessments.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md mb-8'
          ]"
        >
          <component
            :is="assessments.icon"
            :class="[
              assessments.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
              'mr-3 shrink-0 h-6 w-6'
            ]"
            aria-hidden="true"
          />
          <span class="flex-1">
            {{ assessments.name }}
          </span>
          <Tooltip
            v-if="!userHasOrganisationAccess"
          >
            <template #parent>
              <LockClosedIcon
                v-if="!userHasOrganisationAccess"
                class="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </template>

            {{ noAccessMessage }}
          </Tooltip>
          <span
            v-else-if="assessments.count"
            :class="[
              assessments.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
              'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full'
            ]"
          >
            {{ assessments.count }}
          </span>
        </a>

        <p class="text-sm leading-4 font-semibold px-2 pb-1 text-gray-500">
          {{ $t('defaults.templates') }}
        </p>
        <a
          v-for="item in navigation"
          :key="item.name"
          :href="userHasOrganisationAccess ? item.href : null"
          :class="[
            item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
          ]"
        >
          <component
            :is="item.icon"
            :class="[
              item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
              'mr-3 shrink-0 h-6 w-6'
            ]"
            aria-hidden="true"
          />
          <span class="flex-1">
            {{ item.name }}
          </span>
          <Tooltip
            v-if="!userHasOrganisationAccess"
          >
            <template #parent>
              <LockClosedIcon
                v-if="!userHasOrganisationAccess"
                class="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </template>

            {{ noAccessMessage }}
          </Tooltip>
          <span
            v-else-if="item.count"
            :class="[
              item.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
              'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full'
            ]"
          >
            {{ item.count }}
          </span>
        </a>
      </div>
      <div
        v-if="userCanEditOrganisation"
        class="mb-24"
      >
        <a
          v-if="organisation.access == 'private_access'"
          :href="organisationMemberships.href"
          :class="[
            organisationMemberships.current ?
              'bg-gray-100 text-gray-900'
              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
          ]"
        >
          <component
            :is="organisationMemberships.icon"
            :class="[
              organisationMemberships.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
              'mr-3 shrink-0 h-6 w-6'
            ]"
            aria-hidden="true"
          />
          <span class="flex-1">
            {{ organisationMemberships.name }}
          </span>
          <span
            v-if="assessments.count"
            :class="[
              assessments.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
              'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full'
            ]"
          >
            {{ organisationMemberships.count }}
          </span>
        </a>
        <div>
          <a
            :href="settings.href"
            :class="[
              settings.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
              'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
            ]"
          >
            <component
              :is="settings.icon"
              :class="[
                settings.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                'mr-3 shrink-0 h-6 w-6'
              ]"
              aria-hidden="true"
            />
            <span class="flex-1">
              {{ settings.name }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import {
  ClipboardListIcon, CogIcon, HomeIcon, LockClosedIcon, NewspaperIcon, UsersIcon,
} from '@heroicons/vue/outline';
import Sidebar from '@/components/Sidebar.vue';
import { useI18n } from 'vue-i18n';
import {
  editOrganisationPath,
  organisationAssessmentsPath,
  organisationCompetenceModelTemplatesPath,
  organisationReportTemplatesPath,
  organisationMembershipsPath,
} from '@/util/url-helpers';
import BetaFeatureBadge from '@/components/generic/FeatureBadges/BetaFeatureBadge.vue';

export default {
  components: {
    Sidebar,
    BetaFeatureBadge,
    LockClosedIcon,
  },
  props: {
    organisation: {
      type: Object,
      required: true,
    },
    countStatistics: {
      type: Object,
      default: () => ({}),
    },
    logoAspectRation: {
      type: Number,
      default: 2.0,
    },
    userHasOrganisationAccess: {
      type: Boolean,
      required: true,
    },
    userCanEditOrganisation: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const assessments = {
      name: t('activerecord.models.assessment.one'),
      icon: HomeIcon,
      href: organisationAssessmentsPath(props.organisation.id),
      count: props.countStatistics.assessments || 0,
      current: window.location.pathname.startsWith(organisationAssessmentsPath(props.organisation.id)),
    };

    const navigation = [
      {
        name: t('activerecord.models.competence_model.one'),
        icon: ClipboardListIcon,
        href: organisationCompetenceModelTemplatesPath(props.organisation.id),
        count: props.countStatistics.competenceModels || 0,
        current: window.location.pathname.startsWith(organisationCompetenceModelTemplatesPath(props.organisation.id)),
      },
      {
        name: t('activerecord.models.report_templates.one'),
        icon: NewspaperIcon,
        href: organisationReportTemplatesPath(props.organisation.id),
        count: props.countStatistics.reportTemplates || 0,
        current: window.location.pathname.startsWith(organisationReportTemplatesPath(props.organisation.id)),
      },
    ];

    const organisationMemberships = {
      name: t('defaults.access'),
      icon: UsersIcon,
      href: organisationMembershipsPath(props.organisation.id),
      count: props.countStatistics.organisationMemberships || 0,
      current: window.location.pathname === organisationMembershipsPath(props.organisation.id),
    };

    const settings = {
      name: t('defaults.settings'),
      icon: CogIcon,
      href: editOrganisationPath(props.organisation.id),
      current: window.location.pathname === editOrganisationPath(props.organisation.id),
    };

    return {
      assessments,
      navigation,
      organisationMemberships,
      settings,
      logoUrl: props.organisation?.logo?.url,
      logoSize: props.logoAspectRation <= 1.7 ? 'h-16' : 'h-8',
      noAccessMessage: t('components.organisations.sidebar.user_can_not_view_assessment_and_templates'),
    };
  },
};
</script>
