<template>
  <div class="mb-6">
    <PageHeading>
      <div class="ph-no-capture">
        {{ candidate.firstName }} {{ candidate.lastName }}
      </div>
      <template #actions>
        <div class="flex items-center space-x-4">
          <BaseButton
            v-if="assessmentEditable"
            as="a"
            :is-primary="false"
            :href="candidatePath"
            data-turbo-method="delete"
            :is-disabled="submitting"
            icon="TrashIcon"
            @click="deleteCandidate"
          >
            {{ $t('defaults.delete') }}
          </BaseButton>
          <SaveButton
            v-if="!assessmentCompleted"
            :is-loading="submitting"
            form="candidate-form"
          >
            {{ $t('defaults.save') }}
          </SaveButton>
        </div>
      </template>
    </PageHeading>
  </div>

  <Alert
    v-if="candidate.demo"
    type="info"
    class="mb-6"
  >
    <template #content>
      {{ $t('components.candidates.candidate_form.add_demo_candidate_notice') }}
    </template>
  </Alert>

  <form
    id="candidate-form"
    :action="candidatePath"
    accept-charset="UTF-8"

    method="post"
    enctype="multipart/form-data"
    @submit="submitting = true"
  >
    <input
      type="hidden"
      name="_method"
      value="patch"
    >
    <input
      type="hidden"
      name="authenticity_token"
      :value="$csrfToken"
    >

    <div class="space-y-5 mb-5">
      <TwoColumnCard
        :header="$t('components.candidates.candidate_form.categories.general.header')"
        :helptext="$t('components.candidates.candidate_form.categories.general.helptext')"
      >
        <div class="space-y-6">
          <div class="flex space-x-6">
            <div class="flex-1">
              <BaseInput
                v-model="candidate.firstName"
                name="candidate[first_name]"
                :disabled="assessmentCompleted || candidate.demo"
                :label="$t('activerecord.attributes.candidate.first_name')"
                :error="getError('first_name')"
                qa-class="candidate-first-name"
              />
            </div>
            <div class="flex-1">
              <BaseInput
                v-model="candidate.lastName"
                name="candidate[last_name]"
                :disabled="assessmentCompleted || candidate.demo"
                :label="$t('activerecord.attributes.candidate.last_name')"
                :error="getError('last_name')"
                qa-class="candidate-last-name"
              />
            </div>
          </div>
          <div
            class="flex space-x-6"
          >
            <div class="flex-1">
              <SelectList
                :label="$t('activerecord.attributes.candidate.sex')"
                :selected="initialSex"
                name="candidate[sex]"
                :options="sexOptions"
                :error="getError('sex')"
                :disabled="assessmentCompleted"
                :hint="$t('defaults.optional')"
              />
            </div>
            <div class="flex-1">
              <date-picker
                v-model.string="candidate.birthdate"
                :locale="$i18n.locale"
                mode="date"
                color="gray"
                :masks="masks"
                :update-on-input="false"
              >
                <template #default="{ inputValue, inputEvents }">
                  <div>
                    <BaseInput
                      type="text"
                      leading-icon="ClockIcon"
                      :disabled="assessmentCompleted"
                      :model-value="inputValue"
                      :label="$t('activerecord.attributes.candidate.birthdate')"
                      :error="getError('birthdate')"
                      :hint="$t('defaults.optional')"
                      v-on="inputEvents"
                    />
                    <input
                      :value="candidate.birthdate"
                      type="hidden"
                      :disabled="assessmentCompleted"
                      name="candidate[birthdate]"
                    >
                  </div>
                </template>
              </date-picker>
            </div>
          </div>
          <SelectList
            :label="$t('activerecord.attributes.candidate.locale')"
            :selected="initialLocale"
            name="candidate[locale]"
            :options="localeOptions"
            :error="getError('locale')"
            :disabled="assessmentCompleted"
          />
          <BaseInput
            v-model="candidate.email"
            name="candidate[email]"
            :disabled="assessmentCompleted"
            :label="$t('activerecord.attributes.candidate.email')"
            :error="getError('email')"
            :hint="$t('defaults.optional')"
            qa-class="candidate-email"
          />
          <ColorPicker
            v-model="candidate.color"
            :custom-palette="colorPalette"
            :disabled="assessmentCompleted"
            name="candidate[color]"
            :error="getError('color')"
          />
        </div>
      </TwoColumnCard>

      <Card>
        <MultiFileUpload
          accepted-mime-types="image/jpeg, image/png, image/gif, application/pdf, video/quicktime, video/mp4,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.openxmlformats-officedocument.presentationml.presentation"
          accepted-file-types="PDF, PNG, JPEG, MOV, MP4, DOCX, PPTX"
          :collection-path="collectionPath"
          :has-shareable-files="true"
          :disabled="assessmentCompleted"
          :help-text="$t('components.candidates.candidate_form.categories.multi_file_upload.helptext')"
          :use-primary-button="false"
          :parent-id="candidate.id"
          parent-type="Candidate"
        />
      </Card>
      <Card
        v-if="testsIsEnabled"
      >
        <TestList
          v-if="!candidate.demo"
          :tests="candidateData.tests"
          :assessment-id="assessmentId"
          :candidate="candidateData"
          :disabled="assessmentCompleted"
          :sex-options="sexOptions"
        />
      </Card>
    </div>
  </form>
</template>

<script>
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import useLocaleOptions from '@/composables/localeOptions';
import { useI18n } from '@/plugins/i18n';
import { assessmentCandidatePath, assessmentCandidatesPath, organisationAssessmentCandidatePath, organisationAssessmentCandidatesPath } from '@/util/url-helpers';
import { DatePicker } from 'v-calendar';
import { computed, ref, toRefs } from 'vue';
import { useConfirmDialogModal } from '@/composables/useConfirmDialogModal';
import TestList from './TestList.vue';

export default {
  components: {
    TestList,
    DatePicker,
    SelectList,
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    colorPalette: {
      type: Object,
      default: () => ({}),
    },
    candidateData: {
      type: Object,
      default: () => ({}),
    },
    assessmentId: {
      type: String,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    assessmentEditable: {
      type: Boolean,
      required: true,
    },
    assessmentActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    assessmentCompleted: {
      type: Boolean,
      required: true,
    },
    collectionPath: {
      type: String,
      required: true,
    },
    sexOptions: {
      type: Array,
      required: true,
    },
    testsIsEnabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { assessmentActive } = toRefs(props);

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];
    const candidate = { ...props.candidateData };
    const candidatesPath = props.organisationId
      ? organisationAssessmentCandidatesPath(props.organisationId, props.assessmentId)
      : assessmentCandidatesPath(props.assessmentId);
    const submitting = ref(false);
    const candidatePath = props.organisationId
      ? organisationAssessmentCandidatePath(props.organisationId, props.assessmentId, candidate.id)
      : assessmentCandidatePath(props.assessmentId, candidate.id);
    const initialSex = candidate.sex ? props.sexOptions.find((option) => option.value === candidate.sex) : null;

    const masks = { modelValue: 'YYYY-MM-DD' };

    const { t, availableLocales } = useI18n();
    const confirmDeletionMessage = computed(() => (assessmentActive.value ? t(
      'components.candidates.candidates_table.candidate_actions.do_you_want_to_delete_with_ratings',
      { name: candidate.name },
    ) : t(
      'components.candidates.candidates_table.candidate_actions.do_you_want_to_delete',
      { name: candidate.name },
    )));

    const { localeOptions, initialLocale } = useLocaleOptions(availableLocales, candidate.locale);

    const { openConfirmModal } = useConfirmDialogModal();

    const deleteCandidate = (event) => {
      event.preventDefault();
      openConfirmModal({
        open: true,
        message: confirmDeletionMessage,
        challenge: candidate.name,
        sourceElement: event.target.cloneNode(),
      });
    };

    return {
      getError,
      candidate,
      candidatePath,
      candidatesPath,
      submitting,

      confirmDeletionMessage,
      masks,
      initialSex,

      localeOptions,
      initialLocale,
      deleteCandidate,
    };
  },
};
</script>
