<template>
  <div class="relative space-y-6">
    <div class="width-mobile mx-auto sm:w-full sm:px-12 ">
      <p class="text-2xl leading-7 font-bold text-gray-900">
        {{ test.name }}
      </p>
    </div>
    <div class="flex-1  justify-between content-center content-height">
      <template v-if="test.status === 'started'">
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <TestStarted />
          <p class="text-center text-3xl leading-9 font-bold p-6">
            {{ $t('components.remote_session_tool.overview.test.results_not_available') }}
          </p>
        </div>
      </template>
      <template v-else-if="test.status === 'waiting'">
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div class="flex flex-col justify-center items-center">
            <TestReady />
            <p class="mt-7 text-3xl leading-9 font-bold text-gray-900">
              {{ $t('components.remote_session_tool.overview.test.ready') }}
            </p>
            <p
              v-if="test.duration"
              class="text-center mt-4 text-sm font-normal text-gray-500"
            >
              {{
                $t('components.remote_session_tool.overview.test.duration', { time: test.duration })
              }}
            </p>
            <BaseButton
              class="mt-12"
              :is-primary="true"
              icon="ClipboardCheckIcon"
              @click="openTest(test.url)"
            >
              {{ $t('defaults.start') }}
            </BaseButton>
          </div>
        </div>
      </template>
      <template v-else-if="test.status === 'aborted'">
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div class="flex flex-col justify-center items-center">
            <TestAborted />
            <p class="mt-7 text-3xl leading-9 font-bold text-gray-900">
              {{ $t('components.remote_session_tool.overview.test.aborted') }}
            </p>
            <p
              v-if="test.duration"
              class="text-center mt-4 text-sm font-normal text-gray-500"
            >
              {{
                $t('components.remote_session_tool.overview.test.contact_to_continue')
              }}
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="test.files.length > 0">
          <div class="justify-center sm:justify-start px-12 pb-6 gap-6 flex flex-row flex-wrap min-h-fit">
            <div
              v-for="(file, file_index) in test.files"
              :key="`file-${file_index}`"
            >
              <PreviewTile
                :file="file"
                @select-file="$emit('select-file', $event)"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div class="flex flex-col justify-center items-center">
              <TestSubmitted />
              <p class="text-center text-3xl leading-9 font-bold p-6">
                {{ $t('components.remote_session_tool.overview.test.submitted') }}
              </p>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import PreviewTile from '@/components/RemoteSessionTool/PreviewTile.vue';
import TestAborted from '@/components/RemoteSessionTool/Graphics/TestAborted.vue';
import TestReady from '@/components/RemoteSessionTool/Graphics/TestReady.vue';
import TestStarted from '@/components/RemoteSessionTool/Graphics/TestStarted.vue';
import TestSubmitted from '@/components/RemoteSessionTool/Graphics/TestSubmitted.vue';

import BaseButton from '../generic/BaseButton/BaseButton.vue';

export default {
  name: 'TestView',
  components: {
    PreviewTile,
    TestAborted,
    TestReady,
    BaseButton,
    TestStarted,
    TestSubmitted,
  },
  props: {
    test: {
      type: Object,
      required: true,
    },
  },
  emits: ['select-file'],
  setup() {
    function openTest(url) {
      window.open(url);
    }
    return {
      openTest,
    };
  },
};
</script>
<style scoped>
.content-height {
    height: calc(100vh - 164px); /* subtract header and title */
}
</style>
