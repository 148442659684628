<template>
  <form
    ref="form"
    class="h-full"
    @submit.prevent="submit"
  >
    <input
      type="hidden"
      name="authenticity_token"
      :value="$csrfToken"
    >

    <div class="flex flex-col justify-between h-full">
      <div class="px-4 py-6 sm:px-6 space-y-5">
        <BaseInput
          v-model="user.firstName"
          :label="$t('activerecord.attributes.user.first_name')"
          type="text"
          name="user[first_name]"
          :error="getError('first_name')"
        />
        <BaseInput
          v-model="user.lastName"
          :label="$t('activerecord.attributes.user.last_name')"
          type="text"
          name="user[last_name]"
          :error="getError('last_name')"
        />
        <BaseInput
          v-model="user.email"
          :label="$t('activerecord.attributes.user.email')"
          type="text"
          name="user[email]"
          :error="getError('email')"
        />
        <SelectList
          v-model="locale"
          :label="$t('activerecord.attributes.user.locale')"
          :selected="locale"
          name="user[locale]"
          :options="localeOptions"
          :error="getError('locale')"
        />
        <SearchableSelectList
          id="select-timezone"
          v-model:selected="timezone"
          :label="$t('activerecord.attributes.user.time_zone')"
          :options="availableTimeZones"
          value-prop="name"
          label-prop="name"
          secondary-label-prop="offset"
          name="user[time_zone]"
          :allow-reset="false"
        />
        <div class="space-y-5">
          <span
            class="block text-sm font-medium text-gray-700"
          >
            {{ $t(`components.create_observer_form.sub_header.roles`) }}
          </span>
          <div
            v-for="role in availableRoles"
            :key="role.name"
          >
            <BaseCheckbox
              :value="role.name"
              :unchecked-value="''"
              :disabled="role.name === 'observer'"
              :model-value="role.name === 'observer'"
              :label="$t(`components.shared.roles.${role.name}.name`)"
              :secondary-label="$t(`components.shared.roles.${role.name}.helptext`)"
              name="user[role_names][]"
              @update:model-value="updateRole($event, role.name)"
            />
          </div>
        </div>
        <div class="space-y-5">
          <span
            class="block text-sm font-medium text-gray-700"
          >
            {{ $t(`components.create_observer_form.sub_header.notifications`) }}
          </span>
          <BaseCheckbox
            v-model="user.sendInvitation"
            :label="$t(`components.create_observer_form.invitation`)"
            :secondary-label="$t(`components.create_observer_form.invitation_description`)"
            name="user[role_names][]"
          />
        </div>
      </div>

      <div class="space-x-5 flex justify-end border-t border-gray-200 p-5">
        <BaseButton
          :is-primary="false"
          :is-disabled="isSubmitting"
          @click="$emit('cancel')"
        >
          {{ $t('defaults.cancel') }}
        </BaseButton>
        <SaveButton
          :is-loading="isSubmitting"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent, ref } from 'vue';
import useLocaleOptions from '@/composables/localeOptions';
import { useTimezoneOptions } from '@/composables/timezoneOptions';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import SaveButton from '@/components/generic/SaveButton/SaveButton.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    BaseInput,
    BaseButton,
    SelectList,
    SearchableSelectList,
    SaveButton,
  },
  props: {
    userData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    availableRoles: {
      type: Array,
      required: true,
    },
    availableTimeZones: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['submit-form', 'cancel'],
  setup(props, { emit }) {
    const user = ref({ ...props.userData, sendInvitation: true });
    user.value.roleNames = ['observer'];

    const updateRole = (add, name) => {
      if (add) {
        user.value.roleNames.push(name);
      } else {
        user.value.roleNames = user.value.roleNames.filter((role) => role !== name);
      }
    };

    const { availableLocales } = useI18n();

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    const { timezoneOptions, initialTimezone } = useTimezoneOptions(props.availableTimeZones, user.value.timeZone);
    const { localeOptions, initialLocale } = useLocaleOptions(availableLocales, user.value.locale);

    const locale = ref(initialLocale);
    const timezone = ref(initialTimezone);

    const submit = () => {
      const userToSubmit = user.value;
      userToSubmit.locale = locale.value.value;
      userToSubmit.timeZone = timezone.value?.name;
      emit('submit-form', userToSubmit);
    };

    return {
      user,
      submit,
      getError,
      updateRole,
      timezoneOptions,
      timezone,
      localeOptions,
      locale,
    };
  },
});
</script>
