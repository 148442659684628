import Axios from 'axios';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// eslint-disable-next-line import/prefer-default-export
export const useCandidateSelfRatingsStore = defineStore('candidateSelfRatings', () => {
  const toast = inject('toast');
  const { t } = useI18n();
  const isSubmitting = ref(false);

  const candidateSelfRatings = ref([]);

  const currentExercise = ref(null);
  const setCurrentExercise = (exercise) => {
    currentExercise.value = exercise || null;
  };

  const candidateSelfRatingsConfig = ref(null);
  function setConfig(config) {
    candidateSelfRatingsConfig.value = config;
  }

  const competenceModel = ref(null);
  function setCompetenceModel(model) {
    competenceModel.value = model;
  }

  const sharedSelfAssessmentExerciseIds = ref([]);
  function setSharedSelfAssessmentExerciseIds(shared) {
    sharedSelfAssessmentExerciseIds.value = shared;
  }
  function shareSharedSelfAssessment(exerciseId) {
    if (!sharedSelfAssessmentExerciseIds.value.includes(exerciseId)) {
      sharedSelfAssessmentExerciseIds.value = [...sharedSelfAssessmentExerciseIds.value, exerciseId];
    }
  }
  function revokeSharedSelfAssessment(exerciseId) {
    sharedSelfAssessmentExerciseIds.value = sharedSelfAssessmentExerciseIds.value.filter((id) => id !== exerciseId);
  }

  const candidate = ref(null);
  function setCandidate(newCandidate) {
    candidate.value = newCandidate;
  }

  const exercises = computed(() => {
    const exercisesList = competenceModel.value?.exercises || [];

    return candidateSelfRatingsConfig.value.perExercise && candidateSelfRatingsConfig.value.shareSelfAssessment
      ? exercisesList.map((exercise) => ({
        ...exercise,
        shared: sharedSelfAssessmentExerciseIds.value.includes(exercise.id),
      }))
      : exercisesList;
  });

  const competencies = computed(() => {
    if (currentExercise.value) {
      return currentExercise?.value.competencies;
    }

    return competenceModel.value?.competencies || [];
  });

  const scaleLabels = computed(() => competenceModel.value?.scaleLabels || []);

  const anyScaleLabelDescription = computed(() => scaleLabels.value.some((label) => label.description));

  const symbolScaleEnabled = computed(() => competenceModel.value?.scaleLabelSymbolsEnabled || false);

  function upsertCandidateSelfRating({ exerciseId, competencyId, value }) {
    const rating = candidateSelfRatings.value.find((r) => r.exercise_id === exerciseId
      && r.competency_id === competencyId);

    if (rating) {
      rating.value = value;
    } else {
      candidateSelfRatings.value.push({
        candidate_id: candidate.value.id,
        exercise_id: exerciseId,
        competency_id: competencyId,
        value,
      });
    }
  }

  function setRatingForCompetency(competency, value) {
    upsertCandidateSelfRating({
      exerciseId: currentExercise?.value?.id || null,
      competencyId: competency.id,
      value,
    });
  }

  // eslint-disable-next-line max-len
  const ratingForCompetencyId = computed(() => (competencyId) => candidateSelfRatings.value.find((rating) => rating.competency_id === competencyId && rating.exercise_id === (currentExercise?.value?.id || null)));

  const exerciseCompleted = computed(() => (exercise) => exercise.competencies.every((competency) => {
    const rating = candidateSelfRatings.value.find((r) => r.competency_id === competency.id
        && r.exercise_id === exercise.id);
    return rating !== undefined && rating !== null;
  }));

  const currentRatingCompleted = computed(() => {
    if (currentExercise.value) {
      return exerciseCompleted.value(currentExercise?.value);
    }

    return competencies.value.every((competency) => {
      const rating = candidateSelfRatings.value.find((r) => r.competency_id === competency.id);
      return rating !== undefined && rating !== null;
    });
  });

  const ratingCompleted = ref(false);
  const setRatingCompleted = (status) => {
    ratingCompleted.value = status;
  };
  const setRatingCompletedStatus = () => {
    if (candidateSelfRatingsConfig.value.perExercise) {
      ratingCompleted.value = exercises.value.every((exercise) => exerciseCompleted.value(exercise));
    } else {
      ratingCompleted.value = competencies.value.every((competency) => {
        const rating = candidateSelfRatings.value.find((r) => r.competency_id === competency.id);
        return rating !== undefined && rating !== null;
      });
    }
  };

  const ratingLocked = computed(() => {
    if (candidateSelfRatingsConfig.value.perExercise) {
      return exercises.value.every((exercise) => !exercise.shared);
    }
    return false;
  });

  const setCandidateSelfRatings = (ratings) => {
    candidateSelfRatings.value = ratings;
    setRatingCompletedStatus();
  };

  async function fetchRatings() {
    try {
      const ratings = await Axios.get('/remote/candidate_self_ratings');
      candidateSelfRatings.value = ratings.data;
      setRatingCompletedStatus();
    } catch (error) {
      // ToDo localize
      toast.error('Error fetching self ratings');
    }
  }

  const submitSelfRating = async () => {
    try {
      isSubmitting.value = true;
      if (currentExercise.value) {
        await Axios.put('/remote/candidate_self_ratings/batch_update', {
          candidate_self_ratings: candidateSelfRatings.value.filter((r) => r.exercise_id === currentExercise.value.id),
        });
      } else {
        await Axios.put('/remote/candidate_self_ratings/batch_update', {
          candidate_self_ratings: candidateSelfRatings.value,
        });
      }
      setRatingCompletedStatus();
    } catch (error) {
      // ToDo localize
      toast.error(t('components.candidate_self_rating.submit_error'));
      await fetchRatings();
    } finally {
      isSubmitting.value = false;
    }

    currentExercise.value = null;
  };

  return {
    isSubmitting,
    fetchRatings,

    setConfig,
    setCompetenceModel,
    setSharedSelfAssessmentExerciseIds,
    selfRatingEnabled: computed(() => candidateSelfRatingsConfig.value.enabled),
    selfRatingPerExercise: computed(() => candidateSelfRatingsConfig.value.perExercise),
    showCompetencyDescription: computed(() => candidateSelfRatingsConfig.value.showCompetencyDescription),
    showBehaviorAnchors: computed(() => candidateSelfRatingsConfig.value.showBehaviorAnchors),
    candidateInstructions: computed(() => candidateSelfRatingsConfig.value.candidateInstructions),
    currentExercise: computed(() => currentExercise.value),
    setCurrentExercise,
    exercises,
    competencies,
    scaleLabels,
    submitSelfRating,
    symbolScaleEnabled,
    ratingForCompetencyId,
    exerciseCompleted,
    upsertCandidateSelfRating,
    setRatingForCompetency,
    setCandidate,
    currentRatingCompleted,
    ratingCompleted,
    setRatingCompleted,
    ratingLocked,
    setCandidateSelfRatings,
    anyScaleLabelDescription,
    shareSharedSelfAssessment,
    revokeSharedSelfAssessment,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCandidateSelfRatingsStore, import.meta.hot));
}
