import { useI18n } from 'vue-i18n';

export default (roles) => {
  const { t } = useI18n();

  const observerRoleOptions = roles.map((role) => ({
    value: role,
    name: t(`components.shared.observer_roles.${role}.name`),
    description: t(`components.shared.observer_roles.${role}.description`),
  }));

  return {
    observerRoleOptions,
  };
};
