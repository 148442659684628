<template>
  <button
    ref="button"
    name="button"
    type="submit"
    :class="[buttonClass]"
    :disabled="disabled"
  >
    <div
      :class="{'opacity-0': disabled}"
      class="transition-opacity"
    >
      <slot>{{ $t('defaults.save') }}</slot>
    </div>
    <div
      :class="{'opacity-0': !disabled}"
      class="spinner transition-opacity"
    />
  </button>
</template>
<script>
export default {
  name: 'SubmitButton',
  props: {
    buttonClass: {
      type: String,
      default: 'btn btn-green relative',
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  mounted() {
    const { button } = this.$refs;
    const form = button.closest('form');

    form.addEventListener('submit', () => {
      this.disabled = true;
    });

    const enableButton = () => {
      this.disabled = false;
    };
    form.addEventListener('ajax:error', enableButton);
  },
};
</script>
