<template>
  <div>
    <div class="mb-6">
      <PageHeading>
        {{ title }}

        <template #actions>
          <SaveButton
            :is-loading="submitting"
            form="edit-competence-model-form"
          >
            {{ $t('defaults.save') }}
          </SaveButton>
        </template>
      </PageHeading>
    </div>
    <form
      id="edit-competence-model-form"
      accept-charset="UTF-8"

      :action="path"
      method="post"
      @submit="submitting = true"
    >
      <input
        type="hidden"
        name="authenticity_token"
        :value="$csrfToken"
      >
      <input
        type="hidden"
        name="_method"
        value="put"
      >
      <div class="space-y-5">
        <CompetenceModelFormFields
          :is-editable="isEditable"
          :show-name="showName"
          :competence-model="competenceModel"
          :errors="errors"
          :organisation="organisation"
          :available-organisations="availableOrganisations"
          :available-report-templates="availableReportTemplates"
        />
        <div>
          <Card>
            <MatrixWizard
              v-model:competence-model-data="model"
              :organisation-id="organisation?.id"
              :disabled="!competenceModel.isEditable"
            />
          </Card>
        </div>
        <CandidateSelfRatingConfig
          v-if="!!competenceModel"
          :competence-model-id="model.id"
          :disabled="!isEditable"
          :config="model.candidateSelfRatingConfig"
        />
        <div class="pb-5">
          <TargetProfile
            v-model:competence-model="model"
            :disabled="!isEditable"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { competenceModelTemplatePath, organisationCompetenceModelTemplatePath } from '@/util/url-helpers';
import { defineComponent, ref } from 'vue';
import CandidateSelfRatingConfig from '../CandidateSelfRatingConfig.vue';
import MatrixWizard from '../MatrixWizard.vue';
import TargetProfile from '../TargetProfile/TargetProfile.vue';

export default defineComponent({
  components: {
    MatrixWizard,
    CandidateSelfRatingConfig,
    TargetProfile,
  },
  props: {
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    showName: {
      type: Boolean,
      required: false,
      default: true,
    },
    competenceModel: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    organisation: {
      type: Object,
      required: false,
      default: null,
    },
    availableOrganisations: {
      type: Array,
      default: () => [],
      required: false,
    },
    availableReportTemplates: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  setup(props) {
    const path = props.organisation
      ? organisationCompetenceModelTemplatePath(props.organisation.id, props.competenceModel.id)
      : competenceModelTemplatePath(props.competenceModel.id);
    const model = ref(props.competenceModel);

    const submitting = ref(false);

    return {
      model,
      path,
      submitting,
    };
  },
});
</script>
