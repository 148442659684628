<template>
  <div>
    <Card class="p-6">
      <div
        v-if="!symbolScaleEnabled && anyScaleLabelDescription"
        class="hidden lg:flex justify-end pb-8"
      >
        <ButtonGroup :class="[buttonGroupWidthClass]">
          <BaseButton
            v-for="scaleLabel in scaleLabels"
            :key="scaleLabel.id"
            :is-primary="false"
            as="div"
            class="flex-1 overflow-hidden text-xs font-normal leading-tight
              hover:bg-transparent px-2 text-center break-all whitespace-break-spaces"
          >
            {{ scaleLabel.description || scaleLabel.value }}
          </BaseButton>
        </ButtonGroup>
      </div>

      <div
        v-for="competency in competencies"
        :key="competency.id"
      >
        <p class="text-gray-800 text-sm font-medium mb-2">
          {{ competency.name }}
        </p>
        <div class="flex items-start flex-wrap pb-8">
          <div :class="[textWidthClass, 'space-y-4 mb-4 2xl:mb-0']">
            <p
              v-if="showCompetencyDescription && competency.description"
              class="text-sm text-gray-500 2xl:pr-8"
            >
              {{ competency.description }}
            </p>

            <div
              v-if="showBehaviorAnchors && competency.behaviorAnchorDescriptions.length > 0"
              class="text-sm text-gray-500 2xl:pr-8"
            >
              <p class="mb-2">
                {{ $t('components.candidate_self_rating.behavior_examples') }}
              </p>

              <ul class="space-y-2 list-disc list-inside ">
                <template
                  v-for="behaviorAnchor in behaviorAnchorDescriptionsWithIndex(competency)"
                  :key="behaviorAnchor.index"
                >
                  <li
                    v-if="behaviorAnchor.index <= 5"
                  >
                    {{ behaviorAnchor.description }}
                  </li>
                </template>
              </ul>

              <TransitionExpand>
                <ul
                  v-show="showMoreBehaviorAnchors[competency.id]"
                  class="space-y-2 mt-2  list-disc list-inside"
                >
                  <template
                    v-for="behaviorAnchor in behaviorAnchorDescriptionsWithIndex(competency)"
                    :key="behaviorAnchor.index"
                  >
                    <li
                      v-if="behaviorAnchor.index > 5"
                    >
                      {{ behaviorAnchor.description }}
                    </li>
                  </template>
                </ul>
              </TransitionExpand>
              <button
                v-if="behaviorAnchorDescriptionsWithIndex(competency).length > 5"
                class="text-primary-500 mt-2 flex items-center"
                @click="showMoreBehaviorAnchors[competency.id] = !showMoreBehaviorAnchors[competency.id]"
              >
                <span>{{ $t('components.candidate_self_rating.show_more') }}</span>
                <ChevronDownIcon
                  :class="['block h-4 w-4 ml-1 transition-transform',
                           showMoreBehaviorAnchors[competency.id] ? 'rotate-180' : '']"
                />
              </button>
            </div>
          </div>
          <ButtonGroup :class="[buttonGroupWidthClass, 'hidden lg:flex']">
            <BaseButton
              v-for="scaleLabel in scaleLabels"
              :key="scaleLabel.id"
              :is-primary="isSelected(competency, scaleLabel.value)"
              class="flex-1 px-2 text-center break-all whitespace-break-spaces"
              @click="selfRatingStore.setRatingForCompetency(competency, scaleLabel.value)"
            >
              {{ symbolScaleEnabled ? scaleLabel.description : scaleLabel.value }}
            </BaseButton>
          </ButtonGroup>
          <RadioList
            class="w-full block lg:hidden"
            :model-value="valueForCompetency(competency)"
            :options="scaleLabels"
            label-prop="description"
            fallback-label-prop="value"
            :description-prop="null"
            @update:model-value="selfRatingStore.setRatingForCompetency(competency, $event)"
          />
        </div>
      </div>
    </Card>
    <div class="flex w-full justify-end">
      <BaseButton
        icon="CheckIcon"
        class="mt-4"
        :is-loading="isSubmitting"
        :is-disabled="!currentRatingCompleted"
        @click="selfRatingStore.submitSelfRating"
      >
        {{ $t('components.candidate_self_rating.submit') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { useCandidateSelfRatingsStore } from '@/components/RemoteSessionTool/stores/candidateSelfRatingsStore';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import ButtonGroup from '@/components/generic/ButtonGroup/ButtonGroup.vue';
import Card from '@/components/generic/Card/Card.vue';
import RadioList from '@/components/generic/RadioList/RadioList.vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import TransitionExpand from '@/components/generic/TransitionExpand/TransitionExpand.vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';

export default {
  name: 'CandidateSelfRatingMatrix',
  components: {
    Card,
    BaseButton,
    ButtonGroup,
    RadioList,
    TransitionExpand,
    ChevronDownIcon,
  },
  setup() {
    const selfRatingStore = useCandidateSelfRatingsStore();
    const {
      currentExercise,
      competencies,
      showCompetencyDescription,
      showBehaviorAnchors,
      scaleLabels,
      symbolScaleEnabled,
      isSubmitting,
      currentRatingCompleted,
      anyScaleLabelDescription,
    } = storeToRefs(selfRatingStore);

    const showMoreBehaviorAnchors = ref({
      ...competencies.value.reduce((acc, competency) => {
        acc[competency.id] = false;
        return acc;
      }, {}),
    });

    const textWidthClass = computed(() => {
      if (scaleLabels.value.length < 7) {
        return 'w-full 2xl:w-1/2';
      }

      return 'w-full 2xl:w-1/4';
    });

    const buttonGroupWidthClass = computed(() => {
      if (scaleLabels.value.length < 7) {
        return 'w-full 2xl:w-1/2';
      }

      return 'w-full 2xl:w-3/4';
    });

    // eslint-disable-next-line max-len
    const isSelected = computed(() => (competency, value) => selfRatingStore.ratingForCompetencyId(competency.id)?.value === value);
    // eslint-disable-next-line max-len
    const valueForCompetency = computed(() => (competency) => selfRatingStore.ratingForCompetencyId(competency.id)?.value);

    const behaviorAnchorDescriptionsWithIndex = computed(() => (competency) => {
      const { behaviorAnchorDescriptions } = competency;
      return behaviorAnchorDescriptions.map((description, index) => ({ index: index + 1, description }));
    });

    return {
      currentExercise,
      competencies,
      showCompetencyDescription,
      showBehaviorAnchors,
      scaleLabels,
      selfRatingStore,
      textWidthClass,
      buttonGroupWidthClass,
      symbolScaleEnabled,
      isSelected,
      valueForCompetency,
      isSubmitting,
      currentRatingCompleted,
      anyScaleLabelDescription,
      behaviorAnchorDescriptionsWithIndex,
      showMoreBehaviorAnchors,
    };
  },
};
</script>
