<template>
  <BaseButton
    icon="ClipboardListIcon"
    :is-primary="false"
    @click="openAddTestSlideOver"
  >
    {{ $t('components.candidates.candidates_table.add_tests') }}
  </BaseButton>
  <TestSlideOver
    v-model:open="showAddTestSlideOver"
    :assessment-id="assessmentId"
    :candidates="candidates"
  />
</template>

<script>
import { defineComponent, ref } from 'vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import TestSlideOver from '@/components/Candidates/EditCandidate/TestSlideOver.vue';

export default defineComponent({
  components: {
    BaseButton,
    TestSlideOver,
  },
  props: {
    candidates: {
      type: Array,
      required: true,
    },
    assessmentId: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    // SlideOver State
    const showAddTestSlideOver = ref(false);

    const openAddTestSlideOver = () => {
      showAddTestSlideOver.value = true;
    };

    return {
      showAddTestSlideOver,
      openAddTestSlideOver,
    };
  },
});
</script>
