<template>
  <div
    class="border rounded-lg m-2 p-2 h-12 flex justify-center items-center text-center
    text-sm font-semibold text-black-900 hover:bg-gray-50"
    :class="[{ 'cursor-pointer': isInteractive, 'pointer-events-none': !isInteractive },
             qaClass !== '' ? `qa-${qaClass}` : null]"
    :draggable="isInteractive"
    @click="click"
    @dragstart="dragstart"
  >
    <!-- eslint-disable-next-line max-len -->
    {{ $t('components.competence_model.matrix_wizard.behavior_anchor_count', { count: ratingAnchor.behaviorAnchorsCount}) }}
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AnchorItem',
  props: {
    ratingAnchor: {
      type: Object,
      default: null,
    },
    path: {
      type: String,
      required: true,
    },
    isInteractive: {
      type: Boolean,
      required: true,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const dragstart = (event) => {
      event.dataTransfer.setData('text/plain', props.ratingAnchor.id);
    };

    return {
      click() { Turbo.visit(props.path); },
      dragstart,
    };
  },
});
</script>
