<template>
  <div
    id="bryntumContainer"
    ref="bryntumContainer"
    class="content-container"
  >
    <bryntum-scheduler
      ref="scheduler"
      v-bind="schedulerConfig"
      :start-date="startDate"
      :end-date="endDate"
    />
    <bryntum-splitter style="height: 100%; background: #e9eaeb; cursor: col-resize;" />
  </div>
</template>

<script>
import AssessmentEventStore from '@/lib/Scheduler/AssessmentEventStore';
import Drag from '@/lib/Scheduler/Drag';
import UnplannedGrid from '@/lib/Scheduler/UnplannedGrid';
import { destroyStores, initializeStores, stores } from '@/lib/Scheduler/stores';
import { DateHelper } from '@bryntum/scheduler';
import { BryntumScheduler, BryntumSplitter } from '@bryntum/scheduler-vue-3';
import { configureGrid } from './config/grid';
import { configureScheduler, setLocale } from './config/scheduler';

export default {
  components: {
    BryntumSplitter,
    BryntumScheduler,
  },
  props: {
    assessmentId: {
      type: Number,
      required: true,
    },
    assessmentStartDate: {
      type: Date,
      required: true,
    },
    assessmentEndDate: {
      type: Date,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startDate: DateHelper.add(this.assessmentStartDate, -2, 'h'),
      endDate: DateHelper.add(this.assessmentEndDate, 2, 'h'),

      crudManagerConfig: {
        supportShortSyncResponse: false,
        skipSuccessProperty: false,
        autoLoad: true,
        autoSync: true,
        resourceStore: stores.resourceStore,
        eventStore: {
          storeClass: AssessmentEventStore,
        },
        transport: {
          load: {
            url: `/assessments/${this.assessmentId}/schedule/edit`,
            headers: {
              Accept: 'application/json',
            },
          },
          sync: {
            method: 'PUT',
            url: `/assessments/${this.assessmentId}/schedule/sync`,
            headers: {
              Accept: 'application/json',
              'X-CSRF-Token': this.$csrfToken,
            },
            credentials: 'include',
          },
        },
        listeners: {
          load: this.onLoad,
          sync: this.beforeSync,
          loadFail: this.onError,
          syncFail: this.onError,
        },
      },
    };
  },
  computed: {
    schedulerConfig() {
      const { schedulerFeatures, config } = configureScheduler({
        startDate: this.assessmentStartDate,
        endDate: this.assessmentEndDate,
        onChangeStartDate: this.onChangeStartDate,
        onChangeEndDate: this.onChangeEndDate,
        onZoom: this.zoom,
        config: {
          ref: 'scheduler',
          crudManager: this.crudManagerConfig,
          readOnly: this.disabled,
        },
      });

      return {
        ...schedulerFeatures,
        ...config,
      };
    },
    gridConfig() {
      return configureGrid({
        eventStore: this.scheduler.eventStore,
        assignmentStore: this.scheduler.assignmentStore,
        assessmentId: this.assessmentId,
        config: {
          ref: 'grid',
          readOnly: this.disabled,
        },
      });
    },
  },
  beforeCreate() {
    initializeStores();
    // only show observers as columns
    stores.resourceStore.filter((resource) => resource.type === 'observer');
  },
  created() {
    setLocale();
  },
  beforeUnmount() {
    destroyStores();
  },
  mounted() {
    this.scheduler = this.$refs.scheduler.instance.value;

    this.scheduler.autoRescheduleTasks = false;

    this.scheduler.eventStore.on({
      update: this.onEventStoreUpdate,
      add: this.onEventStoreAdd,
      thisObj: this.scheduler,
    });

    const grid = new UnplannedGrid({
      appendTo: this.$refs.bryntumContainer,
      ...this.gridConfig,
    });

    // posthog:mask table header
    const headers = this.$refs.bryntumContainer.getElementsByTagName('header');
    headers[0].classList.add('ph-no-capture');

    if (!this.disabled) {
      // eslint-disable-next-line no-new
      new Drag({
        grid,
        schedule: this.scheduler,
        constrain: false,
        outerElement: grid.element,
      });
    }
  },
  methods: {
    // specific to this example - reschedules the tasks
    onEventStoreUpdate({ record }) {
      if (this.scheduler.autoRescheduleTasks) {
        this.scheduler.eventStore.rescheduleOverlappingEvents(record);
      }
    },
    // specific to this example - reschedules the tasks
    onEventStoreAdd({ records }) {
      if (this.scheduler.autoRescheduleTasks) {
        records.forEach((eventRecord) => this.scheduler.eventStore.rescheduleOverlappingEvents(eventRecord));
      }
    },

    setTimeSpan() {
      this.scheduler.setTimeSpan(this.startDate, this.endDate);
    },
    onChangeStartDate({ value }) {
      if (value.toDateString() === this.assessmentStartDate.toDateString()) {
        this.startDate = DateHelper.add(this.assessmentStartDate, -2, 'h');
      } else {
        this.startDate = value;
      }
      this.scheduler.tbar.widgetMap.endDate.min = this.scheduler.tbar.widgetMap.startDate.value;
      this.setTimeSpan();
    },
    onChangeEndDate({ value }) {
      if (value.toDateString() === this.assessmentEndDate.toDateString()) {
        this.endDate = DateHelper.add(this.assessmentEndDate, 2, 'h');
      } else {
        this.endDate = DateHelper.add(value, 1, 'days');
      }
      this.scheduler.tbar.widgetMap.startDate.max = this.scheduler.tbar.widgetMap.endDate.value;
      this.setTimeSpan();
    },
    zoom({ value }) {
      this.scheduler.zoomLevel = value;
      this.setTimeSpan();
    },

    onError(event) {
      try {
        this.$toast.error(event.response.error);
      } catch (error) {
        this.$toast.error(this.$t('errors.unknown_error'));
      }
      const { eventStore } = this.scheduler;
      this.scheduler.crudManager.revertChanges();
      eventStore.stm.undoAll();
    },
    async onLoad() {
      await this.scheduler.project.commitAsync();
      this.scheduler.eventStore.stm.autoRecord = true;
      this.scheduler.eventStore.stm.enable();
    },
    beforeSync() {
      // workaround for when the stateManager is not ready before adding a new event
      // see https://www.bryntum.com/forum/viewtopic.php?p=73749#p73749
      const clearUndoStack = () => {
        if (this.scheduler.eventStore.stm.isReady) {
          this.scheduler.eventStore.stm.resetQueue();
        } else {
          this.scheduler.eventStore.stm.on({
            recordingStop: clearUndoStack,
            once: true,
          });
        }
      };

      clearUndoStack();
    },
  },
};
</script>

<style lang="scss">
.content-container {
  @apply flex flex-row h-full;

  .b-vue-scheduler-container {
    @apply h-full;
  }

  .b-unplannedgrid {
    .b-grid-cell {
      @apply cursor-pointer;
    }

    i {
      @apply mr-2;
      @apply text-blue-500;
    }
  }
}

.b-grid-header {
  height: 57px;
}

.b-sch-event,
.b-sch-event-content {
  @apply grow;
}

.b-sch-event {
  @apply pr-2;
}
</style>
