<template>
  <div
    class="relative w-full my-0 mx-auto"
    v-bind="$attrs"
  >
    <div class="flex justify-between">
      <label
        v-if="label"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </label>
      <span class="text-sm text-gray-500">{{ hint }}</span>
    </div>
    <div
      v-click-away="blur"
      :tabindex="searching || disabled ? -1 : 0"
      :class="[
        (error && !disabled) ? 'border-red-500 focus-within:ring-red-500 focus-within:border-red-500' :
        (disabled && !error) ? 'bg-gray-100' :
        'border-gray-300 focus-within:ring-primary-500 focus-within:border-primary-500',
        !disabled ? 'focus-within:ring-1' : '',
        'listbox-button relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2',
        'text-left cursor-default focus:outline-none mt-1 min-h-10',
        `${qaClass !== '' ? `qa-${qaClass}` : ''}`,
      ]"

      v-on="eventListeners"
    >
      <input
        v-if="searching || !selectedResult"
        :id="id"
        ref="searchInput"
        v-model.trim="searchTerm"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholderText"
        v-bind="$attrs"
        type="text"
        tabindex="0"
        role="input"
        autocomplete="off"
        class="block w-full pr-7 outline-none border-none focus:ring-0 focus:border-none p-0 text-sm"
        :class="{
          'bg-gray-100': disabled,
        }"
        @keyup.up="previousResult"
        @keyup.down="nextResult"
        @keyup.enter.stop="selectResult(resultPointer)"
        @keydown.enter.prevent
        @keyup.esc.stop="blur"
        @blur="blur"
      >
      <template v-else>
        <input
          :value="selectedResult[valueProp]"
          :name="name"
          type="hidden"
        >

        <slot
          name="selected-result"
          :result="selectedResult"
        >
          <div class="flex text-sm">
            {{ selectedResult[labelProp] }}
            <div
              v-if="secondaryLabelProp"
              class="ml-2 truncate text-gray-500 text-sm"
            >
              {{ selectedResult[secondaryLabelProp] }}
            </div>
          </div>
        </slot>
      </template>

      <div
        v-if="!disabled"
        class="absolute inset-y-0 right-0 pr-2 flex items-center text-gray-500"
      >
        <button
          v-if="selectedResult && allowReset"
          class="transition-colors duration-200 cursor-pointer
            outline-none focus:ring ring-primary-500 hover:text-primary-500 rounded-sm"
          @focus.stop
          @click.stop.prevent="clear"
        >
          <TrashIcon
            class="h-5 w-5"
            aria-hidden="true"
          />
        </button>

        <SelectorIcon
          class="h-5 w-5"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      v-show="searching"
      ref="optionList"
      class="absolute mt-1 w-full overflow-y-auto shadow-lg rounded bg-white z-30"
      :style="{ 'max-height': maxHeight }"
    >
      <span
        v-for="(result, index) in results"
        :key="`${result[labelProp]}-${index}`"
        :class="`flex
        w-full
        cursor-pointer
        text-sm
        relative
        py-2
        pl-3
        ${optionQaClass}
        ${index === resultPointer ? 'bg-primary-600' : ''}`"
        @mousedown.stop.prevent="selectResult(index)"
        @mousemove="resultPointer = index"
      >
        <slot
          :result="result"
          :selected="selectedResult && result[valueProp] == selectedResult[valueProp]"
          :active="index === resultPointer"
          name="dropdown-result"
        >
          <div class="flex space-between w-full">
            <div class="flex items-center flex-1">
              <div
                :class="{
                  'font-semibold' : selectedResult && result[valueProp] == selectedResult[valueProp],
                  'text-white bg-primary-600': index === resultPointer
                }"
              >
                {{ result[labelProp] }}
              </div>
              <div
                v-if="secondaryLabelProp"
                class="ml-2 truncate"
                :class="{
                  'text-primary-200' : index === resultPointer,
                  'text-gray-500': !(index === resultPointer),
                }"
              >
                {{ result[secondaryLabelProp] }}
              </div>
            </div>
            <div
              v-if="selectedResult && result[valueProp] === selectedResult[valueProp]"
              class="h-full flex items-center pr-4"
            >
              <CheckIcon
                class="h-5 w-5"
                :class="{
                  'text-white': index === resultPointer,
                  'text-primary-500': index !== resultPointer
                }"
                aria-hidden="true"
              />
            </div>
          </div>
        </slot>
      </span>
    </div>
    <span
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>

import { CheckIcon, SelectorIcon, TrashIcon } from '@heroicons/vue/outline';

import {
  ref, computed, watch, nextTick, watchEffect,
} from 'vue';
import { mixin as clickaway } from '../../../util/click-away';

export default {
  name: 'SearchableSelectList',
  components: {
    CheckIcon,
    SelectorIcon,
    TrashIcon,
  },
  mixins: [clickaway],
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    optionsEmptyMessage: {
      type: String,
      default: '',
    },
    selected: {
      type: Object,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },

    valueProp: {
      type: String,
      default: 'value',
    },
    labelProp: {
      type: String,
      default: 'name',
    },
    secondaryLabelProp: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: String,
      default: '15rem',
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowReset: {
      type: Boolean,
      default: true,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:selected', 'focus', 'blur'],
  setup(props, { emit }) {
    const searchTerm = ref('');
    const resultPointer = ref(-1);
    const searching = ref(false);

    const searchInput = ref(null);
    const optionList = ref(null);

    const selectedResult = ref(props.selected);
    watchEffect(() => { selectedResult.value = props.selected; });

    const results = computed(() => {
      if (searchTerm.value) {
        const query = searchTerm.value.toLowerCase();

        if (props.secondaryLabelProp) {
          return props.options.filter((option) => (
            `${option[props.labelProp].toLowerCase()} ${option[props.secondaryLabelProp].toLowerCase()}`
              .includes(query)));
        }
        return props.options.filter((option) => option[props.labelProp].toLowerCase().includes(query));
      }

      return props.options;
    });

    const resetSelection = () => {
      resultPointer.value = props.options.indexOf(selectedResult.value);
      searching.value = true;
      nextTick(() => searchInput.value.focus());
    };

    const openSelection = () => {
      resultPointer.value = 0;
      searching.value = true;
      nextTick(() => searchInput.value.focus());
    };

    const eventListeners = computed(() => {
      if (props.disabled) return {};

      const eventHandler = props.selectedResult ? resetSelection : openSelection;

      return {
        click: eventHandler,
        focus: eventHandler,
      };
    });

    const optionQaClass = computed(() => ((props.qaClass !== '')
      ? `qa-${props.qaClass}-option`
      : ''));

    const selectResult = (optionIndex) => {
      searching.value = false;
      selectedResult.value = results.value[optionIndex];
      searchTerm.value = '';
      emit('update:selected', selectedResult.value);
    };

    const previousResult = () => {
      if (results.value.length === 0) return;

      let index = resultPointer.value - 1;
      if (index < 0) {
        index = results.value.length - 1;
      }

      resultPointer.value = index;
    };

    const nextResult = () => {
      if (results.value.length === 0) return;

      resultPointer.value = (resultPointer.value + 1) % results.value.length;
    };

    const clear = () => {
      selectedResult.value = null;
      emit('update:selected', null);
    };

    const blur = () => {
      if (searching.value) {
        searchTerm.value = '';
        searching.value = false;
        emit('update:selected', selectedResult.value);
      }
    };

    const adjustScroll = () => {
      const currentOption = optionList.value.children[resultPointer.value];

      if (currentOption) {
        const bounds = optionList.value.getBoundingClientRect();
        const { top, bottom, height } = currentOption.getBoundingClientRect();

        if (top < bounds.top) {
          optionList.value.scrollTop = currentOption.offsetTop;
        } else if (bottom > bounds.bottom) {
          optionList.value.scrollTop = currentOption.offsetTop - (bounds.height - height);
        }
      }
    };

    watch(searchTerm, (newValue) => {
      if (searching.value && newValue && !results.value.includes(props.options[resultPointer.value])) {
        resultPointer.value = 0;
      } else if (newValue && !searching.value) {
        openSelection();
      }
    });

    watch(resultPointer, (newValue) => {
      if (searching.value && newValue >= 0) {
        adjustScroll();
      }
    });

    watch(searching, () => {
      if (searching.value) {
        emit('focus');
      } else {
        emit('blur');
      }
    });

    const hasNoOptions = !props.options || (props.options && props.options.length === 0);
    const placeholderText = hasNoOptions ? props.optionsEmptyMessage : '';

    return {
      searchInput,
      optionList,
      eventListeners,
      resetSelection,
      openSelection,
      clear,
      blur,
      previousResult,
      nextResult,
      selectResult,
      searchTerm,
      resultPointer,
      searching,
      results,
      selectedResult,
      placeholderText,
      optionQaClass,
    };
  },
};
</script>

<style scoped>
  .listbox-button {
    min-height: 37px
  }
</style>
