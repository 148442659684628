<template>
  <button
    class="flex flex-row items-center border border-white rounded p-2"
    @click="editInOffice"
  >
    <div class="mr-2">
      <HeroiconOutline
        class="h-5 w-5 text-white"
        aria-hidden="true"
        :icon="editInOfficeItem.icon"
      />
    </div>
    <span class="text-white">{{ editInOfficeItem.text }}</span>
  </button>
</template>

<script>
import { useI18n } from '@/plugins/i18n';
import { computed, defineComponent, toRefs } from 'vue';
import HeroiconOutline from '@/components/HeroiconOutline.vue';
import { useWebdavSessionStore } from '@/components/Templates/ReportTemplates/stores/webdavSessionStore';

export default defineComponent({
  components: {
    HeroiconOutline,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { file, fileType } = toRefs(props);
    const { t } = useI18n();

    const store = useWebdavSessionStore();

    const editInOffice = async () => {
      store.createWebdavSession({ file: file.value, fileType: fileType.value });
    };

    const editInOfficeItem = computed(() => {
      let officeFileType = '';

      if (file.value?.templateDocument) {
        officeFileType = file.value.templateDocument.type;
      } else {
        officeFileType = file.value.type;
      }

      if (officeFileType === 'docx') {
        return {
          icon: 'DocumentTextIcon',
          text: t('components.report_templates.report_template_actions.edit_in_word'),
        };
      }
      if (officeFileType === 'pptx') {
        return {
          icon: 'PresentationChartBarIcon',
          text: t('components.report_templates.report_template_actions.edit_in_powerpoint'),
        };
      }
      return null;
    });

    return {
      store,
      editInOffice,
      editInOfficeItem,
    };
  },
});
</script>
