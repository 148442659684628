<!-- eslint-disable max-len -->
<template>
  <svg
    width="418"
    height="239"
    viewBox="0 0 418 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M124.431 80.0711C105.83 75.4386 89.86 66.3326 77.3753 51.3005C76.4235 50.7171 75.7419 49.7798 75.48 48.6943C75.2181 47.6087 75.3973 46.4635 75.9782 45.51L97.6949 9.95392C98.278 9.00168 99.2148 8.31973 100.3 8.05769C101.385 7.79565 102.529 7.97492 103.482 8.55615L150.538 37.3268C151.489 37.9102 152.171 38.8474 152.433 39.933C152.695 41.0186 152.516 42.1637 151.935 43.1173L130.218 78.6733C129.635 79.6256 128.698 80.3075 127.613 80.5696C126.528 80.8316 125.384 80.6524 124.431 80.0711Z"
      :fill="color"
    />
    <path
      d="M111.885 68.6537C102.091 67.584 91.9123 59.7522 81.6332 50.1573C80.7419 49.6109 80.1035 48.7331 79.8583 47.7165C79.613 46.6998 79.7808 45.6273 80.3249 44.7343L98.9885 14.1768C99.5346 13.285 100.412 12.6463 101.428 12.4008C102.444 12.1554 103.516 12.3232 104.409 12.8676L146.282 38.4695C147.173 39.0159 147.811 39.8938 148.057 40.9105C148.302 41.9272 148.134 42.9997 147.59 43.8927L136.028 62.8224C133.596 66.7949 129.688 69.6398 125.162 70.733C120.635 71.8262 115.861 71.0784 111.885 68.6537Z"
      fill="white"
    />
    <path
      d="M202.725 136.646C184.124 132.014 168.154 122.908 155.669 107.876C154.717 107.292 154.036 106.355 153.774 105.269C153.512 104.184 153.691 103.039 154.272 102.085L175.989 66.5291C176.572 65.5769 177.509 64.8949 178.594 64.6329C179.679 64.3708 180.823 64.5501 181.776 65.1313L228.832 93.902C229.783 94.4853 230.465 95.4226 230.727 96.5082C230.989 97.5937 230.81 98.7389 230.229 99.6925L208.512 135.249C207.929 136.201 206.992 136.883 205.907 137.145C204.822 137.407 203.678 137.228 202.725 136.646Z"
      :fill="color"
    />
    <path
      d="M190.178 125.229C180.384 124.16 170.205 116.328 159.926 106.733C159.035 106.187 158.397 105.309 158.151 104.292C157.906 103.275 158.074 102.203 158.618 101.31L177.282 70.7525C177.828 69.8607 178.705 69.222 179.721 68.9765C180.737 68.7311 181.809 68.8989 182.702 69.4433L224.575 95.0452C225.466 95.5916 226.104 96.4695 226.35 97.4861C226.595 98.5028 226.427 99.5754 225.883 100.468L214.321 119.398C211.889 123.371 207.981 126.215 203.455 127.309C198.928 128.402 194.153 127.654 190.178 125.229Z"
      fill="white"
    />
    <path
      d="M140.96 159.277C122.359 154.644 106.389 145.538 93.9046 130.506C92.9528 129.923 92.2712 128.985 92.0093 127.9C91.7474 126.814 91.9266 125.669 92.5075 124.716L114.224 89.1595C114.807 88.2072 115.744 87.5253 116.829 87.2633C117.914 87.0012 119.059 87.1805 120.012 87.7617L167.067 116.532C168.019 117.116 168.7 118.053 168.962 119.139C169.224 120.224 169.045 121.369 168.464 122.323L146.747 157.879C146.164 158.831 145.227 159.513 144.143 159.775C143.058 160.037 141.913 159.858 140.96 159.277Z"
      :fill="color"
    />
    <path
      d="M128.413 147.859C118.619 146.79 108.441 138.958 98.1615 129.363C97.2702 128.817 96.6319 127.939 96.3866 126.922C96.1413 125.905 96.3091 124.833 96.8532 123.94L115.517 93.3824C116.063 92.4906 116.94 91.8518 117.956 91.6064C118.973 91.361 120.045 91.5288 120.937 92.0731L162.81 117.675C163.701 118.221 164.34 119.099 164.585 120.116C164.83 121.133 164.662 122.205 164.118 123.098L152.556 142.028C150.124 146 146.216 148.845 141.69 149.939C137.164 151.032 132.389 150.284 128.413 147.859Z"
      fill="white"
    />
    <path
      d="M43.5196 76.8878C46.0368 76.8878 48.0774 74.8536 48.0774 72.3443C48.0774 69.835 46.0368 67.8008 43.5196 67.8008C41.0025 67.8008 38.9619 69.835 38.9619 72.3443C38.9619 74.8536 41.0025 76.8878 43.5196 76.8878Z"
      :fill="color"
    />
    <path
      d="M106.189 13.8463C108.706 13.8463 110.746 11.8121 110.746 9.30279C110.746 6.79348 108.706 4.75928 106.189 4.75928C103.671 4.75928 101.631 6.79348 101.631 9.30279C101.631 11.8121 103.671 13.8463 106.189 13.8463Z"
      :fill="color"
    />
    <path
      d="M155.214 19.1866C157.731 19.1866 159.772 17.1524 159.772 14.6431C159.772 12.1338 157.731 10.0996 155.214 10.0996C152.697 10.0996 150.656 12.1338 150.656 14.6431C150.656 17.1524 152.697 19.1866 155.214 19.1866Z"
      :fill="color"
    />
    <path
      d="M191.137 52.9972C193.654 52.9972 195.695 50.963 195.695 48.4537C195.695 45.9444 193.654 43.9102 191.137 43.9102C188.62 43.9102 186.579 45.9444 186.579 48.4537C186.579 50.963 188.62 52.9972 191.137 52.9972Z"
      :fill="color"
    />
    <path
      d="M174.284 144.509C176.801 144.509 178.842 142.475 178.842 139.966C178.842 137.457 176.801 135.422 174.284 135.422C171.767 135.422 169.727 137.457 169.727 139.966C169.727 142.475 171.767 144.509 174.284 144.509Z"
      :fill="color"
    />
    <path
      d="M130.116 165.486C132.634 165.486 134.674 163.452 134.674 160.943C134.674 158.434 132.634 156.399 130.116 156.399C127.599 156.399 125.559 158.434 125.559 160.943C125.559 163.452 127.599 165.486 130.116 165.486Z"
      :fill="color"
    />
    <path
      d="M83.9689 156.967C86.486 156.967 88.5266 154.933 88.5266 152.423C88.5266 149.914 86.486 147.88 83.9689 147.88C81.4517 147.88 79.4111 149.914 79.4111 152.423C79.4111 154.933 81.4517 156.967 83.9689 156.967Z"
      :fill="color"
    />
    <path
      d="M52.0958 123.156C54.613 123.156 56.6535 121.122 56.6535 118.613C56.6535 116.104 54.613 114.069 52.0958 114.069C49.5787 114.069 47.5381 116.104 47.5381 118.613C47.5381 121.122 49.5787 123.156 52.0958 123.156Z"
      :fill="color"
    />
    <path
      d="M63.4591 35.4283C65.9763 35.4283 68.0168 33.3941 68.0168 30.8848C68.0168 28.3755 65.9763 26.3413 63.4591 26.3413C60.9419 26.3413 58.9014 28.3755 58.9014 30.8848C58.9014 33.3941 60.9419 35.4283 63.4591 35.4283Z"
      :fill="color"
    />
    <path
      d="M77.132 78.0236C79.6491 78.0236 81.6897 75.9894 81.6897 73.48C81.6897 70.9707 79.6491 68.9365 77.132 68.9365C74.6148 68.9365 72.5742 70.9707 72.5742 73.48C72.5742 75.9894 74.6148 78.0236 77.132 78.0236Z"
      :fill="color"
    />
    <path
      d="M149.486 59.2814C152.004 59.2814 154.044 57.2472 154.044 54.7379C154.044 52.2285 152.004 50.1943 149.486 50.1943C146.969 50.1943 144.929 52.2285 144.929 54.7379C144.929 57.2472 146.969 59.2814 149.486 59.2814Z"
      :fill="color"
    />
    <path
      d="M160.312 100.741C162.829 100.741 164.869 98.7071 164.869 96.1978C164.869 93.6885 162.829 91.6543 160.312 91.6543C157.794 91.6543 155.754 93.6885 155.754 96.1978C155.754 98.7071 157.794 100.741 160.312 100.741Z"
      :fill="color"
    />
    <path
      d="M192.216 110.396C199.453 110.396 205.319 104.548 205.319 97.3336C205.319 90.1193 199.453 84.271 192.216 84.271C184.979 84.271 179.112 90.1193 179.112 97.3336C179.112 104.548 184.979 110.396 192.216 110.396Z"
      :fill="color"
    />
    <path
      d="M127.268 134.818C134.504 134.818 140.371 128.969 140.371 121.755C140.371 114.541 134.504 108.692 127.268 108.692C120.031 108.692 114.164 114.541 114.164 121.755C114.164 128.969 120.031 134.818 127.268 134.818Z"
      :fill="color"
    />
    <path
      d="M110.746 54.738C117.983 54.738 123.85 48.8897 123.85 41.6754C123.85 34.4611 117.983 28.6128 110.746 28.6128C103.509 28.6128 97.6426 34.4611 97.6426 41.6754C97.6426 48.8897 103.509 54.738 110.746 54.738Z"
      :fill="color"
    />
    <path
      d="M122.377 36.4837C143.632 29.8753 166.385 37.022 187.756 30.9222C192.671 29.5768 197.318 27.3934 201.491 24.4679C205.051 21.7671 208.394 18.7908 211.488 15.5665C217.917 9.20468 224.898 2.52462 234.557 2.59831C247.276 2.69535 257.041 12.1481 265.519 20.4949C266.717 21.6746 268.564 19.8296 267.364 18.6485C259.574 10.9787 251.158 2.88724 240.07 0.597787C235.095 -0.505927 229.904 -0.0744107 225.18 1.83539C221.081 3.69857 217.337 6.25988 214.115 9.40511C210.7 12.5384 207.551 15.9496 204.09 19.0333C200.476 22.322 196.314 24.9506 191.792 26.7992C181.995 30.7024 171.249 31.1732 160.829 31.0596C147.748 30.917 134.356 30.0258 121.684 33.9658C120.085 34.4629 120.767 36.9844 122.377 36.4837V36.4837Z"
      fill="#3F3D56"
    />
    <path
      d="M270.18 2.89744L276.714 14.4212L279.875 19.9971C280.437 20.9881 281.014 21.9719 281.561 22.971C281.897 23.5847 282.631 24.6918 281.93 25.2733C281.315 25.5743 280.622 25.6824 279.944 25.5834C278.793 25.5859 277.64 25.5601 276.489 25.5484L270.009 25.4827L256.618 25.3469C256.28 25.3586 255.959 25.5013 255.724 25.7449C255.489 25.9885 255.357 26.3139 255.357 26.6525C255.357 26.9911 255.489 27.3166 255.724 27.5601C255.959 27.8037 256.28 27.9464 256.618 27.9581C264.392 28.0369 272.17 28.2012 279.944 28.1945C281.301 28.3243 282.658 27.9529 283.759 27.1503C284.299 26.6133 284.656 25.9202 284.78 25.1691C284.904 24.4179 284.789 23.6468 284.451 22.9646C283.685 21.2911 282.803 19.6729 281.812 18.1216L278.967 13.1032L272.433 1.5795C272.253 1.29303 271.968 1.08801 271.639 1.0077C271.31 0.927394 270.963 0.978065 270.67 1.14903C270.378 1.31999 270.163 1.59788 270.072 1.92407C269.981 2.25026 270.019 2.59925 270.18 2.89741V2.89744Z"
      fill="#3F3D56"
    />
    <path
      d="M356.538 31.1344H296.486C296.157 31.1357 295.83 31.0718 295.526 30.9466C295.221 30.8213 294.944 30.6371 294.711 30.4045C294.477 30.1719 294.292 29.8955 294.166 29.5911C294.04 29.2868 293.975 28.9605 293.975 28.6309C293.975 28.3014 294.04 27.9751 294.166 27.6707C294.292 27.3664 294.477 27.09 294.711 26.8574C294.944 26.6248 295.221 26.4406 295.526 26.3153C295.83 26.1901 296.157 26.1262 296.486 26.1275H356.538C356.867 26.1262 357.194 26.1901 357.498 26.3153C357.803 26.4406 358.08 26.6248 358.313 26.8574C358.547 27.09 358.732 27.3664 358.858 27.6707C358.984 27.9751 359.049 28.3014 359.049 28.6309C359.049 28.9605 358.984 29.2868 358.858 29.5911C358.732 29.8955 358.547 30.1719 358.313 30.4045C358.08 30.6371 357.803 30.8213 357.498 30.9466C357.194 31.0718 356.867 31.1357 356.538 31.1344H356.538Z"
      :fill="color"
    />
    <path
      d="M356.538 71.4577H296.486C296.157 71.4589 295.83 71.3951 295.526 71.2698C295.221 71.1445 294.944 70.9603 294.711 70.7277C294.477 70.4951 294.292 70.2187 294.166 69.9144C294.04 69.61 293.975 69.2837 293.975 68.9542C293.975 68.6246 294.04 68.2983 294.166 67.994C294.292 67.6896 294.477 67.4132 294.711 67.1806C294.944 66.948 295.221 66.7638 295.526 66.6386C295.83 66.5133 296.157 66.4495 296.486 66.4507H356.538C356.867 66.4495 357.194 66.5133 357.498 66.6386C357.803 66.7638 358.08 66.948 358.313 67.1806C358.547 67.4132 358.732 67.6896 358.858 67.994C358.984 68.2983 359.049 68.6246 359.049 68.9542C359.049 69.2837 358.984 69.61 358.858 69.9144C358.732 70.2187 358.547 70.4951 358.313 70.7277C358.08 70.9603 357.803 71.1445 357.498 71.2698C357.194 71.3951 356.867 71.4589 356.538 71.4577H356.538Z"
      :fill="color"
    />
    <path
      d="M415.489 51.2961H296.486C296.157 51.2973 295.83 51.2334 295.526 51.1082C295.221 50.9829 294.944 50.7987 294.711 50.5661C294.477 50.3335 294.292 50.0571 294.166 49.7528C294.04 49.4484 293.975 49.1221 293.975 48.7926C293.975 48.463 294.04 48.1367 294.166 47.8324C294.292 47.528 294.477 47.2516 294.711 47.019C294.944 46.7864 295.221 46.6022 295.526 46.477C295.83 46.3517 296.157 46.2879 296.486 46.2891H415.489C415.818 46.2879 416.145 46.3517 416.449 46.477C416.754 46.6022 417.031 46.7864 417.264 47.019C417.498 47.2516 417.683 47.528 417.809 47.8324C417.935 48.1367 418 48.463 418 48.7926C418 49.1221 417.935 49.4484 417.809 49.7528C417.683 50.0571 417.498 50.3335 417.264 50.5661C417.031 50.7987 416.754 50.9829 416.449 51.1082C416.145 51.2334 415.818 51.2973 415.489 51.2961Z"
      :fill="color"
    />
    <path
      d="M329.19 154.945H269.138C268.809 154.946 268.483 154.882 268.178 154.757C267.873 154.632 267.596 154.448 267.363 154.215C267.13 153.982 266.945 153.706 266.818 153.402C266.692 153.097 266.627 152.771 266.627 152.441C266.627 152.112 266.692 151.786 266.818 151.481C266.945 151.177 267.13 150.901 267.363 150.668C267.596 150.435 267.873 150.251 268.178 150.126C268.483 150.001 268.809 149.937 269.138 149.938H329.19C329.52 149.937 329.846 150.001 330.151 150.126C330.455 150.251 330.732 150.435 330.966 150.668C331.199 150.901 331.384 151.177 331.51 151.481C331.637 151.786 331.702 152.112 331.702 152.441C331.702 152.771 331.637 153.097 331.51 153.402C331.384 153.706 331.199 153.982 330.966 154.215C330.732 154.448 330.455 154.632 330.151 154.757C329.846 154.882 329.52 154.946 329.19 154.945Z"
      :fill="color"
    />
    <path
      d="M329.19 195.269H269.138C268.809 195.27 268.483 195.206 268.178 195.081C267.873 194.956 267.596 194.771 267.363 194.539C267.13 194.306 266.945 194.03 266.818 193.725C266.692 193.421 266.627 193.095 266.627 192.765C266.627 192.436 266.692 192.109 266.818 191.805C266.945 191.501 267.13 191.224 267.363 190.992C267.596 190.759 267.873 190.575 268.178 190.45C268.483 190.324 268.809 190.261 269.138 190.262H329.19C329.52 190.261 329.846 190.324 330.151 190.45C330.455 190.575 330.732 190.759 330.966 190.992C331.199 191.224 331.384 191.501 331.51 191.805C331.637 192.109 331.702 192.436 331.702 192.765C331.702 193.095 331.637 193.421 331.51 193.725C331.384 194.03 331.199 194.306 330.966 194.539C330.732 194.771 330.455 194.956 330.151 195.081C329.846 195.206 329.52 195.27 329.19 195.269Z"
      :fill="color"
    />
    <path
      d="M388.141 175.107H269.138C268.809 175.108 268.483 175.044 268.178 174.919C267.873 174.793 267.596 174.609 267.363 174.377C267.13 174.144 266.945 173.868 266.818 173.563C266.692 173.259 266.627 172.933 266.627 172.603C266.627 172.274 266.692 171.947 266.818 171.643C266.945 171.339 267.13 171.062 267.363 170.83C267.596 170.597 267.873 170.413 268.178 170.288C268.483 170.162 268.809 170.098 269.138 170.1H388.141C388.471 170.098 388.797 170.162 389.102 170.288C389.406 170.413 389.683 170.597 389.917 170.83C390.15 171.062 390.335 171.339 390.461 171.643C390.588 171.947 390.653 172.274 390.653 172.603C390.653 172.933 390.588 173.259 390.461 173.563C390.335 173.868 390.15 174.144 389.917 174.377C389.683 174.609 389.406 174.793 389.102 174.919C388.797 175.044 388.471 175.108 388.141 175.107Z"
      :fill="color"
    />
    <path
      d="M62.5634 198.676H2.51153C2.18216 198.678 1.85578 198.614 1.55113 198.489C1.24647 198.363 0.969519 198.179 0.73618 197.947C0.502841 197.714 0.31769 197.437 0.191359 197.133C0.0650282 196.829 0 196.502 0 196.173C0 195.843 0.0650282 195.517 0.191359 195.213C0.31769 194.908 0.502841 194.632 0.73618 194.399C0.969519 194.167 1.24647 193.983 1.55113 193.857C1.85578 193.732 2.18216 193.668 2.51153 193.669H62.5634C62.8927 193.668 63.2192 193.732 63.5238 193.857C63.8285 193.983 64.1054 194.167 64.3388 194.399C64.5721 194.632 64.7572 194.908 64.8836 195.213C65.0099 195.517 65.0749 195.843 65.0749 196.173C65.0749 196.502 65.0099 196.829 64.8836 197.133C64.7572 197.437 64.5721 197.714 64.3388 197.947C64.1054 198.179 63.8285 198.363 63.5238 198.489C63.2192 198.614 62.8927 198.678 62.5634 198.676Z"
      :fill="color"
    />
    <path
      d="M62.5634 239H2.51153C2.18216 239.001 1.85578 238.938 1.55113 238.812C1.24647 238.687 0.969519 238.503 0.73618 238.27C0.502841 238.038 0.31769 237.761 0.191359 237.457C0.0650282 237.153 0 236.826 0 236.497C0 236.167 0.0650282 235.841 0.191359 235.536C0.31769 235.232 0.502841 234.956 0.73618 234.723C0.969519 234.491 1.24647 234.306 1.55113 234.181C1.85578 234.056 2.18216 233.992 2.51153 233.993H62.5634C62.8927 233.992 63.2192 234.056 63.5238 234.181C63.8285 234.306 64.1054 234.491 64.3388 234.723C64.5721 234.956 64.7572 235.232 64.8836 235.536C65.0099 235.841 65.0749 236.167 65.0749 236.497C65.0749 236.826 65.0099 237.153 64.8836 237.457C64.7572 237.761 64.5721 238.038 64.3388 238.27C64.1054 238.503 63.8285 238.687 63.5238 238.812C63.2192 238.938 62.8927 239.001 62.5634 239Z"
      :fill="color"
    />
    <path
      d="M121.514 218.838H2.51153C2.18216 218.839 1.85578 218.775 1.55113 218.65C1.24647 218.525 0.969519 218.341 0.73618 218.108C0.502841 217.876 0.31769 217.599 0.191359 217.295C0.0650282 216.99 0 216.664 0 216.335C0 216.005 0.0650282 215.679 0.191359 215.374C0.31769 215.07 0.502841 214.794 0.73618 214.561C0.969519 214.328 1.24647 214.144 1.55113 214.019C1.85578 213.894 2.18216 213.83 2.51153 213.831H121.514C121.844 213.83 122.17 213.894 122.475 214.019C122.779 214.144 123.056 214.328 123.29 214.561C123.523 214.794 123.708 215.07 123.835 215.374C123.961 215.679 124.026 216.005 124.026 216.335C124.026 216.664 123.961 216.99 123.835 217.295C123.708 217.599 123.523 217.876 123.29 218.108C123.056 218.341 122.779 218.525 122.475 218.65C122.17 218.775 121.844 218.839 121.514 218.838Z"
      :fill="color"
    />
    <path
      d="M189.929 97.582L234.83 131.476L247.61 141.122C248.951 142.134 250.253 139.868 248.927 138.868L204.025 104.974L191.246 95.3273C189.905 94.3151 188.603 96.5813 189.929 97.582H189.929Z"
      fill="#3F3D56"
    />
    <path
      d="M228.136 143.191C237.929 147.892 248.575 150.551 259.428 151.005C259.628 151.002 259.825 150.954 260.003 150.864C260.182 150.774 260.338 150.644 260.459 150.485C260.581 150.326 260.664 150.141 260.703 149.945C260.742 149.748 260.736 149.546 260.686 149.352L253.732 121.427C253.326 119.797 250.809 120.487 251.215 122.121L258.169 150.046L259.428 148.393C249.035 147.964 238.837 145.426 229.454 140.936C227.946 140.213 226.621 142.464 228.136 143.191V143.191Z"
      fill="#3F3D56"
    />
    <path
      d="M125.573 121.154L93.3088 167.322L84.2149 180.334C83.2504 181.714 85.5119 183.021 86.4684 181.652L118.733 135.484L127.827 122.472C128.791 121.092 126.53 119.785 125.573 121.154Z"
      fill="#3F3D56"
    />
    <path
      d="M81.3708 161.019C77.04 170.948 74.7874 181.66 74.752 192.493C74.7544 192.693 74.8024 192.89 74.8924 193.069C74.9823 193.248 75.1118 193.404 75.271 193.526C75.4302 193.647 75.615 193.73 75.8113 193.77C76.0075 193.809 76.2101 193.803 76.4037 193.752L104.119 185.72C104.442 185.618 104.713 185.396 104.875 185.098C105.037 184.801 105.078 184.452 104.988 184.126C104.898 183.799 104.684 183.52 104.393 183.348C104.102 183.176 103.755 183.123 103.425 183.202L75.7099 191.234L77.3617 192.493C77.3744 182.119 79.5055 171.857 83.6242 162.337C84.2872 160.811 82.0395 159.48 81.3708 161.019V161.019Z"
      fill="#3F3D56"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestReady',
  props: {
    color: {
      type: String,
      default: 'var(--color-primary-500)',
    },
  },
};
</script>
<!-- eslint-enable max-len -->
