<template>
  <fieldset>
    <div class="field">
      <BaseSelect
        id="candidate_import-first_name"
        name="candidate_import[column_mapping[first_name]]"
        :disabled="disabled"
        :label="$t('components.candidate_import.column_mapping_form.column_for',
                   { column_name: $t('activerecord.attributes.candidate.first_name') })"
        :options="columnNames.map(columnName => ({ value: columnName, label: columnName }))"
        :model-value="columnMapping.firstName"
        @input="updateMapping('firstName', $event.target.value)"
      />
    </div>

    <div class="field">
      <BaseSelect
        id="candidate_import-last_name"
        name="candidate_import[column_mapping[last_name]]"
        :disabled="disabled"
        :label="$t('components.candidate_import.column_mapping_form.column_for',
                   { column_name: $t('activerecord.attributes.candidate.last_name') })"
        :options="columnNames.map(columnName => ({ value: columnName, label: columnName }))"
        :model-value="columnMapping.lastName"
        @input="updateMapping('lastName', $event.target.value)"
      />
    </div>

    <div class="field">
      <SearchableSelectList
        id="candidate_import-email"
        name="candidate_import[column_mapping[email]]"
        :disabled="disabled"
        :label="$t('components.candidate_import.column_mapping_form.column_for',
                   { column_name: $t('activerecord.attributes.candidate.email') })"
        :options="columnNames.map(columnName => ({ id: columnName, name: columnName }))"
        value-prop="name"
        :selected="{ name: columnMapping.email }"
        @update:selected="updateMapping('email', !!$event? $event.id : null)"
      />
    </div>

    <div class="field">
      <SearchableSelectList
        id="candidate_import-locale"
        name="candidate_import[column_mapping[locale]]"
        :disabled="disabled"
        :label="$t('components.candidate_import.column_mapping_form.column_for',
                   { column_name: $t('activerecord.attributes.candidate.locale') })"
        :options="columnNames.map(columnName => ({ id: columnName, name: columnName }))"
        value-prop="name"
        :selected="{ name: columnMapping.locale }"
        @update:selected="updateMapping('locale', !!$event? $event.id : null)"
      />
    </div>

    <div class="field">
      <SearchableSelectList
        id="candidate_import-sex"
        name="candidate_import[column_mapping[sex]]"
        :disabled="disabled"
        :label="$t('components.candidate_import.column_mapping_form.column_for',
                   { column_name: $t('activerecord.attributes.candidate.sex') })"
        :options="columnNames.map(columnName => ({ id: columnName, name: columnName }))"
        value-prop="name"
        :selected="{ name: columnMapping.sex }"
        @update:selected="updateMapping('sex', !!$event? $event.id : null)"
      />
    </div>

    <div class="field">
      <SearchableSelectList
        id="candidate_import-birthdate"
        name="candidate_import[column_mapping[birthdate]]"
        :disabled="disabled"
        :label="$t('components.candidate_import.column_mapping_form.column_for',
                   { column_name: $t('activerecord.attributes.candidate.birthdate') })"
        :options="columnNames.map(columnName => ({ id: columnName, name: columnName }))"
        value-prop="name"
        :selected="{ name: columnMapping.birthdate }"
        @update:selected="updateMapping('birthdate', !!$event? $event.id : null)"
      />
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'ColumnMappingForm',
  props: {
    columnMapping: {
      type: Object,
      required: true,
    },
    columnNames: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:columnMapping'],
  methods: {
    updateMapping(attributeName, mapping) {
      this.$emit('update:columnMapping', { ...this.columnMapping, [attributeName]: mapping });
    },
  },
};
</script>
