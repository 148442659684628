<template>
  <div class="flex bg-yellow-50 p-4 rounded-lg w-full">
    <div class="w-8 mt-0.5">
      <ExclamationIcon
        class="shrink-0 h-5 w-5 text-yellow-400"
        aria-hidden="true"
      />
    </div>
    <div class="flex flex-col">
      <span class="text-yellow-800 pb-2">
        {{ customHeader ? customHeader : $t('components.warnings.title') }}
      </span>
      <span
        v-for="warning in warnings"
        :key="warning"
        class="text-yellow-700"
      >
        {{ warning }}
      </span>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from '@heroicons/vue/solid';

export default {
  components: {
    ExclamationIcon,
  },
  props: {
    warnings: {
      type: Array,
      required: true,
    },
    customHeader: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
