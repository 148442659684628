import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import Axios from 'axios';
import { userPath } from '@/util/url-helpers';
import getMetaPropertyContent from '@/util/getMetaPropertyContent';

// eslint-disable-next-line import/prefer-default-export
export const useWebdavEditingInfoModalStore = defineStore('webdavEditingInfoModalStore', () => {
  const userData = getMetaPropertyContent('applysia:user');
  const user = JSON.parse(userData);

  const showInfoModal = ref(false);
  const isLoading = ref(false);
  const shouldShowInfoModal = ref(!user.openInOfficeOnboardingModalShown);

  async function openInfoModal() {
    if (shouldShowInfoModal.value) {
      showInfoModal.value = true;
      shouldShowInfoModal.value = false;
    }
  }

  async function setOpenInOfficeOnboardingModalShownAt() {
    await Axios.put(
      userPath(user.userId),
      {
        user: {
          open_in_office_onboarding_modal_shown_at: new Date(),
        },
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
  }

  async function closeInfoModal() {
    isLoading.value = true;
    await setOpenInOfficeOnboardingModalShownAt();
    showInfoModal.value = false;
    shouldShowInfoModal.value = false;
    isLoading.value = false;
  }

  async function cancelInfoModal() {
    showInfoModal.value = false;
    shouldShowInfoModal.value = true;
  }

  return {
    showInfoModal,
    openInfoModal,
    shouldShowInfoModal,
    closeInfoModal,
    cancelInfoModal,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWebdavEditingInfoModalStore, import.meta.hot));
}
