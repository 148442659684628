<template>
  <Modal
    :open="open"
    outer-modal-classes="h-full px-0 pb-0 sm:px-4 sm:pb-4 sm:pt-20 pt-16"
    inner-modal-classes="w-full h-full sm:min-w-3/4 md:min-w-0 md:max-w-4xl sm:h-3/4"
    :hide-overflow="true"
    @close="close"
  >
    <div class="flex flex-col h-full">
      <div class="w-full pt-4 px-4 shrink-0 shadow z-10">
        <BaseInput
          v-model="query"
          :trailing-icon-clickable="true"
          class="w-full"
          :placeholder="$t('defaults.search')"
          leading-icon="SearchIcon"
          @keyup.exact.prevent.stop.esc="query = ''"
        >
          <template #trailing-icon>
            <button
              v-if="query != ''"
              class="transition-colors duration-200 cursor-pointer text-gray-300 hover:text-gray-500
                       focus:outline-none focus:ring ring-primary-500 focus:text-gray-500 rounded-sm"
              @focus.stop
              @click.stop="query=''"
            >
              <XIcon
                class="h-5 w-5"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $t('components.search_input.clear') }}</span>
            </button>
          </template>
        </BaseInput>
        <div class="flex flex-row justify-between py-4">
          <button
            type="button"
            class="text-primary-600 hover:text-primary-700 active:underline
                   cursor-pointer text-sm font-normal"
            @click="selectAll"
          >
            {{ $t('defaults.select') }}
          </button>
          <button
            type="button"
            class="text-primary-600 hover:text-primary-700 active:underline
                   cursor-pointer text-sm font-normal"
            @click="unselectAll"
          >
            {{ $t('defaults.reset') }}
          </button>
        </div>
      </div>
      <div class="flex-1 overflow-y-scroll overscroll-contain">
        <div
          v-for="user in filteredAvailableUsers"
          :key="user.id"
        >
          <BaseCheckbox
            class="px-4 group hover:bg-gray-50"
            label-classes="ph-no-capture py-3.5 max-h-fit flex w-full justify-between
                   space-x-4 text-gray-900 text-sm font-normal leading-relaxed"
            secondary-label-classes="sm:flex hidden text-gray-500 text-sm font-normal leading-relaxed items-center"
            :secondary-label="user.email"
            :disabled="false"
            :model-value="isSelected(user.id)"
            @update:model-value="updateUser($event, user.id)"
          >
            <template #label>
              {{ user.name }}
            </template>
          </BaseCheckbox>
        </div>
      </div>

      <div class="bg-gray-50 py-3 px-2 flex flex-row justify-end">
        <div
          class="w-full px-1 sm:flex sm:w-1 sm:flex-row-reverse"
        >
          <BaseButton
            :is-disabled="isLoading || !isEditable || !selectedUsers.length"
            :is-loading="isLoading"
            class="mx-auto p-2 w-full justify-center rounded-md border border-transparent
             shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
            @click="submitSelection"
          >
            {{ $t('defaults.add') }}
          </BaseButton>
          <BaseButton
            :is-primary="false"
            :disabled="isLoading || !isEditable"
            class="mt-3 w-full justify-center rounded-md border border-gray-300
             shadow-sm p-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="close"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/generic/Modal/Modal.vue';
import { organisationMembershipsPath } from '@/util/url-helpers';
import { XIcon } from '@heroicons/vue/outline';
import Axios from 'axios';
import {
  defineComponent, inject, ref, watchEffect, toRefs,
} from 'vue';

export default defineComponent({
  name: 'CreateOrganisationMembershipsModal',
  components: {
    Modal,
    XIcon,
  },
  props: {
    availableUsers: {
      type: Array,
      required: true,
    },
    organisationId: {
      type: Number,
      default: null,
      required: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const toast = inject('toast');

    const { availableUsers, organisationId } = toRefs(props);

    const query = ref('');
    const isEditable = ref(true);

    const isLoading = ref(false);

    const selectedUsers = ref([]);

    function isSelected(id) {
      return selectedUsers.value.map((userId) => userId).includes(id);
    }

    const filteredAvailableUsers = ref([]);

    watchEffect(() => {
      if (query.value === '') {
        filteredAvailableUsers.value = availableUsers.value;
      } else {
        filteredAvailableUsers.value = availableUsers.value.filter((user) => user.name
          .toLowerCase().includes(query.value.toLowerCase())
           || user.email
             .toLowerCase().includes(query.value.toLowerCase()));
      }
    });

    const updateUser = (isChecked, userId) => {
      if (isChecked) {
        selectedUsers.value.push(userId);
      } else {
        const index = selectedUsers.value.indexOf(userId);
        if (index !== -1) {
          selectedUsers.value.splice(index, 1);
        }
      }
    };

    const close = () => {
      selectedUsers.value = [];
      isEditable.value = true;
      isLoading.value = false;
      emit('close');
    };

    const submitSelection = () => {
      isEditable.value = false;
      isLoading.value = true;
      const data = {
        organisation_membership: {
          user_ids: selectedUsers.value,
        },
      };

      Axios.post(organisationMembershipsPath(organisationId.value), data)
        .then((_response) => {
          Turbo.visit(organisationMembershipsPath(organisationId.value));
          close();
        })
        .catch(({ response }) => {
          isEditable.value = true;
          isLoading.value = false;
          toast.error(response.data.error);
        });
    };

    const selectAll = () => {
      selectedUsers.value = availableUsers.value.map((user) => user.id);
    };

    const unselectAll = () => {
      selectedUsers.value = [];
    };

    return {
      query,
      isLoading,
      isEditable,
      filteredAvailableUsers,
      selectedUsers,

      isSelected,
      updateUser,
      selectAll,
      unselectAll,

      close,
      submitSelection,
    };
  },
});
</script>
