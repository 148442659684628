<template>
  <Card>
    <div class="px-4 py-5 sm:px-6 h-full">
      <div class="mb-2 flex justify-between items-center">
        <div>
          <p class="mt-1 text-sm text-gray-500">
            {{ subtitle }}
          </p>
          <h2 class="text-lg leading-6 font-medium text-gray-900">
            {{ title }}
          </h2>
        </div>
        <slot name="head-action" />
      </div>
      <slot />
    </div>
  </Card>
</template>
<script>
export default {
  name: 'ChartCard',
  props: {
    subtitle: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
