<template>
  <div>
    <PageHeading class="mb-6">
      {{ title }}
    </PageHeading>
    <form
      ref="form"
      action="/auth/invitation"
      accept-charset="UTF-8"

      method="post"
      enctype="multipart/form-data"
      @submit="submitting = true"
    >
      <input
        type="hidden"
        name="_method"
        value="post"
      >
      <input
        type="hidden"
        name="authenticity_token"
        :value="$csrfToken"
      >
      <input
        type="hidden"
        name="user[send_invitation]"
        :value="sendInvitation"
      >
      <div class="space-y-5 mb-5">
        <TwoColumnCard
          :header="$t('components.invite_user_form.categories.profile.header')"
        >
          <div class="space-y-5">
            <div class="flex items-center space-x-6">
              <div class="flex-1">
                <BaseInput
                  v-model="user.first_name"
                  :label="$t('activerecord.attributes.user.first_name')"
                  type="text"
                  name="user[first_name]"
                  qa-class="first-name"
                  :error="getError('first_name')"
                />
              </div>
              <div class="flex-1">
                <BaseInput
                  v-model="user.last_name"
                  :label="$t('activerecord.attributes.user.last_name')"
                  type="text"
                  name="user[last_name]"
                  qa-class="last-name"
                  :error="getError('last_name')"
                />
              </div>
            </div>
            <BaseInput
              v-model="user.email"
              :label="$t('activerecord.attributes.user.email')"
              type="text"
              name="user[email]"
              qa-class="email"
              :error="getError('email')"
            />
          </div>
        </TwoColumnCard>

        <TwoColumnCard
          :header="$t('components.invite_user_form.categories.localization.header')"
          :helptext="$t('components.invite_user_form.categories.localization.helptext')"
        >
          <div class="space-y-5">
            <SelectList
              :label="$t('activerecord.attributes.user.locale')"
              :selected="currentLocale"
              name="user[locale]"
              :options="localeOptions"
              :error="getError('locale')"
            />
            <SearchableSelectList
              id="select-timezone"
              :selected="currentTimezone"
              :label="$t('activerecord.attributes.user.time_zone')"
              :options="availableTimeZones"
              value-prop="name"
              label-prop="name"
              secondary-label-prop="offset"
              name="user[time_zone]"
            />
          </div>
        </TwoColumnCard>

        <TwoColumnCard
          :header="$t('components.edit_user_form.categories.roles.header')"
          :helptext="$t('components.edit_user_form.categories.roles.helptext')"
          :info-link="$t('components.invite_user_form.categories.roles.info_link')"
        >
          <div class="space-y-5">
            <div
              v-for="role in availableRoles"
              :key="role.name"
            >
              <BaseCheckbox
                :value="role.name"
                :unchecked-value="''"
                :label="$t(`components.shared.roles.${role.name}.name`)"
                :secondary-label="$t(`components.shared.roles.${role.name}.helptext`)"
                name="user[role_names][]"
                :model-value="userRoles[role.name]"
                @update:model-value="userRoles[role.name] = $event"
              />
            </div>
          </div>
        </TwoColumnCard>

        <OrganisationAccessCard
          v-if="isWorkspaceOwner"
          :organisation-membership-ids="organisationsIds"
          :organisations="organisations"
          :has-admin-role-selected="hasAdminRoleSelected"
        />

        <div
          class="flex justify-end space-x-4"
        >
          <BaseButton
            :is-loading="submitting"
            :is-primary="false"
            @click="submitWithoutInvite"
          >
            {{ $t('components.invite_user_form.create_user_without_invite') }}
          </BaseButton>
          <BaseButton
            type="submit"
            :is-loading="submitting"
            :is-primary="true"
            qa-class="save-button"
          >
            {{ $t('components.invite_user_form.submit_button') }}
          </BaseButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import BaseCheckbox from '@/components/generic/BaseCheckbox/BaseCheckbox.vue';
import { useI18n } from 'vue-i18n';
import { computed, ref, nextTick } from 'vue';
import OrganisationAccessCard from '../OrganisationAccessCard/OrganisationAccessCard.vue';

export default {
  components: {
    PageHeading,
    TwoColumnCard,
    BaseInput,
    SelectList,
    SearchableSelectList,
    BaseCheckbox,
    OrganisationAccessCard,
  },
  props: {
    userData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isWorkspaceOwner: {
      type: Boolean,
      required: true,
    },
    availableRoles: {
      type: Array,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    availableTimeZones: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const { availableLocales, t } = useI18n();

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];
    const localeOptions = availableLocales.map((locale) => ({
      value: locale,
      label: t(`defaults.langs.${locale}`),
    }));

    const form = ref(null);

    const sendInvitation = ref('true');

    const user = { ...props.userData, send_invitation: true };

    const currentLocale = localeOptions.find((option) => option.value === user.locale);

    const currentTimezone = props.availableTimeZones.find((option) => option.name === user.time_zone);

    const userRoles = ref({});
    props.availableRoles.forEach((role) => {
      userRoles.value[role.name] = user.role_names?.includes(role.name);
    });
    const hasAdminRoleSelected = computed(() => userRoles.value.admin === true);

    const organisationsIds = ref(
      props.userData?.organisations?.map((organisation) => organisation.id) || [],
    );

    const submitWithoutInvite = () => {
      sendInvitation.value = 'false';
      nextTick(() => {
        form.value.submit();
      });
    };

    const submitting = ref(false);

    return {
      getError,
      localeOptions,
      user,
      form,
      sendInvitation,
      currentLocale,
      submitWithoutInvite,
      submitting,
      currentTimezone,
      hasAdminRoleSelected,
      organisationsIds,
      userRoles,
    };
  },
};
</script>
