export const useTimezoneOptions = (timezones, current) => {
  const timezoneOptions = timezones.map((timezone) => ({
    value: timezone.name,
    secondary: timezone.offset,
    name: timezone.name,
  }));

  const currentTimezone = timezoneOptions.find((option) => option.value === current);

  return {
    timezoneOptions,
    initialTimezone: currentTimezone ?? {
      value: 'Berlin',
      secondary: '(GMT+01:00)',
      name: 'Berlin',
    },
  };
};

export const getCurrentTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone.split('/')[1];
};
