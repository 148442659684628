/* eslint-disable func-names */

export default {

  localeName: 'De',
  localeDesc: 'Deutsch',

  // region Custom localization

  EventType: {
    Meeting: 'Session',
    Phone: 'Telefon',
    Lunch: 'Mittagessen',
    Workout: 'Trainieren',
  },

  EventColor: {
    Meeting: 'green',
    Phone: 'red',
    Lunch: 'blue',
    Workout: 'orange',
  },

  // endregion

  // region Shared localization

  Button: {
    'Add column': 'Spalte hinzufügen',
    Apply: 'Anwenden',
    'Display hints': 'Tipps anzeigen',
    'Remove column': 'Spalte entfernen',
  },

  CodeEditor: {
    'Code editor': 'Code-Editor',
    'Download code': 'Code herunterladen',
  },

  // endregion

  // Translations for common words and phrases which are used by all classes.
  Object: {
    Yes: 'Ja',
    No: 'Nein',
    Cancel: 'Abbrechen',
  },

  // region Features

  ColumnPicker: {
    column: 'Spalte',
    columnsMenu: 'Spalten',
    hideColumn: 'Spalte verbergen',
    hideColumnShort: 'Verbergen',
  },

  Filter: {
    applyFilter: 'Filter anwenden',
    filter: 'Filter',
    editFilter: 'Filter bearbeiten',
    on: 'Auf',
    before: 'Vor',
    after: 'Nach',
    equals: 'Gleichen',
    lessThan: 'Weniger als',
    moreThan: 'Mehr als',
    removeFilter: 'Filter entfernen',
  },

  FilterBar: {
    enableFilterBar: 'Filterleiste anzeigen',
    disableFilterBar: 'Filterleiste ausblenden',
  },

  Group: {
    group: 'Gruppe',
    groupAscending: 'Aufsteigend gruppieren',
    groupDescending: 'Absteigend gruppieren',
    groupAscendingShort: 'Aufsteigend',
    groupDescendingShort: 'Absteigend',
    stopGrouping: 'Gruppierung stoppen',
    stopGroupingShort: 'Stoppen',
  },

  Search: {
    searchForValue: 'Suche nach Wert',
  },

  Sort: {
    sort: 'Sorte',
    sortAscending: 'Aufsteigend sortieren',
    sortDescending: 'Absteigend sortieren',
    multiSort: 'Multi sortieren',
    removeSorter: 'Sortierung entfernen',
    addSortAscending: 'Aufsteigend sortieren hinzufügen',
    addSortDescending: 'Absteigend sortieren hinzufügen',
    toggleSortAscending: 'Zu aufsteigend wechseln',
    toggleSortDescending: 'Zu absteigend wechseln',
    sortAscendingShort: 'Aufsteigend',
    sortDescendingShort: 'Absteigend',
    removeSorterShort: 'Entfernen',
    addSortAscendingShort: '+ Aufsteigend',
    addSortDescendingShort: '+ Absteigend',
  },

  // endregion

  // region Grid

  GridBase: {
    loadFailedMessage: 'Wird geladen, bitte versuchen Sie es erneut!',
    syncFailedMessage: 'Datensynchronisation fehlgeschlagen!',
    unspecifiedFailure: 'Nicht spezifizierter Fehler',
    networkFailure: 'Netzwerkfehler',
    parseFailure: 'Serverantwort konnte nicht analysiert werden',
    loadMask: 'Laden...',
    syncMask: 'Speichere Änderungen, bitte warten...',
    noRows: 'Keine Zeilen zum Anzeigen',
    removeRow: 'Zeile löschen',
    removeRows: 'Zeilen löschen',
    moveColumnLeft: 'Bewegen Sie sich zum linken Bereich',
    moveColumnRight: 'Bewegen Sie sich nach rechts',
    moveColumnTo(region) {
      return `Spalte verschieben nach ${region}`;
    },
  },

  CellMenu: {
    removeRow: 'Zeile löschen',
    removeRows: 'Zeilen löschen',
  },

  // endregion

  // region Widgets

  Combo: {
    noResults: 'Keine Ergebnisse',
  },

  FilePicker: {
    file: 'Datei',
  },

  Field: {
    badInput: 'Ungültiger Feldwert',
    patternMismatch: 'Der Wert sollte einem bestimmten Muster entsprechen',
    rangeOverflow(value) {
      return `Der Wert muss kleiner als oder gleich ${value.max} sein`;
    },
    rangeUnderflow(value) {
      return `Der Wert muss größer als oder gleich ${value.min} sein`;
    },
    stepMismatch: 'Der Wert sollte zum Schritt passen',
    tooLong: 'Der Wert sollte kürzer sein',
    tooShort: 'Der Wert sollte länger sein',
    typeMismatch: 'Der Wert muss in einem speziellen Format vorliegen',
    valueMissing: 'Dieses Feld wird benötigt',

    fieldRequired: 'Dieses Feld wird benötigt',
    invalidValue: 'Ungültiger Feldwert',
    maximumValueViolation: 'Maximalwertverletzung',
    minimumValueViolation: 'Mindestwertverletzung',
    validateFilter: 'Der Wert muss aus der Liste ausgewählt werden',
  },

  DateField: {
    invalidDate: 'Ungültige Datumseingabe',
  },

  NumberFormat: {
    locale: 'de',
    currency: 'EUR',
  },

  TimeField: {
    invalidTime: 'Ungültige Zeitangabe',
  },

  // endregion

  // region Others

  DateHelper: {
    locale: 'de',
    weekStartDay: 1,
    unitNames: [
      { single: 'Millisekunde', plural: 'Millisekunden', abbrev: 'ms' },
      { single: 'Sekunde', plural: 'Sekunden', abbrev: 's' },
      { single: 'Minute', plural: 'Minuten', abbrev: 'min' },
      { single: 'Stunde', plural: 'Stunden', abbrev: 'std' },
      { single: 'Tag', plural: 'Tage', abbrev: 't' },
      { single: 'Woche', plural: 'Wochen', abbrev: 'W' },
      { single: 'Monat', plural: 'Monate', abbrev: 'mon' },
      { single: 'Quartal', plural: 'Quartale', abbrev: 'Q' },
      { single: 'Jahr', plural: 'Jahre', abbrev: 'jahr' },
    ],
    // Used to build a RegExp for parsing time units.
    // The full names from above are added into the generated Regexp.
    // So you may type "2 w" or "2 wk" or "2 week" or "2 weeks" into a DurationField.
    // When generating its display value though, it uses the full localized names above.
    unitAbbreviations: [
      ['mil'],
      ['s', 'sec'],
      ['m', 'min'],
      ['h', 'hr'],
      ['d'],
      ['w', 'wk'],
      ['mo', 'mon', 'mnt'],
      ['q', 'quar', 'qrt'],
      ['y', 'yr'],
    ],
    parsers: {
      L: 'DD.MM.YYYY',
      LT: 'HH:mm',
    },
    ordinalSuffix(number) {
      return number;
    },
  },

  PagingToolbar: {
    firstPage: 'Gehe zur ersten Seite',
    prevPage: 'Zurück zur letzten Seite',
    page: 'Seite',
    nextPage: 'Gehe zur nächsten Seite',
    lastPage: 'Gehe zur letzten Seite',
    reload: 'Aktuelle Seite neu laden',
    noRecords: 'Keine Zeilen zum Anzeigen',
    pageCountTemplate(data) {
      return `von ${data.lastPage}`;
    },
    summaryTemplate(data) {
      return `Ergebnisse ${data.start} - ${data.end} von ${data.allCount}`;
    },
  },

  List: {
    loading: 'Beladung...',
  },

  // region Export

  PdfExport: {
    'Waiting for response from server': 'Warten auf Antwort vom Server...',
    'Export failed': 'Export fehlgeschlagen',
    'Server error': 'Serverfehler',
    'Generating pages': 'Seiten generieren ...',
  },

  ExportDialog: {
    width: '40em',
    labelWidth: '12em',
    exportSettings: 'Exporteinstellungen',
    export: 'Export',
    exporterType: 'Kontrolliere die Paginierung',
    cancel: 'Stornieren',
    fileFormat: 'Datei Format',
    rows: 'Reihen',
    alignRows: 'Zeilen ausrichten',
    columns: 'Säulen',
    paperFormat: 'Papierformat',
    orientation: 'Orientierung',
    repeatHeader: 'Header wiederholen',
  },

  ExportRowsCombo: {
    all: 'Alle Zeilen',
    visible: 'Sichtbare Zeilen',
  },

  ExportOrientationCombo: {
    portrait: 'Porträt',
    landscape: 'Landschaft',
  },

  SinglePageExporter: {
    singlepage: 'Einzelne Seite',
  },

  MultiPageExporter: {
    multipage: 'Mehrere Seiten',
    exportingPage(data) {
      return `Seite exportieren ${data.currentPage}/${data.totalPages}`;
    },
  },

  MultiPageVerticalExporter: {
    multipagevertical: 'Mehrere Seiten (vertikal)',
    exportingPage(data) {
      return `Seite exportieren ${data.currentPage}/${data.totalPages}`;
    },
  },

  // endregion

  // endregion

  // region Scheduler

  ResourceInfoColumn: {
    eventCountText(data) {
      return `${data} Veranstaltung${data !== 1 ? 'en' : ''}`;
    },
  },

  Dependencies: {
    from: 'Von',
    to: 'Zo',
    valid: 'Gültig',
    invalid: 'Ungültig',
    Checking: 'Überprüfung…',
  },

  DependencyType: {
    SS: 'AA',
    SF: 'EA',
    FS: 'AE',
    FF: 'EE',
    StartToStart: 'Anfang-Anfang',
    StartToEnd: 'Anfang-Ende',
    EndToStart: 'Ende-Anfang',
    EndToEnd: 'Ende-Ende',
    short: [
      'AA',
      'AE',
      'EA',
      'EE',
    ],
    long: [
      'Anfang-Anfang',
      'Anfang-Ende',
      'Ende-Anfang',
      'Ende-Ende',
    ],
  },

  DependencyEdit: {
    From: 'Von',
    To: 'Zu',
    Type: 'Typ',
    Lag: 'Verzögern',
    'Edit dependency': 'Abhängigkeit bearbeiten',
    Save: 'Speichern',
    Delete: 'Löschen',
    Cancel: 'Abbrechen',
    StartToStart: 'Anfang-Anfang',
    StartToEnd: 'Anfang-Ende',
    EndToStart: 'Ende-Anfang',
    EndToEnd: 'Ende-Ende',
  },

  EventEdit: {
    Name: 'Name',
    Resource: 'Ressource',
    Start: 'Start',
    End: 'Ende',
    Save: 'Speichern',
    Delete: 'Löschen',
    Cancel: 'Abbrechen',
    'Edit event': 'Ereignis bearbeiten',
    Repeat: 'Wiederholen',
  },

  EventDrag: {
    eventOverlapsExisting: 'Ereignis überlappt vorhandenes Ereignis für diese Ressource',
    noDropOutsideTimeline: 'Event wird möglicherweise nicht vollständig außerhalb der Timeline gelöscht',
  },

  SchedulerBase: {
    'Add event': 'Ereignis hinzufügen',
    'Delete event': 'Ereignis löschen',
    'Unassign event': 'Ereignis nicht zuordnen',
  },

  //! 'headerContextMenu' is deprecated
  //! Please see https://bryntum.com/docs/scheduler/#guides/upgrades/3.1.0.md for more information.
  HeaderContextMenu: {
    pickZoomLevel: 'Zoomen',
    activeDateRange: 'Datumsbereich',
    startText: 'Anfangsdatum',
    endText: 'Endtermin',
    todayText: 'Heute',
  },

  TimeAxisHeaderMenu: {
    pickZoomLevel: 'Zoomen',
    activeDateRange: 'Datumsbereich',
    startText: 'Anfangsdatum',
    endText: 'Endtermin',
    todayText: 'Heute',
  },

  EventFilter: {
    filterEvents: 'Ereignisse filtern',
    byName: 'Namentlich',
  },

  TimeRanges: {
    showCurrentTimeLine: 'Aktuelle Zeitleiste anzeigen',
  },

  PresetManager: {
    minuteAndHour: {
      topDateFormat: 'ddd DD.MM, HH:mm',
    },
    hourAndDay: {
      topDateFormat: 'ddd DD.MM',
    },
    weekAndDay: {
      displayDateFormat: 'HH:mm',
    },
  },

  RecurrenceConfirmationPopup: {
    'delete-title': 'Sie löschen ein Ereignis',
    'delete-all-message': 'Möchten Sie alle Vorkommen dieses Ereignisses löschen?',
    // eslint-disable-next-line max-len
    'delete-further-message': 'Möchten Sie dieses und alle zukünftigen Vorkommen dieses Ereignisses oder nur das ausgewählte Vorkommen löschen?',
    'delete-further-btn-text': 'Alle zukünftigen Ereignisse löschen',
    'delete-only-this-btn-text': 'Nur dieses Ereignis löschen',

    'update-title': 'Sie ändern ein sich wiederholendes Ereignis',
    'update-all-message': 'Möchten Sie alle Vorkommen dieses Ereignisses ändern?',
    // eslint-disable-next-line max-len
    'update-further-message': 'Möchten Sie nur dieses Vorkommen des Ereignisses oder dieses und aller zukünftigen Ereignisse ändern?',
    'update-further-btn-text': 'Alle zukünftigen Ereignisse',
    'update-only-this-btn-text': 'Nur dieses Ereignis',

    Yes: 'Ja',
    Cancel: 'Abbrechen',

    width: 600,
  },

  RecurrenceLegend: {
    // list delimiters
    ' and ': ' und ',
    // frequency patterns
    Daily: 'Täglich',
    'Weekly on {1}': function (data) {
      return `Wöchentlich am ${data.days}`;
    },
    'Monthly on {1}': function (data) {
      return `Monatlich am ${data.days}`;
    },
    'Yearly on {1} of {2}': function (data) {
      return `Jährlich am ${data.days} von ${data.months}`;
    },
    'Every {0} days': function (data) {
      return `Alle ${data.interval} Tage`;
    },
    'Every {0} weeks on {1}': function (data) {
      return `Alle ${data.interval} Wochen am ${data.days}`;
    },
    'Every {0} months on {1}': function (data) {
      return `Alle ${data.interval} Monate auf ${data.days}`;
    },
    'Every {0} years on {1} of {2}': function (data) {
      return `Alle ${data.interval} Jahre auf ${data.days} von ${data.months}`;
    },
    // day position translations
    position1: 'ersten',
    position2: 'zweiten',
    position3: 'dritten',
    position4: 'vierten',
    position5: 'fünften',
    'position-1': 'letzten',
    // day options
    day: 'Tag',
    weekday: 'Wochentag',
    'weekend day': 'Wochenend-Tag',
    // {0} - day position info ("the last"/"the first"/...)
    // {1} - day info ("Sunday"/"Monday"/.../"day"/"weekday"/"weekend day")
    // For example:
    //  "the last Sunday"
    //  "the first weekday"
    //  "the second weekend day"
    daysFormat(data) {
      return `${data.position} ${data.days}`;
    },
  },

  RecurrenceEditor: {
    'Repeat event': 'Ereignis wiederholen',
    Cancel: 'Abbrechen',
    Save: 'Speichern',
    Frequency: 'Häufigkeit',
    Every: 'Jede(n/r)',
    DAILYintervalUnit: 'Tag',
    WEEKLYintervalUnit: 'Woche am:',
    MONTHLYintervalUnit: 'Monat',
    YEARLYintervalUnit: 'Jahr in:',
    Each: 'Jeder',
    'On the': 'Am',
    'End repeat': 'Ende',
    'time(s)': 'Zeit',
  },

  RecurrenceDaysCombo: {
    day: 'Tag',
    weekday: 'Wochentag',
    'weekend day': 'Wochenend-Tag',
  },

  RecurrencePositionsCombo: {
    position1: 'ersten',
    position2: 'zweiten',
    position3: 'dritten',
    position4: 'vierten',
    position5: 'fünften',
    'position-1': 'letzten',
  },

  RecurrenceStopConditionCombo: {
    Never: 'Niemals',
    After: 'Nach',
    'On date': 'Am Tag',
  },

  RecurrenceFrequencyCombo: {
    Daily: 'täglich',
    Weekly: 'wöchentlich',
    Monthly: 'monatlich',
    Yearly: 'jährlich',
  },

  RecurrenceCombo: {
    None: 'Nie',
    Custom: 'Benutzerdefiniert...',
  },

  // region Features

  Summary: {
    'Summary for': function (date) {
      return `Zusammenfassung für ${date}`;
    },
  },

  // endregion

  // region Export

  ScheduleRangeCombo: {
    completeview: 'Vollständiger Zeitplan',
    currentview: 'Sichtbarer Zeitplan',
    daterange: 'Datumsbereich',
    completedata: 'Vollständiger Zeitplan (für alle Veranstaltungen)',
  },

  SchedulerExportDialog: {
    'Schedule range': 'Zeitplanbereich ',
    'Export from': 'Von',
    'Export to': 'Zu',
  },

  ExcelExporter: {
    'No resource assigned': 'Keine Ressource zugewiesen',
  },

  // endregion

  // region Examples

  Column: {
    Name: 'Name',
    Age: 'Alter',
    City: 'Stadt',
    Food: 'Essen',
    Color: 'Farbe',
    'First name': 'Vorname',
    Surname: 'Nachname',
    Team: 'Team',
    Score: 'Ergebnis',
    Rank: 'Rang',
    Percent: 'Prozent',
    Birthplace: 'Geburtsort',
    Start: 'Anfang',
    Finish: 'Ende',
    Template: 'Vorlage (template)',
    Date: 'Datum',
    Check: 'Check',
    Contact: 'Kontakt',
    Favorites: 'Favoriten',
    'Customer#': 'Kunde#',
    When: 'Wann',
    Brand: 'Marke',
    Model: 'Modell',
    'Personal best': 'Persönlicher Rekord',
    'Current rank': 'Aktueller Rang',
    Hometown: 'Heimatstadt',
    Satisfaction: 'Zufriedenheit',
    'Favorite color': 'Lieblingsfarbe',
    Rating: 'Wertung',
    Cooks: 'Zubereiten',
    Birthday: 'Geburtstag',
    Staff: 'Personal',
    Machines: 'Maschinen',
    Type: 'Typ',
    'Task color': 'Aufgabenfarbe',
    'Employment type': 'Beschäftigungsverhältnis',
    Capacity: 'Kapazität',
    'Production line': 'Fließband',
    Company: 'Firma',
    End: 'Ende',
  },

  // endregion

  CrudManagerView: {
    serverResponseLabel: 'Serverantwort:',
  },

};
