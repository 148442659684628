<template>
  <div
    v-click-away="away"
    class="relative inline-block"
    @click="open = !open"
  >
    <div
      role="button"
      class="inline-block select-none"
    >
      <slot
        name="link"
        :open="open"
      />
    </div>

    <div
      v-show="open"
      class="absolute w-48 mt-2 z-10 bg-white shadow-md rounded-lg right-0 border"
      :class="directionClass"
    >
      <slot name="dropdown-items" />
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from '@/util/click-away';

export default {
  mixins: [clickaway],
  props: {
    direction: {
      type: String,
      default: 'bottom',
      validator(value) {
        return ['top', 'bottom', 'left', 'right'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    directionClass() {
      return {
        'bottom-auto': this.direction === 'bottom',
        'top-0': this.direction === 'top',
        'left-0': this.direction === 'left',
        'right-0': this.direction === 'right',
      };
    },
  },
  methods: {
    away() {
      this.open = false;
    },
  },
};
</script>
