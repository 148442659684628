<template>
  <!-- from https://undraw.co/ -->

  <!-- eslint-disable max-len, vue/max-attributes-per-line -->
  <svg width="100%" height="100%" viewBox="0 0 491 460" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-332.5,0)">
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M1004.25,640.116L528.25,640.116C524.137,640.112 520.754,636.729 520.75,632.616L520.75,425.616C520.754,421.504 524.137,418.121 528.25,418.116L1004.25,418.116C1008.36,418.121 1011.75,421.504 1011.75,425.616L1011.75,632.616C1011.75,636.729 1008.36,640.112 1004.25,640.116ZM528.25,421.116C525.782,421.119 523.753,423.149 523.75,425.616L523.75,632.616C523.753,635.084 525.782,637.114 528.25,637.116L1004.25,637.116C1006.72,637.114 1008.75,635.084 1008.75,632.616L1008.75,425.616C1008.75,423.149 1006.72,421.119 1004.25,421.116L528.25,421.116Z" style="fill:rgb(230,230,230);fill-rule:nonzero;" />
      </g>
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M547.75,443.616C545.281,443.616 543.25,445.648 543.25,448.116C543.25,450.585 545.281,452.616 547.75,452.616L610.75,452.616C613.219,452.616 615.25,450.585 615.25,448.116C615.25,445.648 613.219,443.616 610.75,443.616L547.75,443.616Z" style="fill:rgb(230,230,230);fill-rule:nonzero;" />
      </g>
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M979.04,474.911L979.04,558.361L554.21,558.361L554.21,514.423C557.151,512.534 560.111,510.654 563.097,508.836C575.851,501.031 589.503,493.732 604.381,491.027C611.417,489.715 618.626,489.625 625.693,490.761C633.159,491.993 640.304,494.565 647.379,497.146C654.356,499.701 661.35,502.335 668.647,503.816C675.758,505.212 683.058,505.356 690.218,504.242C703.878,502.193 716.712,496.703 729.492,491.736C742.353,486.743 755.604,482.024 769.522,481.404C783.263,480.783 796.834,484.029 809.872,488.055C836.455,496.259 863.598,508.428 891.746,500.082C920.328,491.621 946.618,467.195 978.294,474.441C978.593,474.497 978.861,474.665 979.04,474.911Z" style="fill:rgb(230,230,230);fill-rule:nonzero;" />
      </g>
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M979.04,476.366C978.762,476.888 978.158,477.153 977.586,477.005C973.811,476.139 969.945,475.74 966.074,475.816C934.916,476.366 910.961,501.448 880.496,505.2C853.028,508.579 827.174,495.222 801.348,488.242C787.929,484.614 774.093,482.69 760.284,485.155C746.581,487.594 733.721,493.2 720.807,498.175C708.373,502.964 695.45,507.417 681.987,507.639C667.184,507.887 653.854,501.785 640.125,497.137C632.985,494.716 625.659,492.8 618.094,492.614C610.721,492.526 603.384,493.662 596.382,495.976C581.793,500.614 568.649,508.88 555.85,517.093C555.631,517.248 555.364,517.32 555.097,517.297C554.417,517.206 553.905,516.62 553.905,515.935C553.905,515.479 554.132,515.051 554.511,514.796L555.097,514.423C558.032,512.534 560.986,510.654 563.966,508.836C576.693,501.031 590.316,493.732 605.163,491.027C612.184,489.715 619.379,489.625 626.431,490.761C633.881,491.993 641.012,494.565 648.071,497.146C655.034,499.701 662.014,502.335 669.295,503.816C676.391,505.212 683.676,505.356 690.821,504.242C704.452,502.193 717.259,496.703 730.013,491.736C742.847,486.743 756.071,482.024 769.96,481.404C783.671,480.783 797.215,484.029 810.226,488.055C836.753,496.259 863.839,508.428 891.928,500.082C920.451,491.621 946.686,467.195 978.295,474.441C978.595,474.497 978.861,474.665 979.04,474.911C979.319,475.355 979.319,475.922 979.04,476.366Z" :fill="color" style="fill-rule:nonzero;" />
      </g>
      <circle cx="424.496" cy="311.459" r="6.208" :fill="color" />
      <circle cx="581.479" cy="303.477" r="6.208" :fill="color" />
      <circle cx="773.052" cy="295.494" r="6.208" :fill="color" />
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M609.25,593.616C606.781,593.616 604.75,595.648 604.75,598.116C604.75,600.585 606.781,602.616 609.25,602.616L672.25,602.616C674.719,602.616 676.75,600.585 676.75,598.116C676.75,595.648 674.719,593.616 672.25,593.616L609.25,593.616Z" style="fill:rgb(230,230,230);fill-rule:nonzero;" />
      </g>
      <circle cx="380.5" cy="417.233" r="17" :fill="color" />
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M761.25,593.616C758.781,593.616 756.75,595.648 756.75,598.116C756.75,600.585 758.781,602.616 761.25,602.616L824.25,602.616C826.719,602.616 828.75,600.585 828.75,598.116C828.75,595.648 826.719,593.616 824.25,593.616L761.25,593.616Z" style="fill:rgb(230,230,230);fill-rule:nonzero;" />
      </g>
      <circle cx="532.5" cy="417.233" r="17" :fill="color" />
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M913.25,593.616C910.781,593.616 908.75,595.648 908.75,598.116C908.75,600.585 910.781,602.616 913.25,602.616L976.25,602.616C978.719,602.616 980.75,600.585 980.75,598.116C980.75,595.648 978.719,593.616 976.25,593.616L913.25,593.616Z" style="fill:rgb(230,230,230);fill-rule:nonzero;" />
      </g>
      <circle cx="684.5" cy="417.233" r="17" :fill="color" />
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M971.669,362.226L736.831,362.226C732.253,362.226 728.543,359.583 728.538,356.326L728.538,305.284C728.543,302.027 732.253,299.384 736.831,299.384L971.669,299.384C976.247,299.384 979.957,302.027 979.962,305.284L979.962,356.325C979.958,359.582 976.247,362.222 971.669,362.226ZM736.831,301.742C734.084,301.742 731.858,303.328 731.855,305.282L731.855,356.323C731.855,358.277 734.084,359.861 736.831,359.863L971.669,359.863C974.416,359.863 976.642,358.277 976.645,356.323L976.645,305.284C976.645,303.33 974.416,301.746 971.669,301.744L736.831,301.742Z" style="fill:rgb(230,230,230);fill-rule:nonzero;" />
      </g>
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M789.929,328.391C787.187,328.394 784.932,330.649 784.929,333.391L784.929,368.391C784.932,371.133 787.187,373.388 789.929,373.391L968.929,373.391C971.671,373.388 973.926,371.133 973.929,368.391L973.929,333.391C973.926,330.649 971.671,328.394 968.929,328.391L789.929,328.391Z" :fill="color" style="fill-rule:nonzero;" />
      </g>
      <circle cx="626.679" cy="171.007" r="11" style="fill:white;" />
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M865.061,346.616C862.592,346.616 860.561,348.648 860.561,351.116C860.561,353.585 862.592,355.616 865.061,355.616L928.061,355.616C930.53,355.616 932.561,353.585 932.561,351.116C932.561,348.648 930.53,346.616 928.061,346.616L865.061,346.616Z" style="fill:white;fill-rule:nonzero;" />
      </g>
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M795.669,243.226L560.831,243.226C556.253,243.226 552.543,240.583 552.538,237.326L552.538,186.284C552.543,183.027 556.253,180.384 560.831,180.384L795.669,180.384C800.247,180.384 803.957,183.027 803.962,186.284L803.962,237.325C803.958,240.582 800.247,243.222 795.669,243.226ZM560.831,182.742C558.084,182.742 555.858,184.328 555.855,186.282L555.855,237.323C555.855,239.277 558.084,240.861 560.831,240.863L795.669,240.863C798.416,240.863 800.642,239.277 800.645,237.323L800.645,186.284C800.645,184.33 798.416,182.746 795.669,182.744L560.831,182.742Z" style="fill:rgb(230,230,230);fill-rule:nonzero;" />
      </g>
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M613.929,209.391C611.187,209.394 608.932,211.649 608.929,214.391L608.929,249.391C608.932,252.133 611.187,254.388 613.929,254.391L792.929,254.391C795.671,254.388 797.926,252.133 797.929,249.391L797.929,214.391C797.926,211.649 795.671,209.394 792.929,209.391L613.929,209.391Z" :fill="color" style="fill-rule:nonzero;" />
      </g>
      <circle cx="450.679" cy="52.007" r="11" style="fill:white;" />
      <g transform="matrix(1,0,0,1,-188.25,-180.384)">
        <path d="M689.061,227.616C686.592,227.616 684.561,229.648 684.561,232.116C684.561,234.585 686.592,236.616 689.061,236.616L752.061,236.616C754.53,236.616 756.561,234.585 756.561,232.116C756.561,229.648 754.53,227.616 752.061,227.616L689.061,227.616Z" style="fill:white;fill-rule:nonzero;" />
      </g>
    </g>
  </svg>
  <!-- eslint-enable max-len, vue/max-attributes-per-line -->
</template>

<script>
export default {
  name: 'DataReportIcon',
  props: {
    color: {
      type: String,
      default: 'var(--color-primary-500)',
    },
  },
};
</script>
