<template>
  <div>
    <input
      v-if="name"
      :name="name"
      type="hidden"
      :value="state"
    >
    <label
      :for="id"
      :class="['flex items-center', disabled ? '' : 'cursor-pointer']"
    >
      <input
        :id="id"
        type="checkbox"
        :name="name"
        :disabled="disabled"
        :form="form"
        class="focus:ring-primary-500 h-4 w-4 border-gray-300 rounded"
        :class="[disabled ? 'bg-gray-100 text-primary-400': 'text-primary-600 cursor-pointer']"
        :checked="modelValue"
        :value="state"
        :indeterminate.prop="isIntermediate"
        @change="onChange"
      >
      <div
        v-if="label"
        class="ml-3 text-sm"
      >
        <div
          :class="[labelClasses ? labelClasses
            : 'font-medium text-gray-700 flex flex-col']"
        >
          {{ label }}
          <span
            v-if="secondaryLabel"
            class="text-gray-500 font-normal"
          >
            {{ secondaryLabel }}
          </span>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    id: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    form: {
      type: String,
      default: null,
    },

    value: {
      type: String,
      default: '1',
    },
    uncheckedValue: {
      type: String,
      default: '0',
    },
    indeterminateValue: {
      type: String,
      default: null,
    },
    isIntermediate: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },
    secondaryLabel: {
      type: String,
      default: null,
    },
    labelClasses: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isIntermediate = ref(props.isIntermediate);

    const computeStateValue = (checked) => {
      if (isIntermediate.value) return props.intermediateValue;
      if (checked) return props.value;
      return props.uncheckedValue;
    };

    const state = ref(computeStateValue(props.modelValue));

    const onChange = ({ target }) => {
      if (isIntermediate.value) {
        isIntermediate.value = false;
      }
      state.value = computeStateValue(target.checked);
      emit('update:modelValue', target.checked);
    };

    return {
      onChange,
      state,
    };
  },
};
</script>
