<template>
  <div
    v-if="showNpsComponent"
    class="fixed bottom-0 w-full z-50"
  >
    <div

      class="flex items-center justify-center"
    >
      <div class="bg-white rounded-t-lg shadow-xl  w-full xl:max-w-[40%] overflow-clip">
        <div class="bg-primary-500 h-2 w-full" />
        <div class="p-6">
          <h2 class="text-lg font-medium mb-4">
            {{ $t('components.nps_popup.question') }}
          </h2>

          <div
            class="flex justify-between"
          >
            <div
              v-for="index in [1,2,3,4,5,6,7,8,9,10]"
              :key="index"
              class="flex flex-col items-center justify-center"
            >
              <label
                class="block text-sm font-medium leading-6 text-gray-900 mb-1"
              >{{ index }}</label>
              <input
                v-model="npsScore"
                name="notification-method"
                type="radio"
                class="h-4 w-4 border-gray-300"
                :value="index"
              >
            </div>
          </div>
          <div class="flex justify-between mt-2 text-gray-700 text-sm mb-6">
            <p>{{ $t('components.nps_popup.not_likely') }}</p> <p>{{ $t('components.nps_popup.very_likely') }}</p>
          </div>

          <div class="flex items-center justify-end mt-4 space-x-2">
            <BaseButton
              :is-primary="false"
              @click="hideForOneDay"
            >
              {{ $t('components.nps_popup.not_now') }}
            </BaseButton>
            <BaseButton
              :disabled="!npsScore"
              :loading="isLoading"
              @click="submitNpsScore"
            >
              {{ $t('components.nps_popup.submit') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { posthog } from 'posthog-js';
import { authPath } from '@/util/url-helpers';
import Axios from 'axios';
import getMetaPropertyContent from '@/util/getMetaPropertyContent';

export default {
  setup() {
    const showNpsComponent = ref(false);
    const npsScore = ref(null);
    const isLoading = ref(false);

    const userData = JSON.parse(getMetaPropertyContent('applysia:user'));
    const userCreatedAt = new Date(userData.createdAt);

    watchEffect(() => {
      // Check if 1 day has passed since the last shown date
      const today = new Date();
      const storedDate = localStorage.getItem('lastShownDate') ? new Date(localStorage.getItem('lastShownDate')) : null;

      const userCreatedAtLeast30DaysAgo = today - userCreatedAt >= 30 * 24 * 60 * 60 * 1000;
      const lastShownAtLeast1DayAgo = !storedDate || today - storedDate >= 1 * 24 * 60 * 60 * 1000;

      if ((userCreatedAtLeast30DaysAgo && lastShownAtLeast1DayAgo)
      && posthog.persistence
    && posthog.isFeatureEnabled('nps-popup')) {
        showNpsComponent.value = true;
      }
    });

    const submitNpsScore = () => {
      // Capture an event with the rating
      posthog.capture('nps_rated', {
        rating: npsScore.value,
      });
      // Set a property on the respondent based on the rating
      posthog.people.set({
        last_nps_rating: npsScore.value,
      });

      const data = new FormData();
      data.append('user[nps_last_rated_at]', new Date());

      Axios
        .put(authPath(), data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          showNpsComponent.value = false;
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    const hideForOneDay = () => {
      localStorage.setItem('lastShownDate', new Date());
      showNpsComponent.value = false;
    };

    return {
      showNpsComponent,
      isLoading,
      npsScore,
      submitNpsScore,
      hideForOneDay,
    };
  },
};
</script>
