import { nextTick } from 'vue';
import scrollIntoView from 'scroll-into-view-if-needed';

const defaultOptions = {
  scrollMode: 'if-needed',
  behavior: 'smooth',
  block: 'end',
  inline: 'nearest',
};

export default function scrollIntoViewIfNeeded(node, options) {
  const scrollOptions = { ...defaultOptions, ...options };

  nextTick(() => scrollIntoView(node, scrollOptions));
}
