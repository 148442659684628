<template>
  <div
    v-if="reportTemplate.error || waitingForFilePdf"
    :class="['z-10 w-full h-14 px-5 justify-center items-center inline-flex space-x-2',
             waitingForFilePdf ? 'bg-white bg-opacity-5' : 'bg-red-100']"
  >
    <Spinner
      v-if="waitingForFilePdf"
      primary-style="stroke-orange-500"
      secondary-style="stroke-gray-200"
      size="w-10 h-10"
    />
    <ExclamationIcon
      v-else
      class="h-5 w-5 text-red-800"
    />
    <p :class="[waitingForFilePdf ? 'text-gray-900 ': 'text-red-800']">
      {{ message }}
    </p>
  </div>
</template>

<script>
import Spinner from '@/components/generic/Spinner/Spinner.vue';
import { ExclamationIcon } from '@heroicons/vue/outline';
import { computed, defineComponent, toRefs } from 'vue';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PreviewTileStatusBanner',
  components: {
    Spinner,
    ExclamationIcon,
  },
  props: {
    reportTemplate: {
      type: Object,
      required: true,
    },
  },
  emits: ['edit'],
  setup(props) {
    const { t } = useI18n();
    const { reportTemplate } = toRefs(props);

    const waitingForFilePdf = computed(() => !reportTemplate.value.templateDocumentPdf);

    const message = computed(() => (waitingForFilePdf.value
      ? t('components.report_templates.preview_tile.banner.waiting_for_file_pdf')
      : t('components.report_templates.preview_tile.banner.template_error')));

    return {
      waitingForFilePdf,
      message,
    };
  },
});
</script>
