<!-- eslint-disable max-len -->
<template>
  <svg
    width="308"
    height="288"
    viewBox="0 0 308 288"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_366_17593)">
      <path
        d="M302.4 287.833H4.38218C3.13723 287.831 1.94366 287.336 1.06335 286.456C0.183038 285.575 -0.312118 284.382 -0.313477 283.137V153.537C-0.312118 152.292 0.183038 151.098 1.06335 150.218C1.94366 149.338 3.13723 148.843 4.38218 148.841H302.4C303.645 148.843 304.838 149.338 305.718 150.218C306.599 151.098 307.094 152.292 307.095 153.537V283.137C307.094 284.382 306.599 285.575 305.718 286.456C304.838 287.336 303.645 287.831 302.4 287.833ZM4.38218 150.72C3.63521 150.72 2.91907 151.017 2.39088 151.546C1.86269 152.074 1.5656 152.79 1.56478 153.537V283.137C1.5656 283.884 1.86269 284.6 2.39088 285.128C2.91907 285.656 3.63521 285.954 4.38218 285.954H302.4C303.147 285.954 303.863 285.656 304.391 285.128C304.919 284.6 305.216 283.884 305.217 283.137V153.537C305.216 152.79 304.919 152.074 304.391 151.546C303.863 151.017 303.147 150.72 302.4 150.72H4.38218Z"
        fill="#E6E6E6"
      />
      <path
        d="M16.5908 164.806C15.8436 164.806 15.127 165.103 14.5986 165.632C14.0703 166.16 13.7734 166.877 13.7734 167.624C13.7734 168.371 14.0703 169.088 14.5986 169.616C15.127 170.144 15.8436 170.441 16.5908 170.441H56.0343C56.7815 170.441 57.4982 170.144 58.0265 169.616C58.5549 169.088 58.8517 168.371 58.8517 167.624C58.8517 166.877 58.5549 166.16 58.0265 165.632C57.4982 165.103 56.7815 164.806 56.0343 164.806H16.5908Z"
        fill="#E6E6E6"
      />
      <path
        d="M286.617 184.4V236.647H20.6357V209.138C22.4776 207.955 24.3306 206.778 26.2002 205.64C34.1852 200.753 42.7322 196.183 52.0471 194.49C56.4524 193.668 60.9662 193.612 65.3906 194.323C70.0648 195.095 74.5386 196.705 78.9679 198.321C83.336 199.92 87.7152 201.569 92.2837 202.497C96.7355 203.371 101.306 203.461 105.789 202.763C114.341 201.481 122.376 198.043 130.378 194.934C138.43 191.808 146.726 188.853 155.44 188.465C164.043 188.076 172.54 190.108 180.703 192.629C197.346 197.766 214.34 205.384 231.963 200.159C249.858 194.862 266.318 179.569 286.149 184.106C286.337 184.14 286.504 184.246 286.617 184.4Z"
        fill="#E6E6E6"
      />
      <path
        d="M286.616 185.311C286.531 185.47 286.395 185.596 286.23 185.669C286.065 185.741 285.88 185.756 285.705 185.71C283.342 185.169 280.922 184.919 278.498 184.966C258.991 185.311 243.993 201.014 224.919 203.363C207.721 205.479 191.535 197.116 175.365 192.746C166.964 190.475 158.301 189.27 149.655 190.814C141.076 192.341 133.025 195.85 124.94 198.965C117.155 201.964 109.064 204.751 100.635 204.89C91.3672 205.045 83.0213 201.225 74.4255 198.315C69.9555 196.8 65.3688 195.6 60.6323 195.484C56.0159 195.428 51.4224 196.139 47.0389 197.588C37.9045 200.492 29.6752 205.667 21.6624 210.809C21.5251 210.906 21.3579 210.952 21.1904 210.937C21.0175 210.914 20.8557 210.839 20.7264 210.721C20.5972 210.604 20.5067 210.45 20.467 210.281C20.4272 210.111 20.44 209.933 20.5038 209.77C20.5676 209.608 20.6792 209.469 20.824 209.371L21.1904 209.138C23.0284 207.955 24.8775 206.778 26.7433 205.64C34.7116 200.753 43.2408 196.183 52.5362 194.49C56.9321 193.668 61.4369 193.612 65.8519 194.323C70.5163 195.095 74.9808 196.705 79.4008 198.321C83.7598 199.92 88.1299 201.569 92.6888 202.497C97.1311 203.371 101.692 203.461 106.166 202.763C114.7 201.481 122.719 198.043 130.704 194.934C138.738 191.808 147.018 188.853 155.714 188.465C164.298 188.076 172.777 190.108 180.923 192.629C197.532 197.766 214.49 205.384 232.076 200.159C249.934 194.862 266.359 179.569 286.15 184.106C286.337 184.141 286.504 184.246 286.616 184.4C286.702 184.536 286.747 184.694 286.747 184.855C286.747 185.016 286.702 185.174 286.616 185.311Z"
        :fill="color"
      />
      <path
        d="M57.2844 198.887C59.4312 198.887 61.1714 197.147 61.1714 195C61.1714 192.854 59.4312 191.113 57.2844 191.113C55.1377 191.113 53.3975 192.854 53.3975 195C53.3975 197.147 55.1377 198.887 57.2844 198.887Z"
        :fill="color"
      />
      <path
        d="M155.57 193.89C157.716 193.89 159.457 192.149 159.457 190.003C159.457 187.856 157.716 186.116 155.57 186.116C153.423 186.116 151.683 187.856 151.683 190.003C151.683 192.149 153.423 193.89 155.57 193.89Z"
        :fill="color"
      />
      <path
        d="M275.511 188.892C277.658 188.892 279.398 187.152 279.398 185.005C279.398 182.858 277.658 181.118 275.511 181.118C273.364 181.118 271.624 182.858 271.624 185.005C271.624 187.152 273.364 188.892 275.511 188.892Z"
        :fill="color"
      />
      <path
        d="M55.0957 258.719C54.3485 258.719 53.6319 259.016 53.1035 259.545C52.5751 260.073 52.2783 260.79 52.2783 261.537C52.2783 262.284 52.5751 263.001 53.1035 263.529C53.6319 264.057 54.3485 264.354 55.0957 264.354H94.5392C95.2864 264.354 96.003 264.057 96.5314 263.529C97.0598 263.001 97.3566 262.284 97.3566 261.537C97.3566 260.79 97.0598 260.073 96.5314 259.545C96.003 259.016 95.2864 258.719 94.5392 258.719H55.0957Z"
        fill="#E6E6E6"
      />
      <path
        d="M29.7392 271.867C35.6174 271.867 40.3827 267.102 40.3827 261.224C40.3827 255.346 35.6174 250.58 29.7392 250.58C23.861 250.58 19.0957 255.346 19.0957 261.224C19.0957 267.102 23.861 271.867 29.7392 271.867Z"
        :fill="color"
      />
      <path
        d="M150.261 258.719C149.514 258.719 148.797 259.016 148.269 259.545C147.74 260.073 147.443 260.79 147.443 261.537C147.443 262.284 147.74 263.001 148.269 263.529C148.797 264.057 149.514 264.354 150.261 264.354H189.704C190.451 264.354 191.168 264.057 191.696 263.529C192.225 263.001 192.522 262.284 192.522 261.537C192.522 260.79 192.225 260.073 191.696 259.545C191.168 259.016 190.451 258.719 189.704 258.719H150.261Z"
        fill="#E6E6E6"
      />
      <path
        d="M124.904 271.867C130.782 271.867 135.548 267.102 135.548 261.224C135.548 255.346 130.782 250.58 124.904 250.58C119.026 250.58 114.261 255.346 114.261 261.224C114.261 267.102 119.026 271.867 124.904 271.867Z"
        :fill="color"
      />
      <path
        d="M245.426 258.719C244.679 258.719 243.962 259.016 243.434 259.545C242.905 260.073 242.608 260.79 242.608 261.537C242.608 262.284 242.905 263.001 243.434 263.529C243.962 264.057 244.679 264.354 245.426 264.354H284.869C285.616 264.354 286.333 264.057 286.861 263.529C287.39 263.001 287.687 262.284 287.687 261.537C287.687 260.79 287.39 260.073 286.861 259.545C286.333 259.016 285.616 258.719 284.869 258.719H245.426Z"
        fill="#E6E6E6"
      />
      <path
        d="M220.069 271.867C225.947 271.867 230.713 267.102 230.713 261.224C230.713 255.346 225.947 250.58 220.069 250.58C214.191 250.58 209.426 255.346 209.426 261.224C209.426 267.102 214.191 271.867 220.069 271.867Z"
        :fill="color"
      />
      <path
        d="M282.001 113.849H134.972C132.106 113.849 129.783 112.194 129.78 110.155V78.1983C129.783 76.1591 132.106 74.5044 134.972 74.5044H282.001C284.868 74.5044 287.19 76.1591 287.194 78.1983V110.154C287.191 112.194 284.868 113.846 282.001 113.849ZM134.972 75.9807C133.253 75.9807 131.859 76.9737 131.857 78.1971V110.153C131.857 111.377 133.253 112.368 134.972 112.37H282.001C283.721 112.37 285.115 111.377 285.117 110.153V78.1983C285.117 76.9749 283.721 75.9832 282.001 75.982L134.972 75.9807Z"
        fill="#E6E6E6"
      />
      <path
        d="M168.216 92.6653C167.386 92.6663 166.591 92.9964 166.004 93.5832C165.417 94.1701 165.087 94.9658 165.086 95.7957V117.709C165.087 118.539 165.417 119.334 166.004 119.921C166.591 120.508 167.386 120.838 168.216 120.839H280.286C281.116 120.838 281.912 120.508 282.498 119.921C283.085 119.334 283.415 118.539 283.416 117.709V95.7957C283.415 94.9658 283.085 94.1701 282.498 93.5832C281.912 92.9964 281.116 92.6663 280.286 92.6653H168.216Z"
        :fill="color"
      />
      <path
        d="M183.868 113.952C187.672 113.952 190.755 110.869 190.755 107.065C190.755 103.262 187.672 100.178 183.868 100.178C180.065 100.178 176.981 103.262 176.981 107.065C176.981 110.869 180.065 113.952 183.868 113.952Z"
        fill="white"
      />
      <path
        d="M215.256 104.076C214.509 104.076 213.792 104.373 213.264 104.901C212.735 105.429 212.438 106.146 212.438 106.893C212.438 107.641 212.735 108.357 213.264 108.886C213.792 109.414 214.509 109.711 215.256 109.711H254.699C255.447 109.711 256.163 109.414 256.692 108.886C257.22 108.357 257.517 107.641 257.517 106.893C257.517 106.146 257.22 105.429 256.692 104.901C256.163 104.373 255.447 104.076 254.699 104.076H215.256Z"
        fill="white"
      />
      <path
        d="M171.81 39.3446H24.781C21.9148 39.3446 19.592 37.6898 19.5889 35.6506V3.69392C19.592 1.65475 21.9148 0 24.781 0H171.81C174.676 0 176.999 1.65475 177.002 3.69392V35.65C177 37.6892 174.676 39.342 171.81 39.3446ZM24.781 1.47631C23.0611 1.47631 21.6674 2.46928 21.6656 3.69266V35.6488C21.6656 36.8721 23.0611 37.8639 24.781 37.8651H171.81C173.53 37.8651 174.924 36.8721 174.925 35.6488V3.69392C174.925 2.47054 173.53 1.47882 171.81 1.47757L24.781 1.47631Z"
        fill="#E6E6E6"
      />
      <path
        d="M58.025 18.1609C57.195 18.1619 56.3994 18.492 55.8125 19.0788C55.2256 19.6657 54.8955 20.4614 54.8945 21.2913V43.2044C54.8955 44.0343 55.2256 44.83 55.8125 45.4168C56.3994 46.0037 57.195 46.3338 58.025 46.3348H170.095C170.924 46.3338 171.72 46.0037 172.307 45.4168C172.894 44.83 173.224 44.0343 173.225 43.2044V21.2913C173.224 20.4614 172.894 19.6657 172.307 19.0788C171.72 18.492 170.924 18.1619 170.095 18.1609H58.025Z"
        :fill="color"
      />
      <path
        d="M73.677 39.448C77.4806 39.448 80.5639 36.3646 80.5639 32.561C80.5639 28.7575 77.4806 25.6741 73.677 25.6741C69.8734 25.6741 66.79 28.7575 66.79 32.561C66.79 36.3646 69.8734 39.448 73.677 39.448Z"
        fill="white"
      />
      <path
        d="M105.064 29.5718C104.317 29.5718 103.601 29.8686 103.072 30.397C102.544 30.9253 102.247 31.642 102.247 32.3892C102.247 33.1364 102.544 33.853 103.072 34.3814C103.601 34.9097 104.317 35.2066 105.064 35.2066H144.508C145.255 35.2066 145.972 34.9097 146.5 34.3814C147.029 33.853 147.325 33.1364 147.325 32.3892C147.325 31.642 147.029 30.9253 146.5 30.397C145.972 29.8686 145.255 29.5718 144.508 29.5718H105.064Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_366_17593">
        <rect
          width="307.409"
          height="288"
          fill="red"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TestReady',
  props: {
    color: {
      type: String,
      default: 'var(--color-primary-500)',
    },
  },
};
</script>
<!-- eslint-enable max-len -->
