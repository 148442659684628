import colors from 'tailwindcss/colors';

const deprecated = ['lightblue', 'warmgray', 'truegray', 'coolgray', 'bluegray'];
const blocked = ['stone', 'neutral', 'slate', 'gray', 'gray', 'black', 'white', 'inherit', 'current', 'transparent'];
const disabledColors = deprecated.concat(blocked);

const allowdColors = Object.keys(colors)
  .filter((key) => !(disabledColors.some((colorName) => key.toLowerCase().includes(colorName))))
  .map((key) => ({ name: key, ...colors[key] }));

const TAILWIND_COLOR_OPTIONS = allowdColors.map((color) => {
  const bgColor = `bg-${color.name}-500`;
  const selectedColor = `ring-${color.name}-500`;

  return { name: color.name, bgColor, selectedColor, hexColor: color[500] };
});

const nameToTailwind = (name) => TAILWIND_COLOR_OPTIONS.find((color) => color.name === name);

export {
  TAILWIND_COLOR_OPTIONS,
  nameToTailwind,
};
