<template>
  <p
    :class="remainingPathColor"
    class="flex flex-col"
  >
    <span class="hidden sm:flex text-sm leading-5 font-medium">
      {{ timerText }}
    </span>
    <span class="text-left w-34 text-base leading-6 font-semibold">
      {{ formattedTimeLeft }}
    </span>
  </p>
</template>

<script>
const WARNING_THRESHOLD = 0.3;
const ALERT_THRESHOLD = 0.1;
const COLOR_CODES = {
  info: {
    color: 'text-green-500',
  },
  warning: {
    color: 'text-orange-500',
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: 'text-red-500',
    threshold: ALERT_THRESHOLD,
  },
};

export default {
  name: 'Timer',
  props: {
    // end date in ISO8601 format
    endDate: {
      type: String,
      required: true,
    },
    // total duration in seconds
    totalDuration: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['end'],
  data() {
    return {
      // total time from now in seconds
      timeLimit: Math.floor((new Date(this.endDate).getTime() - new Date().getTime()) / 1000),
      timePassed: 0,
      timerInterval: null,
    };
  },
  computed: {
    formattedEndDate() {
      const date = new Date(this.endDate);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return (this.$i18n.locale === 'en')
        ? `${month}/${day}/${year} ${hours}:${minutes}` : `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    timerText() {
      return this.timeLeft < 86400 ? this.$t('components.remote_session_tool.timer.remaining_time')
        : this.$t('components.remote_session_tool.timer.until');
    },
    timeUnitStringHours() {
      return (this.$i18n.locale === 'en') ? 'h' : 'Std.';
    },
    timeUnitStringMinutes() {
      return (this.$i18n.locale === 'en') ? 'min' : 'Min.';
    },
    formattedTimeLeft() {
      if (this.timeLeft >= 86400) { // more than 1 day
        return this.formattedEndDate;
      }

      const hours = Math.floor(this.timeLeft / 3600);
      const minutes = Math.floor((this.timeLeft % 3600) / 60);

      const minutesString = minutes.toString().padStart(2, '0');

      if (this.timeLeft >= 600) { // more than 10 minutes
        const hoursString = hours.toString().padStart(2, '0');
        return `${hoursString} ${this.timeUnitStringHours} ${minutesString} ${this.timeUnitStringMinutes}`;
      }

      const secondsString = (this.timeLeft % 60).toString().padStart(2, '0');
      return `${minutesString} ${this.timeUnitStringMinutes} ${secondsString}s`;
    },
    timeLeft() {
      return this.timeLimit - this.timePassed;
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },
    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold * this.totalDuration) {
        return alert.color;
      } if (this.timeLeft <= warning.threshold * this.totalDuration) {
        return warning.color;
      }
      return info.color;
    },
  },
  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.$emit('end');
    },
    startTimer() {
      this.timerInterval = setInterval(() => { this.timePassed += 1; }, 1000);
    },
  },
};
</script>
