<template>
  <div>
    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 justify-between items-center sm:px-12 pt-12 space-x-2">
      <div
        class="width-mobile sm:w-full flex flex-col sm:flex-row
            space-y-4 sm:space-y-0 sm:justify-between pb-6 sm:space-x-2"
      >
        <p class="text-2xl leading-7 font-bold text-gray-900">
          {{ category.name }}
        </p>
        <div class="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <BaseInput
            v-model="searchTerm"
            :trailing-icon-clickable="true"
            class="width-mobile w-64"
            leading-icon="SearchIcon"
            qa-class="remote-session-file-search"
            @keyup.exact.prevent.stop.esc="searchTerm = ''"
          >
            <template #trailing-icon>
              <button
                v-if="searchTerm != ''"
                class="transition-colors
                    duration-200
                    cursor-pointer
                    text-gray-300
                    hover:text-gray-500
                    focus:outline-none
                    focus:ring
                    ring-primary-500
                    focus:text-gray-500
                    rounded-sm"
                @focus.stop
                @click.stop="searchTerm=''"
              >
                <XIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
                <span class="sr-only">
                  {{ $t('components.search_input.clear') }}
                </span>
              </button>
            </template>
          </BaseInput>
          <BaseButton
            :is-disabled="!(downloadableFiles.length > 0)"
            :is-primary="false"
            icon="DownloadIcon"
            @click="downloadFiles"
          >
            {{ $t('defaults.download') }}
            {{ `(${downloadableFiles.length})` }}
          </BaseButton>
        </div>
      </div>
    </div>
    <div
      :class="[
        'justify-center sm:justify-start px-0',
        'sm:px-12 pb-6 gap-6 flex flex-row flex-wrap min-h-fit',
        'qa-remote-session-files'
      ]"
    >
      <div
        v-for="(file, file_index) in filteredFiles"
        :key="`file-${file_index}`"
        class="qa-remote-session-file"
      >
        <PreviewTile
          :file="file"
          :class="`qa-file-${file.id}`"
          @select-file="$emit('select-file', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import download from '@/util/download';
import PreviewTile from '@/components/RemoteSessionTool/PreviewTile.vue';
import { XIcon } from '@heroicons/vue/solid';

export default {
  name: 'FileOverview',
  components: {
    PreviewTile,
    XIcon,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  emits: ['select-file'],
  setup(props) {
    const searchTerm = ref('');

    const filteredFiles = computed(() => props.category.files.filter(
      (file) => (file.filename.toLowerCase().includes(searchTerm.value.toLowerCase())
      && (!searchTerm.value || file.shared)),
    ));

    const isDownloadAbleFile = (file) => file.downloadable && file.shared;

    const downloadableFiles = computed(() => props.category.files.filter(isDownloadAbleFile));

    function downloadFiles() {
      downloadableFiles.value.forEach((file) => {
        download({
          path: file.url,
          filename: file.filename,
        });
      });
    }

    return {
      filteredFiles,
      searchTerm,
      downloadableFiles,
      downloadFiles,
    };
  },
};
</script>
