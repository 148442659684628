import { ref } from 'vue';

// eslint-disable-next-line import/prefer-default-export
export function useSearchHighlighter({ searchParam = 'q' } = {}) {
  const params = new URLSearchParams(window.location.search);
  const searchTerm = params.get(searchParam);

  const text = ref(searchTerm);

  const highlightedText = (inputString) => {
    if (!text.value) {
      return inputString;
    }
    // Creating a safe regular expression to avoid special characters being treated as part of regex
    const safeRegex = new RegExp(text.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
    return inputString.replace(safeRegex, (match) => `<mark>${match}</mark>`);
  };

  return { highlightedText };
}
