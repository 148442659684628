<template>
  <nav
    v-if="pageNumbers.length > 1"
    class="bg-white rounded-b-md border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"
    aria-label="pager"
    role="navigation"
  >
    <div class="-mt-px w-0 flex-1 flex">
      <a
        v-if="hasPreviousPage"
        :href="previousPageLink"
        aria-label="previous"
        class="border-t-2 border-transparent p-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500
          hover:text-gray-700 hover:border-gray-300"
      >
        <ArrowNarrowLeftIcon
          class="mr-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        {{ $t('components.pagination.previous') }}
      </a>
    </div>

    <div class="hidden md:-mt-px md:flex">
      <template
        v-for="(page, index) in pageNumbers"
        :key="index"
      >
        <component
          :is="Number(page) ? 'a' : 'span'"
          :href="pageLink(page)"
          :aria-current="page === currentPage ? 'page' : null"
          :class="[
            'border-t-2 p-4 inline-flex items-center text-sm font-medium',
            page !== '...' && page === currentPage ? 'border-primary-500 text-primary-600' : 'border-transparent',
            page !== '...' && page !== currentPage ? 'text-gray-500 hover:text-gray-700 hover:border-gray-300' : '']"
        >
          {{ page }}
        </component>
      </template>
    </div>

    <div class="-mt-px w-0 flex-1 flex justify-end">
      <a
        v-if="hasNextPage"
        :href="nextPageLink"
        aria-label="next"
        class="border-t-2 border-transparent p-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500
          hover:text-gray-700 hover:border-gray-300"
      >
        {{ $t('components.pagination.next') }}
        <ArrowNarrowRightIcon
          class="ml-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </a>
    </div>
  </nav>
</template>

<script>
import { toRefs, computed } from 'vue';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid';

export default {
  components: {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    // Example: [1, "gap", 7, 8, "9", 10, 11, "gap", 36]
    pages: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { currentPage, pages } = toRefs(props);

    const pageNumbers = computed(() => pages.value.map((page) => {
      if (Number(page)) return Number(page);
      if (page === 'gap') return '...';

      return null;
    }).filter(Boolean));

    const pageLink = (page) => {
      const { pathname, search } = window.location;
      const params = new URLSearchParams(search);
      params.set('page', page);

      return `${pathname}?${params.toString()}`;
    };

    const previousPageLink = computed(() => pageLink(currentPage.value - 1));
    const nextPageLink = computed(() => pageLink(currentPage.value + 1));

    const hasPreviousPage = computed(() => currentPage.value > 1);
    const hasNextPage = computed(() => currentPage.value < pageNumbers.value[pageNumbers.value.length - 1]);

    return {
      pageNumbers,

      pageLink,
      hasPreviousPage,
      previousPageLink,
      hasNextPage,
      nextPageLink,
    };
  },
};
</script>
