<template>
  <div
    ref="trigger"
    @mouseenter="setShowTooltip(true)"
    @mouseleave="setShowTooltip(false)"
  >
    <slot
      name="parent"
    >
      <a class="material-icons-outlined text-gray-500 cursor-pointer">{{ defaultParentIcon }}</a>
    </slot>
    <div
      v-if="enabled && showToolTip"
      id="tooltip"
      ref="container"
      class="bg-black text-white text-sm text-center whitespace-normal
               max-w-[85%] rounded-lg py-2.5 px-4 z-50"
    >
      <span
        id="arrow"
        ref="arrow"
        data-popper-arrow
      />
      <slot />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import usePopper from '@/util/use-popper';
import { debounce } from 'lodash';

export default {
  name: 'Tooltip',
  props: {
    defaultParentIcon: {
      type: String,
      required: false,
      default: 'info',
    },
    defaultPlacement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    debounce: {
      type: Number,
      default: 0,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const showToolTip = ref(false);

    const setShowTooltip = debounce((value) => {
      showToolTip.value = value;
    }, props.debounce);

    const [trigger, container] = usePopper({
      placement: props.defaultPlacement,
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [0, 4] } },
      ],
    });
    return {
      showToolTip,
      trigger,
      container,
      setShowTooltip,
    };
  },
};
</script>

<style>
#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

#arrow::before {
  content: '';
  transform: rotate(45deg);
  background: #000000;
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
  margin-left: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
  margin-left: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -0px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -8px;
}
</style>
