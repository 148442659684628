<template>
  <div>
    <input
      type="hidden"
      :value="scale.id"
      :name="`competence_model[scale_attributes][id]`"
    >

    <div class="space-y-6">
      <TwoColumnCard
        :header="$t('components.assessment.competence_model_form.categories.scale.header')"
        :helptext="$t('components.assessment.competence_model_form.categories.scale.helptext')"
      >
        <div class="flex flex-row justify-between mb-2 space-x-4">
          <div class="w-full">
            <BaseInput
              v-model.number="scale.min"
              :label="$t('activerecord.attributes.scale.min')"
              type="number"
              :step="scale.step"
              min="0"
              :max="SCALE_LIMIT_MAX"
              name="competence_model[scale_attributes][min]"
              :disabled="disableScaleEditing"
              qa-class="competence-model-scale-min"
              :error="getError('scale', 'min')"
            />
          </div>
          <div class="w-full">
            <BaseInput
              v-model.number="scale.max"
              :label="$t('activerecord.attributes.scale.max')"
              type="number"
              :step="scale.step"
              min="0"
              :max="SCALE_LIMIT_MAX"
              name="competence_model[scale_attributes][max]"
              :disabled="disableScaleEditing"
              qa-class="competence-model-scale-max"
              :error="getError('scale', 'max')"
            />
          </div>
          <div class="w-full">
            <SelectList
              v-model:selected="initialScaleOptionRef"
              :label="$t('activerecord.attributes.scale.step') "
              :options="scaleOptions"
              :disabled="disableScaleEditing"
              name="competence_model[scale_attributes][step]"
              :error="getError('scale', 'step')"
            />
          </div>
        </div>
      </TwoColumnCard>
      <TwoColumnCard
        :header="$t('components.assessment.competence_model_form.categories.scale_labels.header')"
        :helptext="$t('components.assessment.competence_model_form.categories.scale_labels.helptext')"
      >
        <div class="space-y-4">
          <div class="block text-sm font-medium text-gray-700 mb-1">
            {{ $t('activerecord.models.scale_label.other') }}
          </div>
          <div class="grid md:grid-cols-2 grid-cols-1 grid-flow-row gap-4">
            <div
              v-for="scaleLabel in scaleLabels"
              :key="scaleLabel.value"
              class="grow"
            >
              <input
                type="hidden"
                :value="scaleLabel.id"
                :name="`competence_model[scale_labels_attributes][${scaleLabel.id || scaleLabel.tempId}][id]`"
              >
              <input
                type="hidden"
                :value="`${scaleLabel.value}`"
                :name="`competence_model[scale_labels_attributes][${scaleLabel.id || scaleLabel.tempId}][value]`"
              >
              <BaseInput
                v-model="scaleLabel.description"
                v-debounce:1000ms="commitScaleLabel"
                debounce-events="input"
                type="text"
                :name="`competence_model[scale_labels_attributes][${scaleLabel.id || scaleLabel.tempId}][description]`"
                :placeholder="scaleLabel.value.toString()"
                :disabled="disableScaleLabelEditing"
                :error="labelDescriptionErrorMessage(scaleLabel.description)"
              >
                <template #leading-addon>
                  {{ scaleLabel.value }}
                </template>
              </BaseInput>
            </div>
          </div>
          <div
            class="flex justify-between items-center space-x-4"
          >
            <div>
              <legend class="flex text-base font-medium text-gray-900 pr-4">
                {{ $t('components.assessment.competence_model_form.categories.scale_labels.symbols.header') }}
              </legend>
              <span class="text-sm text-gray-500">
                {{ $t('components.assessment.competence_model_form.categories.scale_labels.symbols.description') }}
                <a
                  :href="$t('components.competence_model.scale_label.support_link')"
                  class="text-primary-500 text-sm leading-5 inline-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ $t('defaults.more_information') }}
                </a>
              </span>
            </div>
            <Toggle
              v-model="isEnabledScaleLabelSymbols"
              :disabled="disableScaleLabelEditing"
              qa-class="enable-scale-label-symbols"
              @update:model-value="updateIsEnabledScaleLabelSymbols"
            />
            <input
              type="hidden"
              name="competence_model[scale_attributes][scale_label_symbols_enabled]"
              :value="isEnabledScaleLabelSymbols"
            >
          </div>
        </div>
      </TwoColumnCard>
    </div>
  </div>
</template>

<script>
import { inject, ref, toRefs, watch } from 'vue';
import vueDebounce from 'vue-debounce';

import SelectList from '@/components/generic/SelectList/SelectList.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import useScaleOptions from '@/composables/scaleOptions';
import { competenceModelSettingsPath } from '@/util/url-helpers';
import Axios from 'axios';
import { useI18n } from 'vue-i18n';

export default {
  name: 'CompetenceModelScaleInput',
  components: {
    SelectList,
    TwoColumnCard,
  },
  directives: {
    debounce: vueDebounce({ lock: true }),
  },
  props: {
    competenceModelId: {
      type: Number,
      default: null,
    },
    initialScaleLabels: {
      type: Array,
      default: () => [],
    },
    initialScale: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    disableScaleEditing: {
      type: Boolean,
      required: true,
    },
    disableScaleLabelEditing: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:scale-labels', 'update:scale_label-symbols-enabled'],
  setup(props, { emit }) {
    const toast = inject('toast');
    const { t } = useI18n();
    const { initialScale, initialScaleLabels } = toRefs(props);

    const SCALE_LIMIT_MAX = 30;
    const scaleLabels = ref([...props.initialScaleLabels]);
    const scale = ref({ ...props.initialScale });
    const isEnabledScaleLabelSymbols = ref(props.initialScale?.scaleLabelSymbolsEnabled);
    const tempIdCounter = ref(0);

    const generateTempId = () => {
      const tempId = parseInt(
        `${new Date().getTime()}${tempIdCounter.value}`,
        10,
      );

      tempIdCounter.value += 1;
      return tempId;
    };

    const updateScaleLabels = (newScale) => {
      if (
        newScale.min != null
        && newScale.max != null
        && newScale.step != null
      ) {
        const newScaleLabels = [];

        if (newScale.max > SCALE_LIMIT_MAX) return;

        for (
          let value = newScale.min;
          value <= newScale.max;
          value += newScale.step
        ) {
          let scaleLabelForValue = scaleLabels.value.find(
            (scaleLabel) => scaleLabel.value === value,
          );

          scaleLabelForValue = scaleLabelForValue || {
            value,
            description: '',
          };
          scaleLabelForValue = {
            ...scaleLabelForValue,
            tempId: generateTempId(),
          };

          newScaleLabels.push(scaleLabelForValue);
        }

        scaleLabels.value = newScaleLabels;
      }
    };

    const checkLabelDescriptionLength = () => scaleLabels.value.some((label) => label.description?.length > 3);

    const labelSizeLimitExceeded = ref(checkLabelDescriptionLength());

    const labelDescriptionErrorMessage = (description) => (isEnabledScaleLabelSymbols.value && description?.length > 3
      ? t('components.competence_model.scale_label.errors.description_length')
      : '');

    function commitScaleLabel() {
      labelSizeLimitExceeded.value = checkLabelDescriptionLength();
      if (!(isEnabledScaleLabelSymbols.value && labelSizeLimitExceeded.value)) {
        emit('update:scale-labels');
      }
    }
    const updateIsEnabledScaleLabelSymbols = (value) => {
      if (value && labelSizeLimitExceeded.value) {
        isEnabledScaleLabelSymbols.value = false;
        toast.error(t('components.assessment.competence_model_form.categories.scale_labels.symbols.warning'));
      } else if (props.competenceModelId && !(value && checkLabelDescriptionLength.value)) {
        const data = {
          competence_model: {
            scale_attributes: {
              id: scale.value.id,
              scale_label_symbols_enabled: isEnabledScaleLabelSymbols.value,
            },
          },
        };

        Axios.put(competenceModelSettingsPath(props.competenceModelId), data)
          .then((response) => {
            isEnabledScaleLabelSymbols.value = value;
            if (!value) { commitScaleLabel(); } // emit when no size limit is set
            emit('update:scale_label-symbols-enabled', response.data);
          })
          .catch((_) => {
            isEnabledScaleLabelSymbols.value = !value;
            toast.error(t('components.assessment.competence_model_form.categories.scale_labels.symbols.warning'));
          });
      }
    };

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    watch(scale, (newScale) => {
      updateScaleLabels(newScale);
    }, { deep: true });

    watch(initialScale, () => {
      scaleLabels.value = initialScaleLabels.value;
    }, { deep: true });

    const {
      scaleOptions,
      initialScaleOption,
    } = useScaleOptions(props.initialScale.step);

    updateScaleLabels(scale.value);

    const initialScaleOptionRef = ref(initialScaleOption);

    watch(initialScaleOptionRef, (newScaleValue) => {
      scale.value.step = newScaleValue?.value;
    });

    return {
      scaleOptions,
      initialScaleOptionRef,
      tempIdCounter,
      scale,
      scaleLabels,
      isEnabledScaleLabelSymbols,
      SCALE_LIMIT_MAX,
      labelSizeLimitExceeded,

      commitScaleLabel,
      updateScaleLabels,
      updateIsEnabledScaleLabelSymbols,
      getError,
      labelDescriptionErrorMessage,
    };
  },
};
</script>
