<template>
  <Badge color="primary">
    Beta
  </Badge>
</template>

<script>
import Badge from '@/components/generic/Badge/Badge.vue';

export default {
  components: { Badge },
};
</script>
