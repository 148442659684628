<template>
  <Teleport to="body">
    <div
      class="
      fixed
      w-screen
      h-screen
      bg-black/60
      top-0
      left-0
      z-50
      flex flex-col
      items-center
    "
    >
      <div class="shrink-0 grow-0 w-full">
        <PreviewOverlayHeader
          :file="file"
          @close="$emit('close')"
          @download="downloadFile"
          @print="printFile"
        />
      </div>

      <div
        v-if="previewFile"
        class="w-full flex-1 overflow-auto"
      >
        <ResourceViewer
          :url="previewFile.url"
          :type="previewFile.type"
          @click-outside="$emit('close')"
        />
      </div>
      <div
        v-else
        class="w-full h-full flex items-center justify-center"
      >
        <Spinner
          primary-style="stroke-orange-500"
          secondary-style="stroke-gray-200"
          size="w-16 h-16"
        />
      </div>
    </div>
  </Teleport>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, computed } from 'vue';
import download from '@/util/download';
import printJS from 'print-js';
import ResourceViewer from '@/components/Viewers/ResourceViewer.vue';
import PreviewOverlayHeader from './PreviewOverlayHeader.vue';
import Spinner from '../generic/Spinner/Spinner.vue';

export default defineComponent({
  components: {
    ResourceViewer,
    PreviewOverlayHeader,
    Spinner,
  },
  props: {
    file: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['close'],
  setup(props) {
    onMounted(() => {
      if (window.ZohoHCAsap) {
        window.ZohoHCAsap.Actions.Disable();
      }
      document.querySelectorAll('.overflow-y-auto').forEach((node) => {
        node.classList.add('noscroll');
      });
    });

    onUnmounted(() => {
      if (window.ZohoHCAsap) {
        window.ZohoHCAsap.Actions.Enable();
      }
      document.querySelectorAll('.noscroll').forEach((node) => {
        node.classList.remove('noscroll');
      });
    });

    const downloadFile = () => {
      download({
        path: props.file.url,
        filename: props.file.filename,
      });
    };

    const printFile = () => {
      printJS(props.file.url);
    };

    const previewFile = computed(() => {
      if (props.file.type === 'docx' || props.file.type === 'pptx') {
        return props.file.filePdf;
      }

      return {
        url: props.file.url,
        type: props.file.type,
      };
    });

    return { downloadFile, printFile, previewFile };
  },
});
</script>
