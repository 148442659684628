<template>
  <div class="mt-6">
    <BaseTable
      :columns="columns"
      :records="observerAssignments"
      :pages="pages"
      :standalone="standalone"
      :preview="preview"
      :view-all-link="viewAllLink"
      :emit-row-click="true"
      @on-row-click="visitObserverPath"
    >
      <template #default="{ record: observer }">
        <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {{ observer.user.firstName }}
        </td>
        <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {{ observer.user.lastName }}
        </td>
        <td class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
          {{ observer.user.email }}
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
          {{ $t(`activerecord.enums.observer_assignment.role.${observer.role}`) }}
        </td>
        <td class="ph-no-capture px-6 py-4 whitespace-nowrap">
          <FileTiles :files="observer.files" />
        </td>
        <ObserverAssignmentActions
          v-if="standalone"
          :observer-assignment="observer"
          :organisation-id="organisationId"
          :assessment-editable="assessmentEditable"
          :is-active-assessment="assessmentStatus === 'active'"
          @open-replace-dialog="openReplaceModal"
        />
      </template>
    </BaseTable>
  </div>
  <ReplaceModal
    v-if="observerAssignment"
    :observer-assignment="observerAssignment"
    :organisation-id="organisationId"
    :assessment-status="assessmentStatus"
    :users="users"
    :open="observerAssignment != undefined"
    @close="closeReplaceModal"
  />
</template>

<script>
import { ref } from 'vue';
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import ReplaceModal from '@/components/Observers/ObserversTable/ObserverAssignmentActions/ReplaceModal.vue';
import { useI18n } from '@/plugins/i18n';
import { editOrganisationAssessmentObserverAssignmentPath } from '@/util/url-helpers';
import FileTiles from '@/components/FileTiles/FileTiles.vue';
import ObserverAssignmentActions from './ObserverAssignmentActions/ObserverAssignmentActions.vue';

export default {
  components: {
    BaseTable,
    FileTiles,
    ObserverAssignmentActions,
    ReplaceModal,
  },
  props: {
    observerAssignments: {
      type: Array,
      required: true,
    },
    pages: {
      type: Object,
      default: () => {},
      required: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    standalone: {
      type: Boolean,
      default: true,
    },
    viewAllLink: {
      type: String,
      default: '/',
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    assessmentEditable: {
      type: Boolean,
      required: true,
    },
    assessmentStatus: {
      type: String,
      default: '',
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();

    const columns = [
      {
        key: 'first_name',
        name: t('activerecord.attributes.user.first_name'),
        sortable: true,
        association: 'user',
      },
      {
        key: 'last_name',
        name: t('activerecord.attributes.user.last_name'),
        sortable: true,
        association: 'user',
      },
      {
        key: 'email',
        name: t('activerecord.attributes.user.email'),
        sortable: true,
        association: 'user',
      },
      {
        key: 'role',
        name: t('activerecord.attributes.observer_assignment.role'),
        sortable: true,
      },
      {
        key: 'files',
        name: t('activerecord.attributes.observer_assignment.files'),
        sortable: false,
      },
      {
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      },
    ];

    const observerAssignment = ref(null);

    const visitObserverPath = (observer) => {
      const editPath = props.organisationId
        ? editOrganisationAssessmentObserverAssignmentPath(props.organisationId, observer.assessmentId, observer)
        : observer.path;
      Turbo.visit(editPath);
    };

    const openReplaceModal = (event) => {
      observerAssignment.value = event;
    };

    const closeReplaceModal = () => {
      observerAssignment.value = undefined;
    };

    return {
      columns,
      openReplaceModal,
      closeReplaceModal,
      observerAssignment,
      visitObserverPath,
    };
  },
};
</script>
