import consumer from '@/channels/consumer';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref, reactive } from 'vue';

import { useI18n } from '@/plugins/i18n';
import { useNotifications } from '@/plugins/toast';
import { uploadFile } from '@/util/file-management';
import File from '@/models/File';
import { directUploadPath } from '@/util/url-helpers';

// eslint-disable-next-line import/prefer-default-export
export const useReportTemplatesStore = defineStore('reportTemplates', () => {
  const reportTemplates = ref([]);
  const subscription = ref(null);
  const replacementReportTemplate = ref(null);
  const uploadingTemplateFile = ref(null);
  const uploadProgress = computed(() => uploadingTemplateFile.value?.progress);

  function setReportTemplates(templates) {
    reportTemplates.value = templates;
  }

  function upsertReportTemplate(newReportTemplate) {
    const reportTemplateIndex = reportTemplates.value.findIndex((report) => report.id === newReportTemplate.id);
    if (reportTemplateIndex !== -1) {
      reportTemplates.value[reportTemplateIndex] = newReportTemplate;
    } else {
      reportTemplates.value.unshift(newReportTemplate);
    }
  }

  function addReportTemplates(newReportTemplates) {
    newReportTemplates.forEach((newReportTemplate) => upsertReportTemplate(newReportTemplate));
  }

  function subscribeUpdates({ assessmentId, organisationId }) {
    let options = { channel: 'ReportTemplatesChannel' };

    if (assessmentId) {
      options = {
        channel: 'AssessmentReportTemplatesChannel',
        id: assessmentId,
      };
    } else if (organisationId) {
      options = {
        channel: 'ReportTemplatesChannel',
        id: organisationId,
      };
    }

    subscription.value = consumer.subscriptions.create(options, {
      received({ reportTemplate }) {
        upsertReportTemplate(reportTemplate);
      },
    });
  }

  function setReplacementDocument(template) {
    replacementReportTemplate.value = template;
  }

  function unsubscribe() {
    subscription.value.unsubscribe();
  }

  const getReportTemplateById = computed(() => (id) => reportTemplates.value.find((report) => report.id === id));

  const uploadSelectedFile = async (filesToUpload, {
    acceptedMimeTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ], maxFileSize = 1024 * 1024 * 200,
  } = {}) => {
    const { error, warn } = useNotifications();
    const { t } = useI18n();

    const uploadedFiles = [];
    for (let i = 0; i < filesToUpload.length; i += 1) { // upload files
      const binary = filesToUpload[i];
      const file = reactive(File.pending({ file: binary }));
      uploadingTemplateFile.value = file;

      if (acceptedMimeTypes && !acceptedMimeTypes.includes(binary.type)) {
        file.onUploadError();
        warn(t('components.multi_file_upload.content_type_not_allowed'));
      } else if (binary.size > maxFileSize) {
        file.onUploadError();
        warn(t('components.multi_file_upload.file_size_too_large'));
      } else if (binary.size <= 0) {
        file.onUploadError();
        warn(t('components.multi_file_upload.empty_file'));
      } else {
        try {
          // eslint-disable-next-line no-await-in-loop
          await uploadFile({ file, binary, path: directUploadPath() }).then((blob) => {
            uploadedFiles.push(blob);
          });
        } catch (e) {
          error(t('components.multi_file_upload.failed_to_upload_file', { filename: file.filename }));
        }
      }
    }
    return uploadedFiles;
  };

  return {
    reportTemplates: computed(() => reportTemplates.value),
    replacementReportTemplate,
    setReportTemplates,
    setReplacementDocument,
    getReportTemplateById,
    upsertReportTemplate,
    addReportTemplates,
    subscribeUpdates,
    unsubscribe,
    uploadSelectedFile,
    uploadProgress,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReportTemplatesStore, import.meta.hot));
}
