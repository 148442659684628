<template>
  <video
    id="video-player"
    ref="videoPlayer"
    class="video-js w-full vjs-big-play-centered"
  />
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import videojs from 'video.js';

export default defineComponent({
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const player = ref(null);
    const videoPlayer = ref(null);

    onMounted(() => {
      player.value = videojs('video-player', props.options || {});
    });

    onUnmounted(() => {
      if (player.value) {
        player.value.dispose();
      }
    });

    return {
      videoPlayer,
    };
  },
});
</script>
