import EventBus from '@/util/event-bus';

export default {
  beforeMount: (el, { modifiers }, _) => {
    if (modifiers.toggle === true) {
      // eslint-disable-next-line no-param-reassign
      el.onclick = () => {
        EventBus.emit('toggle-mobile-menu');
      };
    }
    if (modifiers.content === true) {
      EventBus.on('toggle-mobile-menu', () => {
        el.classList.toggle('hidden');
      });
    }
  },
  beforeUnmount() {
    EventBus.off('toggle-mobile-menu');
  },
};
