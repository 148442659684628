let nextId = new Date().getTime();

export default class File {
  static pending({ file }) {
    return new File({ filename: file.name, pending: true });
  }

  static persisted(data) {
    return new File({
      ...data,
      pending: false,
    });
  }

  constructor({
    id, position, filename, url, pending, previewUrl,
    forCandidate, downloadable, textSelectable,
    type, filePdf, category,
  }) {
    if (id) {
      this.id = id;
    } else {
      this.id = nextId;
      nextId += 1;
    }
    this.filename = filename;
    this.position = position;
    this.url = url;
    this.pending = pending;
    this.previewUrl = previewUrl;
    this.forCandidate = forCandidate || false;
    this.downloadable = downloadable || false;
    this.textSelectable = textSelectable || false;
    this.type = type;
    this.errors = null;
    this.progress = 0;
    this.category = category;
    this.filePdf = filePdf;
  }

  onUpload({
    id, filename, url, preview_url: previewUrl, for_candidate: forCandidate, position,
    type, textSelectable,
  }) {
    this.pending = false;
    this.id = id;
    this.forCandidate = forCandidate;
    this.filename = filename;
    this.url = url;
    this.position = position;
    this.previewUrl = previewUrl;
    this.type = type;
    this.textSelectable = textSelectable;
  }

  onUploadError(data) {
    this.pending = false;
    if (data && data.errors && data.errors.file) {
      this.errors = data.errors.file;
    } else {
      this.errors = ['Application Error'];
    }
  }
}
