<template>
  <Modal
    :open="open"
  >
    <div class="p-6">
      <div class="mb-8">
        <p class="text-lg leading-6 font-medium pb-1">
          {{ $t('components.after_login_view.modal.title') }}
        </p>
        <div class="">
          <p class="text-sm text-gray-500">
            {{ $t('components.after_login_view.modal.description', { url: url}) }}
          </p>
          <div class="py-2" />
          <p class="text-sm text-gray-500">
            {{ $t('components.after_login_view.radio_list.description') }}
          </p>
        </div>
      </div>
      <RadioList
        v-model="afterLoginView"
        :options="afterLoginViewOptions"
        :name="inputName"
        :show-label="true"
        :label="label"
        :error="error"
      />
    </div>
    <footer class="flex sm:justify-end bg-gray-50 py-3 px-6">
      <BaseButton
        class="w-full sm:w-auto"
        type="button"
        :is-loading="disabled"
        @click="saveAfterLoginView"
      >
        {{ $t('defaults.save') }}
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { useI18n } from '@/plugins/i18n';
import { authPath } from '@/util/url-helpers';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import Modal from '@/components/generic/Modal/Modal.vue';
import RadioList from '@/components/generic/RadioList/RadioList.vue';
import Axios from 'axios';
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
  name: 'AfterLoginViewModal',
  components: {
    Modal,
    RadioList,
    BaseButton,
  },
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    currentTenant: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const afterLoginView = ref(props.currentUser.after_login_view);
    const { t } = useI18n();
    const toast = inject('toast');

    const afterLoginViewOptions = [
      {
        value: 'admin_view',
        name: t('components.after_login_view.radio_list.admin_view'),
        description: t(
          'components.after_login_view.radio_list.admin_view_description',
        ),
      },
      {
        value: 'observer_view',
        name: t('components.after_login_view.radio_list.observer_view'),
        description: t(
          'components.after_login_view.radio_list.observer_view_description',
        ),
      },
    ];

    const url = ref(`${props.currentTenant.subdomain}.applysia.app/observer_view`);

    const saveAfterLoginView = () => {
      const data = {
        user: {
          after_login_view: afterLoginView.value,
          after_login_view_modal_shown_at: true,
        },
      };

      Axios.put(
        authPath(),
        data,
      ).then((_) => {
        Turbo.visit(window.location.href);
      }).catch((error) => {
        Sentry.captureMessage(error?.toString());
        toast.error(
          t('components.after_login_view.modal.error'),
        );
      });
    };

    return {
      afterLoginView,
      afterLoginViewOptions,
      url,
      saveAfterLoginView,
    };
  },
});
</script>
