<template>
  <div class="sticky z-20 top-0 w-full">
    <nav
      class="flex justify-between w-full bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <div class="hidden px-4 sm:px-6 lg:px-8 sm:flex space-x-4">
        <div
          class="flex shrink items-center text-gray-400 hover:text-gray-500 cursor-pointer"
          @click="$emit('unselect-file')"
        >
          <p class="line-clamp-2">
            {{ selectedCategory.name }}
          </p>
        </div>
        <div class="flex items-center">
          <svg
            class="shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <div
            class="flex shrink items-center ml-4 text-gray-400"
          >
            <p class="line-clamp-2 break-all">
              {{ selectedFile.filename }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex items-center ml-auto pr-6 py-2 min-w-max space-x-6">
        <timer
          v-if="selectedFile.shareUntil"
          :key="selectedFile.shareUntil"
          :end-date="selectedFile.shareUntil"
          :total-duration="selectedFile.shareDuration"
          class="ml-auto"
          @end="$emit('revoke-file-on-timer-end', {category: selectedCategory, file: selectedFile})"
        />
        <BaseButton
          :is-primary="false"
          :is-disabled="!selectedFile.downloadable"
          icon="DownloadIcon"
          @click="downloadFile"
        >
          {{ $t('defaults.download') }}
        </BaseButton>
      </div>
    </nav>
  </div>
</template>

<script>
import download from '@/util/download';
import BaseButton from '../generic/BaseButton/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  props: {
    selectedCategory: {
      type: Object,
      required: true,
    },
    selectedFile: {
      type: Object,
      required: true,
    },
  },
  emits: ['unselect-file', 'revoke-file-on-timer-end'],
  setup(props) {
    function downloadFile() {
      download({
        path: props.selectedFile.url,
        filename: props.selectedFile.filename,
      });
    }

    return {
      downloadFile,
    };
  },
};
</script>
