<template>
  <div>
    <div
      v-if="label || hint"
      :class="['flex', label ? 'justify-between' : 'justify-end' ]"
    >
      <label
        v-if="label"
        :for="id"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </label>
      <span
        v-if="hint"
        class="text-sm text-gray-500"
      >
        {{ hint }}
      </span>
    </div>

    <div :class="inputWrapperCssClasses">
      <template v-if="hasAddonButton">
        <div class="relative flex items-stretch grow focus-within:z-10">
          <div
            v-if="hasLeadingIcon"
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
          >
            <slot
              name="leading-icon"
              :error="error"
            >
              <HeroiconOutline
                :icon="leadingIcon"
                :class="['h-5 w-5', error ? 'text-red-500' : 'text-gray-400']"
                aria-hidden="true"
              />
            </slot>
          </div>
          <div :class="{ 'mt-1': label || hint }">
            <textarea
              :id="id"
              :name="name"
              :disabled="disabled"
              :form="form"
              :rows="rows"
              :cols="cols"
              :minlength="minlength"
              :maxlength="maxlength"
              :placeholder="placeholder"
              :readonly="readonly"
              :required="required"
              class="block w-full shadow-sm sm:text-sm"
              :class="textareaCssClasses"
              :value="modelValue"
              @input="onInput"
              @keydown.enter.stop
            />
          </div>
          <div
            v-if="hasTrailingIcon"
            :class="[
              'absolute inset-y-0 right-0 pr-3 flex items-center text-gray-300',
              trailingIconClickable ? '' : 'pointer-events-none']"
          >
            <slot
              name="trailing-icon"
              :error="error"
            >
              <HeroiconOutline
                :icon="trailingIcon"
                :class="['h-5 w-5', error ? 'text-red-500' : 'text-gray-400']"
                aria-hidden="true"
              />
            </slot>
          </div>
        </div>
        <div
          class="add-button"
        >
          <slot name="addon-button" />
        </div>
      </template>

      <template v-else>
        <div
          v-if="hasLeadingIcon"
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <slot
            name="leading-icon"
            :error="error"
          >
            <HeroiconOutline
              :icon="leadingIcon"
              :class="['h-5 w-5', error ? 'text-red-500' : 'text-gray-400']"
              aria-hidden="true"
            />
          </slot>
        </div>
        <span
          v-else-if="hasLeadingAddon"
          class="inline-flex items-center justify-center px-3 rounded-l-md border border-r-0 border-gray-300
            bg-gray-50 text-gray-500 sm:text-sm min-w-9"
        >
          <slot name="leading-addon" />
        </span>

        <textarea
          :id="id"
          :name="name"
          :disabled="disabled"
          :form="form"
          :rows="rows"
          :cols="cols"
          :minlength="minlength"
          :maxlength="maxlength"
          :placeholder="placeholder"
          :readonly="readonly"
          :required="required"
          class="block w-full shadow-sm sm:text-sm"
          :class="textareaCssClasses"
          :value="modelValue"
          @input="onInput"
          @keydown.enter.stop
        />

        <div
          v-if="hasTrailingIcon"
          :class="[
            'absolute inset-y-0 right-0 pr-3 flex items-center text-gray-300',
            trailingIconClickable ? '' : 'pointer-events-none']"
        >
          <slot
            name="trailing-icon"
            :error="error"
          >
            <HeroiconOutline
              :icon="trailingIcon"
              :class="['h-5 w-5', error ? 'text-red-500' : 'text-gray-400']"
              aria-hidden="true"
            />
          </slot>
        </div>
        <span
          v-else-if="hasTrailingAddon"
          class="inline-flex items-center justify-center  px-3 rounded-r-md border border-l-0 border-gray-300
            bg-gray-50 text-gray-500 sm:text-sm min-w-9"
        >
          <slot name="trailing-addon" />
        </span>
      </template>
    </div>

    <div
      v-if="error || maxChars"
      class="flex justify-between items-start mt-1 "
    >
      <div class="flex-1">
        <p
          v-if="error"
          class="text-sm text-red-600"
        >
          {{ error }}
        </p>
      </div>
      <div
        v-if="maxChars"
        class="ml-2"
      >
        <p :class="['text-sm font-medium', maxChars < modelValue.length ? 'text-red-600' : 'text-gray-500']">
          {{ charCounterText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue';
import HeroiconOutline from '@/components/HeroiconOutline.vue';

export default {
  components: {
    HeroiconOutline,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    form: {
      type: String,
      default: null,
    },

    rows: {
      type: Number,
      default: null,
    },
    cols: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    minlength: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      default: 'default',
    },

    error: {
      type: String,
      default: '',
    },

    classes: {
      type: String,
      default: '',
    },

    leadingIcon: {
      type: String,
      default: null,
    },
    trailingIcon: {
      type: String,
      default: null,
    },
    qaClass: {
      type: String,
      default: '',
    },
    maxChars: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit, slots }) {
    const {
      maxChars, modelValue, disabled, error, leadingIcon, trailingIcon, label,
    } = toRefs(props);

    const onInput = ({ target }) => {
      emit('update:modelValue', target.value);
    };

    const hasLeadingIcon = computed(() => Boolean(leadingIcon.value || slots['leading-icon']));
    const hasTrailingIcon = computed(() => Boolean(trailingIcon.value || slots['trailing-icon']));
    const hasIcon = computed(() => hasLeadingIcon.value || hasTrailingIcon.value);

    const hasLeadingAddon = computed(() => Boolean(slots['leading-addon']));
    const hasTrailingAddon = computed(() => Boolean(slots['trailing-addon']));
    const hasAddon = computed(() => hasLeadingAddon.value || hasTrailingAddon.value);

    const hasAddonButton = computed(() => Boolean(slots['addon-button']));

    const inputWrapperCssClasses = computed(() => ({
      'mt-1': label.value,
      relative: hasIcon.value,
      flex: hasAddon.value || hasAddonButton.value,
      'rounded-md shadow-sm': hasIcon.value || hasAddon.value || hasAddonButton.value,
    }));

    const textareaCssClasses = computed(() => [
      'block w-full sm:text-sm z-10',
      props.classes,
      props.qaClass !== '' ? `qa-${props.qaClass}` : '',
      {
        'shadow-sm': !(hasIcon.value || hasAddon.value || hasAddonButton.value),
        'flex-1 min-w-0 px-3 py-2': hasAddon.value,
        'rounded-md': !(hasAddon.value || hasAddonButton.value),
        'rounded-none': hasAddon.value || hasAddonButton.value,
        'rounded-l-md': (hasTrailingAddon.value || hasAddonButton.value) && !hasLeadingAddon.value,
        'rounded-r-md': hasLeadingAddon.value && !(hasTrailingAddon.value || hasAddonButton.value),
        'pl-10': hasLeadingIcon.value,
        'pr-10': hasTrailingIcon.value,
        'focus:border-red-500 focus:ring-red-500 text-red-900 border-red-300': error.value,
        'focus:ring-primary-500 focus:border-primary-500 border-gray-300': !error.value,
        'bg-gray-100': disabled.value,
      },
    ]);

    const formattedCharCount = (count) => count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u202F');

    const charCounterText = computed(() => {
      if (!maxChars.value) return '';

      const textLength = formattedCharCount(modelValue.value.length);
      const maxCharsText = formattedCharCount(maxChars.value);
      return `${textLength} / ${maxCharsText}`;
    });

    return {
      onInput,
      hasLeadingIcon,
      hasTrailingIcon,
      hasLeadingAddon,
      hasTrailingAddon,
      hasAddonButton,
      inputWrapperCssClasses,
      textareaCssClasses,
      charCounterText,
    };
  },
};
</script>
