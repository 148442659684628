<template>
  <Badge :color="statusColor">
    {{ $t(`components.users.users_table.${status}`) }}
  </Badge>
</template>

<script>
import { defineComponent } from 'vue';
import Badge from '@/components/generic/Badge/Badge.vue';

const statusColors = {
  active: 'green',
  invited: 'blue',
  deactivated: 'gray',
};

export default defineComponent({
  name: 'UserStatusBadge',
  components: {
    Badge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const statusColor = statusColors[props.status];

    return {
      statusColor,
    };
  },
});
</script>
