export default {
  timeAxis: {
    autoAdjustTimeAxis: false,
    suppressFit: true,
    forceFit: false,
  },
  zoomKeepsOriginalTimespan: true,
  viewPreset: '3',
  presets: [
    {
      base: 'hourAndDay',
      id: '1',
      tickWidth: 25,
      tickHeight: 25,
      columnLinesFor: 1,
      mainHeaderLevel: 1,
      timeResolution: {
        unit: 'minute',
        increment: 5,
      },
    },
    {
      base: 'hourAndDay',
      id: '2',
      tickWidth: 50,
      tickHeight: 50,
      columnLinesFor: 1,
      mainHeaderLevel: 1,
      timeResolution: {
        unit: 'minute',
        increment: 5,
      },
    },
    {
      base: 'hourAndDay',
      id: '3',
      tickWidth: 75,
      tickHeight: 75,
      columnLinesFor: 1,
      mainHeaderLevel: 1,
      timeResolution: {
        unit: 'minute',
        increment: 5,
      },
    },
    {
      base: 'hourAndDay',
      id: '4',
      tickWidth: 100,
      tickHeight: 100,
      columnLinesFor: 1,
      mainHeaderLevel: 1,
      timeResolution: {
        unit: 'minute',
        increment: 5,
      },
    },
    {
      base: 'hourAndDay',
      id: '5',
      tickWidth: 150,
      tickHeight: 150,
      columnLinesFor: 1,
      mainHeaderLevel: 1,
      timeResolution: {
        unit: 'minute',
        increment: 5,
      },
    },
  ],
  timeResolution: {
    unit: 'minute',
    increment: 5,
  },
};
