<template>
  <TwoColumnCard
    :header="$t('components.competence_model.target_profile.header')"
    :helptext="targetProfileEnabled ?
      $t('components.competence_model.target_profile.help_text.enabled')
      : $t('components.competence_model.target_profile.help_text.disabled')"
  >
    <div class="space-y-8">
      <div class="flex flex-row justify-end space-x-4 pb-5 border-b border-black border-opacity-10">
        <div>
          {{ $t('components.competence_model.target_profile.use_target_profile') }}
        </div>
        <Toggle
          :model-value="targetProfileEnabled"
          :disabled="disabled"
          @update:model-value="toggleTargetProfile"
        />
        <input
          type="hidden"
          name="tenant[target_profile][enabled]"
          :value="targetProfileEnabled"
        >
      </div>
      <TransitionExpand>
        <div
          v-if="targetProfileEnabled"
          class="space-y-8"
        >
          <TargetRangeSlider
            v-for="competency in competencies"
            :key="competency.id"
            :disabled="disabled"
            :competency="competency"
            :competence-model="competenceModel"
            :input-localization="$i18n.locale"
          />
        </div>
      </TransitionExpand>
    </div>
  </TwoColumnCard>
</template>

<script>
import TransitionExpand from '@/components/generic/TransitionExpand/TransitionExpand.vue';
import { targetProfilePath } from '@/util/url-helpers';
import Axios from 'axios';
import { computed, defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TargetRangeSlider from './TargetRangeSlider.vue';

export default defineComponent({
  components: {
    TargetRangeSlider,
    TransitionExpand,
  },
  props: {
    competenceModel: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:competenceModel'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = inject('toast');

    const competencies = computed(() => props.competenceModel.competencyClusters.reduce(
      (accumulator, currentValue) => accumulator.concat(currentValue.competencies),
      [],
    ));

    const targetProfileEnabled = ref(props.competenceModel.targetProfileEnabled);

    const toggleTargetProfile = (value) => {
      Axios({
        url: targetProfilePath(props.competenceModel),
        method: value ? 'POST' : 'DELETE',
        data: {},
      })
        .then(({ data }) => {
          targetProfileEnabled.value = value;
          emit('update:competenceModel', data);
        })
        .catch((_) => {
          toast.error(t('components.competence_model.target_profile.error_message'));
        });
    };

    return {
      targetProfileEnabled,
      toggleTargetProfile,
      competencies,
    };
  },
});
</script>
