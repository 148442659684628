<template>
  <div class="mt-12">
    <div v-if="backLink">
      <nav
        aria-label="Back"
        class="group"
      >
        <a
          :href="backLink"
          class="flex items-center text-sm font-medium text-gray-500 group-hover:text-gray-700"
        >
          <ChevronLeftIcon
            class="shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-700"
            aria-hidden="true"
          />
          {{ $t('defaults.back') }}
        </a>
      </nav>
    </div>
    <span
      v-if="!!$slots.secondary"
      class="text-sm text-gray-500"
    >
      <slot name="secondary" />
    </span>
    <div class="md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0 flex items-center">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          <slot />
        </h2>
        <slot name="additions" />
      </div>
      <div
        v-if="!!$slots.actions"
        class="mt-4 md:mt-0 md:ml-4"
      >
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon } from '@heroicons/vue/solid';

export default {
  components: {
    ChevronLeftIcon,
  },
  props: {
    backLink: {
      type: String,
      default: null,
    },
  },
};
</script>
