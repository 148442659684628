<template>
  <div v-if="reportTemplatesFeatureEnabled">
    <div class="sm:hidden">
      <BaseSelect
        :model-value="currentTab.href"
        :options="tabs"
        label-prop="name"
        value-prop="href"
        @update:model-value="selectTab"
      />
    </div>
    <div class="hidden sm:block">
      <nav
        class="isolate flex divide-x divide-gray-200 rounded-lg shadow items-center"
        aria-label="Tabs"
      >
        <a
          v-for="(tab, tabIdx) in tabs"
          :key="tab.name"
          :href="tab.href"
          :class="[
            tab.current
              ? 'text-gray-900' :
                'text-gray-500 hover:text-gray-700',
            , tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
            'cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4',
            'text-center text-sm font-medium hover:bg-gray-50 focus:z-10']"

          :aria-current="tab.current ? 'page' : undefined"
        >
          <span> {{ tab.name }} </span>
          <span
            aria-hidden="true"
            :class="[tab.current ? 'bg-primary-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']"
          />
          <BetaFeatureBadge
            v-if="tab.isBeta"
            class="ml-2"
          />
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import BaseSelect from '@/components/generic/BaseSelect/BaseSelect.vue';
import { posthog } from 'posthog-js';
import BetaFeatureBadge from '@/components/generic/FeatureBadges/BetaFeatureBadge.vue';

export default defineComponent({
  name: 'TabBar',
  components: {
    BaseSelect,
    BetaFeatureBadge,
  },
  props: {
    /**
     * { name: string, href: string, isBeta: boolean, current: boolean }[]
     */
    tabs: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:selected-tab'],
  setup(props) {
    const { tabs } = toRefs(props);
    const currentTab = computed(() => tabs.value.find((tab) => tab.current));
    const selectTab = (href) => {
      window.location.href = href;
    };
    return {
      currentTab,
      selectTab,
      reportTemplatesFeatureEnabled: (posthog.persistence && posthog.isFeatureEnabled('report-templates'))
      || window.moduleEnabled('reporting'),
    };
  },
});
</script>
