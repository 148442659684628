<template>
  <component
    :is="icon"
    v-if="icon"
  />
</template>

<script>
import * as Icons from '@heroicons/vue/outline';

export default {
  name: 'HeroiconIcon',
  components: {
    ...Icons,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return Object.keys(Icons).includes(value);
      },
    },
  },
};
</script>
