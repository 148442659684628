<template>
  <transition
    leave-active-class="transition ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <ListboxOptions
      class="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1
      text-sm ring-1 ring-black/5 overflow-auto focus:outline-none sm:text-sm z-20"
    >
      <ListboxOption
        v-for="item in options"
        :key="item[`${valueProp}`]"
        v-slot="{ active, selected }"
        as="template"
        :value="item"
        :class="`cursor-pointer  ${qaClass !== '' ? `qa-${qaClass}` : ''}`"
      >
        <li
          :class="[active ?
            'text-white bg-primary-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']"
        >
          <div class="flex">
            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
              {{ item[`${labelProp}`] }}
            </span>
            <span
              v-if="secondaryLabelProp"
              :class="[active ? 'text-primary-200' : 'text-gray-500', 'ml-2 truncate']"
            >
              {{ item[`${secondaryLabelProp}`] }}
            </span>
          </div>

          <span
            v-if="selected"
            :class="[active ?
              'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']"
          >
            <CheckIcon
              class="h-5 w-5"
              aria-hidden="true"
            />
          </span>
        </li>
      </ListboxOption>
    </ListboxOptions>
  </transition>
</template>

<script>
import {
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';

import { CheckIcon } from '@heroicons/vue/outline';

export default {
  components: {
    ListboxOptions,
    ListboxOption,
    CheckIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    secondaryLabelProp: {
      type: String,
      default: null,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
};
</script>
