<template>
  <div class="relative">
    <Dropdown
      :with-popper-overlay="true"
    >
      <template #button>
        <MenuButton
          ref="trigger"
          class="flex justify-center text-gray-400 focus:outline-none focus:ring-2  ring-primary-500
             hover:bg-gray-100 rounded-full p-1"
        >
          <DotsVerticalIcon
            class="h-5 w-5"
            aria-hidden="true"
          />
          <span class="sr-only">{{ $t('defaults.manage') }}</span>
        </MenuButton>
      </template>
      <div
        ref="container"
        class="origin-top-right absolute right-0 w-56 min-w-fit rounded-md shadow-lg
          bg-white ring-1 ring-black/5 focus:outline-none z-20 text-sm font-normal py-1"
      >
        <ActionDropdownItem
          class="flex flex-row"
          @click="$emit('edit')"
        >
          <div class="mr-4">
            <PencilIcon
              class="h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </div>
          <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
        </ActionDropdownItem>
        <EditInOfficeActionDropdownItem
          :file="reportTemplate"
          file-type="ReportTemplate"
        />

        <DownloadReportDocumentDropdownItem
          :document="reportTemplate.templateDocument"
        >
          {{ $t('defaults.download') }}
        </DownloadReportDocumentDropdownItem>
        <ReplaceReportTemplateDocumentDropdownItem
          :report-template="reportTemplate"
          :is-disabled="false"
        />
        <ActionDropdownItem
          v-if="reportTemplateFeatureEnabled"
          class="flex flex-row"
          @click="generateReports"
        >
          <div class="mr-4">
            <DocumentTextIcon
              class="h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </div>
          <span class="text-gray-700">{{ $t('components.report_templates.preview_tile.generate_reports') }}</span>
        </ActionDropdownItem>
        <LinkDropdownItem
          :href="deletePath"
          method="delete"
          :remote="true"
          :confirm="confirmDeletionMessage"
          :challenge="reportTemplate.name"
          class="flex flex-row border-t border-gray-100"
        >
          <div class="mr-4">
            <TrashIcon
              class="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
          <span class="text-red-700">{{ $t('defaults.delete') }}</span>
        </LinkDropdownItem>
      </div>
    </Dropdown>
  </div>
</template>

<script>
import DownloadReportDocumentDropdownItem from '@/components/Templates/ReportTemplates/DownloadReportDocumentDropdownItem.vue';
import ReplaceReportTemplateDocumentDropdownItem from '@/components/Templates/ReportTemplates/ReplaceReportTemplateDocumentDropdownItem.vue';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import Dropdown from '@/components/generic/Dropdown/Dropdown.vue';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import { assessmentReportsPath, assessmentReportTemplatePath, organisationAssessmentReportsPath, organisationAssessmentReportTemplatePath } from '@/util/url-helpers';
import usePopper from '@/util/use-popper';
import { MenuButton } from '@headlessui/vue';
import { DotsVerticalIcon } from '@heroicons/vue/outline';
import { DocumentTextIcon, PencilIcon, TrashIcon } from '@heroicons/vue/solid';
import { posthog } from 'posthog-js';
import { computed, defineComponent, inject, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Axios from 'axios';
import EditInOfficeActionDropdownItem from '@/components/generic/EditInOffice/EditInOfficeActionDropdownItem.vue';

export default defineComponent({
  name: 'PreviewTileActions',
  components: {
    Dropdown,
    MenuButton,
    PencilIcon,
    TrashIcon,
    DotsVerticalIcon,
    ActionDropdownItem,
    LinkDropdownItem,
    DocumentTextIcon,
    DownloadReportDocumentDropdownItem,
    ReplaceReportTemplateDocumentDropdownItem,
    EditInOfficeActionDropdownItem,

  },
  props: {
    reportTemplate: {
      type: Object,
      required: true,
    },
    assessmentId: {
      type: Number,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ['edit'],
  setup(props) {
    const toast = inject('toast');
    const { t } = useI18n();
    const { reportTemplate } = toRefs(props);

    const [trigger, container] = usePopper({
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [-6, 6] } }],
    });

    const deletePath = computed(() => (props.organisationId
      ? organisationAssessmentReportTemplatePath(props.organisationId, props.assessmentId, reportTemplate.value)
      : assessmentReportTemplatePath(props.assessmentId, reportTemplate.value)));

    const generateReports = () => {
      const data = {
        report_template_id: reportTemplate.value.id,
      };
      const url = props.organisationId
        ? organisationAssessmentReportsPath(props.organisationId, props.assessmentId)
        : assessmentReportsPath(props.assessmentId);

      Axios.post(url, data)
        .then(() => {
          toast.notice(t('components.report_templates.preview_tile.generate_reports_info'));
        })
        .catch(() => {
          toast.error(t('components.report_templates.preview_tile.generate_reports_error'));
        });
    };

    return {
      trigger,
      container,
      deletePath,
      confirmDeletionMessage: t(
        'components.report_templates.report_template_actions.do_you_want_to_delete',
        { name: props.reportTemplate.name },
      ),

      generateReports,
      reportTemplateFeatureEnabled: (posthog.persistence && posthog.isFeatureEnabled('report-templates')),
    };
  },
});
</script>
