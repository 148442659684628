<template>
  <DialogModal
    :on-top="true"
    modal-type="info"
    :open="showInfoModal"
  >
    <template #header>
      {{ $t('components.edit_in_office.webdav_editing_info_modal.title') }}
    </template>
    <template #main>
      <div
        v-dompurify-html:pKbdOlLi="$t('components.edit_in_office.webdav_editing_info_modal.text',
                                      {modifierKey :modifierKey})"
        class="prose space-y-4"
      />
    </template>
    <template #footer>
      <BaseButton
        :is-primary="false"
        class="mt-3 w-full justify-center rounded-md border border-gray-300
           shadow-sm p-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        @click="cancel"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        class="ml-2"
        :is-loading="isLoading"
        @click="openFile"
      >
        {{ $t('components.edit_in_office.webdav_editing_info_modal.button_text') }}
      </BaseButton>
    </template>
  </DialogModal>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { useWebdavSessionStore } from '@/components/Templates/ReportTemplates/stores/webdavSessionStore';
import { useWebdavEditingInfoModalStore } from '@/components/generic/EditInOffice/stores/webdavEditingInfoModalStore';
import DialogModal from '../DialogModal/DialogModal.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

const wedavSessionStore = useWebdavSessionStore();

const infoModalStore = useWebdavEditingInfoModalStore();
const { showInfoModal, isLoading } = storeToRefs(infoModalStore);

const openFile = () => {
  infoModalStore.closeInfoModal();
  wedavSessionStore.continueCreateWebdavSession();
};

const cancel = () => {
  infoModalStore.cancelInfoModal();
};

const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
const isMac = platform.toUpperCase().indexOf('MAC') >= 0;

const modifierKey = isMac ? '⌘' : 'Ctrl';

</script>
