import translations from '@/config/locales/translations.json';
import getMetaPropertyContent from './getMetaPropertyContent';

window.i18n = {};
window.i18n.defaultLocale = getMetaPropertyContent('applysia:defaultLocale');
window.i18n.locale = getMetaPropertyContent('applysia:locale');
const basicModeEnabled = getMetaPropertyContent('applysia:basicModeEnabled') === 'true';
window.i18n.translations = {
  en: translations.en,
  de: basicModeEnabled ? translations['de-Interview'] : translations.de,
};
