/**
 *- Unplanned grid
 *
 * Taken from the vanilla dragfromgrid example
 */
import { Grid } from '@bryntum/scheduler';

export default class UnplannedGrid extends Grid {
  static get defaultConfig() {
    return {
      features: {
        stripe: true,
        sort: 'candidateLastName',
        filter: true,
        cellEdit: false,
      },
      rowHeight: 50,
    };
  }

  construct(config) {
    super.construct(config);

    this.eventStore.on({
      remove: ({
        _source, records: events, _allRecords, _parent, _index, _isChild, _isCollapse, _isMove,
      }) => {
        events.forEach((event) => {
          event.getCandidates().forEach((candidate) => {
            const unplannedEventAlreadyExists = this.store.query(
              (unplannedEvent) => (
                unplannedEvent.exerciseId === event.exerciseId
                && unplannedEvent.candidateId === candidate.id
              ),
            ).length > 0;
            if (unplannedEventAlreadyExists) return;

            this.store.add({
              name: event.name,
              candidateName: candidate.name,
              candidateFirstName: candidate.originalData.firstName,
              candidateLastName: candidate.originalData.lastName,
              candidateId: candidate.id,
              eventColor: candidate.eventColor,
              duration: event.duration,
              exerciseId: event.exerciseId,
            });
          });
        });
      },
      thisObj: this,
    });

    this.assignmentStore.on({
      add: ({ records: assignments }) => {
        assignments.forEach((assignment) => {
          if (assignment.resource.type !== 'candidate') return;

          const newlyPlannedEvents = this.store.query((unplannedEvent) => (
            unplannedEvent.candidateId === assignment.resourceId
            && unplannedEvent.exerciseId === assignment.event?.exerciseId
          ));

          if (newlyPlannedEvents.length > 0) {
            this.store.remove(newlyPlannedEvents);
          }
        });
      },
      remove: ({ records: assignments }) => {
        assignments.forEach((assignment) => {
          if (assignment.resource.type !== 'candidate') return;
          const unplannedEventAlreadyExists = this.store.query(
            (unplannedEvent) => (
              unplannedEvent.exerciseId === assignment.event.exerciseId
              && unplannedEvent.candidateId === assignment.resourceId
            ),
          ).length > 0;
          if (unplannedEventAlreadyExists) return;

          this.store.add({
            name: assignment.event.name,
            candidateName: assignment.resource.name,
            candidateFirstName: assignment.resource.data.firstName,
            candidateLastName: assignment.resource.data.lastName,
            candidateId: assignment.resource.id,
            eventColor: assignment.resource.eventColor,
            duration: assignment.event.duration,
            exerciseId: assignment.event.exerciseId,
          });
        });
      },
      thisObj: this,
    });
  }
}
