<template>
  <div class="inline-flex text-sm items-center space-x-2">
    <div
      v-for="(file, index) in filesToShow"
      :key="index"
      class="w-24 xl:w-32 flex justify-center items-start gap-x-1 border border-gray-300 rounded-md p-1
        transition-colors duration-200 hover:bg-gray-50"
    >
      <div class="w-5 h-5">
        <FileTypeHeroIcon
          :file-type="file.type"
          class="h-5 w-5 text-gray-300"
        />
      </div>

      <div class="w-full truncate text-gray-500">
        {{ file.filename }}
      </div>
    </div>

    <div
      v-if="remainingFilesCount"
      class="inline-flex items-center border border-gray-300 rounded-md px-2 py-1"
    >
      <span class="text-gray-500">+{{ remainingFilesCount }}</span>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue';
import FileTypeHeroIcon from '@/components/FileTypeHeroIcon.vue';

export default {
  components: {
    FileTypeHeroIcon,
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
    maxFiles: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    const { files, maxFiles } = toRefs(props);

    const filesToShow = computed(() => files.value.slice(0, maxFiles.value));

    const remainingFilesCount = computed(() => {
      const remaining = files.value.length - maxFiles.value;
      return remaining > 0 ? remaining : 0;
    });

    return {
      filesToShow,
      remainingFilesCount,
    };
  },
};
</script>
