<template>
  <div :class="['rounded-md', backgroundColorClass, 'p-4']">
    <div class="flex">
      <div class="shrink-0">
        <component
          :is="iconComponent"
          :class="['h-5', 'w-5', iconColorClass]"
          aria-hidden="true"
        />
      </div>

      <div :class="['ml-3', { [textColorClass]: !$slots.title, 'text-sm': !$slots.title }]">
        <template v-if="$slots.title">
          <h3 :class="['text-sm', 'font-medium', titleColorClass]">
            <slot name="title" />
          </h3>
          <div :class="['mt-2', 'text-sm', textColorClass]">
            <slot name="content" />
          </div>
        </template>

        <slot
          v-else
          :class="['mt-2', 'text-sm', textColorClass]"
          name="content"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon, XCircleIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/vue/solid';

const Types = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFORMATION: 'information',
});

export default {
  components: {
    ExclamationIcon,
    XCircleIcon,
    CheckCircleIcon,
    InformationCircleIcon,
  },
  props: {
    type: {
      type: String,
      default: Types.INFORMATION,
      validator(value) {
        return Object.values(Types).includes(value);
      },
    },
  },
  computed: {
    iconComponent() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'check-circle-icon';
        case Types.ERROR:
          return 'x-circle-icon';
        case Types.WARNING:
          return 'exclamation-icon';
        case Types.INFORMATION:
        default:
          return 'information-circle-icon';
      }
    },
    iconColorClass() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'text-green-400';
        case Types.ERROR:
          return 'text-red-400';
        case Types.WARNING:
          return 'text-yellow-400';
        case Types.INFORMATION:
        default:
          return 'text-blue-400';
      }
    },
    backgroundColorClass() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'bg-green-50';
        case Types.ERROR:
          return 'bg-red-50';
        case Types.WARNING:
          return 'bg-yellow-50';
        case Types.INFORMATION:
        default:
          return 'bg-blue-50';
      }
    },
    titleColorClass() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'text-green-800';
        case Types.ERROR:
          return 'text-red-800';
        case Types.WARNING:
          return 'text-yellow-800';
        case Types.INFORMATION:
        default:
          return 'text-blue-800';
      }
    },
    textColorClass() {
      switch (this.type) {
        case Types.SUCCESS:
          return 'text-green-700';
        case Types.ERROR:
          return 'text-red-700';
        case Types.WARNING:
          return 'text-yellow-700';
        case Types.INFORMATION:
        default:
          return 'text-blue-700';
      }
    },
  },
};
</script>
