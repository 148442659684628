<template>
  <BaseTableActions>
    <template #menu-items>
      <LinkDropdownItem
        :href="editPath"
        class="flex flex-row qa-base-table-edit-action"
      >
        <div class="mr-4">
          <PencilIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
      </LinkDropdownItem>

      <LinkDropdownItem
        :href="duplicatePath"
        method="post"
        class="flex flex-row qa-base-table-duplicate-action"
      >
        <div class="mr-4">
          <DuplicateIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">
          {{ $t('components.competence_model_templates.table.template_actions.duplicate') }}</span>
      </LinkDropdownItem>

      <LinkDropdownItem
        :href="templatePath"
        method="delete"
        :remote="true"
        :confirm="confirmDeletionMessage"
        :challenge="competenceModel.name"
        class="flex flex-row border-t border-gray-300 qa-base-table-delete-action"
      >
        <div class="mr-4">
          <TrashIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-red-700">{{ $t('defaults.delete') }}</span>
      </LinkDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { PencilIcon, DuplicateIcon, TrashIcon } from '@heroicons/vue/solid';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import {
  competenceModelTemplatePath,
  editCompetenceModelTemplatePath,
  duplicateCompetenceModelTemplatePath,
  organisationCompetenceModelTemplatePath,
  editOrganisationCompetenceModelTemplatePath,
  duplicateOrganisationCompetenceModelTemplatePath,
} from '@/util/url-helpers';
import { useI18n } from '@/plugins/i18n';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';

export default defineComponent({
  components: {
    PencilIcon,
    DuplicateIcon,
    TrashIcon,
    LinkDropdownItem,
    BaseTableActions,
  },
  props: {
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    competenceModel: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { competenceModel } = toRefs(props);
    const { t } = useI18n();

    const templatePath = props.organisationId
      ? organisationCompetenceModelTemplatePath(props.organisationId, props.competenceModel)
      : competenceModelTemplatePath(props.competenceModel);
    const editPath = props.organisationId
      ? editOrganisationCompetenceModelTemplatePath(props.organisationId, props.competenceModel)
      : editCompetenceModelTemplatePath(props.competenceModel);
    const duplicatePath = props.organisationId
      ? duplicateOrganisationCompetenceModelTemplatePath(props.organisationId, props.competenceModel)
      : duplicateCompetenceModelTemplatePath(props.competenceModel);

    const confirmDeletionMessage = computed(() => t(
      'components.competence_model_templates.table.template_actions.do_you_want_to_delete',
      { name: competenceModel.value.name },
    ));

    return {
      confirmDeletionMessage,
      templatePath,
      editPath,
      duplicatePath,
    };
  },
});
</script>
