// tenants
export function adminTenantsPath() {
  return '/admin/tenants';
}

export function editTenantPath() {
  return '/tenant/edit';
}

export function adminTenantPath(tenant) {
  const tenantId = typeof tenant === 'object' ? tenant.id : tenant;
  return `${adminTenantsPath()}/${tenantId}`;
}

export function editAdminTenantPath(tenant) {
  const tenantId = typeof tenant === 'object' ? tenant.id : tenant;
  return `${adminTenantsPath()}/${tenantId}/edit`;
}

export function restoreAdminTenantPath(tenant) {
  return `${adminTenantPath(tenant)}/restore`;
}

export function adminUserActivitiesPath(tenant) {
  const subdomain = typeof tenant === 'object' ? tenant.subdomain : tenant;
  return `/admin/user_activities.csv?tenant_subdomain=${subdomain}`;
}

// users
export function usersPath() {
  return '/users';
}

export function userPath(user) {
  const userId = typeof user === 'object' ? user.id : user;
  return `/users/${userId}`;
}

export function editUserPath(user) {
  return `${userPath(user)}/edit`;
}

export function userDeactivationPath(user) {
  return `${userPath(user)}/deactivation`;
}

export function authPath() {
  return '/auth/';
}

export function createUserSessionPath() {
  return '/auth/login';
}

export function resetPasswordPath() {
  return '/auth/password';
}

export function editUserRegistrationPath() {
  return '/auth/edit';
}

export function destroyUserSessionPath() {
  return '/auth/logout';
}

export function newPasswordPath() {
  return '/auth/password/new';
}

export function resendUserInvitationPath(user) {
  return `${userPath(user)}/resend_invitation`;
}

export function userInvitationPath() {
  return '/auth/invitation';
}

export function remoteSessionPath() {
  return '/remote';
}

export function createRemoteSessionPath() {
  return '/remote/login';
}

// Organisations
export function organisationsPath() {
  return '/organisations';
}

export function organisationPath(organisation) {
  const organisationId = typeof organisation === 'object' ? organisation.id : organisation;

  return `${organisationsPath()}/${organisationId}`;
}

export function editOrganisationPath(organisation) {
  return `${organisationPath(organisation)}/edit`;
}

export function createOrganisationPath() {
  return `${organisationsPath()}/new`;
}

// templates
// competenceModelTemplates
export function competenceModelTemplatesPath() {
  return '/competence_model_templates';
}

export function competenceModelTemplatePath(competenceModel) {
  const competenceModelId = typeof competenceModel === 'object' ? competenceModel.id : competenceModel;

  return `${competenceModelTemplatesPath()}/${competenceModelId}`;
}

export function editCompetenceModelTemplatePath(competenceModel) {
  return `${competenceModelTemplatePath(competenceModel)}/edit`;
}

export function duplicateCompetenceModelTemplatePath(competenceModel) {
  return `${competenceModelTemplatePath(competenceModel)}/duplicate`;
}

export function competenceModelSettingsPath(competenceModel) {
  const competenceModelId = typeof competenceModel === 'object' ? competenceModel.id : competenceModel;

  return `${competenceModelTemplatePath(competenceModelId)}/settings`;
}

export function competenceModelCandidateSelfRatingConfigPath(competenceModel) {
  const competenceModelId = typeof competenceModel === 'object' ? competenceModel.id : competenceModel;

  return `${competenceModelTemplatePath(competenceModelId)}/candidate_self_rating_config`;
}

export function exercisesPath(competenceModel) {
  return `${competenceModelTemplatePath(competenceModel)}/exercises`;
}

export function exercisePath(competenceModel, exercise) {
  const exerciseId = typeof exercise === 'object' ? exercise.id : exercise;

  return `${exercisesPath(competenceModel)}/${exerciseId}`;
}

export function exerciseFilesPath(competenceModel, exercise) {
  return `${exercisePath(competenceModel, exercise)}/files`;
}

export function competenciesPath(competenceModel) {
  return `${competenceModelTemplatePath(competenceModel)}/competencies`;
}

export function competencyPath(competenceModel, competency) {
  const competencyId = typeof competency === 'object' ? competency.id : competency;

  return `${competenciesPath(competenceModel)}/${competencyId}`;
}

// # CompetenceModelTemplate::RatingAnchors {context: organisation}
export function competenceModelTemplateRatingAnchorsPath(organisationId, competenceModel) {
  const basePath = organisationId ? organisationPath(organisationId) : '';

  return `${basePath}${competenceModelTemplatePath(competenceModel)}/rating_anchors`;
}

// # CompetenceModelTemplate::RatingAnchor {context: organisation}
export function competenceModelTemplateNewRatingAnchorPath(organisationId, competenceModel, exercise, competency) {
  const competencyId = typeof competency === 'object' ? competency.id : competency;
  const exerciseId = typeof exercise === 'object' ? exercise.id : exercise;

  // eslint-disable-next-line max-len
  return `${competenceModelTemplateRatingAnchorsPath(organisationId, competenceModel)}/new?competency_id=${competencyId}&exercise_id=${exerciseId}`;
}

export function dupRatingAnchorPath(competenceModel, exercise, competency, sourceAnchor) {
  const competencyId = typeof competency === 'object' ? competency.id : competency;
  const exerciseId = typeof exercise === 'object' ? exercise.id : exercise;

  // eslint-disable-next-line max-len
  return `${competenceModelTemplatePath(competenceModel)}/rating_anchors/${sourceAnchor}/duplicate?competency_id=${competencyId}&exercise_id=${exerciseId}`;
}

// # CompetenceModelTemplate::RatingAnchor {context: organisation}
export function competenceModelTemplateRatingAnchorPath(organisationId, competenceModel, ratingAnchor) {
  const ratingAnchorId = typeof ratingAnchor === 'object' ? ratingAnchor.id : ratingAnchor;

  return `${competenceModelTemplateRatingAnchorsPath(organisationId, competenceModel)}/${ratingAnchorId}/edit`;
}

export function targetProfilePath(competenceModel) {
  const competenceModelId = typeof competenceModel === 'object' ? competenceModel.id : competenceModel;

  return `${competenceModelTemplatePath(competenceModelId)}/target_profile`;
}

// competencyClusters

export function competencyClustersPath(competenceModel) {
  return `${competenceModelTemplatePath(competenceModel)}/competency_clusters`;
}

export function competencyClusterPath(competenceModel, competencyCluster) {
  const competencyClusterId = typeof competencyCluster === 'object' ? competencyCluster.id : competencyCluster;

  return `${competencyClustersPath(competenceModel)}/${competencyClusterId}`;
}

export function dissolveCompetencyClusterPath(competenceModel) {
  return `${competenceModelTemplatePath(competenceModel)}/competency_clusters/dissolve`;
}

// reportTemplates
export function reportTemplatesPath() {
  return '/report_templates';
}

export function reportTemplatePath(reportTemplate) {
  const reportTemplateId = typeof reportTemplate === 'object' ? reportTemplate.id : reportTemplate;

  return `${reportTemplatesPath()}/${reportTemplateId}`;
}

export function editReportTemplatePath(reportTemplate) {
  return `${reportTemplatePath(reportTemplate)}/edit`;
}

export function duplicateReportTemplatePath(reportTemplate) {
  return `${reportTemplatePath(reportTemplate)}/duplicate`;
}

// report_templates: file_name_preview
export function reportTemplatePreviewFileNamePath() {
  return '/report_templates/preview/file_name';
}

// assessments
export function assessmentsPath() {
  return '/assessments';
}

export function assessmentPath(assessment) {
  const assessmentId = typeof assessment === 'object' ? assessment.id : assessment;

  return `${assessmentsPath()}/${assessmentId}`;
}

export function editAssessmentPath(assessment) {
  return `${assessmentPath(assessment)}/edit`;
}

export function completeAssessmentPath(assessment) {
  return `${assessmentPath(assessment)}/status/complete`;
}

export function duplicateAssessmentNewPath(assessment) {
  return `${assessmentPath(assessment)}/duplicate/new`;
}

export function duplicateAssessmentPath(assessment) {
  return `${assessmentPath(assessment)}/duplicate`;
}

export function insightsArchiveAssessmentPath(assessment) {
  return `${assessmentPath(assessment)}/insights_archive`;
}

export function createAssessmentPath() {
  return `${assessmentsPath()}/new`;
}

export function assessmentCandidatesPath(assessment) {
  return `${assessmentPath(assessment)}/candidates`;
}

export function newAssessmentCandidatesPath(assessment) {
  return `${assessmentCandidatesPath(assessment)}/new`;
}

export function assessmentCandidatePath(assessment, candidate) {
  const candidateId = typeof candidate === 'object' ? candidate.id : candidate;

  return `${assessmentCandidatesPath(assessment)}/${candidateId}`;
}

export function editAssessmentCandidatesPath(assessment, candidate) {
  return `${assessmentCandidatePath(assessment, candidate)}/edit`;
}

export function assessmentCandidateImportPath(assessment) {
  return `${assessmentPath(assessment)}/candidates/import`;
}

export function previewAssessmentCandidateImportPath(assessment) {
  return `${assessmentCandidateImportPath(assessment)}/preview`;
}

export function assessmentObserverAssignmentsPath(assessment) {
  return `${assessmentPath(assessment)}/observer_assignments`;
}

export function assessmentObserverAssignmentPath(assessment, observerAssignment) {
  const observerAssignmentId = typeof observerAssignment === 'object' ? observerAssignment.id : observerAssignment;

  return `${assessmentObserverAssignmentsPath(assessment)}/${observerAssignmentId}`;
}

export function editAssessmentObserverAssignmentPath(assessment, observerAssignment) {
  return `${assessmentObserverAssignmentPath(assessment, observerAssignment)}/edit`;
}

export function newAssessmentObserverAssignmentPath(assessment) {
  return `${assessmentObserverAssignmentsPath(assessment)}/new`;
}

export function assessmentCompetenceModelPath(assessment) {
  return `${assessmentPath(assessment)}/competence_model`;
}

export function removeAssessmentCompetenceModelPath(assessment) {
  return `${assessmentCompetenceModelPath(assessment)}/remove_competence_model`;
}

export function resetTemplateAssessmentCompetenceModelPath(assessment) {
  return `${assessmentCompetenceModelPath(assessment)}/reset_template`;
}

export function saveAsTemplateAssessmentCompetenceModelPath(assessment) {
  return `${assessmentCompetenceModelPath(assessment)}/save_as_template`;
}

export function adoptTemplateAssessmentCompetenceModelPath(assessment) {
  return `${assessmentCompetenceModelPath(assessment)}/adopt_template`;
}

// Assessment::RatingAnchors {context: organisation}
export function assessmentCompetenceModelRatingAnchorsPath(assessmentId) {
  return `${assessmentCompetenceModelPath(assessmentId)}/rating_anchors`;
}

// # AssessmentCompetenceModel::NewRatingAnchor {context: organisation}
export function assessmentCompetenceModelNewRatingAnchorPath(
  organisationId,
  assessmentId,
  exercise,
  competency,
) {
  const basePath = organisationId ? organisationPath(organisationId) : '';

  const competencyId = typeof competency === 'object' ? competency.id : competency;
  const exerciseId = typeof exercise === 'object' ? exercise.id : exercise;

  // eslint-disable-next-line max-len
  return `${basePath}${assessmentCompetenceModelRatingAnchorsPath(assessmentId)}/new?competency_id=${competencyId}&exercise_id=${exerciseId}`;
}

// # AssessmentCompetenceModel::RatingAnchor {context: organisation}
export function assessmentCompetenceModelRatingAnchorPath(organisationId, assessmentId, ratingAnchor) {
  const basePath = organisationId ? organisationPath(organisationId) : '';

  const ratingAnchorId = typeof ratingAnchor === 'object' ? ratingAnchor.id : ratingAnchor;

  return `${basePath}${assessmentCompetenceModelRatingAnchorsPath(assessmentId)}/${ratingAnchorId}/edit`;
}

// Assessment::ReportTemplates

export function assessmentReportTemplatesPath(assessment) {
  return `${assessmentPath(assessment)}/report_templates`;
}

export function assessmentReportTemplatePath(assessment, reportTemplate) {
  const reportTemplateId = typeof reportTemplate === 'object' ? reportTemplate.id : reportTemplate;
  return `${assessmentReportTemplatesPath(assessment)}/${reportTemplateId}`;
}

export function assignToAssessmentReportTemplatesPath(assessment) {
  return `${assessmentPath(assessment)}/report_templates/assign`;
}

export function assessmentReportsPath(assessment) {
  return `${assessmentPath(assessment)}/reports/`;
}

export function assessmentSchedulePath(assessment) {
  return `${assessmentPath(assessment)}/schedule`;
}

export function assessmentScheduleEditPath(assessment) {
  return `${assessmentPath(assessment)}/schedule/edit`;
}

export function assessmentGeneralDocumentsPath(assessment) {
  return `${assessmentPath(assessment)}/documents`;
}

export function assessmentFeaturesPath(assessment) {
  return `${assessmentPath(assessment)}/features`;
}

export function assessmentStatusPath(assessment) {
  return `${assessmentPath(assessment)}/status/edit`;
}

// insights

export function insightsPath() {
  return '/insights';
}

export function insightPath(competenceModel) {
  const competenceModelId = typeof competenceModel === 'object' ? competenceModel.id : competenceModel;

  return `${insightsPath()}/${competenceModelId}`;
}

export function insightFilterPath() {
  return `${insightsPath()}/filter`;
}

export function insightCompetencyPath(competenceModel, competencyCluster, competency) {
  const competencyClusterName = typeof competencyCluster === 'object' ? competencyCluster.name : competencyCluster;
  const competencyName = typeof competency === 'object' ? competency.name : competency;

  return `${insightsPath()}/competency_clusters/${competencyClusterName}/competencies/${competencyName}`;
}

// direct upload path
export function directUploadPath() {
  return '/rails/active_storage/direct_uploads';
}

// catalog tests
export function testsPath() {
  return '/tests';
}

export function catalogTestPath(testId, testFormId, testProviderName) {
  return `${testsPath()}/${testId}?test_form_id=${testFormId}&test_provider_name=${testProviderName}`;
}

// Tests
export function assessmentCandidateTestsPath(assessment, candidate) {
  return `${assessmentCandidatePath(assessment, candidate)}/tests`;
}

export function assessmentCandidateTestPath(assessment, candidate, test) {
  const testId = typeof test === 'object' ? test.id : test;

  return `${assessmentCandidatePath(assessment, candidate)}/tests/${testId}`;
}

export function continueTestPath(assessment, candidate, test) {
  return `${assessmentCandidateTestPath(assessment, candidate, test)}/continue`;
}

export function assessmentTestBulkCreate(assessment) {
  return `${assessmentPath(assessment)}/test_bulk_create`;
}

export function webdavSessionsPath() {
  return '/webdav_sessions';
}

// OrganisationAssessment
export function organisationAssessmentsPath(organisation) {
  const organisationId = typeof organisation === 'object' ? organisation.id : organisation;

  return `${organisationPath(organisationId)}/assessments`;
}

export function organisationAssessmentPath(organisation, assessment) {
  const assessmentId = typeof assessment === 'object' ? assessment.id : assessment;

  return `${organisationAssessmentsPath(organisation, assessment)}/${assessmentId}`;
}

export function createOrganisationAssessmentPath(organisation) {
  return `${organisationAssessmentsPath(organisation)}/new`;
}

export function editOrganisationAssessmentPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/edit`;
}

export function completeOrganisationAssessmentPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/status/complete`;
}

export function insightsArchiveOrganisationAssessmentPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/insights_archive`;
}

export function duplicateOrganisationAssessmentNewPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/duplicate/new`;
}

export function duplicateOrganisationAssessmentPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/duplicate`;
}

// OrganisationAssessment association: Candidate
export function organisationAssessmentCandidatesPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/candidates`;
}

export function organisationAssessmentCandidatePath(organisation, assessment, candidate) {
  const candidateId = typeof candidate === 'object' ? candidate.id : candidate;
  return `${organisationAssessmentCandidatesPath(organisation, assessment)}/${candidateId}`;
}

export function editOrganisationAssessmentCandidatesPath(organisation, assessment, candidate) {
  return `${organisationAssessmentCandidatePath(organisation, assessment, candidate)}/edit`;
}

export function organisationAssessmentCandidateImportPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/candidates/import`;
}

// OrganisationAssessment association: ObserverAssignment

export function organisationAssessmentObserverAssignmentsPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/observer_assignments`;
}

export function organisationAssessmentObserverAssignmentPath(organisation, assessment, observerAssignment) {
  const observerAssignmentId = typeof observerAssignment === 'object' ? observerAssignment.id : observerAssignment;
  return `${organisationAssessmentObserverAssignmentsPath(organisation, assessment)}/${observerAssignmentId}`;
}

export function editOrganisationAssessmentObserverAssignmentPath(organisation, assessment, observerAssignment) {
  return `${organisationAssessmentObserverAssignmentPath(organisation, assessment, observerAssignment)}/edit`;
}

export function newOrganisationAssessmentObserverAssignmentPath(organisation, assessment) {
  return `${organisationAssessmentObserverAssignmentsPath(organisation, assessment)}/new`;
}

// OrganisationAssessment association: CompetenceModel
export function organisationAssessmentCompetenceModelPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/competence_model`;
}

// OrganisationAssessment association: ReportTemplate
export function organisationAssessmentReportTemplatesPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/report_templates`;
}

export function organisationAssessmentReportTemplatePath(organisation, assessment, reportTemplate) {
  const reportTemplateId = typeof reportTemplate === 'object' ? reportTemplate.id : reportTemplate;
  return `${organisationAssessmentReportTemplatesPath(organisation, assessment)}/${reportTemplateId}`;
}

// OrganisationAssessment association: Report
export function organisationAssessmentReportsPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/reports/`;
}

// OrganisationAssessment association: Schedule
export function organisationAssessmentSchedulePath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/schedule`;
}

export function organisationAssessmentScheduleEditPath(organisation, assessment) {
  return `${organisationAssessmentSchedulePath(organisation, assessment)}/edit`;
}

// OrganisationAssessment association: GeneralDocuments
export function organisationAssessmentGeneralDocumentsPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/documents`;
}

// OrganisationAssessment association: Features
export function organisationAssessmentFeaturesPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/features`;
}

// OrganisationAssessment association: Status
export function organisationAssessmentStatusPath(organisation, assessment) {
  return `${organisationAssessmentPath(organisation, assessment)}/status/edit`;
}

// Organisation

// Organisation association: CompetenceModelTemplate
export function organisationCompetenceModelTemplatesPath(organisation) {
  return `${organisationPath(organisation)}/competence_model_templates`;
}

export function organisationCompetenceModelTemplatePath(organisation, competenceModel) {
  const competenceModelId = typeof competenceModel === 'object' ? competenceModel.id : competenceModel;
  return `${organisationCompetenceModelTemplatesPath(organisation)}/${competenceModelId}`;
}

export function editOrganisationCompetenceModelTemplatePath(organisation, competenceModel) {
  return `${organisationCompetenceModelTemplatePath(organisation, competenceModel)}/edit`;
}

export function duplicateOrganisationCompetenceModelTemplatePath(organisation, competenceModel) {
  return `${organisationCompetenceModelTemplatePath(organisation, competenceModel)}/duplicate`;
}

// Organisation association: ReportTemplate
export function organisationReportTemplatesPath(organisation) {
  return `${organisationPath(organisation)}/report_templates`;
}

export function organisationReportTemplatePath(organisation, reportTemplate) {
  const reportTemplateId = typeof reportTemplate === 'object' ? reportTemplate.id : reportTemplate;
  return `${organisationReportTemplatesPath(organisation)}/${reportTemplateId}`;
} //

export function editOrganisationReportTemplatePath(organisation, reportTemplate) {
  return `${organisationReportTemplatePath(organisation, reportTemplate)}/edit`;
} //

export function duplicateOrganisationReportTemplatePath(organisation, reportTemplate) {
  return `${organisationReportTemplatePath(organisation, reportTemplate)}/duplicate`;
} //

export function mergeOrganisationPath(organisation) {
  return `${organisationPath(organisation)}/merge`;
} //

// OrganisationMemberships
export function organisationMembershipsPath(organisation) {
  const organisationId = typeof organisation === 'object' ? organisation.id : organisation;

  return `${organisationPath(organisationId)}/memberships`;
}

export function organisationMembershipPath(organisation, organisationMembership) {
  const organisationMembershipId = typeof organisationMembership === 'object'
    ? organisationMembership.id : organisationMembership;

  return `${organisationMembershipsPath(organisation)}/${organisationMembershipId}`;
}

export function observerViewAssessmentsPath(assessment) {
  const assessmentId = typeof assessment === 'object' ? assessment.id : assessment;

  return `/observer_view/assessments/${assessmentId}`;
}

// RatingAnchor

// RatingAnchor {context: organisation, assessment, competenceModelTemplate}
export function newRatingAnchorPath(organisationId, assessmentId, competenceModel, exercise, competency) {
  return assessmentId
    ? assessmentCompetenceModelNewRatingAnchorPath(organisationId, assessmentId, exercise, competency)
    : competenceModelTemplateNewRatingAnchorPath(organisationId, competenceModel, exercise, competency);
}

// RatingAnchor {context: organisation, assessment, competenceModelTemplate}
export function ratingAnchorPath(organisationId, assessmentId, competenceModel, ratingAnchor) {
  return assessmentId
    ? assessmentCompetenceModelRatingAnchorPath(organisationId, assessmentId, ratingAnchor)
    : competenceModelTemplateRatingAnchorPath(organisationId, competenceModel, ratingAnchor);
}
