<template>
  <Modal
    :open="open"
    :on-top="onTop"
    @click-outside="$emit('click-outside')"
  >
    <div class="bg-white py-2 px-3 sm:p-6 sm:max-w-lg">
      <div class="sm:flex sm:items-start">
        <div
          v-if="modalType === 'warning'"
          class="mx-auto shrink-0 flex items-center justify-center
           rounded-full bg-red-100 h-12 w-12 sm:h-10 sm:w-10 sm:mx-0"
        >
          <ExclamationIcon class="h-6 w-6 text-red-600" />
        </div>
        <div
          v-else
          class="mx-auto shrink-0 flex items-center justify-center
           rounded-full bg-blue-100 h-12 w-12 sm:h-10 sm:w-10 sm:mx-0"
        >
          <InformationCircleIcon class="h-6 w-6 text-blue-500" />
        </div>
        <div class="mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            <slot name="header" />
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              <slot name="main" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 py-3 px-2 flex flex-row justify-end">
      <slot
        name="footer"
      />
    </div>
  </Modal>
</template>

<script>
import { ExclamationIcon, InformationCircleIcon } from '@heroicons/vue/outline';
import Modal from '../Modal/Modal.vue';

export default {
  components: {
    Modal,
    ExclamationIcon,
    InformationCircleIcon,
  },
  props: {
    modalType: { // warning, info
      type: String,
      default: 'warning',
    },
    open: {
      type: Boolean,
      default: false,
    },
    onTop: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click-outside'],
};
</script>
