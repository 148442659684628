<template>
  <Modal
    :open="open"
    @click-outside="$emit('click-outside')"
  >
    <div class="bg-white py-2 px-3 sm:p-6 sm:max-w-lg qa-consent-modal">
      <div class="sm:flex sm:items-start">
        <div class="mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" />
          <div class="space-y-8">
            <AnalyticsGraphic class="mx-auto" />
            <div class="space-y-1">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ $t('components.policy_consent.modal.header') }}
              </h3>
              <p class="text-sm leading-5 font-normal text-left text-gray-500">
                {{ $t('components.policy_consent.modal.text') }}
              </p>
            </div>
            <PolicyConsent
              v-model:privacy-policy-consent="privacyPolicyConsent"
              v-model:analytics-policy-consent="analyticsPolicyConsent"
              @update:privacy-policy-enabled="privacyPolicyConsent = $event"
              @update:analytics-policy-enabled="analyticsPolicyConsent = $event"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 py-3 px-2 flex flex-row justify-end">
      <div class="flex sm:flex-row-reverse w-full pr-4 sm:w-1 ">
        <BaseButton
          :is-disabled="!privacyPolicyConsent"
          :is-loading="isLoading"
          class="mx-auto p-2 w-full justify-center rounded-md border border-transparent
           shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
          qa-class="consent-button"
          @click="save"
        >
          {{ $t('defaults.save') }}
        </BaseButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import { inject, ref } from 'vue';
import { authPath } from '@/util/url-helpers';
import Axios from 'axios';
import Modal from '../Modal/Modal.vue';
import PolicyConsent from './PolicyConsent.vue';
import AnalyticsGraphic from './Graphics/PolicyConsentGraphic.vue';

export default {
  components: {
    Modal,
    PolicyConsent,
    AnalyticsGraphic,
  },
  props: {
    privacyConsent: {
      type: Boolean,
      default: false,
    },
    analyticsConsent: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click-outside'],
  setup(props) {
    const privacyPolicyConsent = ref(props.privacyConsent);
    const analyticsPolicyConsent = ref(props.analyticsConsent);

    const isLoading = ref(false);
    const toast = inject('toast');

    const save = () => {
      isLoading.value = true;

      const data = {
        user: {
          privacy_policy_consent: privacyPolicyConsent.value,
          analytics_policy_consent: analyticsPolicyConsent.value,
        },
      };

      Axios.put(authPath(), data)
        .then((response) => {
          // Follow redirect
          Turbo.visit(response.request.responseURL, { action: 'replace' });
        })
        .catch(() => {
          isLoading.value = false;
          toast.error('Could not update the privacy policy. Please contact your admin');
        });
    };

    return {
      privacyPolicyConsent,
      analyticsPolicyConsent,
      save,
      isLoading,
    };
  },
};
</script>
