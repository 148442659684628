<template>
  <div class="w-full">
    <label
      class="block text-sm font-medium text-gray-700 mb-1"
    > {{ $t('components.date_range.title') }}
    </label>
    <div class="flex items-center justify-between">
      <div class="flex w-full">
        <DatePicker
          ref="calendarStartDateRef"
          v-model.string="startDate"
          :locale="$i18n.locale"
          mode="date"
          color="gray"
          :masks="masks"
          :min-date="minStartDate"
          :max-date="maxEndDate"
          :popover="popover"
          :input-debounce="500"
          :is-required="isRequired"
          :initial-page="initialStartPage"
        >
          <template
            #default="{ inputValue, inputEvents }"
          >
            <div
              class="flex relative w-full z-10"
            >
              <div
                v-if="leadingIcon"
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-20"
              >
                <HeroiconOutline
                  :icon="leadingIcon"
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                :value="inputValue"
                :class="[leadingIcon ? 'pl-10' : 'pl-2', disabled ? 'bg-gray-100' : '', 'w-full px-2 py-2 sm:text-sm',
                         'z-10 rounded-md focus:ring-primary-500 focus:border-primary-500 border-gray-300']"
                autocomplete="off"
                :disabled="disabled"
                :placeholder="$t('components.date_range.date_format')"
                v-on="inputEvents"
              >
            </div>
          </template>
          <template #footer>
            <div
              v-if="resetStartDateButtonLabel"
              class="flex justify-center space-x-3 p-2
                      border-t border-gray-100 bg-gray-50 rounded-b-lg"
            >
              <BaseButton
                @click="resetStartDate"
              >
                {{ resetStartDateButtonLabel }}
              </BaseButton>
            </div>
          </template>
        </DatePicker>
      </div>
      <div class="flex flex-shrink-0 min-w-min items-center">
        <ArrowNarrowRightIcon class="w-6 h-6 text-gray-500 px-1" />
      </div>
      <DatePicker
        ref="calendarEndDateRef"
        v-model.string="endDate"
        :locale="$i18n.locale"
        mode="date"
        color="gray"
        :masks="masks"
        :popover="popover"
        :min-date="minStartDate"
        :max-date="maxEndDate"
        :input-debounce="500"
        :is-required="isRequired"
        :initial-page="initialEndPage"
      >
        <template #default="{ inputValue, inputEvents }">
          <div
            class="flex relative w-full z-10"
          >
            <div
              v-if="leadingIcon"
              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-20"
            >
              <HeroiconOutline
                :icon="leadingIcon"
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              :value="inputValue"
              :class="[leadingIcon ? 'pl-10' : 'pl-2', disabled ? 'bg-gray-100' : '', 'w-full px-2 py-2 sm:text-sm',
                       'z-10 rounded-md focus:ring-primary-500 focus:border-primary-500 border-gray-300']"
              autocomplete="off"
              :disabled="disabled"
              :placeholder="$t('components.date_range.date_format')"
              v-on="inputEvents"
            >
          </div>
        </template>
        <template #footer>
          <div
            v-if="resetEndDateButtonLabel"
            class="flex justify-center space-x-3 p-2
            border-t border-gray-100 bg-gray-50 rounded-b-lg"
          >
            <BaseButton
              @click="resetEndDate"
            >
              {{ resetEndDateButtonLabel }}
            </BaseButton>
          </div>
        </template>
      </DatePicker>
    </div>
  </div>
</template>

<script>

import HeroiconOutline from '@/components/HeroiconOutline.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import { ArrowNarrowRightIcon } from '@heroicons/vue/outline';
import { DatePicker } from 'v-calendar';
import { computed, ref, watch } from 'vue';

export default {
  name: 'DateRangeInput',
  components: {
    ArrowNarrowRightIcon,
    DatePicker,
    BaseButton,
    HeroiconOutline,
  },
  props: {
    dateRange: {
      type: Object,
      required: false,
      default: () => ({ startDate: null, endDate: null }),
    },
    minStartDate: {
      type: String,
      default: null,
    },
    maxEndDate: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    leadingIcon: {
      type: String,
      default: null,
    },
    resetStartDateButtonLabel: {
      type: String,
      default: null,
    },
    resetEndDateButtonLabel: {
      type: String,
      default: null,
    },
  },
  emits: ['update:dateRange'],
  setup(props, { emit }) {
    const calendarStartDateRef = ref(null);
    const calendarEndDateRef = ref(null);
    const startDate = ref(props.dateRange.startDate);
    const endDate = ref(props.dateRange.endDate);

    const resetStartDate = async () => {
      if (props.minStartDate !== null && props.isRequired) {
        await calendarStartDateRef.value.move(props.minStartDate);
        startDate.value = props.minStartDate;
      } else {
        startDate.value = null;
      }
    };

    const resetEndDate = async () => {
      if (props.maxEndDate !== null && props.isRequired) {
        await calendarEndDateRef.value.move(props.maxEndDate);
        endDate.value = props.maxEndDate;
      } else {
        endDate.value = null;
      }
    };

    watch(startDate, (newStartDate) => {
      if (newStartDate && endDate.value
        && new Date(newStartDate) > new Date(endDate.value)) {
        endDate.value = newStartDate;
      }

      emit('update:dateRange', {
        startDate: newStartDate,
        endDate: endDate.value,
      });
    });

    const parseDate = (dateString) => {
      if (dateString) {
        const [year, month] = dateString.split('-').map(Number);
        return { month: Number(month), year: Number(year) };
      }
      return null;
    };

    const initialStartPage = computed(() => parseDate(startDate.value) || parseDate(props.minStartDate));
    const initialEndPage = computed(() => parseDate(endDate.value) || parseDate(props.maxEndDate));

    watch(endDate, (newEndDate) => {
      if (startDate.value && newEndDate && new Date(newEndDate) < new Date(startDate.value)) {
        startDate.value = endDate.value;
      }

      emit('update:dateRange', {
        startDate: startDate.value,
        endDate: newEndDate,
      });
    });

    const popover = ref(
      {
        visibility: 'focus',
        autoHide: true,
        placement: 'bottom-start',
        showDelay: 0,
        hideDelay: 110,
      },
    );

    return {
      startDate,
      endDate,
      calendarStartDateRef,
      calendarEndDateRef,
      masks: { modelValue: 'YYYY-MM-DD' },
      popover,
      initialStartPage,
      initialEndPage,

      resetStartDate,
      resetEndDate,
    };
  },
};
</script>
