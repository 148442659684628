<template>
  <Modal
    :open="open"
    :hide-overflow="false"
    inner-modal-classes="w-full sm:min-w-3/4 md:min-w-0 md:max-w-2xl"
    @close="close"
  >
    <div class="p-6">
      <h2 class="font-bold mb-4">
        {{ $t("components.assessment.competence_model_form.save_as_template") }}
      </h2>
      <form
        @keyup.enter="saveAsTemplate"
      >
        <div class="space-y-5">
          <BaseInput
            v-model="templateName"
            :label="$t('activerecord.attributes.competence_model.name')"
            type="text"
            :error="getError('name')"
          />
          <SearchableSelectList
            id="organisation-model-select"
            :selected="currentSelectedOrganisation"
            :options="availableOrganisations"
            :options-empty-message="$t('components.organisations.no_organisations')"
            value-prop="id"
            label-prop="displayName"
            name="competence_model[organisation_id]"
            :allow-reset="false"
            :error="getError('organisation_id')"
            :label="$t('activerecord.attributes.assessment.organisation')"
            @update:selected="currentSelectedOrganisation = $event"
          />
        </div>
      </form>
    </div>

    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4">
      <BaseButton
        type="button"
        :is-primary="false"
        @click="$emit('close')"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        type="button"
        :is-loading="disabled"
        @click="saveAsTemplate"
      >
        {{ $t('defaults.save') }}
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import Modal from '@/components/generic/Modal/Modal.vue';
import { saveAsTemplateAssessmentCompetenceModelPath } from '@/util/url-helpers';
import Axios from 'axios';
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'AssessmentCompetenceModelFormSaveAsTemplateModal',
  components: {
    Modal,
  },
  props: {
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    organisationId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    availableOrganisations: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'save-competence-model'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const templateName = ref(props.name ? t(
      'components.assessment.competence_model_form.save_as_template_modal.name',
      { name: props.name },
    ) : '');

    const currentSelectedOrganisation = ref(props.availableOrganisations.find(
      (organisation) => organisation.id === props.organisationId,
    ) || null);

    const errors = ref({});
    const disabled = ref(false);
    const saveAsTemplateForm = ref(null);

    const toast = inject('toast');

    const close = () => {
      templateName.value = null;
      currentSelectedOrganisation.value = null;
      emit('close');
    };

    const getError = (...keys) => errors.value[keys.join('.')]?.[0];

    const saveAsTemplate = () => {
      disabled.value = true;
      const data = {
        competence_model: {
          name: templateName.value,
          organisation_id: currentSelectedOrganisation.value.id,
        },
      };

      Axios.post(saveAsTemplateAssessmentCompetenceModelPath(props.assessmentId), data)
        .then((response) => {
          emit('save-competence-model', response.data);
          errors.value = {};
          templateName.value = null;
          currentSelectedOrganisation.value = null;
          close();
          // eslint-disable-next-line max-len
          toast.success(t('components.assessment.competence_model_form.save_as_template_modal.success'));
          disabled.value = false;
        })
        .catch(({ response }) => {
          disabled.value = false;
          errors.value = response.data.errors;
          // eslint-disable-next-line max-len
          toast.error(t('components.assessment.competence_model_form.save_as_template_modal.error'));
        });
    };

    return {
      errors,
      disabled,
      saveAsTemplateForm,
      getError,
      saveAsTemplate,
      close,
      templateName,
      currentSelectedOrganisation,
    };
  },
};
</script>
