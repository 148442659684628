<template>
  <div>
    <span class="text-gray-900 text-lg leading-6 font-medium block">{{ header }}</span>
    <span class="text-gray-500 text-sm leading-5 block mt-1">
      {{ helptext }}
      <template v-if="infoLink">
        <a
          :href="infoLink"
          class="text-primary-500 text-sm leading-5 inline-block"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('defaults.more_information') }}
        </a>
      </template>
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardDescription',
  props: {
    header: {
      type: String,
      required: true,
    },
    helptext: {
      type: String,
      default: '',
    },
    infoLink: {
      type: String,
      default: null,
    },
  },
});
</script>
