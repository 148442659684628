<template>
  <div class="pointer-events-none flex justify-center ">
    <div class="self-end">
      <a
        v-if="isDownloadable"
        :href="url"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ViewerControl icon="DownloadIcon" />
      </a>
    </div>
  </div>
</template>

<script>
import ViewerControl from './ViewerControl.vue';

export default {
  components: {
    ViewerControl,
  },
  props: {
    isDownloadable: {
      type: Boolean,
      required: false,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
