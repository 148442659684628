<template>
  <ConfirmDialogModal
    :open="show"
    :title="$t('components.competence_model.matrix_wizard.dissolve_modal.title')"
    :message="$t('components.competence_model.matrix_wizard.dissolve_modal.text')"
    :is-loading="disabled"
    :button-text="$t('defaults.dissolve')"
    @close="$emit('close')"
    @confirm="onDissolve"
  />
</template>

<script>
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';

import { dissolveCompetencyClusterPath } from '@/util/url-helpers';
import Axios from 'axios';

export default {
  name: 'MatrixWizardDissolveModal',
  components: {
    ConfirmDialogModal,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    competenceModelId: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ['close', 'dissolved'],
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },

    onDissolve() {
      this.disabled = true;

      Axios.post(dissolveCompetencyClusterPath(this.competenceModelId))
        .then((response) => {
          this.disabled = false;
          this.$emit('dissolved', response.data);
        })
        .catch((_) => {
          this.$emit('close');
          this.$toast.error(this.$t('components.competence_model.matrix_wizard.errors.dissolve'));
        });
    },
  },
};
</script>
