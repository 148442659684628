<template>
  <div
    class="slider"
    :class="{ 'rounded-t-lg': isRounded, 'bg-primary-300': showsProgress }"
    :style="{ height: sliderHeight }"
  >
    <div
      class="line"
      :style="{ width: showsProgress ? progress + '%' : '100%', height: sliderHeight }"
    />
    <div
      v-if="!showsProgress"
      class="subline inc"
      :style="{ height: sliderHeight }"
    />
    <div
      v-if="!showsProgress"
      class="subline dec"
      :style="{ height: sliderHeight }"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    progress: {
      type: Number,
      default: null,
    },
    // hight in px
    height: {
      type: Number,
      default: 5,
    },
    isRounded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      sliderHeight: `${props.height}px`,
      showsProgress: props.progress !== null,
    };
  },
});
</script>

<style>
.slider {
  position: absolute;
  width: 100%;
  overflow-x: hidden;
}

.line {
  position: absolute;
  background: var(--color-primary-500);
}

.subline {
  position: absolute;
  background: var(--color-primary-100);
}

.inc {
  animation: increase 2s infinite;
}

.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
</style>
