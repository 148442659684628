<template>
  <div class="flex bg-red-50 p-4 rounded-lg w-full">
    <div class="w-8 mt-0.5">
      <ExclamationIcon
        class="shrink-0 h-5 w-5 text-red-400"
        aria-hidden="true"
      />
    </div>
    <div class="flex flex-col">
      <span class="text-red-800 pb-2">
        {{ $t('components.errors.title') }}
      </span>
      <span
        v-for="error in errors"
        :key="error"
        class="text-red-700"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from '@heroicons/vue/solid';

export default {
  components: {
    ExclamationIcon,
  },
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
};
</script>
