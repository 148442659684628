<template>
  <Teleport to="body">
    <div
      class="
        fixed
        w-screen
        h-screen
        bg-black/60
        top-0
        left-0
        z-50
        flex flex-col
        items-center
      "
    >
      <div class="shrink-0 grow-0 w-full">
        <ReportTemplatePreviewOverlayHeader
          v-model:selected-tab="selectedTemplate"
          :report-template="reportTemplate"
          @close="$emit('close')"
          @download="downloadFile"
          @print="printFile"
        />
      </div>
      <div
        v-if="selectedTemplate?.filePdfs"
        class="flex w-full flex-1 overflow-auto"
      >
        <ResourceViewer
          v-if="selectedTemplate?.filePdfs[0]?.url"
          class="w-1/2"
          :url="selectedTemplate.filePdfs[0].url"
          :type="selectedTemplate.filePdfs[0].type"
          :text-selectable="true"
          default-scale="page-width"
          :keep-pdf-scroll-position-on-url-change="true"
          @click-outside="$emit('close')"
        />
        <div class="h-full w-4 bg-black" />
        <ResourceViewer
          v-if="selectedTemplate?.filePdfs[1]?.url"
          class="w-1/2"
          :url="selectedTemplate.filePdfs[1].url"
          :type="selectedTemplate.filePdfs[1].type"
          :text-selectable="true"
          default-scale="page-width"
          :keep-pdf-scroll-position-on-url-change="true"
          @click-outside="$emit('close')"
        />
      </div>
      <div
        v-else
        class="w-full flex-1 overflow-auto"
      >
        <ResourceViewer
          :url="selectedTemplate?.filePdf?.url"
          :type="selectedTemplate?.filePdf?.type"
          :text-selectable="true"
          :keep-pdf-scroll-position-on-url-change="true"
          @click-outside="$emit('close')"
        />
      </div>
    </div>
  </Teleport>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import download from '@/util/download';
import printJS from 'print-js';
import ResourceViewer from '@/components/Viewers/ResourceViewer.vue';
import ReportTemplatePreviewOverlayHeader from './ReportTemplatePreviewOverlayHeader.vue';

export default defineComponent({
  components: {
    ResourceViewer,
    ReportTemplatePreviewOverlayHeader,
  },
  props: {
    reportTemplate: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const selectedTemplate = ref(null);

    onMounted(() => {
      if (window.ZohoHCAsap) {
        window.ZohoHCAsap.Actions.Disable();
      }
      document.querySelectorAll('.overflow-y-auto').forEach((node) => {
        node.classList.add('noscroll');
      });
    });

    onUnmounted(() => {
      if (window.ZohoHCAsap) {
        window.ZohoHCAsap.Actions.Enable();
      }
      document.querySelectorAll('.noscroll').forEach((node) => {
        node.classList.remove('noscroll');
      });
    });

    const downloadFile = () => {
      const template = selectedTemplate.value.file || selectedTemplate.value.files[0];
      download({
        path: template.url,
        filename: template.filename,
      });
    };

    const printFile = () => {
      printJS(selectedTemplate.value.file.url);
    };

    return { downloadFile, printFile, selectedTemplate };
  },
});
</script>
