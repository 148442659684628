<template>
  <div
    v-for="exercise in exercises"
    :key="exercise.name"
  >
    <div class="pb-5 border-b border-gray-200 mb-5">
      <h3 class="text-lg leading-6 font-medium text-gray-900 mt-10">
        {{ exercise.name }}
      </h3>
    </div>
    <chart-card
      :title="$t('components.insights.competencies.show.competency')"
      :subtitle="$t('components.insights.competencies.show.statistics')"
    >
      <div class="flex flex-row flex-1 mt-2 flex-wrap">
        <competency-stat
          class="mr-auto my-2"
          :percentage="true"
          :name="$t('components.insights.competencies.show.agreement')"
          :value="exercise.ratingAnchorStatistic.agreement"
          icon-name="UsersIcon"
          :color-class="agreementColor(exercise.ratingAnchorStatistic.agreement)"
          :description="$t('components.insights.competencies.show.agreement_description')"
        />
        <div class="border-l-2 border-gray-200 self-stretch my-2 mr-auto" />
        <competency-stat
          class="mr-auto my-2"
          :percentage="true"
          :name="$t('components.insights.competencies.show.observability')"
          :value="exercise.ratingAnchorStatistic.observability"
          icon-name="EyeIcon"
          :color-class="observabilityColor(exercise.ratingAnchorStatistic.observability)"
          :description="$t('components.insights.competencies.show.observability_description')"
        />
        <div class="border-l-2 border-gray-200 self-stretch my-2 mr-auto" />
        <competency-stat
          class="mr-auto my-2"
          :name="$t('components.insights.competencies.show.internal_consistency')"
          :value="exercise.ratingAnchorStatistic.alpha"
          icon-name="ChartPieIcon"
          :color-class="internalConsistencyColor(exercise.ratingAnchorStatistic.alpha)"
          :description="$t('components.insights.competencies.show.internal_consistency_description')"
        />
      </div>
    </chart-card>
    <exercise-item-details
      class="my-5"
      :exercise="exercise"
    />
  </div>
</template>
<script>
import ExerciseItemDetails from '@/components/Insights/ExerciseItemDetails.vue';
import ChartCard from '@/components/Insights/ChartCard.vue';
import CompetencyStat from '@/components/Insights/CompetencyStat.vue';
import Utils from '@/components/Insights/Utils';

export default {
  name: 'ItemDetails',
  components: {
    ExerciseItemDetails,
    ChartCard,
    CompetencyStat,
  },
  props: {
    exercises: {
      type: Object,
      required: true,
    },
  },
  methods: {
    agreementColor: (value) => Utils.agreementColor(value),
    observabilityColor: (value) => Utils.observabilityColor(value),
    internalConsistencyColor: (value) => Utils.internalConsistencyColor(value),
  },
};
</script>
