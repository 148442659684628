<template>
  <div class="relative">
    <div
      id="pdf"
      ref="pdf"
      class="h-full active:cursor-grabbing overflow-auto flex flex-col"
      @click.self="$emit('click-outside')"
    >
      <img
        ref="pdfimg"
        :src="url"
        class="max-w-none w-full h-fit m-auto"
        :style="{width: `${width}%`}"
      >
    </div>
    <div class="absolute top-0 right-0 py-6 mr-6">
      <DownloadControl
        :is-downloadable="isDownloadable"
        :url="url"
      />
    </div>
    <div class="absolute bottom-0 right-0 mb-6 mr-6">
      <ZoomControls
        :zoom-out-disabled="zoomOutDisabled"
        @zoom-in="zoomIn"
        @zoom-out="zoomOut"
      />
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import ZoomControls from '../Controls/ZoomControls.vue';
import DownloadControl from '../Controls/DownloadControl.vue';

export default defineComponent({
  name: 'ImageViewer',
  components: {
    ZoomControls,
    DownloadControl,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click-outside'],
  setup() {
    const zoomStep = 20;
    const maxZoomOutSteps = 2;

    const width = ref(100);
    const pdfimg = ref(null);
    const pdf = ref(null);
    let prevWidth = null;
    let prevHeight = null;
    const zoomOutDisabled = computed(() => width.value === 100 - maxZoomOutSteps * zoomStep);

    useResizeObserver(pdfimg, (entries) => {
      const entry = entries[0];
      const { width: imageWidth, height: imageHeight } = entry.contentRect;
      if (!prevWidth) {
        pdf.value.scrollLeft += (imageWidth - pdf.value.clientWidth) / 2;
        pdf.value.scrollTop += (imageHeight - pdf.value.clientHeight) / 2;
      } else {
        pdf.value.scrollLeft += (imageWidth - prevWidth) / 2;
        pdf.value.scrollTop += (imageHeight - prevHeight) / 2;
      }
      prevWidth = imageWidth;
      prevHeight = imageHeight;
    });

    const zoomIn = () => {
      width.value += zoomStep;
    };

    const zoomOut = () => {
      width.value -= zoomStep;
    };

    return {
      zoomIn,
      zoomOut,
      width,
      pdfimg,
      zoomOutDisabled,
      pdf,
    };
  },
});
</script>
