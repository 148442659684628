<template>
  <TwoColumnCard
    :header="$t('components.tenants.tenants_form.card.iona_integration.heading')"
  >
    <div class="space-y-6">
      <div class="flex flex-row justify-between ">
        <div>
          {{ $t('components.tenants.tenants_form.card.iona_integration.iona_integration_enabled') }}
        </div>
        <Toggle
          v-model="ionaIntegrationEnabled"
        />
        <input
          type="hidden"
          name="tenant[iona_integration][enabled]"
          :value="ionaIntegrationEnabled"
        >
      </div>
      <div class="flex space-x-6 grow w-full">
        <BaseInput
          v-model="username"
          class="flex-1"
          :label=" $t('components.tenants.tenants_form.card.iona_integration.username')"
          :disabled="!ionaIntegrationEnabled"
          name="tenant[iona_integration][credentials][username]"
          :error="getIonaIntegrationError('credentials')"
        />
        <BaseInput
          v-model="password"
          class="flex-1"
          :type="'password'"
          :label=" $t('components.tenants.tenants_form.card.iona_integration.password')"
          :disabled="!ionaIntegrationEnabled"
          name="tenant[iona_integration][credentials][password]"
          :error="getIonaIntegrationError('credentials')"
        />
      </div>
      <BaseInput
        v-model="url"
        :label=" $t('components.tenants.tenants_form.card.iona_integration.url')"
        :disabled="!ionaIntegrationEnabled"
        name="tenant[iona_integration][url]"
        :error="getIonaIntegrationError('url')"
      />
      <BaseInput
        v-model="testUrl"
        :label=" $t('components.tenants.tenants_form.card.iona_integration.test_url')"
        :disabled="!ionaIntegrationEnabled"
        name="tenant[iona_integration][credentials][test_url]"
        :error="getIonaIntegrationError('test_url')"
      />
    </div>
  </TwoColumnCard>
</template>

<script>
import { defineComponent, ref } from 'vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';

export default defineComponent({
  name: 'TenantForm',
  components: {
    TwoColumnCard,
    BaseInput,
  },
  props: {
    ionaIntegrationData: {
      type: Object,
      required: false,
      default: null,
    },
    ionaIntegrationErrors: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup(props) {
    const { enabled, url, credentials } = { ...props.ionaIntegrationData };
    const { username, password, testUrl } = credentials || {};
    const ionaIntegrationEnabled = ref(enabled || false);

    const getIonaIntegrationError = (...keys) => props.ionaIntegrationErrors[keys.join('.')]?.[0];

    return {

      url,
      testUrl,
      username,
      password,
      getIonaIntegrationError,
      ionaIntegrationEnabled,
    };
  },
});
</script>
