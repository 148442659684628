<template>
  <div class="relative">
    <Dropdown
      :with-popper-overlay="true"
    >
      <template #button>
        <MenuButton
          ref="trigger"
          class="flex justify-center text-gray-400 focus:outline-none focus:ring-2  ring-primary-500
             hover:bg-gray-200 rounded-full p-1.5"
        >
          <DotsVerticalIcon
            class="h-5 w-5"
            aria-hidden="true"
          />
          <span class="sr-only">{{ $t('defaults.manage') }}</span>
        </MenuButton>
      </template>
      <div
        ref="container"
        class="origin-top-right absolute right-0 w-56 min-w-fit rounded-md shadow-lg
          bg-white ring-1 ring-black/5 focus:outline-none z-20 text-sm font-normal py-1"
      >
        <ActionDropdownItem
          class="flex flex-row"
          @click="$emit('edit-click')"
        >
          <div class="mr-4">
            <PencilIcon
              class="h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </div>
          <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
        </ActionDropdownItem>
        <template v-if="cluster">
          <ActionDropdownItem
            class="flex flex-row"
            :disabled="cluster.relativePosition == 'first'"
            @click="$emit('move-up-click')"
          >
            <div class="mr-4">
              <ArrowNarrowUpIcon
                :class="['h-5 w-5', cluster.relativePosition == 'first' ? 'text-gray-300' : ' text-gray-500']"
                aria-hidden="true"
              />
            </div>
            <span :class="[cluster.relativePosition == 'first' ? 'text-gray-300' : 'text-gray-700']">
              {{ $t('components.competence_model.matrix_wizard.actions.move_up') }}
            </span>
          </ActionDropdownItem>
          <ActionDropdownItem
            class="flex flex-row"
            :disabled="cluster.relativePosition == 'last'"
            @click="$emit('move-down-click')"
          >
            <div class="mr-4">
              <ArrowNarrowDownIcon
                :class="['h-5 w-5', cluster.relativePosition == 'last' ? 'text-gray-300' : ' text-gray-500']"
                aria-hidden="true"
              />
            </div>
            <span :class="[cluster.relativePosition == 'last' ? 'text-gray-300' : 'text-gray-700']">
              {{ $t('components.competence_model.matrix_wizard.actions.move_down') }}
            </span>
          </ActionDropdownItem>
          <ActionDropdownItem
            class="flex flex-row"
            @click="$emit('dissolve-clusters-click')"
          >
            <div class="mr-4">
              <ViewListIcon
                class="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </div>
            <span class="text-gray-700">
              {{ $t('components.competence_model.matrix_wizard.actions.dissolve_all_cluster') }}
            </span>
          </ActionDropdownItem>
        </template>
        <ActionDropdownItem
          class="flex flex-row border-t border-gray-100"
          method="delete"
          :remote="true"
          @click="$emit('delete-click')"
        >
          <div class="mr-4">
            <TrashIcon
              class="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
          <span class="text-red-700">{{ $t('defaults.delete') }}</span>
        </ActionDropdownItem>
      </div>
    </Dropdown>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Dropdown from '@/components/generic/Dropdown/Dropdown.vue';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import { MenuButton } from '@headlessui/vue';
import {
  ArrowNarrowDownIcon, ArrowNarrowUpIcon, PencilIcon, TrashIcon, ViewListIcon,
} from '@heroicons/vue/solid';
import { DotsVerticalIcon } from '@heroicons/vue/outline';
import usePopper from '@/util/use-popper';

export default defineComponent({
  name: 'MatrixActions',
  components: {
    Dropdown,
    MenuButton,
    PencilIcon,
    ArrowNarrowUpIcon,
    ArrowNarrowDownIcon,
    ViewListIcon,
    TrashIcon,
    DotsVerticalIcon,
    ActionDropdownItem,
  },
  props: {
    cluster: {
      type: Object,
      default: null,
    },
  },
  emits: ['edit-click', 'delete-click', 'dissolve-clusters-click', 'move-down-click', 'move-up-click'],
  setup() {
    const [trigger, container] = usePopper({
      placement: 'bottom-end',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [-6, 6] } }],
    });

    return {
      trigger,
      container,
    };
  },
});
</script>
