<template>
  <div>
    <div class="mb-6">
      <PageHeading>
        {{ title }}
      </PageHeading>
    </div>
    <div class="w-full mb-4 space-y-5">
      <alert type="info">
        <template #title>
          {{ $t('components.candidate_import.prerequisites.title') }}
        </template>
        <template #content>
          <li>{{ $t('components.candidate_import.prerequisites.must_be_a_spreadsheet') }}</li>
          <li>{{ $t('components.candidate_import.prerequisites.must_have_header') }}</li>
          <li>{{ $t('components.candidate_import.prerequisites.must_not_containing_anything_other') }}</li>
          <li>{{ $t('components.candidate_import.prerequisites.must_be_default_sheet') }}</li>
        </template>
      </alert>

      <form ref="importForm">
        <Card class="xl:flex w-full px-0 xl:px-6">
          <div class="xl:w-1/2 p-4 xl:pb-8 xl:pl-0">
            <SingleFileUpload
              :file="file"
              :accepted-mime-types="acceptedFileTypes"
              :accepted-file-types="$t('components.candidate_import.accepted_file_types')"
              name="candidate_import[file]"
              @selected="onFileSelected"
              @removed="onFileRemoved"
            />
          </div>

          <div class="xl:w-1/2 p-4 xl:pr-0">
            <csv-delimiter-select
              v-model:csv-delimiter="csvDelimiter"
              :disabled="!isCSV"
            />
            <column-mapping-form
              v-model:column-mapping="columnMapping"
              :column-names="columnNames"
              :disabled="columnNames.length === 0 || !previewData"
            />
          </div>
        </Card>
      </form>
    </div>

    <div class="mb-6">
      <preview-table
        :preview-data="previewData"
        :loading="loadingPreview"
        :column-mapping="columnMapping"
      />
    </div>

    <div
      class="flex justify-end space-x-5"
    >
      <BaseButton
        as="a"
        :href="assessmentCandidatesPath"
        :is-primary="false"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        :is-disabled="!readyForImport"
        :is-loading="importing"
        @click="importCandidates"
      >
        {{ $t('components.candidate_import.import') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { ColumnMappingForm, CsvDelimiterSelect, PreviewTable } from '@/components/CandidateImport/index';
import {
  assessmentCandidateImportPath,
  assessmentCandidatesPath,
  organisationAssessmentCandidatesPath,
  organisationAssessmentCandidateImportPath,
  previewAssessmentCandidateImportPath,
} from '@/util/url-helpers';
import Axios from 'axios';

export default {
  name: 'CandidateImport',
  components: {
    CsvDelimiterSelect,
    ColumnMappingForm,
    PreviewTable,
  },
  props: {
    assessmentId: {
      type: Number,
      required: true,
    },
    organisationId: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line max-len
      acceptedFileTypes: '.csv, .ods, .xlsx, .xlsm, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet',

      loadingPreview: false,
      importing: false,

      file: null,
      csvDelimiter: ',',
      previewData: null,
      isCSV: false,
      columnNames: [],
      columnMapping: {
        firstName: null,
        lastName: null,
        email: null,
        locale: null,
        sex: null,
        birthdate: null,
      },
    };
  },
  computed: {
    assessmentCandidatesPath() {
      return this.organisationId
        ? organisationAssessmentCandidatesPath(this.organisationId, this.assessmentId)
        : assessmentCandidatesPath(this.assessmentId);
    },
    readyForImport() {
      return this.columnNames.length !== 0 && this.previewData && this.previewData.length !== 0;
    },
  },
  watch: {
    csvDelimiter() {
      this.onFileSelected(this.file);
    },
  },
  methods: {
    onFileSelected(file) {
      this.loadingPreview = true;
      this.file = file;

      const data = new FormData();
      data.append('candidate_import[file]', file);
      data.append('candidate_import[csv_delimiter]', this.csvDelimiter);

      Axios
        .post(previewAssessmentCandidateImportPath(this.assessmentId), data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data: { isCSV, data: previewData, columnNames, columnMapping } }) => {
          this.previewData = previewData;
          this.isCSV = isCSV;
          this.columnNames = columnNames;
          this.columnMapping = columnMapping;
          this.loadingPreview = false;
        })
        .catch(({ response: { data: { errors } } }) => {
          this.onFileRemoved();
          this.loadingPreview = false;
          this.$toast.error(errors[0]);
        });
    },
    onFileRemoved() {
      this.file = null;

      this.previewData = null;
      this.isCSV = false;
      this.columnNames = [];
      this.columnMapping = {
        firstName: null,
        lastName: null,
        email: null,
        locale: null,
        sex: null,
        birthdate: null,
      };
    },
    importCandidates() {
      this.importing = true;
      const data = new FormData(this.$refs.importForm);
      const url = this.organisationId
        ? organisationAssessmentCandidateImportPath(this.organisationId, this.assessmentId)
        : assessmentCandidateImportPath(this.assessmentId);

      Axios
        .post(url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          Turbo.visit(this.assessmentCandidatesPath);
          this.$toast.success(this.$t('components.candidate_import.imported_successfully'));
          this.importing = false;
        })
        .catch(({ response: { data: { errors } } }) => {
          this.importing = false;
          this.$toast.error(errors[0]);
        });
    },
  },
};
</script>
