<template>
  <MenuItem
    v-slot="{ active }"
    :disabled="disabled"
  >
    <button
      :class="[active ? 'bg-gray-50' : '', 'block px-4 py-2 text-sm text-gray-700 w-full text-left']"
      :name="name"
      :type="type"
      @click="$emit('click')"
    >
      <slot />
    </button>
  </MenuItem>
</template>

<script>
import { defineComponent } from 'vue';
import { MenuItem } from '@headlessui/vue';

export default defineComponent({
  components: {
    MenuItem,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
});
</script>
