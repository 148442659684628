<template>
  <div>
    <input
      :name="name"
      type="hidden"
      :value="uncheckedValue"
    >
    <label
      :for="id"
      :class="[disabled ? '' : 'cursor-pointer']"
    >
      <div class="flex items-center">
        <input
          :id="id"
          type="checkbox"
          :name="name"
          :disabled="disabled"
          :form="form"
          class="focus:ring-primary-500 h-4 w-4 border-gray-300 rounded"
          :class="[
            disabled ? 'bg-gray-100 text-primary-400': 'text-primary-600 cursor-pointer',
            qaClass !== '' ? `qa-${qaClass}` : ''
          ]"
          :checked="modelValue"
          :value="value"
          @change="onChange"
        >
        <div
          v-if="$slots.label || label || secondaryLabel"
          class="ml-2 text-sm w-full"
        >
          <div :class="labelClasses || 'font-medium text-gray-700 flex flex-col'">
            <div class="flex justify-between items-center">
              <slot name="label" />
              <template v-if="!$slots.label">
                <p>{{ label }}</p>
              </template>
              <div
                v-if="hasError"
                class="p-1"
              >
                <HeroiconOutline
                  icon="ExclamationIcon"
                  class="h-5 w-5 text-red-500"
                />
              </div>
            </div>
            <div
              v-if="secondaryLabel || optionIcon"
              class="flex"
            >
              <span
                v-if="secondaryLabel"
                :class="secondaryLabelClasses || 'text-gray-500 font-normal'"
              >
                {{ secondaryLabel }}
              </span>
              <div
                v-if="optionIcon"
                class="flex items-center mt-0.5 px-1"
              >
                <HeroiconOutline
                  :icon="optionIcon"
                  class="h-5 w-5 group-hover:block hidden text-gray-400 hover:text-gray-600 hover:cursor-pointer"
                  aria-hidden="true"
                  @click.prevent="$emit('click:option-icon')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import HeroiconOutline from '@/components/HeroiconOutline.vue';

export default {
  components: {
    HeroiconOutline,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    form: {
      type: String,
      default: null,
    },

    value: {
      type: String,
      default: '1',
    },
    uncheckedValue: {
      type: String,
      default: '0',
    },

    label: {
      type: String,
      default: '',
    },
    secondaryLabel: {
      type: String,
      default: null,
    },
    labelClasses: {
      type: String,
      default: null,
    },
    secondaryLabelClasses: {
      type: String,
      default: null,
    },
    optionIcon: {
      type: String,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'click:option-icon'],
  setup(props, { emit }) {
    const onChange = ({ target }) => {
      emit('update:modelValue', target.checked);
    };

    return {
      onChange,
    };
  },
};
</script>
