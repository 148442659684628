<template>
  <div
    :class="['py-4 pr-4 inline-flex items-center min-w-0  justify-between',
             editingFilename ? '' : 'max-w-[224px] xl:max-w-[500px]']"
  >
    <template v-if="editingFilename">
      <input
        ref="filenameInput"
        v-model="newFilename"
        class="grow border-b border-gray-300 focus:border-gray-500 focus:outline-none"
        @keyup.prevent.exact.enter="applyFilename"
        @keyup.prevent.exact.esc="discardFilename"
        @keydown.enter.prevent
      >
      <div class="inline-flex space-x-2 items-center ml-2">
        <button
          class="flex items-center rounded-sm text-gray-400
            hover:text-gray-500 focus:outline-none focus:ring ring-gray-500"
          @click="applyFilename"
        >
          <Heroicon
            icon="CheckIcon"
            class="h-5 w-5"
            aria-hidden="true"
          />
          <span class="sr-only">{{ $t('defaults.save') }}</span>
        </button>
        <button
          class="flex items-center rounded-sm text-gray-400
            hover:text-gray-500 focus:outline-none focus:ring ring-gray-500"
          @click="discardFilename"
        >
          <Heroicon
            icon="XIcon"
            class="h-5 w-5"
            aria-hidden="true"
          />
          <span class="sr-only">{{ $t('defaults.cancel') }}</span>
        </button>
      </div>
    </template>

    <template
      v-else
    >
      <a
        :class="['truncate', pending ? 'animate-pulse' : 'cursor-pointer hover:underline', 'qa-file-name']"
        @click="$emit('click')"
        @mouseover="$emit('mouseover')"
        @mouseleave="$emit('mouseleave')"
      >
        {{ modelValue }}
      </a>
      <div
        v-if="pending && uploadProgress >= 100"
        class="pl-3 flex flex-row min-w-[105px]"
      >
        <span>
          {{ $t("components.file_list.virus_scan") }}
        </span>
        <Spinner />
      </div>
      <p
        v-else-if="pending"
        class="ml-4 text-gray-800"
      >
        {{ Math.round(uploadProgress) }}%
      </p>
      <button
        v-if="!disabled && !preview"
        class="ml-2 flex items-center rounded-sm text-gray-400
          hover:text-gray-500 focus:outline-none focus:ring ring-gray-500"
        @click="editFilename"
      >
        <Heroicon
          icon="PencilIcon"
          class="h-5 w-5"
          aria-hidden="true"
        />
        <span class="sr-only">{{ $t('defaults.edit') }}</span>
      </button>
    </template>
  </div>
</template>

<script>
import {
  defineComponent, toRefs, ref, nextTick, watchEffect,
} from 'vue';
import Heroicon from '@/components/Heroicon.vue';
import Spinner from '@/components/generic/Spinner/Spinner.vue';

export default defineComponent({
  components: {
    Heroicon,
    Spinner,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    uploadProgress: {
      type: Number,
      default: 100,
    },
  },
  emits: ['update:modelValue', 'click', 'mouseover', 'mouseleave'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const filenameInput = ref(null);
    const editingFilename = ref(false);
    const newFilename = ref(modelValue.value);
    const oldFilename = ref(modelValue.value);

    watchEffect(() => {
      newFilename.value = modelValue.value;
      oldFilename.value = modelValue.value;
    });

    const editFilename = () => {
      editingFilename.value = true;
      nextTick(() => {
        filenameInput.value.focus();
      });
    };

    const applyFilename = () => {
      editingFilename.value = false;
      emit('update:modelValue', newFilename.value);
    };

    const discardFilename = () => {
      editingFilename.value = false;
      newFilename.value = oldFilename.value;
    };

    return {
      filenameInput,
      editingFilename,
      newFilename,
      editFilename,
      applyFilename,
      discardFilename,
    };
  },
});
</script>
