<template>
  <div class="flex flex-col space-y-6">
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
      <MultiSelectComboBox
        v-model:selected-options="selectedOrganisations"
        class="sm:col-span-1"
        :options="organisations"
        secondary-option-label="unit"
        :label="$t('components.insights.filters.organisations')"
        :hint="$t('defaults.optional')"
      />
      <MultiSelectComboBox
        v-model:selected-options="selectedPositions"
        class="sm:col-span-1"
        :options="positions"
        :label="$t('components.insights.filters.positions')"
        :hint="$t('defaults.optional')"
      />
    </div>
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
      <div class="lg:col-span-1">
        <DateRangeInput
          v-model:date-range="dateRange"
          :min-start-date="initialStartDate"
          :max-end-date="maxEndDate"
          :is-required="true"
          :reset-start-date-button-label="$t('components.insights.filters.from_start')"
          :reset-end-date-button-label="$t('components.insights.filters.today')"
          leading-icon="CalendarIcon"
        />
      </div>
      <SelectList
        v-model:selected="selectedScale"
        class="lg:col-span-1"
        :options="scales"
        :label="$t('components.insights.filters.scales')"
        secondary-label-prop="secondaryLabel"
      />
    </div>
  </div>
</template>
<script>
import { insightFilterPath } from '@/util/url-helpers';
import * as Sentry from '@sentry/vue';
import Axios from 'axios';
import { inject, ref, watchPostEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import DateRangeInput from '../generic/DateRangeInput/DateRangeInput.vue';
import MultiSelectComboBox from '../generic/MultiSelectComboBox/MultiSelectComboBox.vue';
import SelectList from '../generic/SelectList/SelectList.vue';

export default {
  name: 'InsightsFilters',
  components: {
    MultiSelectComboBox,
    SelectList,
    DateRangeInput,
  },
  props: {
    initialOrganisations: {
      type: Array,
      required: true,
    },
    initialPositions: {
      type: Array,
      required: true,
    },
    initialStartDate: {
      type: String,
      required: true,
    },
    initialEndDate: {
      type: String,
      required: true,
    },
    initialScales: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
      required: false,
      default: () => ({
        organisations: [],
        positions: [],
        dateRange: { start: null, end: null },
        scale: null,
      }),
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const toast = inject('toast');
    const { t } = useI18n();
    const maxEndDate = new Date().toISOString().split('T')[0];

    const organisations = ref(props.initialOrganisations
      .map((organisation) => (
        { id: organisation.id, name: organisation.name, disabled: true, unit: organisation.unit }
      )));

    const selectedOrganisations = ref(props.modelValue.organisations);
    const positions = ref(props.initialPositions
      .map((position) => ({ id: position, name: position, disabled: false })));
    const selectedPositions = ref(props.modelValue.positions);
    const dateRange = ref({
      startDate: (props.modelValue.startDate || props.initialStartDate),
      endDate: (props.modelValue.endDate || props.initialEndDate),
    });
    const scales = ref(props.initialScales
      .map((scale) => ({ label: `${scale.min}-${scale.max}`, secondaryLabel: `(${scale.step})`, value: scale })));
    const selectedScale = ref(props.modelValue.scale || scales.value[0]);

    const updateFilterOptions = () => {
      const data = {
        start_date: dateRange.value.startDate,
        end_date: dateRange.value.endDate,
        scale_min: selectedScale.value.value.min,
        scale_max: selectedScale.value.value.max,
        scale_step: selectedScale.value.value.step,
        organisations: selectedOrganisations.value.map((organisation) => organisation.id),
        target_positions: selectedPositions.value.map((position) => position.name),
      };

      Axios.post(insightFilterPath(), data)
        .then(({
          data: {
            organisations: filteredOrganisations, positions: filteredPositions, scales: filteredScales,
          },
        }) => {
          organisations.value = organisations.value
            .map((organisation) => {
              const isOrganisationIncluded = filteredOrganisations
                .some((filteredOrg) => filteredOrg.id === organisation.id);
              return { ...organisation, disabled: !isOrganisationIncluded };
            });
          positions.value = positions.value
            .map((position) => ({ ...position, disabled: !filteredPositions.includes(position.name) }));
          scales.value = filteredScales
            .map((scale) => ({ label: `${scale.min}-${scale.max}`, secondaryLabel: `(${scale.step})`, value: scale }));

          if (!scales.value.some((scale) => scale.value.min.toString() === selectedScale.value.value.min.toString()
          && scale.value.max.toString() === selectedScale.value.value.max.toString()
          && scale.value.step.toString() === selectedScale.value.value.step.toString())) {
            [selectedScale.value] = scales.value;
          }
        })
        .catch((error) => {
          Sentry.captureMessage(error?.toString());
          toast.error(t('components.insights.filters.error'));
        });
    };

    watchPostEffect(() => {
      emit('update:modelValue', {
        organisations: [...selectedOrganisations.value],
        positions: [...selectedPositions.value],
        startDate: dateRange.value.startDate,
        endDate: dateRange.value.endDate,
        scale: { ...selectedScale.value },
      });
      updateFilterOptions();
    });

    return {
      organisations,
      selectedOrganisations,
      positions,
      selectedPositions,
      scales,
      selectedScale,
      dateRange,
      maxEndDate,
    };
  },
};
</script>
