<template>
  <button
    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400
      hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
    @click.prevent="toggleSidebar"
  >
    <span class="sr-only">
      {{ showSidebar ? $t('components.sidebar_toggle.collapse') : $t('components.sidebar_toggle.show') }}
    </span>
    <ChevronLeftIcon
      v-if="showSidebar"
      class="block h-6 w-6"
      aria-hidden="true"
    />
    <ChevronRightIcon
      v-else
      class="block h-6 w-6"
      aria-hidden="true"
    />
  </button>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline';
import EventBus from '@/util/event-bus';
import { ref, onBeforeUnmount } from 'vue';

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  setup() {
    const showSidebar = ref(false);
    const toggleSidebar = () => {
      EventBus.emit('sidebar-toggle');
    };

    EventBus.on('sidebar-toggled', (sidebarShown) => {
      showSidebar.value = sidebarShown;
    });
    onBeforeUnmount(() => {
      EventBus.off('sidebar-toggled');
    });

    return {
      showSidebar,
      toggleSidebar,
    };
  },
};
</script>
