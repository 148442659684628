<template>
  <div
    class="relative w-full my-0 mx-auto"
    v-bind="$attrs"
  >
    <div
      v-click-away="blur"
      :class="inputStyle"
      :tabindex="searching ? -1 : 0"
      v-on="eventListeners"
    >
      <input
        v-if="searching || !selectedResult"
        ref="searchInput"
        v-model.trim="searchTerm"
        :disabled="disabled"
        :name="paramName"
        :placeholder="placeholder"
        v-bind="$attrs"
        type="text"
        tabindex="0"
        role="input"
        autocomplete="off"
        class="text-sm block w-full pr-7 outline-none border-none focus:ring-0 focus:border-none p-0"
        @keyup.up="previousResult"
        @keyup.down="nextResult"
        @keydown.enter.prevent
        @keyup.enter.stop="selectResult(resultPointer)"
        @keyup.esc.stop="blur"
        @blur="blur"
      >
      <template v-else>
        <input
          :value="selectedResult[valueProp]"
          :name="paramName"
          type="hidden"
        >

        <slot
          name="selected-result"
          :result="selectedResult"
        >
          {{ selectedResult[labelProp] }}
        </slot>
      </template>

      <div
        v-if="!disabled"
        class="absolute inset-y-0 right-0 pr-2 flex items-center"
      >
        <button
          v-if="selectedResult && allowReset"
          type="button"
          :class="iconHolderCssClasses"
          @click="clear"
          @keyup.enter.stop="clear"
        >
          close
        </button>
        <div
          v-else
          :class="iconHolderCssClasses"
        >
          keyboard_arrow_down
        </div>
      </div>
    </div>

    <div
      v-show="searching"
      ref="optionList"
      class="absolute w-full overflow-y-auto shadow-lg rounded bg-white z-10"
      :style="{ 'max-height': maxHeight }"
    >
      <span
        v-for="(result, index) in results"
        :key="`${result[labelProp]}-${index}`"
        class="flex w-full cursor-pointer text-sm"
        @mousedown="selectResult(index)"
        @mousemove="resultPointer = index"
      >
        <slot
          :result="result"
          :selected="selectedResult && result.id == selectedResult.id"
          :active="index === resultPointer"
          name="dropdown-result"
        >
          <div
            :class="[(selectedResult && result.id == selectedResult.id) ? 'font-semibold' : 'font-normal',
                     index === resultPointer ? 'bg-gray-300' : '', 'h-full', 'w-full', 'py-4', 'px-5']"
          >
            {{ result[labelProp] }} </div>
        </slot>
      </span>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from '@/util/click-away';

export default {
  name: 'InsightsDropDownList',
  mixins: [clickaway],
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: [String, Number],
      required: false,
      default: null,
    },
    paramName: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    valueProp: {
      type: String,
      default: 'value',
    },
    labelProp: {
      type: String,
      default: 'name',
    },
    maxHeight: {
      type: String,
      default: '25rem',
    },
    inputStyle: {
      type: String,
      default: `relative search-input cursor-text rounded px-3 py-2 w-full mt-2 block shadow-none transition-colors
      duration-200 outline-none border focus-within:border-primary-500 border-gray-300 disabled:bg-gray-300 text-sm`,
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowReset: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:selected', 'focus', 'blur'],
  data() {
    return {
      selectedResult: this.selected ? this.$_findOption(this.selected) : null,
      searchTerm: '',
      resultPointer: -1,
      searching: false,
    };
  },
  computed: {
    eventListeners() {
      if (this.disabled) return {};

      const eventHandler = this.selectedResult ? this.resetSelection : this.openSelection;

      return {
        click: eventHandler,
        focus: eventHandler,
      };
    },
    results() {
      if (this.searchTerm) {
        const query = this.searchTerm.toLowerCase();

        return this.options.filter((option) => option[this.labelProp].toLowerCase().includes(query));
      }

      return this.options;
    },
    inputCssClasses() {
      return [
        'relative', 'search-input', 'cursor-text', 'text-sm',
        'rounded', 'border', 'px-3', 'py-2', 'w-full', 'mt-2', 'block', 'shadow-none',
        'transition-colors', 'duration-200', 'outline-none',
        {
          'border-primary-500': this.searching,
          'bg-gray-300': this.disabled,
          'border-gray-300': !this.searching,
        },
      ];
    },
    iconHolderCssClasses() {
      return [
        'search-input-icon', 'material-icons-outlined', 'transition-colors',
        'duration-200', 'cursor-pointer', 'ring-primary-500', 'focus:outline-none', 'focus:ring', 'ring-primary-500',
        {
          'text-black': this.searching,
          'text-gray-500': !this.searching,
          'text-lg': this.selectedResult,
          'text-2xl': !this.selectedResult,
        },
      ];
    },
  },
  watch: {
    searchTerm(value) {
      if (this.searching && value && !this.results.includes(this.options[this.resultPointer])) {
        this.resultPointer = 0;
      } else if (value && !this.searching) {
        this.openSelection();
      }
    },
    resultPointer(value) {
      if (this.searching && value >= 0) {
        this.$_adjustScroll();
      }
    },
    selected(value) {
      this.selectedResult = value ? this.$_findOption(value) : null;
    },
    searching() {
      if (this.searching) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
  },
  methods: {
    resetSelection() {
      this.resultPointer = this.options.indexOf(this.selectedResult);
      // this.selectedResult = null;
      this.searching = true;
      this.$nextTick(() => this.$refs.searchInput.focus());
    },
    openSelection() {
      this.resultPointer = 0;
      this.searching = true;
      this.$nextTick(() => this.$refs.searchInput.focus());
    },
    selectResult(optionIndex) {
      this.searching = false;
      this.selectedResult = this.results[optionIndex];
      this.searchTerm = '';
    },
    clear() {
      this.selectedResult = null;
      this.$emit('update:selected', null);
    },
    blur() {
      this.searching = false;
      this.$emit('update:selected', this.selectedResult?.[this.valueProp]);
    },

    previousResult() {
      if (this.results.length === 0) return;

      let index = this.resultPointer - 1;
      if (index < 0) {
        index = this.results.length - 1;
      }

      this.resultPointer = index;
    },
    nextResult() {
      if (this.results.length === 0) return;

      this.resultPointer = (this.resultPointer + 1) % this.results.length;
    },

    $_findOption(value) {
      const filteredOptions = this.options.filter(
        (option) => option[this.valueProp].toString() === value.toString(),
      );
      if (filteredOptions.length > 0) {
        return filteredOptions[0];
      }

      return null;
    },
    $_adjustScroll() {
      const currentOption = this.$refs.optionList.children[this.resultPointer];

      if (currentOption) {
        const bounds = this.$refs.optionList.getBoundingClientRect();
        const { top, bottom, height } = currentOption.getBoundingClientRect();

        if (top < bounds.top) {
          this.$refs.optionList.scrollTop = currentOption.offsetTop;
        } else if (bottom > bounds.bottom) {
          this.$refs.optionList.scrollTop = currentOption.offsetTop - (bounds.height - height);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input:hover .search-input-icon {
  @apply text-black;
}
</style>
