<template>
  <Modal
    :open="open"
    @close="close"
  >
    <div class="p-6">
      <h2 class="mb-2 text-lg leading-6 font-medium">
        {{ $t("components.assessment_form.reschedule_modal.title") }}
      </h2>
      <h3 class="mb-5 text-gray-500 break-words">
        <p>
          {{ infoText }}
        </p>
      </h3>
      <form
        ref="rescheduleForm"
        @keyup.enter="$emit('click');"
      >
        <div class="space-y-5">
          <RadioGroup
            v-model="selected"
          >
            <RadioGroupLabel class="sr-only">
              Privacy setting
            </RadioGroupLabel>
            <div class="bg-white rounded-md -space-y-px">
              <RadioGroupOption
                v-for="(option, optionIdx) in options"
                :key="option.name"
                v-slot="{ checked, active }"
                as="template"
                :value="option"
              >
                <div
                  :class="[optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                           optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                           checked ? 'bg-primary-50 border-primary-200 z-10' : 'border-gray-200',
                           'relative border p-4 flex cursor-pointer focus:outline-none']"
                >
                  <span
                    :class="[checked ? 'bg-primary-600 border-transparent' : 'bg-white border-gray-300',
                             active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
                             'h-4 w-4 mt-0.5 cursor-pointer shrink-0',
                             'rounded-full border flex items-center justify-center']"
                    aria-hidden="true"
                  >
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <span class="ml-3 flex flex-col">
                    <RadioGroupLabel
                      as="span"
                      :class="[checked ? 'text-primary-900' : 'text-gray-900', 'block text-sm font-medium']"
                    >
                      {{ option.name }}
                    </RadioGroupLabel>
                    <RadioGroupDescription
                      as="span"
                      :class="[checked ? 'text-primary-700' : 'text-gray-500', 'block text-sm']"
                    >
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <p v-html="option.description" />

                    </RadioGroupDescription>
                  </span>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>
      </form>
    </div>

    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4">
      <BaseButton
        type="button"
        :is-primary="false"
        @click="$emit('close')"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        type="button"
        icon="CheckIcon"
        :is-loading="isSubmitting"
        @click="$emit('click');"
      >
        {{ $t('defaults.save') }}
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/generic/Modal/Modal.vue';
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';

export default {
  name: 'RescheduleModal',
  components: {
    Modal,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    changeOptions: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selected', 'close', 'click'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const selected = ref();

    watch(selected, async (newSelection, _) => {
      emit('update:selected', newSelection.type);
    });

    const options = computed(() => {
      const move = {
        type: props.changeOptions.extend ? 'extend' : 'move',
        name: t('components.assessment_form.reschedule_modal.options.move_to_new_start_date.title'),
        description: `${t('components.assessment_form.reschedule_modal.options.move_to_new_start_date.text')}
         <br> ${props.changeOptions.extend
    ? `${t('components.assessment_form.reschedule_modal.options.move_to_new_start_date.info').bold()}` : ''}`,
      };

      const clear = {
        type: 'clear',
        name: t('components.assessment_form.reschedule_modal.options.clear_scheduler.title'),
        description: t('components.assessment_form.reschedule_modal.options.clear_scheduler.text'),
      };

      const keep = {
        type: 'keep',
        name: t('components.assessment_form.reschedule_modal.options.keep_events_date.title'),
        description: t('components.assessment_form.reschedule_modal.options.keep_events_date.text'),
      };

      const op = [];
      if (props.changeOptions.move) op.push(move);
      if (props.changeOptions.keep) { op.push(keep); } else { op.push(clear); }
      [selected.value] = op;
      return op;
    });

    const infoText = computed(() => {
      const text = `${props.changeOptions.extend || (props.changeOptions.move && !props.changeOptions.keep)
        ? t('components.assessment_form.reschedule_modal.warning') : ''}
      ${t('components.assessment_form.reschedule_modal.text')}`;
      return text;
    });

    const close = () => {
      emit('close');
    };

    return {
      selected,
      close,
      options,
      infoText,
    };
  },
};
</script>
