<template>
  <Card class="overflow-hidden">
    <table class="w-full">
      <tbody>
        <tr
          v-for="(exercise, index) in exercises"
          :key="exercise.id"
          :class="[exercise.shared ? 'cursor-pointer hover:bg-gray-100 transition duration-150 ease-in-out' : '',
                   index !== exercises.length - 1 ? 'border-b border-gray-200' : '' ]"
          @click="selectExercise(exercise)"
        >
          <td>
            <div :class="['py-4 pl-6', exercise.shared ? 'text-primary-600' : 'text-gray-400']">
              {{ exercise.name }}
            </div>
          </td>
          <td>
            <div
              v-if="selfRatingStore.exerciseCompleted(exercise)"
              class="flex"
            >
              <CheckCircleIcon class="w-5 h-5 text-green-400 mr-1" /><p class="text-sm text-gray-500">
                {{ $t('components.candidate_self_rating.completed') }}
              </p>
            </div>
            <div
              v-else-if="!exercise.shared"
              class="flex"
            >
              <LockClosedIcon class="w-5 h-5 text-gray-900 mr-1" /><p class="text-sm text-gray-500">
                {{ $t('components.candidate_self_rating.not_shared') }}
              </p>
            </div>
            <div
              v-else
              class="flex"
            >
              <ClipboardListIcon class="w-5 h-5 text-gray-900 mr-1" /><p class="text-sm text-gray-500">
                {{ $t('components.candidate_self_rating.not_completed') }}
              </p>
            </div>
          </td>
          <td v-if="exercise.shared">
            <div class="pr-6 w-full flex justify-end">
              <ChevronRightIcon class="w-5 h-5 text-gray-400" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </Card>
</template>

<script>
import { useCandidateSelfRatingsStore } from '@/components/RemoteSessionTool/stores/candidateSelfRatingsStore';
import Card from '@/components/generic/Card/Card.vue';
import { ChevronRightIcon, ClipboardListIcon, LockClosedIcon } from '@heroicons/vue/outline';
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { storeToRefs } from 'pinia';

export default {
  name: 'CandidateSelfRatingExerciseNavigator',
  components: {
    Card,
    ChevronRightIcon,
    CheckCircleIcon,
    ClipboardListIcon,
    LockClosedIcon,
  },
  setup() {
    const selfRatingStore = useCandidateSelfRatingsStore();
    const { exercises } = storeToRefs(selfRatingStore);

    const selectExercise = (exercise) => {
      if (exercise.shared) {
        selfRatingStore.setCurrentExercise(exercise);
      }
    };

    return {
      exercises,
      selfRatingStore,
      selectExercise,
    };
  },
};
</script>
