<template>
  <ConfirmDialogModal
    :title="title"
    :open="open"
    :message="message"
    :challenge="challenge"
    :button-text="$t('defaults.delete')"
    @confirm="confirm"
    @close="close"
  />

  <form
    ref="form"
    :action="deleteUrl"
    method="post"
    data-turbo-method="delete"
  >
    <input
      type="hidden"
      name="_method"
      value="delete"
    >
    <input
      type="hidden"
      name="authenticity_token"
      :value="$csrfToken"
    >
  </form>
</template>

<script>
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';
import { ref } from 'vue';

export default {
  name: 'DeleteModal',
  components: {
    ConfirmDialogModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    challenge: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    deleteUrl: {
      type: String,
      required: false,
      default: null,
    },
    itemType: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['close', 'delete'],
  setup(props, { emit }) {
    const form = ref(null);

    const confirm = () => {
      if (props.deleteUrl) {
        form.value.submit();
      } else {
        emit('delete');
      }
    };

    const close = () => {
      emit('close');
    };
    return {
      form,
      close,
      confirm,
    };
  },
};
</script>
