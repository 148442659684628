<template>
  <div class="bg-white shadow rounded-md">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Card',
});
</script>
