<template>
  <Modal
    :show="show"
    inner-modal-classes="w-full sm:min-w-3/4 md:min-w-0 md:max-w-5xl"
    @close="closeModal"
  >
    <div class="p-4">
      <BaseTextarea
        v-model="bulkItemsInput"
        classes="h-64"
        :label="$t('activerecord.models.behavior_anchor') "
      />
    </div>
    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4">
      <BaseButton
        :is-primary="false"
        @click="closeModal"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        @click="bulkAddItems"
      >
        {{ $t('defaults.insert') }}
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import Modal from '@/components/generic/Modal/Modal.vue';

export default {
  name: 'BulkAddModal',
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    input: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['bulk-add', 'close'],
  data() {
    return {
      bulkItemsInput: this.input,
    };
  },
  methods: {
    bulkAddItems() {
      const itemsToAdd = this.bulkItemsInput.split('\n');
      this.$emit('bulk-add', itemsToAdd);
      this.bulkItemsInput = '';
      this.$emit('close');
    },
    closeModal() {
      this.bulkItemsInput = '';
      this.$emit('close');
    },
  },
};
</script>
