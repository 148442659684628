<template>
  <BaseTable
    :columns="columns"
    :records="users"
    :pages="pages"
  >
    <template #default="{ record: user }">
      <td
        v-dompurify-html:mark="highlightedText(user.firstName)"
        class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
      />
      <td
        v-dompurify-html:mark="highlightedText(user.lastName)"
        class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
      />
      <td
        v-dompurify-html:mark="highlightedText(user.email)"
        class="ph-no-capture px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500"
      />
      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
        <div class="inline-flex items-center space-x-1">
          <Badge
            v-for="role in user.roles"
            :key="role"
            :color="role === 'super_admin' ? 'primary' : 'slate'"
          >
            {{ $t(`activerecord.enums.role.name.${role}`) }}
          </Badge>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <UserStatusBadge :status="user.status" />
      </td>
      <UserActions
        :user="user"
      />
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import { useI18n } from '@/plugins/i18n';
import { useSearchHighlighter } from '@/composables/useSearchHighlighter';
import UserStatusBadge from '../UserStatusBadge/UserStatusBadge.component.vue';
import UserActions from './UserActions/UserActions.vue';

export default {
  components: {
    BaseTable,
    UserStatusBadge,
    UserActions,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    pages: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const { highlightedText } = useSearchHighlighter();

    const columns = [
      {
        key: 'first_name',
        name: t('activerecord.attributes.user.first_name'),
        sortable: true,
      },
      {
        key: 'last_name',
        name: t('activerecord.attributes.user.last_name'),
        sortable: true,
      },
      {
        key: 'email',
        name: t('activerecord.attributes.user.email'),
        sortable: true,
      },
      {
        key: 'role_names',
        name: t('activerecord.attributes.user.role_names'),
        sortable: false,
      },
      {
        key: 'status',
        name: t('components.users.users_table.status'),
        sortable: false,
      },
      {
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      },
    ];

    const statusColor = ({ status }) => {
      switch (status) {
        case 'active':
          return 'green';
        case 'invited':
          return 'blue';
        case 'deactivated':
        default:
          return 'gray';
      }
    };

    return {
      columns,
      statusColor,
      highlightedText,
    };
  },
};
</script>
