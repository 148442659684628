<template>
  <Heroicon
    :icon="icon"
    aria-hidden="true"
  />
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue';
import Heroicon from '@/components/Heroicon.vue';

const icons = {
  pdf: 'DocumentTextIcon',
  image: 'PhotographIcon',
  video: 'FilmIcon',
};

export default defineComponent({
  components: {
    Heroicon,
  },
  props: {
    fileType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { fileType } = toRefs(props);
    const icon = computed(() => icons[fileType.value]);
    return { icon };
  },
});
</script>
