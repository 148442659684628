export default (competenceModels, current) => {
  const competenceModelOptions = competenceModels.map((model) => ({
    value: model.id,
    id: model.id,
    name: model.name,
    organisation: model.organisation,
    organisationName: model.organisation_name,
  }));

  const initialCompetenceModel = competenceModelOptions.find((option) => option.value === current);

  return {
    competenceModelOptions,
    initialCompetenceModel,
  };
};
