<template>
  <div class="inline-flex relative">
    <BaseButton
      class="plain rounded-r-none focus:z-10"
      :type="type"
      :is-primary="isPrimary"
      :is-loading="isLoading"
      :is-disabled="isDisabled"
      :icon="icon"
      :qa-class="qaClass"
      @click="$emit('click')"
    >
      <slot />
    </BaseButton>
    <Dropdown>
      <template #button>
        <BaseButton
          as="MenuButton"
          class="plain -ml-px rounded-l-none focus:z-10"
          icon="ChevronDownIcon"
          :is-primary="isPrimary"
          :is-disabled="isLoading || isDisabled"
        />
      </template>
      <slot name="dropdownContent" />
    </Dropdown>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Dropdown from '@/components/generic/Dropdown/Dropdown.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';

export default defineComponent({
  components: {
    Dropdown,
    BaseButton,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
});
</script>
