<template>
  <div class="field">
    <BaseSelect
      id="candidate_import-delimiter"
      ref="select"
      :disabled="disabled"
      name="candidate_import[csv_delimiter]"
      :label="$t('components.candidate_import.csv_delimiter_select.csv_delimiter')"
      :options="csvDelimiters"
      @update:model-value="onChange($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'CsvDelimiterSelect',
  props: {
    csvDelimiter: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:csvDelimiter'],
  data() {
    return {
      csvDelimiters: [
        {
          label: `, (${this.$t('components.candidate_import.csv_delimiter_select.delimiters.comma')})`,
          value: ',',
        },
        {
          label: `; (${this.$t('components.candidate_import.csv_delimiter_select.delimiters.semicolon')})`,
          value: ';',
        },
        {
          label: this.$t('components.candidate_import.csv_delimiter_select.delimiters.tab'),
          value: '\t',
        },
        {
          label: this.$t('components.candidate_import.csv_delimiter_select.delimiters.space'),
          value: ' ',
        },
        {
          label: `| (${this.$t('components.candidate_import.csv_delimiter_select.delimiters.pipe')})`,
          value: '|',
        },
      ],
    };
  },
  methods: {
    onChange(value) {
      this.$emit('update:csvDelimiter', value);
    },
  },
};
</script>
