<template>
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="file.previewUrl || file.type"
      v-show="show"
    >
      <div
        ref="root"
        :class="[
          'absolute z-10 ',
          'right-0 translate-x-full',
          {
            'top-4': position === 'top',
            'bottom-6 translate-y-1/2': position === 'middle',
            'bottom-4': position === 'bottom',
          }]"
      >
        <div
          class="bg-white border border-gray-300 shadow-md rounded-md overflow-hidden h-[198px] w-[140px]"
        >
          <div
            v-if="file.pending || (file.previewUrl && previewIsLoading)"
            class="animate-pulse w-full h-full bg-white px-4 py-6"
          >
            <!-- skeleton -->
            <div class="h-3 w-3/5 bg-gray-200 rounded-lg mb-4" />
            <div class="h-2 w-9/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-11/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-8/12 bg-gray-200 rounded-lg mb-4" />
            <div class="h-2 w-10/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-7/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-8/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-11/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-10/12 bg-gray-200 rounded-lg mb-4" />
            <div class="h-2 w-8/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-9/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-11/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-8/12 bg-gray-200 rounded-lg mb-2" />
            <div class="h-2 w-10/12 bg-gray-200 rounded-lg mb-2" />
          </div>

          <div
            v-if="file.previewUrl"
            v-show="!(file.pending || previewIsLoading)"
            class="w-full h-full bg-black/10"
          >
            <span class="h-full w-0 inline-block align-middle" />
            <img
              :src="file.previewUrl"
              class="inline-block align-middle"
              @load="previewIsLoading = false"
            >
          </div>
          <div
            v-if="!file.previewUrl && file.type"
            class="w-full h-full bg-white flex justify-center items-center"
          >
            <FileTypeHeroIcon
              :file-type="file.type"
              class="h-8 w-8 text-gray-500"
            />
          </div>
        </div>
        <div class="bg-white border border-gray-300 shadow-md rounded-md overflow-hidden p-2 mt-2">
          <p>
            {{ file.filename }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  defineComponent, toRefs, ref, watch, nextTick,
} from 'vue';
import FileTypeHeroIcon from '@/components/FileTypeHeroIcon.vue';
import { isInViewport, getViewport } from '@/util/dom';

export default defineComponent({
  components: {
    FileTypeHeroIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { show } = toRefs(props);
    const previewIsLoading = ref(true);

    const position = ref('middle');
    const root = ref(null);
    watch(show, () => nextTick().then(() => {
      position.value = 'middle';
      if (!root.value || isInViewport(root.value)) return;

      const { height: viewportHeight } = getViewport();
      const { top, bottom } = root.value.getBoundingClientRect();

      if (top < 0) {
        position.value = 'top';
      } else if (bottom > viewportHeight) {
        position.value = 'bottom';
      }
    }));

    return {
      root,
      position,
      previewIsLoading,
    };
  },
});
</script>
