import { ref, computed } from 'vue';

// Tab mapping object to map error keys to tab indices
const tabMapping = {
  name: 0,
  name_placeholder: 0,
  locale: 0,
  organisation_id: 0,
  use_percentage_rating: 0,

  competency_rating_series_legend: 1,
  cluster_rating_series_legend: 1,
  exercise_rating_series_legend: 1,
  target_profile_min_series_legend: 1,
  target_profile_max_series_legend: 1,
  target_profile_range_series_legend: 1,
  self_assessment_series_legend: 1,
  mean_series_legend: 1,

  ai_summary_placeholder_example: 2,
};

export default function useErrorHandling(errors) {
  const selectedTab = ref(0);

  function showErrorTab() {
    const errorKeys = Object.keys(errors.value);
    const lowestErrorTabIndex = errorKeys
      .map((key) => tabMapping[key])
      .filter((index) => index !== undefined)
      .sort((a, b) => a - b)[0]; // Selects the lowest index

    if (lowestErrorTabIndex !== undefined) {
      selectedTab.value = lowestErrorTabIndex;
    }
  }

  const hasErrorGeneral = computed(() => !!errors.value.name
    || !!errors.value.name_placeholder
    || !!errors.value.locale
    || !!errors.value.organisation_id);

  const hasErrorCharts = computed(() => !!errors.value.competency_rating_series_legend
    || !!errors.value.cluster_rating_series_legend
    || !!errors.value.exercise_rating_series_legend
    || !!errors.value.target_profile_min_series_legend
    || !!errors.value.target_profile_max_series_legend
    || !!errors.value.target_profile_range_series_legend
    || !!errors.value.self_assessment_series_legend
    || !!errors.value.mean_series_legend);

  const hasErrorAI = computed(() => !!errors.value.ai_summary_placeholder_example);

  return {
    selectedTab,
    showErrorTab,
    hasErrorGeneral,
    hasErrorCharts,
    hasErrorAI,
  };
}
