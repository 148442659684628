<template>
  <div class="flex-1  justify-between content-center content-height">
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div class="flex flex-col justify-center items-center">
        <TestSubmitted />
        <p class="text-center text-3xl leading-9 font-bold mt-6 mb-12">
          {{ $t('components.candidate_self_rating.ratings_have_been_submitted') }}
        </p>
        <BaseButton
          icon="PencilIcon"
          @click="setRatingCompleted(false)"
        >
          {{ $t('components.candidate_self_rating.change_rating') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import TestSubmitted from '@/components/RemoteSessionTool/Graphics/TestSubmitted.vue';
import { useCandidateSelfRatingsStore } from '@/components/RemoteSessionTool/stores/candidateSelfRatingsStore';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';

export default {
  name: 'CandidateSelfRatingCompleted',
  components: {
    BaseButton,
    TestSubmitted,
  },
  setup() {
    const selfRatingStore = useCandidateSelfRatingsStore();
    return {
      setRatingCompleted: selfRatingStore.setRatingCompleted,
    };
  },
};
</script>
<style scoped>
.content-height {
    height: calc(100vh - 164px); /* subtract header and title */
}
</style>
