<template>
  <div
    v-if="hasSelectedFilter"
    class="border border-gray-200 rounded-lg px-2 py-2 flex flex-row flex-wrap gap-x-4 gap-y-2"
  >
    <span
      class="flex items-center text-gray-600 hover:text-gray-900  cursor-pointer text-xs font-medium"
      @click="clearAllFilters"
    >
      {{ $t('components.assessments.assessment_filters.clear_all') }}
    </span>
    <Badge
      v-if="dateRangeString"
      color="white"
      class="pl-2 pr-1 select-none border border-gray-200"
    >
      <div class="flex items-center whitespace-nowrap leading-4 text-xs -my-2">
        <span>{{ dateRangeString }} </span>

        <XIcon
          class="text-gray-400 hover:text-gray-600 h-8 w-6 pl-1 py-2 cursor-pointer"
          aria-hidden="true"
          @click="removeFilter('dateRange')"
        />
      </div>
    </Badge>
    <div
      v-for="filter in selectedFilters.organisations"
      :key="filter"
      class="flex items-center max-w-full"
    >
      <Badge
        color="white"
        class="pl-2 pr-1 py-1 select-none max-w-full border border-gray-200"
      >
        <div class="flex items-center -my-2 max-w-full">
          <span class="flex truncate text-ellipsis max-w-full">{{ organisationNameById(filter) }}</span>
          <XIcon
            class="text-gray-400 hover:text-gray-600 h-8 w-6 pl-1 py-2 cursor-pointer"
            aria-hidden="true"
            @click="removeFilter('organisations', filter)"
          />
        </div>
      </Badge>
    </div>
    <div
      v-for="filter in selectedFilters.targetPositions"
      :key="filter"
      class="flex items-center max-w-full"
    >
      <Badge
        color="white"
        class="pl-2 pr-1 py-1 select-none max-w-full border border-gray-200"
      >
        <div class="flex items-center -my-2 max-w-full">
          <span class="flex truncate text-ellipsis max-w-full">{{ filter }}</span>
          <XIcon
            class="text-gray-400 hover:text-gray-600 h-8 w-6 pl-1 py-2 cursor-pointer"
            aria-hidden="true"
            @click="removeFilter('targetPositions', filter)"
          />
        </div>
      </Badge>
    </div>
    <div
      v-for="filter in selectedFilters.status"
      :key="filter"
      class="flex items-center max-w-full"
    >
      <Badge
        :color="statusColor(filter)"
        class="pl-2 pr-1 py-1 select-none max-w-full"
      >
        <div class="flex items-center -my-2 max-w-full">
          <span class="flex truncate text-ellipsis max-w-full">
            {{ $t(`activerecord.enums.assessment.status.${filter}`) }}
          </span>
          <XIcon
            :class="[`text-${statusColor(filter)}-400 hover:text-${statusColor(filter)}-600`,
                     'h-8 w-6 pl-1 py-2 cursor-pointer']"
            aria-hidden="true"
            @click="removeFilter('status', filter)"
          />
        </div>
      </Badge>
    </div>
  </div>

  <AssessmentFilterModal
    v-if="openFilterModal"
    :selected-filters="selectedFilters"
    :available-assessment-filters="availableAssessmentFilters"
    @apply-filters="applyFilters"
    @close="$emit('close')"
  />
</template>

<script>
import { computeDateRangeString } from '@/util/date-helper';
import { XIcon } from '@heroicons/vue/outline';
import { computed, ref, toRefs } from 'vue';
import AssessmentFilterModal from './AssessmentFilterModal.vue';

export default {
  components: {
    AssessmentFilterModal,
    XIcon,
  },
  props: {
    availableAssessmentFilters: {
      type: Object,
      default: () => {},
    },
    openFilterModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const { availableAssessmentFilters } = toRefs(props);
    const selectedFilters = ref({});
    const urlParams = new URLSearchParams(window.location.search);

    selectedFilters.value.startDate = urlParams.get('start_date') || null;
    selectedFilters.value.endDate = urlParams.get('end_date') || null;
    selectedFilters.value.status = urlParams.getAll('status[]').map((status) => (status));
    selectedFilters.value.organisations = urlParams.getAll('organisations[]').map((name) => (name));
    selectedFilters.value.targetPositions = urlParams.getAll('target_positions[]').map((name) => (name));

    const hasSelectedFilter = computed(() => selectedFilters.value.startDate
      || selectedFilters.value.endDate
      || selectedFilters.value.status.length > 0
      || selectedFilters.value.organisations.length > 0
      || selectedFilters.value.targetPositions.length > 0);

    const dateRangeString = computeDateRangeString(selectedFilters);

    const applyFilters = (newFilters) => {
      const existingParams = new URLSearchParams(window.location.search);
      const newUrlParams = new URLSearchParams();

      if (newFilters.startDate) { newUrlParams.set('start_date', newFilters.startDate); }
      if (newFilters.endDate) { newUrlParams.set('end_date', newFilters.endDate); }
      if (newFilters.status) { newFilters.status.forEach((status) => newUrlParams.append('status[]', status)); }
      if (newFilters.organisations) {
        newFilters.organisations.forEach(
          (organisation) => newUrlParams.append('organisations[]', organisation),
        );
      }
      if (newFilters.targetPositions) {
        newFilters.targetPositions.forEach(
          (position) => newUrlParams.append('target_positions[]', position),
        );
      }

      // Preserve search and order params
      if (existingParams.has('order')) { newUrlParams.set('order', existingParams.get('order')); }
      if (existingParams.has('sort')) { newUrlParams.set('sort', existingParams.get('sort')); }
      if (existingParams.has('association')) { newUrlParams.set('association', existingParams.get('association')); }
      if (existingParams.has('q')) { newUrlParams.set('q', existingParams.get('q')); }

      const url = new URL(window.location);
      url.search = newUrlParams;

      Turbo.visit(url.toString());
    };

    const clearAllFilters = () => {
      selectedFilters.value = {
        organisations: [],
        targetPositions: [],
        startDate: null,
        endDate: null,
        status: [],
      };
      applyFilters(selectedFilters.value);
    };

    const removeFilter = (filterType, filter) => {
      if (filterType === 'dateRange') {
        selectedFilters.value.startDate = null;
        selectedFilters.value.endDate = null;
      } else {
        selectedFilters.value[filterType] = selectedFilters.value[filterType]
          .filter((selectedFilter) => selectedFilter !== filter);
      }
      applyFilters(selectedFilters.value);
    };

    const statusColor = (status) => {
      switch (status) {
        case 'active':
          return 'green';
        case 'completed':
          return 'blue';
        case 'draft':
        default:
          return 'gray';
      }
    };

    const organisationNameById = (id) => {
      const organisation = availableAssessmentFilters.value.organisations.find((org) => String(org.id) === id);
      return organisation ? organisation.name : '';
    };

    return {
      selectedFilters,
      dateRangeString,
      hasSelectedFilter,
      applyFilters,
      clearAllFilters,
      removeFilter,
      statusColor,
      organisationNameById,
    };
  },
};
</script>
