<template>
  <ActionDropdownItem
    v-if="editInOfficeItem"
    class="flex flex-row"
    @click="editInOffice"
  >
    <div class="mr-4">
      <HeroiconOutline
        class="h-5 w-5 text-gray-500"
        aria-hidden="true"
        :icon="editInOfficeItem.icon"
      />
    </div>
    <span class="text-gray-700">{{ editInOfficeItem.text }}</span>
  </ActionDropdownItem>
</template>

<script>
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import { useI18n } from '@/plugins/i18n';
import { computed, defineComponent, toRefs } from 'vue';
import HeroiconOutline from '@/components/HeroiconOutline.vue';
import { useWebdavSessionStore } from '@/components/Templates/ReportTemplates/stores/webdavSessionStore';

export default defineComponent({
  components: {
    ActionDropdownItem,
    HeroiconOutline,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { file, fileType } = toRefs(props);
    const { t } = useI18n();

    const store = useWebdavSessionStore();

    const editInOffice = async () => {
      store.createWebdavSession({ file: file.value, fileType: fileType.value });
    };

    const editInOfficeItem = computed(() => {
      if (fileType.value === 'ReportTemplate') {
        const type = file.value.templateDocument?.content_type || file.value?.contentType;
        if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          return {
            icon: 'DocumentTextIcon',
            text: t('components.report_templates.report_template_actions.edit_in_word'),
          };
        }
        if (type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
          return {
            icon: 'PresentationChartBarIcon',
            text: t('components.report_templates.report_template_actions.edit_in_powerpoint'),
          };
        }
      } else if (fileType.value === 'ShareableFile') {
        if (file.value.type === 'docx') {
          return {
            icon: 'DocumentTextIcon',
            text: t('components.report_templates.report_template_actions.edit_in_word'),
          };
        }
        if (file.value.type === 'pptx') {
          return {
            icon: 'PresentationChartBarIcon',
            text: t('components.report_templates.report_template_actions.edit_in_powerpoint'),
          };
        }
      }

      return null;
    });

    return {
      store,
      editInOffice,
      editInOfficeItem,
    };
  },
});
</script>
@/components/Templates/ReportTemplates/stores/webdavSessionStore
