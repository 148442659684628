<template>
  <Card>
    <div class="pt-6">
      <div class="px-4 sm:px-6">
        <p class="mt-1 text-sm text-gray-500">
          {{ $t('components.insights.competencies.show.statistics') }}
        </p>
        <h2 class="text-lg leading-6 font-medium text-gray-900 mb-2">
          {{ $t('components.insights.competencies.show.behavior_anchors') }}
        </h2>
      </div>
      <div class="mt-6 flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden border-t border-gray-200">
              <table class="min-w-full divide-y divide-gray-200 table-auto rounded-md">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {{ $t('components.insights.competencies.show.behavior_anchors') }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                    >
                      {{ $t('components.insights.competencies.show.mean') }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                    >
                      {{ $t('components.insights.competencies.show.standard_deviation') }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                    >
                      {{ $t('components.insights.competencies.show.agreement') }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                    >
                      {{ $t('components.insights.competencies.show.observability') }}
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="{description, behaviorAnchorStatistic: {avg, stddev, agreement, observability} }
                      in exercise.behaviorAnchors"
                    :key="description"
                  >
                    <td
                      :title="description"
                      class="px-6 py-4 text-sm font-medium text-gray-900 max-w-md"
                    >
                      <p class="line-clamp-1 md:line-clamp-2">
                        {{ description }}
                      </p>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900 max-w-0 truncate">
                      {{ avg }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900 max-w-0 truncate">
                      {{ stddev }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900 max-w-0 truncate
                        font-medium align-middle"
                      :class="[agreementColor(agreement)]"
                    >
                      <span v-if="agreement">
                        {{ Math.round(agreement * 100, 2) }}%
                        <heroicon
                          v-if="agreement"
                          :icon="agreementIcon(agreement)"
                          :class="
                            [agreementColor(agreement), 'h-5', 'inline-block']"
                        />
                      </span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900 max-w-0 truncate
                        font-medium align-middle"
                      :class="[observabilityColor(observability)]"
                    >
                      <span v-if="observability">
                        {{ Math.round(observability * 100, 2) }}%
                        <heroicon
                          v-if="observability"
                          :icon="observabilityIcon(observability)"
                          :class="[observabilityColor(observability), 'h-5', 'inline-block']"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import Utils from '@/components/Insights/Utils';
import Heroicon from '@/components/Heroicon.vue';

export default {
  name: 'ExerciseItemDetails',
  components: {
    Heroicon,
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  methods: {
    agreementColor: (value) => Utils.agreementColor(value),
    observabilityColor: (value) => Utils.observabilityColor(value),
    agreementIcon: (value) => Utils.agreementIcon(value),
    observabilityIcon: (value) => Utils.observabilityIcon(value),
  },
};
</script>
