<template>
  <div class="mb-6">
    <PageHeading>
      {{ title }}
      <template #actions>
        <BaseButton
          v-if="hasUserCreationPermission"
          type="button"
          :is-primary="false"
          :is-disabled="assessmentCompleted"
          icon="UserAddIcon"
          @click="showUserSlideOver = true"
        >
          {{ $t("components.new_observer_form.invite") }}
        </BaseButton>
        <SlideOver
          :open="showUserSlideOver"
          @click-outside="showUserSlideOver = false"
          @close-clicked="showUserSlideOver = false"
        >
          <template #title>
            {{ $t("components.new_observer_form.invite") }}
          </template>
          <template #content>
            <CreateObserverForm
              :user-data="user"
              :available-roles="availableRoles"
              :available-time-zones="availableTimeZones"
              :errors="userErrors"
              :is-submitting="isSubmittingSlideOver"
              @submit-form="inviteUser($event)"
              @cancel="showUserSlideOver = false"
            />
          </template>
        </SlideOver>
      </template>
    </PageHeading>
  </div>
  <form
    id="new-observer-form"
    :action="actionUrl"
    accept-charset="UTF-8"

    method="post"
    enctype="multipart/form-data"
    @submit="submitting = true"
  >
    <input
      type="hidden"
      name="_method"
      value="post"
    >
    <input
      type="hidden"
      name="authenticity_token"
      :value="$csrfToken"
    >
    <div class="space-y-5">
      <TwoColumnCard
        :header="$t('components.new_observer_form.categories.observer.header')"
        :helptext="$t('components.new_observer_form.categories.observer.helptext')"
      >
        <div class="space-y-6">
          <SearchableSelectList
            id="selected-user-select-list"
            v-model:selected="selectedUser"
            :options="availableUsers"
            value-prop="id"
            label-prop="name"
            secondary-label-prop="email"
            class="ph-no-capture"
            name="observer_assignment[user_id]"
            :disabled="assessmentCompleted"
            :error="getError('user')"
            qa-class="observer"
          />
          <RadioList
            v-model="selectedRole"
            :options="observerRoleOptions"
            name="observer_assignment[role]"
            :disabled="assessmentCompleted"
            :label="$t('components.new_observer_form.role')"
            :error="getError('role')"
            qa-class="observer-role"
          />
          <div
            class="flex justify-between items-center px-2"
          >
            <div>
              <h3 class="text-gray-900 block text-sm font-medium">
                {{ $t('components.new_observer_form.allow_export.header') }}
              </h3>
              <p class="text-gray-500 block text-sm">
                {{ $t('components.new_observer_form.allow_export.text') }}
              </p>
            </div>
            <Toggle
              :model-value="allowExport"
              :disabled="assessmentCompleted"
              name="observer_assignment[allow_export]"
              @update:model-value="allowExport = $event"
            />
          </div>
        </div>
      </TwoColumnCard>
      <div class="space-x-5 flex justify-end">
        <BaseButton
          as="a"
          :href="actionUrl"
          :is-primary="false"
        >
          {{ $t('defaults.cancel') }}
        </BaseButton>
        <template v-if="!assessmentCompleted">
          <BaseButton
            :is-loading="submitting"
            name="submit_and_new"
            type="submit"
          >
            {{ $t('defaults.save_and_new') }}
          </BaseButton>
          <BaseButton
            :is-loading="submitting"
            name="submit"
            type="submit"
            qa-class="save-button"
          >
            {{ $t('defaults.save') }}
          </BaseButton>
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import { assessmentObserverAssignmentsPath, organisationAssessmentObserverAssignmentsPath } from '@/util/url-helpers';
import SlideOver from '@/components/generic/SlideOver/SlideOver.vue';
import RadioList from '@/components/generic/RadioList/RadioList.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import CreateObserverForm from '@/components/Users/CreateObserverForm/CreateObserverForm.vue';
import useObserverRoleOptions from '@/composables/observerRoleOptions';
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Axios from 'axios';

export default {
  name: 'ObserverAssignmentUserInput',
  components: {
    SlideOver,
    CreateObserverForm,
    PageHeading,
    BaseButton,
    TwoColumnCard,
    SearchableSelectList,
    RadioList,
  },
  props: {
    initialUserId: {
      type: Number,
      required: false,
      default: null,
    },
    assessmentId: {
      type: String,
      required: true,
    },
    organisationId: {
      type: String,
      required: false,
      default: null,
    },
    assessmentCompleted: {
      type: Boolean,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    hasUserCreationPermission: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inviteUserPath: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },

    // invite-user-form props
    newUserData: {
      type: Object,
      required: true,
    },
    availableRoles: {
      type: Array,
      required: true,
    },
    availableTimeZones: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const isSubmittingSlideOver = ref(false);
    const showUserSlideOver = ref(false);
    const userErrors = ref({});
    const user = ref({ ...props.newUserData });
    const availableUsers = ref(props.users);
    const selectedUser = ref(availableUsers.value.find(
      (availableUser) => availableUser.id === props.initialUserId,
    ) || null);
    const toast = inject('toast');

    const submitting = ref(false);

    const selectedRole = ref(props.roleOptions[1]);
    const allowExport = ref(true);

    const { observerRoleOptions } = useObserverRoleOptions(props.roleOptions);

    const { t } = useI18n();

    const actionUrl = props.organisationId
      ? organisationAssessmentObserverAssignmentsPath(props.organisationId, props.assessmentId)
      : assessmentObserverAssignmentsPath(props.assessmentId);

    const inviteUser = (userData) => {
      isSubmittingSlideOver.value = true;

      const data = {
        user: {
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          time_zone: userData.timeZone,
          locale: userData.locale,
          role_names: userData.roleNames,
          send_invitation: userData.sendInvitation,
        },
      };

      Axios.post(props.inviteUserPath, data, {
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response) => {
          const responseData = response.data;
          const newUser = {
            id: responseData.id,
            name: `${responseData.first_name} ${responseData.last_name}`,
            email: `${responseData.email}`,
          };
          availableUsers.value.push(newUser);
          selectedUser.value = newUser;
          userErrors.value = {};
          user.value = { ...props.newUserData };
          showUserSlideOver.value = false;
          isSubmittingSlideOver.value = false;
          toast.success(t('components.new_observer_form.user_invitation.success'));
        })
        .catch((error) => {
          userErrors.value = error.response.data;
          isSubmittingSlideOver.value = false;
          toast.error(t('components.new_observer_form.user_invitation.error'));
        });
    };

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    return {
      isSubmittingSlideOver,
      showUserSlideOver,
      userErrors,
      user,
      availableUsers,
      inviteUser,
      observerRoleOptions,
      selectedRole,
      allowExport,
      selectedUser,
      actionUrl,
      submitting,
      getError,
    };
  },
};
</script>
