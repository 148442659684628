<template>
  <BaseTableActions>
    <template #menu-items>
      <LinkDropdownItem
        :href="adminUserActivitiesPath(tenant)"
        class="flex flex-row"
      >
        <div class="mr-4">
          <DownloadIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">
          {{ $t('components.tenants.tenants_table.tenant_actions.download_user_activities') }}
        </span>
      </LinkDropdownItem>

      <LinkDropdownItem
        v-if="tenant.isDeleted"
        :href="restoreAdminTenantPath(tenant)"
        method="post"
        :remote="true"
        class="flex flex-row"
      >
        <div class="mr-4">
          <RefreshIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">
          {{ $t('components.tenants.tenants_table.tenant_actions.restore') }}
        </span>
      </LinkDropdownItem>

      <LinkDropdownItem
        :href="adminTenantPath(tenant)"
        method="delete"
        :remote="true"
        :confirm="confirmDeletionMessage"
        :challenge="tenant.name"
        class="flex flex-row border-t border-gray-300"
      >
        <div class="mr-4">
          <TrashIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-red-700">{{ $t('defaults.delete') }}</span>
      </LinkDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { DownloadIcon, RefreshIcon, TrashIcon } from '@heroicons/vue/solid';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import { adminTenantPath, restoreAdminTenantPath, adminUserActivitiesPath } from '@/util/url-helpers';
import { useI18n } from '@/plugins/i18n';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';

export default defineComponent({
  components: {
    DownloadIcon,
    RefreshIcon,
    TrashIcon,
    LinkDropdownItem,
    BaseTableActions,
  },
  props: {
    tenant: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { tenant } = toRefs(props);
    const { t } = useI18n();

    const confirmDeletionMessage = computed(() => t(
      'components.tenants.tenants_table.tenant_actions.do_you_want_to_delete',
      { name: tenant.value.name },
    ));

    return {
      confirmDeletionMessage,
      adminTenantPath,
      restoreAdminTenantPath,
      adminUserActivitiesPath,
    };
  },
});
</script>
