<!-- eslint-disable max-len -->
<template>
  <div class="w-min h-min">
    <svg
      :class="[isLoading ? 'animate-spin' : 'animation-none', size]"
      viewBox="0 0 50 50"
    >
      <circle
        :class="primaryStyle"
        cx="25"
        cy="25"
        r="15"
        fill="none"
        stroke-width="4"
      />
      <circle
        :class="secondaryStyle"
        cx="25"
        cy="25"
        r="15"
        fill="none"
        stroke-width="4"
        stroke-dasharray="24 78"
        stroke-dashoffset="-15"
      />
    </svg>
    <span class="sr-only">Loading</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Spinner',
  props: {
    primaryStyle: {
      type: String,
      default: 'stroke-gray-300 opacity-25',
    },
    secondaryStyle: {
      type: String,
      default: 'stroke-gray-500 opacity-75',
    },
    size: {
      type: String,
      default: 'w-6 h-6',
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
