<template>
  <div class="relative space-y-6">
    <div class="sm:width-mobile mx-auto sm:w-full px-12 mb-8">
      <p class="text-2xl leading-7 font-bold text-gray-900">
        {{ title }}
      </p>

      <p
        v-if="!currentExercise && (!ratingCompleted || selfRatingPerExercise)"
        class="mt-2"
      >
        {{ candidateInstructions }}
      </p>

      <CandidateSelfRatingCompleted
        v-if="!selfRatingPerExercise && ratingCompleted"
      />

      <CandidateSelfRatingMatrix
        v-else-if="currentExercise || !selfRatingPerExercise"
        class="mt-6"
      />

      <CandidateSelfRatingExerciseNavigator
        v-else
        class="mt-6"
      />
    </div>
  </div>
</template>

<script>
import { useCandidateSelfRatingsStore } from '@/components/RemoteSessionTool/stores/candidateSelfRatingsStore';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import CandidateSelfRatingCompleted from './CandidateSelfRatingCompleted.vue';
import CandidateSelfRatingExerciseNavigator from './CandidateSelfRatingExerciseNavigator.vue';
import CandidateSelfRatingMatrix from './CandidateSelfRatingMatrix.vue';

export default {
  name: 'CandidateSelfRatingView',
  components: {
    CandidateSelfRatingExerciseNavigator,
    CandidateSelfRatingMatrix,
    CandidateSelfRatingCompleted,
  },
  setup() {
    const { t } = useI18n();

    const selfRatingStore = useCandidateSelfRatingsStore();
    const {
      candidateInstructions,
      currentExercise,
      selfRatingPerExercise,
      ratingCompleted,
    } = storeToRefs(selfRatingStore);

    onMounted(() => {
      selfRatingStore.fetchRatings();
    });

    const title = computed(() => {
      if (currentExercise.value) {
        return currentExercise.value.name;
      }
      return t('components.candidate_self_rating.self_assessment');
    });

    return {
      candidateInstructions,
      currentExercise,
      selfRatingPerExercise,
      ratingCompleted,
      title,
    };
  },
};
</script>
