<template>
  <TwoColumnCard
    :header="$t('components.tenants.tenants_form.card.openid_connect.heading')"
  >
    <div class="space-y-6 divide-y divide-gray-200">
      <div class="flex flex-row justify-between ">
        <div>
          {{ $t('components.tenants.tenants_form.card.openid_connect.enabled') }}
        </div>
        <Toggle
          v-model="enabled"
          qa-class="tenant-open-id-enabled"
        />
        <input
          type="hidden"
          name="tenant[openid_connect_enabled]"
          :value="enabled"
        >
      </div>
      <div class="space-y-6 w-full divide-y divide-gray-200">
        <div class="space-y-6 w-full">
          <div class="mt-6">
            <legend class="contents text-base font-medium text-gray-900">
              {{ $t('components.tenants.tenants_form.card.openid_connect.provider_settings') }}
            </legend>
          </div>
          <BaseInput
            v-model="providerName"
            :label=" $t('components.tenants.tenants_form.card.openid_connect.provider_name')"
            :disabled="!enabled"
            name="tenant[openid_connect_config][providerName]"
            qa-class="tenant-open-id-provider-name"
            :error="getOpenIDConnectError('providerName')"
          />
          <BaseInput
            v-model="issuer"
            :label=" $t('components.tenants.tenants_form.card.openid_connect.issuer')"
            :disabled="!enabled"
            name="tenant[openid_connect_config][issuer]"
            qa-class="tenant-open-id-issuer"
            :error="getOpenIDConnectError('issuer')"
          />
          <BaseInput
            v-model="clientId"
            class="flex-1"
            :label=" $t('components.tenants.tenants_form.card.openid_connect.client_id')"
            :disabled="!enabled"
            name="tenant[openid_connect_config][clientId]"
            qa-class="tenant-open-id-client-id"
            :error="getOpenIDConnectError('clientId')"
          />
          <BaseInput
            v-model="clientSecret"
            class="flex-1"
            :type="'password'"
            :label=" $t('components.tenants.tenants_form.card.openid_connect.client_secret')"
            :disabled="!enabled"
            autocomplete="off"
            name="tenant[openid_connect_config][clientSecret]"
            qa-class="tenant-open-id-client-secret"
            :error="getOpenIDConnectError('clientSecret')"
          />
          <BaseInput
            v-model="redirectUrl"
            class="flex-1"
            :label=" $t('components.tenants.tenants_form.card.openid_connect.redirect_url')"
            :disabled="!enabled"
            name="tenant[openid_connect_config][redirectUrl]"
            qa-class="tenant-open-id-redirect-url"
            :error="getOpenIDConnectError('redirectUrl')"
          />
          <BaseInput
            v-model="mobileRedirectUrl"
            class="flex-1"
            :label=" $t('components.tenants.tenants_form.card.openid_connect.mobile_redirect_url')"
            :disabled="!enabled"
            name="tenant[openid_connect_config][mobileRedirectUrl]"
            qa-class="tenant-open-id-mobile-redirect-url"
            :error="getOpenIDConnectError('mobileRedirectUrl')"
          />
          <BaseInput
            v-model="mobileClientId"
            class="flex-1"
            :label="$t('components.tenants.tenants_form.card.openid_connect.mobile_client_id')"
            :disabled="!enabled"
            name="tenant[openid_connect_config][mobileClientId]"
            qa-class="tenant-open-id-mobile-client-id"
            :hint="$t('defaults.optional')"
            :error="getOpenIDConnectError('mobileClientId')"
          />
        </div>
        <div class="space-y-6 w-full">
          <div class="mt-6">
            <legend class="contents text-base font-medium text-gray-900">
              {{ $t('components.tenants.tenants_form.card.openid_connect.provisioning_settings') }}
            </legend>
            <p class="text-sm text-gray-500">
              {{ $t('components.tenants.tenants_form.card.openid_connect.provisioning_settings_description') }}
            </p>

            <div class="flex flex-row justify-between mt-4">
              <div>
                {{ $t('components.tenants.tenants_form.card.openid_connect.autoprovisioning_enabled') }}
              </div>
              <Toggle
                v-model="provisioningEnabled"
                :disabled="!enabled"
              />
              <input
                type="hidden"
                name="tenant[autoprovisioning_enabled]"
                :value="provisioningEnabled"
              >
            </div>
          </div>
          <div>
            <legend class="contents text-base font-medium text-gray-900">
              {{ $t('components.tenants.tenants_form.card.openid_connect.email_verified_override') }}
            </legend>
            <p class="text-sm text-gray-500">
              {{ $t('components.tenants.tenants_form.card.openid_connect.email_verified_override_description') }}
            </p>

            <div class="flex flex-row justify-between mt-4">
              <div>
                {{ $t('components.tenants.tenants_form.card.openid_connect.email_verified_override_enabled') }}
              </div>
              <Toggle
                v-model="emailVerifiedOverrideEnabled"
                :disabled="!enabled"
              />

              <input
                type="hidden"
                name="tenant[email_verified_override_enabled]"
                :value="emailVerifiedOverrideEnabled"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </TwoColumnCard>
</template>

<script>
import { defineComponent, ref } from 'vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';

export default defineComponent({
  name: 'OpenIDConnectFields',
  components: {
    TwoColumnCard,
    BaseInput,
  },
  props: {
    openidConnectEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    openidConnectConfig: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    openidConnectErrors: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    autoprovisioningEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    emailVerifiedClaimOverrideEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const {
      issuer, clientId, clientSecret, providerName, redirectUrl, mobileRedirectUrl, mobileClientId,
    } = { ...props.openidConnectConfig };

    const enabled = ref(props.openidConnectEnabled || false);
    const provisioningEnabled = ref(props.autoprovisioningEnabled || false);
    const emailVerifiedOverrideEnabled = ref(props.emailVerifiedClaimOverrideEnabled || false);

    const errors = props.openidConnectErrors || {};
    const getOpenIDConnectError = (...keys) => errors[keys.join('.')]?.[0];

    return {

      issuer,
      clientId,
      clientSecret,
      providerName,
      redirectUrl,
      mobileRedirectUrl,
      mobileClientId,
      getOpenIDConnectError,
      enabled,
      provisioningEnabled,
      emailVerifiedOverrideEnabled,
    };
  },
});
</script>
