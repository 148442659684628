<template>
  <BaseInput
    v-model="placeholder"
    v-debounce:500ms="fetchPreview"
    :disabled="disabled"
    :label="$t('activerecord.attributes.report_template.placeholder')"
    :hint="$t('defaults.optional')"
    :placeholder="defaultNamePlaceholder"
    :info-link="$t('components.report_templates.modal.support_link')"
    :trailing-unit="documentType"
    :error="error"
  />
  <span class="ml-2 mt-2 text-gray-500 text-sm animate-pulse line-clamp-1 break-all">
    {{ fileNamePreview }}
  </span>
</template>
<script>
import { reportTemplatePreviewFileNamePath } from '@/util/url-helpers';
import Axios from 'axios';
import { defineComponent, ref, watch } from 'vue';
import vueDebounce from 'vue-debounce';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ReportTemplateNamePlaceholderField',
  components: {
  },
  directives: {
    debounce: vueDebounce({ lock: true }),
  },
  props: {
    namePlaceholder: {
      type: String,
      default: '',
    },
    defaultNamePlaceholder: {
      type: String,
      default: null,
    },
    documentType: {
      type: String,
      default: null,
    },
    initialPreviewFilename: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:name-placeholder'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const placeholder = ref(props.namePlaceholder || '');

    watch(
      () => placeholder.value,
      (newVal, _) => {
        emit('update:name-placeholder', newVal);
      },
    );

    const fileNamePreview = ref(props.initialPreviewFilename);

    const fetchPreview = () => {
      if (!(placeholder.value.includes('{') && placeholder.value.includes('}'))) {
        fileNamePreview.value = t(
          'components.report_templates.modal.file_name_preview',
          { name: placeholder.value || props.defaultNamePlaceholder },
        );
        return;
      }
      const data = {
        report_template: {
          name_placeholder: placeholder.value,
        },
      };

      Axios.post(reportTemplatePreviewFileNamePath(), data)
        .then((response) => {
          fileNamePreview.value = t('components.report_templates.modal.file_name_preview', { name: response.data });
        })
        .catch(() => {
          fileNamePreview.value = t('components.report_templates.modal.file_name_preview', { name: '...' });
        });
    };

    return {
      placeholder,
      fileNamePreview,

      fetchPreview,
    };
  },
});
</script>
