<template>
  <div class="inline-block">
    <div class="relative">
      <svg
        :height="size"
        :width="size"
      >
        <circle
          :transform="`rotate(-90 ${radius} ${radius})`"
          class="stroke-current text-gray-200"
          fill="transparent"
          :stroke-width="strokeWidth"
          stroke-linecap="round"
          :r="normalizedRadius"
          :cx="cx"
          :cy="cy"
        />
        <circle
          :transform="`rotate(-90 ${radius} ${radius})`"
          fill="transparent"
          stroke-linecap="round"
          :class="[colorClass, 'stroke-current', 'circle__progress--fill']"
          :style="fillStyle"
          :r="normalizedRadius"
          :cx="cx"
          :cy="cy"
        />
      </svg>
      <div class="top-1/2 left-1/2 absolute text-center transform -translate-x-1/2 -translate-y-1/2 h-1/4">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressCircle',
  components: {
  },
  props: {
    radius: {
      type: Number,
      default: 100,
    },
    progress: {
      type: Number,
      default: 100,
    },
    strokeWidth: {
      type: Number,
      default: 15,
    },
    strokeColor: {
      type: String,
      default: 'black',
    },
    animate: {
      type: Boolean,
      default: true,
    },
    transitionDuration: {
      type: Number,
      default: 250,
    },
    colorClass: {
      type: String,
      default: 'text-green-400',
    },
  },
  data() {
    const normalizedRadius = this.radius - this.strokeWidth * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    const size = normalizedRadius * 2 + parseInt(this.strokeWidth, 10);
    const cx = parseInt(this.radius, 10) + 1.5 * parseInt(this.strokeWidth, 10);
    const cy = this.radius - 1.5 * parseInt(this.strokeWidth, 10);

    return {
      normalizedRadius,
      circumference,
      size,
      cx,
      cy,
      percent: 0,
    };
  },
  computed: {
    fillStyle() {
      return {
        strokeDashoffset: this.circumference - (this.percent / 100) * this.circumference,
        '--initialStroke': this.circumference,
        '--transitionDuration': `${this.transitionDuration}ms`,
        'stroke-width': this.strokeWidth,
      };
    },
  },
  watch: {
    progress: {
      immediate: true,
      handler(newVal, __) {
        this.initTimeoutHandler = setTimeout(() => {
          this.percent = newVal;
        }, 250);
      },
    },

  },
};
</script>
<style>
.circle__progress--fill {
    --initialStroke: 0;
    --transitionDuration: 0;
    stroke-opacity: 1;
    stroke-dasharray: var(--initialStroke);
    stroke-dashoffset: var(--initialStroke);
    transition: stroke-dashoffset var(--transitionDuration) ease;
}
</style>
