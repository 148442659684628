<template>
  <div>
    <div class="mb-6">
      <PageHeading>
        {{ title }}
      </PageHeading>
    </div>
    <form
      accept-charset="UTF-8"

      :action="path"
      method="post"
      @submit="submitting = true"
    >
      <input
        type="hidden"
        name="authenticity_token"
        :value="$csrfToken"
      >
      <div class="space-y-5">
        <CompetenceModelFormFields
          :is-editable="isEditable"
          :scale-editable="true"
          :show-name="showName"
          :competence-model="competenceModel"
          :errors="errors"
          :organisation="organisation"
          :available-organisations="availableOrganisations"
          :available-report-templates="availableReportTemplates"
        />
        <div
          class="flex justify-end space-x-5 pb-5"
        >
          <BaseButton
            as="a"
            :href="path"
            :is-primary="false"
            qa-class="cancel-button"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
          <SaveButton
            :is-loading="submitting"
          >
            {{ $t('defaults.save') }}
          </SaveButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { competenceModelTemplatesPath, organisationCompetenceModelTemplatesPath } from '@/util/url-helpers';

export default defineComponent({
  props: {
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    showName: {
      type: Boolean,
      required: false,
      default: true,
    },
    competenceModel: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    organisation: {
      type: Object,
      required: false,
      default: null,
    },
    availableOrganisations: {
      type: Array,
      required: true,
      default: () => [],
    },
    availableReportTemplates: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const toast = inject('toast');
    const { t } = useI18n();

    const path = props.organisation?.id
      ? organisationCompetenceModelTemplatesPath(props.organisation.id)
      : competenceModelTemplatesPath();

    const submitting = ref(false);

    // toast for exception while creating competenceModel
    if (props.errors.invalid_record) {
      toast.error(
        t('components.assessment.competence_model_form.creation_modal.create_competence_model.error'),
      );
    }

    return {
      path,
      submitting,
    };
  },
});
</script>
