import { DirectUpload } from '@rails/activestorage';
import { directUploadPath } from '@/util/url-helpers';
import Axios from 'axios';

class Uploader {
  constructor(file, binary, path) {
    this.file = file;
    this.path = path;
    this.upload = new DirectUpload(binary, directUploadPath(), this);
  }

  async attachFile(blob) {
    const self = this;
    return new Promise((resolve, reject) => {
      const data = {
        file: {
          id: blob.signed_id,
          filename: blob.filename,
        },
      };

      Axios.post(self.path, data)
        .then((response) => {
          self.file.onUpload(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          self.file.onUploadError(error.response.data);
          reject(self.file);
        });
    });
  }

  uploadFile() {
    const self = this;
    return new Promise((resolve, _) => {
      this.upload.create((error, blob) => {
        if (error) {
          self.file.onUploadError();
          resolve();
        } else {
          resolve(blob);
        }
      });
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      'progress',
      (event) => this.directUploadDidProgress(event),
    );
  }

  directUploadDidProgress(event) {
    this.file.progress = (event.loaded / event.total) * 100;
  }
}

export default Uploader;
