const DEFAULT_INTERVAL_STEP = 33;

export default class Timer {
  constructor({ delay, onComplete, intervalStep }) {
    this.initialDelay = delay;
    this.delay = delay;
    this.onComplete = onComplete;
    this.intervalStep = intervalStep || DEFAULT_INTERVAL_STEP;
  }

  get progress() {
    return this.delay / this.initialDelay;
  }

  start() {
    this.interval = setInterval(this.$incrementTimer.bind(this), this.intervalStep);
  }

  pause() {
    clearInterval(this.interval);
    this.interval = null;
  }

  resume() {
    this.start();
  }

  stop() {
    clearInterval(this.interval);
    this.interval = null;
  }

  $incrementTimer() {
    this.delay -= this.intervalStep;
    if (this.delay <= 0) {
      this.onComplete.apply(null, []);
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}
