import { useI18n } from 'vue-i18n';

export default (locales, current) => {
  const { t } = useI18n();

  const localeOptions = locales.map((locale) => ({
    value: locale,
    id: locale,
    label: t(`defaults.langs.${locale}`),
  }));

  const initialLocale = localeOptions.find((option) => option.value === current);

  return {
    localeOptions,
    initialLocale,
  };
};
