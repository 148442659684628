import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref, reactive, computed } from 'vue';
import Axios from 'axios';
import { useNotifications } from '@/plugins/toast';
import { useI18n } from '@/plugins/i18n';
import { snakeCase } from 'lodash';
import Uploader from '@/util/uploader';
import File from '@/models/File';
import consumer from '@/channels/consumer';

// eslint-disable-next-line import/prefer-default-export
export const useMultiFileUploadStore = defineStore('multiFileUpload', () => {
  const { error, warn } = useNotifications();
  const { t } = useI18n();

  const hasShareableFiles = ref(false);
  const files = ref([]);
  const collectionPath = ref('');
  const acceptedMimeTypes = ref([]);
  const maxFileSize = ref(0);
  const selectableFileTypes = ['pdf', 'docx', 'pptx'];

  const setCollectionPath = (newCollectionPath) => {
    collectionPath.value = newCollectionPath;
  };

  const setHasShareableFiles = (newHasShareableFiles) => {
    hasShareableFiles.value = newHasShareableFiles;
  };

  const setAcceptedMimeTypes = (newAcceptedMimeTypes) => {
    acceptedMimeTypes.value = newAcceptedMimeTypes.split(',').map((mimeType) => mimeType.trim());
  };

  const setMaxFileSize = (newMaxFileSize) => {
    maxFileSize.value = newMaxFileSize;
  };

  const loadFiles = async (newRawFiles) => {
    const newFiles = newRawFiles.map(File.persisted);
    files.value = [...files.value, ...newFiles];
  };

  const fetchFiles = async () => {
    files.value = [];
    try {
      const response = await Axios.get(collectionPath.value);
      loadFiles(response.data);
    } catch (fetchError) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch files!', fetchError);
      throw fetchError;
    }
  };

  const unlistFile = (file) => {
    const index = files.value.findIndex((f) => f.id === file.id);
    if (index !== -1) {
      files.value.splice(index, 1);
    }
  };

  const fileToDelete = ref(null);
  const setFileToDelete = (file) => { fileToDelete.value = file; };
  const showDeleteDialog = computed(() => fileToDelete.value !== null);
  const closeDeleteDialog = () => {
    fileToDelete.value = null;
  };

  const deleteFileWithConfirmation = async (file) => {
    if (file.pending) {
      unlistFile(file);
    } else {
      fileToDelete.value = file;
      showDeleteDialog.value = true;
    }
  };

  const destroyFile = async (file) => {
    try {
      await Axios.delete(`${collectionPath.value}/${file.id}`, { file });
      unlistFile(file);
    } catch (destroyError) {
      if (destroyError.response?.data?.errors) {
        error(destroyError.response.data.errors);
      }
      // eslint-disable-next-line no-console
      console.error('Failed to destroy file!', destroyError);
      throw destroyError;
    }
  };

  const apiUpdateFile = ({ path, file, isShareable, data }) => {
    const postData = {};
    const snakeCaseData = Object.keys(data).reduce((acc, attr) => {
      acc[snakeCase(attr)] = data[attr];
      return acc;
    }, {});

    if (isShareable) {
      postData.shareable_file = snakeCaseData;
    } else {
      postData.file = snakeCaseData;
    }

    return Axios.put(`${path}/${file.id}`, postData)
      .then((response) => response.data)
      .catch((updateError) => {
        // eslint-disable-next-line no-console
        console.error('Failed to update file!', updateError);
        throw updateError;
      });
  };

  const updateFile = async ({ file, data }) => {
    try {
      const updatedFile = await apiUpdateFile({
        path: collectionPath.value,
        file,
        data,
        isShareable: hasShareableFiles.value,
      });
      const index = files.value.findIndex(({ id }) => id === file.id);
      files.value[index] = File.persisted(updatedFile);
    } catch (e) {
      error(t('components.multi_file_upload.failed_to_update_file', { filename: file.filename }));
    }
  };

  const uploadFile = ({ path, file, binary }) => {
    const uploader = new Uploader(file, binary, path);
    return uploader.uploadFile();
  };

  const attachFile = ({ path, file, binary, blob }) => {
    const uploader = new Uploader(file, binary, path);
    return uploader.attachFile(blob);
  };

  const uploadSelectedFiles = async (filesToUpload) => {
    const pendingFiles = [];

    for (let i = 0; i < filesToUpload.length; i += 1) { // to show files in list
      const binary = filesToUpload[i];
      const file = reactive(File.pending({ file: binary }));
      files.value.push(file);
      pendingFiles.push(file);
    }

    for (let i = 0; i < filesToUpload.length; i += 1) { // upload files
      const binary = filesToUpload[i];
      const file = pendingFiles[i];
      if (!acceptedMimeTypes.value.includes(binary.type)) {
        file.onUploadError();
        unlistFile(file);
        warn(t('components.multi_file_upload.content_type_not_allowed'));
      } else if (binary.size > maxFileSize.value) {
        file.onUploadError();
        unlistFile(file);
        warn(t('components.multi_file_upload.file_size_too_large'));
      } else if (binary.size <= 0) {
        file.onUploadError();
        unlistFile(file);
        warn(t('components.multi_file_upload.empty_file'));
      } else {
        try {
        // eslint-disable-next-line no-await-in-loop
          await uploadFile({ file, binary, path: collectionPath.value }).then((blob) => {
            attachFile({ file, binary, path: collectionPath.value, blob })
              .then((resolve) => resolve, (errorFile) => {
                unlistFile(errorFile);
                error(t('components.multi_file_upload.failed_to_upload_file', { filename: errorFile.filename }));
              });
          });
        } catch (e) {
          unlistFile(file);
          error(t('components.multi_file_upload.failed_to_upload_file', { filename: file.filename }));
        }
      }
    }
  };

  const fileById = computed(() => (id) => files.value.find((file) => file.id === id));

  const subscription = ref(null);

  function upsertFile(newFile) {
    const fileIndex = files.value.findIndex((file) => file.id === newFile.id);
    if (fileIndex !== -1) {
      files.value[fileIndex] = newFile;
    } else {
      files.value.push(newFile);
    }
  }

  function updateFileAndReorder(newFile) {
    const oldFileIndex = files.value.findIndex((file) => file.id === newFile.id);
    const newFileIndex = newFile.position;

    files.value.splice(oldFileIndex, 1);

    files.value.splice(newFileIndex - 1, 0, newFile);

    files.value = files.value.map((file, index) => {
      file.position = index + 1;
      return file;
    });
  }

  function subscribeUpdates({ parentType, parentId }) {
    const options = {
      channel: 'ShareableFilesChannel',
      parent_id: parentId,
      parent_type: parentType,
    };

    subscription.value = consumer.subscriptions.create(options, {
      received({ event, shareableFile }) {
        if (event === 'create') {
          upsertFile(shareableFile, 200);
        } else if (event === 'update') {
          upsertFile(shareableFile);
        } else if (event === 'reorder') {
          updateFileAndReorder(shareableFile);
        } else if (event === 'destroy') {
          unlistFile(shareableFile);
        }
      },
    });
  }

  function unsubscribe() {
    subscription?.value?.unsubscribe();
  }

  const isSelectableFileType = (fileType) => selectableFileTypes.includes(fileType.toLowerCase());

  return {
    files,
    closeDeleteDialog,
    showDeleteDialog,
    fileToDelete,
    setFileToDelete,

    setCollectionPath,
    setHasShareableFiles,
    setAcceptedMimeTypes,
    setMaxFileSize,
    fetchFiles,
    unlistFile,
    deleteFileWithConfirmation,
    destroyFile,
    updateFile,
    uploadSelectedFiles,

    subscribeUpdates,
    unsubscribe,
    fileById,
    isSelectableFileType,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMultiFileUploadStore, import.meta.hot));
}
