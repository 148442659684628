<!-- eslint-disable max-len -->
<template>
  <svg
    width="280"
    height="251"
    viewBox="0 0 280 251"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M277.544 95.9875C277.443 95.9875 277.345 95.9569 277.263 95.8996L141.41 1.4187C140.996 1.13252 140.505 0.979574 140.002 0.98043C139.499 0.981287 139.008 1.1359 138.596 1.42349L3.72072 95.8986C3.61404 95.9734 3.48201 96.0027 3.35367 95.9802C3.22532 95.9577 3.11118 95.8852 3.03635 95.7787C2.96152 95.6721 2.93214 95.5403 2.95466 95.4121C2.97719 95.284 3.04978 95.17 3.15646 95.0953L138.031 0.620126C138.609 0.217559 139.296 0.00116045 140 4.65409e-06C140.705 -0.00115114 141.392 0.21299 141.971 0.613659L277.825 95.0943C277.91 95.154 277.975 95.2395 278.009 95.3384C278.042 95.4373 278.044 95.5443 278.012 95.644C277.981 95.7436 277.919 95.8307 277.834 95.8924C277.75 95.9542 277.648 95.9875 277.543 95.9875L277.544 95.9875Z"
      fill="#3F3D56"
    />
    <path
      d="M11.4277 99.3367L140.135 4.08105L269.82 106.115L146.767 178.961L79.96 163.754L11.4277 99.3367Z"
      fill="#E6E6E6"
    />
    <path
      d="M85.5992 223.954H22.0145C21.6241 223.955 21.2374 223.879 20.8765 223.73C20.5157 223.581 20.1877 223.362 19.9115 223.087C19.6352 222.811 19.4161 222.484 19.2665 222.124C19.117 221.764 19.04 221.378 19.04 220.988C19.04 220.598 19.117 220.212 19.2665 219.852C19.4161 219.492 19.6352 219.165 19.9115 218.889C20.1877 218.613 20.5157 218.395 20.8765 218.246C21.2374 218.097 21.6241 218.021 22.0145 218.021H85.5992C85.9896 218.021 86.3764 218.097 86.7372 218.246C87.0981 218.395 87.426 218.613 87.7022 218.889C87.9785 219.165 88.1977 219.492 88.3472 219.852C88.4967 220.212 88.5737 220.598 88.5737 220.988C88.5737 221.378 88.4967 221.764 88.3472 222.124C88.1977 222.484 87.9785 222.811 87.7022 223.087C87.426 223.362 87.0981 223.581 86.7372 223.73C86.3764 223.879 85.9896 223.955 85.5992 223.954Z"
      :fill="color"
    />
    <path
      d="M44.8273 211.201H22.0145C21.6241 211.201 21.2374 211.125 20.8765 210.976C20.5157 210.827 20.1877 210.609 19.9115 210.333C19.6352 210.058 19.4161 209.73 19.2665 209.37C19.117 209.01 19.04 208.624 19.04 208.234C19.04 207.844 19.117 207.458 19.2665 207.098C19.4161 206.738 19.6352 206.411 19.9115 206.135C20.1877 205.86 20.5157 205.641 20.8765 205.492C21.2374 205.343 21.6241 205.267 22.0145 205.268H44.8273C45.2177 205.267 45.6044 205.343 45.9653 205.492C46.3261 205.641 46.654 205.86 46.9303 206.135C47.2066 206.411 47.4257 206.738 47.5752 207.098C47.7248 207.458 47.8017 207.844 47.8017 208.234C47.8017 208.624 47.7248 209.01 47.5752 209.37C47.4257 209.73 47.2066 210.058 46.9303 210.333C46.654 210.609 46.3261 210.827 45.9653 210.976C45.6044 211.125 45.2177 211.201 44.8273 211.201Z"
      :fill="color"
    />
    <path
      d="M141.946 152.393C141.462 152.393 140.984 152.3 140.536 152.117L61.1582 119.227V16.2737C61.1593 15.3633 61.5219 14.4905 62.1665 13.8468C62.8111 13.203 63.6852 12.8409 64.5968 12.8398H216.877C217.789 12.8409 218.663 13.203 219.308 13.8468C219.952 14.4905 220.315 15.3633 220.316 16.2737V119.298L220.167 119.362L143.398 152.101C142.938 152.294 142.444 152.393 141.946 152.393Z"
      fill="white"
    />
    <path
      d="M141.945 152.638C141.43 152.639 140.919 152.539 140.442 152.344L60.9121 119.391V16.2738C60.9132 15.2984 61.3017 14.3633 61.9924 13.6735C62.6831 12.9838 63.6195 12.5958 64.5963 12.5947H216.877C217.854 12.5958 218.79 12.9838 219.481 13.6735C220.172 14.3633 220.56 15.2984 220.561 16.2738V119.46L143.494 152.327C143.003 152.533 142.477 152.638 141.945 152.638ZM61.8945 118.735L140.817 151.437C141.553 151.735 142.377 151.731 143.11 151.424L219.579 118.813V16.2738C219.578 15.5585 219.293 14.8728 218.787 14.367C218.28 13.8612 217.593 13.5767 216.877 13.5758H64.5963C63.88 13.5767 63.1933 13.8612 62.6868 14.367C62.1804 14.8728 61.8954 15.5585 61.8946 16.2738L61.8945 118.735Z"
      fill="#3F3D56"
    />
    <path
      d="M276.561 95.0063H276.463L220.07 119.053L142.913 151.954C142.612 152.08 142.288 152.147 141.961 152.148C141.634 152.15 141.31 152.087 141.007 151.964L61.4035 118.984L3.62521 95.0456L3.53694 95.0063H3.4386C2.52693 95.0074 1.6529 95.3695 1.00826 96.0132C0.363613 96.657 0.00101156 97.5298 0 98.4402V247.566C0.00101286 248.477 0.363613 249.349 1.00826 249.993C1.6529 250.637 2.52693 250.999 3.4386 251H276.561C277.473 250.999 278.347 250.637 278.992 249.993C279.636 249.349 279.999 248.477 280 247.566V98.4402C279.999 97.5298 279.636 96.657 278.992 96.0132C278.347 95.3695 277.473 95.0074 276.561 95.0063ZM279.018 247.566C279.017 248.217 278.758 248.84 278.298 249.3C277.837 249.76 277.213 250.019 276.561 250.019H3.4386C2.78727 250.019 2.16269 249.76 1.70213 249.3C1.24157 248.84 0.982717 248.217 0.982456 247.566V98.4402C0.983347 97.8059 1.22972 97.1965 1.67007 96.7394C2.11041 96.2823 2.71064 96.0129 3.34529 95.9874L61.4035 120.044L140.629 152.871C141.487 153.22 142.447 153.214 143.301 152.856L220.07 120.117L276.66 95.9874C277.293 96.0148 277.892 96.2849 278.331 96.7418C278.77 97.1986 279.016 97.8069 279.018 98.4402V247.566Z"
      fill="#3F3D56"
    />
    <path
      d="M141.151 123.199C138.221 123.205 135.368 122.258 133.024 120.503L132.878 120.394L102.269 96.9914C100.852 95.9067 99.6621 94.5539 98.7681 93.0101C97.8741 91.4663 97.2934 89.7618 97.059 87.9939C96.8247 86.226 96.9413 84.4293 97.4023 82.7063C97.8633 80.9834 98.6596 79.3681 99.7457 77.9525C100.832 76.5369 102.187 75.3488 103.732 74.456C105.278 73.5633 106.985 72.9833 108.756 72.7493C110.526 72.5153 112.325 72.6317 114.051 73.0921C115.776 73.5524 117.394 74.3476 118.811 75.4323L138.637 90.6136L185.49 29.5962C186.576 28.1809 187.931 26.9933 189.478 26.1011C191.024 25.2088 192.731 24.6295 194.501 24.3961C196.272 24.1627 198.071 24.2798 199.796 24.7408C201.521 25.2018 203.139 25.9977 204.556 27.0828L204.264 27.4778L204.563 27.0886C207.422 29.2826 209.292 32.5194 209.764 36.0888C210.236 39.6582 209.271 43.2688 207.08 46.1284L151.971 117.899C150.696 119.553 149.057 120.892 147.181 121.811C145.304 122.73 143.241 123.205 141.151 123.199Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestSubmitted',
  props: {
    color: {
      type: String,
      default: 'var(--color-primary-500)',
    },
  },
};
</script>
<!-- eslint-enable max-len -->
