<template>
  <div class="shadow-sm rounded-md inline-flex button-group">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ButtonGroup',
});
</script>

<style lang="scss" scoped>
  .button-group:deep(.base-button) {
    @apply shadow-none focus:ring-offset-0 #{!important};

    &:first-child {
      @apply rounded-r-none focus:z-10 #{!important};
    }

    &:last-child {
      @apply -ml-px rounded-l-none focus:z-10 #{!important};
    }

    &:not(:first-child):not(:last-child) {
      @apply -ml-px rounded-none focus:z-10 #{!important};
    }
  }
</style>
