<template>
  <chart-card
    :title="title"
    :subtitle="subtitle"
  >
    <template #head-action>
      <div class="flex flex-wrap items-center justify-end">
        <p class="block text-sm font-medium text-gray-700 mr-2">
          {{ optionsLabel }}
        </p>
        <select-box
          v-model="selectedOption"
          :options="options"
          value-prop="id"
          lable-prop="name"
          class="w-48"
        >
          <template
            #default="{option}"
          >
            {{ option.name }}
          </template>
        </select-box>
      </div>
    </template>
    <div
      :class="['relative', 'w-full', computeChartHeight ? '' : 'aspect-square']"
      :style="computeChartHeight ? { height: `${chartHeight}px` } : {}"
    >
      <canvas
        ref="chart"
      />
    </div>
  </chart-card>
</template>
<script>
import Utils from '@/components/Insights/Utils';

import { Chart } from 'chart.js/auto';

import ChartCard from '@/components/Insights/ChartCard.vue';
import SelectBox from '@/components/generic/SelectBox/SelectBox.vue';

export default {
  name: 'DistributionChart',
  components: {
    ChartCard,
    SelectBox,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    optionsLabel: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    computeChartHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: this.options[0],
      chart: null,
    };
  },
  computed: {
    chartHeight() {
      return Math.max(this.options.length * 100, 400);
    },
  },
  watch: {
    selectedOption() {
      this.updateChart();
    },
  },
  mounted() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      const chartElement = this.$refs.chart;

      const labels = [];
      const freqData = [];

      const data = this.data.filter((result) => result.name === this.selectedOption.name);

      data.forEach((scaleValue) => {
        labels.push(scaleValue.value);
        freqData.push(scaleValue.count);
      });

      const maxFreq = Math.max(...freqData);

      const chartData = {
        labels,
        datasets: [
          {
            borderWidth: 0,
            backgroundColor: Utils.setOpacity(Utils.defaultColors[2], 0.3),
            data: freqData,
          },
        ],
      };

      const config = {
        type: 'bar',
        data: chartData,
        options: {
          locale: 'en',
          maintainAspectRatio: false,
          barPercentage: 0.85,
          categoryPercentage: 1,
          scales: {
            y: {
              max: maxFreq + Math.max(maxFreq * 0.1, 1),
              ticks: {
                stepSize: 1,
                font: {
                  size: 14,
                },
              },
              title: {
                display: true,
                text: this.$t('components.insights.show.frequency'),
                font: {
                  size: 14,
                },
                color: '#333',
              },
              grid: {},
            },
            x: {
              min: this.min,
              max: this.max,
              grid: {
                drawOnChartArea: false,
              },
              title: {
                display: true,
                text: this.$t('components.insights.show.value'),
                font: {
                  size: 14,
                },
                color: '#333',
              },
              time: {},
              ticks: {
                font: {
                  size: 14,
                },
              },
            },
          },
          animation: false,
          plugins: {
            legend: { display: false },
            tooltip: {
              displayColors: false,
              callbacks: {},
            },
            title: {
              font: {
                size: 20,
              },
              color: '#333',
            },
          },
          interaction: {},
        },
      };

      // eslint-disable-next-line no-new
      this.chart = new Chart(
        chartElement,
        config,
      );
    },

  },
};
</script>
