<template>
  <div>
    <div
      v-show="sidebarVisible"
      class="flex lg:hidden modal-backdrop fixed left-0 right-0 top-0 bottom-0 z-30 justify-center items-center"
      @click="hideSidebar"
    />

    <transition
      enter-active-class="lg:transform-none transition-transform duration-500 ease-in-out"
      leave-active-class="lg:transform-none transition-transform duration-500 ease-in-out"
      enter-from-class="-translate-x-100 lg:translate-x-0"
      leave-to-class="-translate-x-100 lg:translate-x-0"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
    >
      <div
        v-show="sidebarVisible || show"
        class="absolute lg:static max-w-xs w-68 h-full z-40 lg:z-auto
          flex flex-col grow border-r border-gray-200 bg-white overflow-y-auto"
        :style="{ 'max-height': 'calc(100vh - 4rem)' }"
      >
        <div class="mt-5 grow flex flex-col">
          <nav
            class="flex-1 px-2 bg-white space-y-1"
            aria-label="Sidebar"
          >
            <slot />
          </nav>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import EventBus from '@/util/event-bus';

export default {
  data() {
    return {
      sidebarStatus: null,
      show: false,
    };
  },
  computed: {
    sidebarVisible() {
      return this.sidebarStatus === 'visible';
    },
    sidebarCollapsed() {
      return this.sidebarStatus === 'collapsed';
    },
  },
  mounted() {
    this.evaluateViewPortWidth();
    window.addEventListener('resize', this.evaluateViewPortWidth);
    EventBus.on('sidebar-toggle', () => {
      if (this.sidebarVisible) {
        this.hideSidebar();
      } else {
        this.showSidebar();
      }
    });
  },
  beforeUnmount() {
    EventBus.off('sidebar-toggle');
    window.removeEventListener('resize', this.evaluateViewPortWidth);
  },
  methods: {
    showSidebar() {
      this.sidebarStatus = 'visible';
      EventBus.emit('sidebar-toggled', true);
    },
    hideSidebar() {
      if (this.sidebarVisible) {
        this.sidebarStatus = 'collapsed';
        EventBus.emit('sidebar-toggled', false);
      }
    },

    evaluateViewPortWidth() {
      this.show = (window.innerWidth || 0) >= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
