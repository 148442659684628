<template>
  <TwoColumnCard
    :header="$t('components.tenants.tenants_form.card.general_information.heading')"
    :helptext="tenantIsNewRecord ? $t('components.tenants.tenants_form.card.general_information.new_text')
      : $t('components.tenants.tenants_form.card.general_information.edit_text')"
  >
    <div class="space-y-6">
      <BaseInput
        v-model="tenant.name"
        name="tenant[name]"
        :label="$t('activerecord.attributes.tenant.name')"
        :model-value="tenant.name"
        :error="getTenantError('name')"
        qa-class="tenant-name"
      />
      <BaseInput
        v-model="tenant.subdomain"
        :disabled="tenantIsNewRecord ? false : true"
        name="tenant[subdomain]"
        :label="$t('activerecord.attributes.tenant.subdomain')"
        :model-value="tenant.subdomain"
        :error="getTenantError('subdomain')"
      >
        <template #leading-addon>
          https://
        </template>
        <template #trailing-addon>
          .applysia.app
        </template>
      </BaseInput>
    </div>
  </TwoColumnCard>

  <AssessmentWorkflowSettingsCard
    :assessment-workflow-settings="tenant.defaultAssessmentWorkflow"
  />

  <Card>
    <div class="p-6 space-y-6">
      <CardDescription
        :header="$t('components.tenants.tenants_form.card.access_settings.title')"
      />
      <div class="flex flex-row justify-between ">
        <div>
          <h3>
            {{ $t('components.tenants.tenants_form.card.access_settings.admins_can_manage_organisations.title') }}
          </h3>
          <p class="text-gray-500 text-sm leading-5">
            {{ $t('components.tenants.tenants_form.card.access_settings.admins_can_manage_organisations.description') }}
          </p>
        </div>
        <Toggle
          v-model="tenant.adminsCanManageOrganisations"
          qa-class="tenant-admin-manage-organisations"
        />
        <input
          type="hidden"
          name="tenant[admins_can_manage_organisations]"
          :value="tenant.adminsCanManageOrganisations"
        >
      </div>
      <p
        v-if="getTenantError('password_auth_enabled')"
        class="text-sm text-red-600"
      >
        {{ getTenantError('password_auth_enabled') }}
      </p>
    </div>
  </Card>

  <TwoColumnCard
    :header="$t('components.tenants.tenants_form.card.branding.heading')"
    :helptext="$t('components.tenants.tenants_form.card.branding.text')"
  >
    <div class="space-y-6">
      <ColorPicker
        v-model="tenant.color"
        name="tenant[color]"
        :label="$t('activerecord.attributes.tenant.color')"
        :model-value="tenant.color || ''"
        :error="getTenantError('color')"
      />
      <RadioColorPicker
        v-model="tenant.cockpitColor"
        name="tenant[cockpit_color]"
        :label="$t('activerecord.attributes.tenant.cockpit_color')"
        :model-value="tenant.cockpitColor || ''"
        :error="getTenantError('cockpit_color')"
      />
      <SingleFileUpload
        v-if="!tenantIsNewRecord"
        :label="$t('activerecord.attributes.tenant.logo')"
        :v-model="tenant.logo"
        name="tenant[logo]"
        :file="tenant.logo"
        :accepted-mime-types="'image/jpeg, image/png'"
        :accepted-file-types="'PNG, JPG'"
        :error="getTenantError('logo')"
        :destroy-name="'destroy_logo'"
        custom-direct-upload-path="/tenant_logo_uploads"
      />
    </div>
  </TwoColumnCard>
  <TwoColumnCard
    :header="$t('components.tenants.tenants_form.card.password_auth.heading')"
    :helptext="$t('components.tenants.tenants_form.card.password_auth.text')"
  >
    <div class="space-y-6">
      <div class="flex flex-row justify-between ">
        <div>
          {{ $t('components.tenants.tenants_form.card.password_auth.active') }}
        </div>
        <Toggle
          v-model="tenant.passwordAuthEnabled"
          qa-class="tenant-password-auth"
        />
        <input
          type="hidden"
          name="tenant[password_auth_enabled]"
          :value="tenant.passwordAuthEnabled"
        >
      </div>
      <p
        v-if="getTenantError('password_auth_enabled')"
        class="text-sm text-red-600 qa-tenant-password-auth-error"
      >
        {{ getTenantError('password_auth_enabled') }}
      </p>
    </div>
  </TwoColumnCard>
  <OpenIDConnectFields
    :openid-connect-enabled="tenantData.openIDConnectEnabled"
    :openid-connect-config="tenantData.openIDConnectConfig"
    :openid-connect-errors="openidConnectConfigErrors"
    :autoprovisioning-enabled="tenantData.autoprovisioningEnabled"
    :email-verified-claim-override-enabled="tenantData.emailVerifiedOverrideEnabled"
  />
</template>

<script>
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AssessmentWorkflowSettingsCard from '@/components/AssessmentWorkflowSettingsCard/AssessmentWorkflowSettingsCard.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import ColorPicker from '@/components/generic/ColorPicker/ColorPicker.vue';
import RadioColorPicker from '@/components/generic/RadioColorPicker/RadioColorPicker.vue';
import OpenIDConnectFields from './OpenIDConnectFields.vue';

export default defineComponent({
  name: 'TenantForm',
  components: {
    TwoColumnCard,
    BaseInput,
    ColorPicker,
    RadioColorPicker,
    OpenIDConnectFields,
    AssessmentWorkflowSettingsCard,
  },
  props: {
    tenantData: {
      type: Object,
      required: true,
    },
    tenantErrors: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    openidConnectConfigErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const toast = inject('toast');
    const { t } = useI18n();

    const tenant = ref({ ...props.tenantData });
    const tenantIsNewRecord = !tenant.value.id;

    const getTenantError = (...keys) => props.tenantErrors[keys.join('.')]?.[0];

    if (props.tenantErrors?.default_assessment_workflow) {
      toast.error(
        t('components.assessment_workflow_setting.errors.update_failed'),
      );
    }

    return {
      tenant,
      getTenantError,
      tenantIsNewRecord,
    };
  },
});
</script>
