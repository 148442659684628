/* eslint-disable import/prefer-default-export */

export const getViewport = () => ({
  width: window.innerWidth || document.documentElement.clientHeight,
  height: window.innerHeight || document.documentElement.clientWidth,
});

export const isInViewport = (element) => {
  const { width: viewportWidth, height: viewportHeight } = getViewport();
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= viewportHeight
    && rect.right <= viewportWidth
  );
};
