let app;

const getCurrentVueApp = () => app;
const setCurrentVueApp = (vueApp) => {
  app = vueApp;
  return app;
};

export {
  getCurrentVueApp,
  setCurrentVueApp,
};
