<template>
  <div>
    <div class="bg-white px-4 py-5">
      <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="mt-2">
          <h3 class="text-lg leading-6 ml-6 font-medium text-gray-900">
            {{ $t('components.multi_file_upload.files') }}
          </h3>
          <p
            v-if="!helpText.empty"
            class="text-sm leading-6 ml-6 text-gray-500 font-normal"
          >
            {{ helpText }}
          </p>
        </div>
        <div class="ml-4 mt-2 shrink-0">
          <BaseButton
            v-if="!preview"
            :is-disabled="disabled"
            icon="UploadIcon"
            :is-primary="usePrimaryButton"
            qa-class="file-upload-button"
            @click="onUploadButtonClick"
          >
            {{ $t('components.multi_file_upload.upload_files') }}
          </BaseButton>
          <BaseButton
            v-else
            as="a"
            :is-disabled="disabled"
            :is-primary="false"
            :href="changeUrl"
          >
            {{ $t('components.multi_file_upload.change') }}
          </BaseButton>
          <input
            ref="fileInput"
            type="file"
            :accept="acceptedMimeTypes"
            :disabled="disabled"
            multiple
            class="hidden qa-file-upload"
            @change="onFileUpload"
          >
        </div>
      </div>
    </div>
    <template v-if="!disabled || files.length > 0">
      <FileList
        :disabled="disabled"
        :file-types-and-size-limit="fileTypesAndSizeLimit"
        :has-shareable-files="hasShareableFiles"
        :preview="preview"
        @filename-clicked="showFilePreview"
        @upload-file-clicked="onUploadButtonClick"
      />
    </template>
    <template v-else>
      <p
        class="border-t border-gray-200 flex justify-center
         text-gray-500 text-sm font-semibold tracking-wide uppercase py-3"
      >
        {{ $t("components.multi_file_upload.file_list.no_files") }}
      </p>
    </template>

    <ConfirmDialogModal
      v-if="showDeleteDialog"
      :open="showDeleteDialog"
      :message="$t('components.multi_file_upload.delete_confirmation_message', { filename: fileToDelete.filename })"
      @close="closeDeleteDialog"
      @confirm="deleteCurrentFile"
    />
    <PreviewOverlay
      v-if="previewFile"
      :file="previewFile"
      @close="previewFileId = null"
    />
    <WebdavSessionUnlockModal />
    <WebdavEditingInfoModal />
  </div>
</template>

<script>
import {
  computed, defineComponent, onMounted, ref, toRefs, watch, onUnmounted,
} from 'vue';

import PreviewOverlay from '@/components/PreviewOverlay/PreviewOverlay.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';
import { useI18n } from '@/plugins/i18n';
import { useNotifications } from '@/plugins/toast';
import WebdavSessionUnlockModal from '@/components/generic/EditInOffice/WebdavSessionUnlockModal.vue';
import { useMultiFileUploadStore } from '@/components/generic/MultiFileUpload/multiFileUploadStore';
import { storeToRefs } from 'pinia';
import FileList from './FileList/FileList.vue';
import WebdavEditingInfoModal from '../EditInOffice/WebdavEditingInfoModal.vue';

export default defineComponent({
  components: {
    BaseButton,
    ConfirmDialogModal,
    PreviewOverlay,
    FileList,
    WebdavSessionUnlockModal,
    WebdavEditingInfoModal,
  },
  props: {
    collectionPath: {
      type: String,
      required: true,
    },
    acceptedMimeTypes: {
      type: String,
      required: true,
    },
    acceptedFileTypes: {
      type: String,
      required: true,
    },
    parentType: {
      type: String,
      required: false,
      default: null,
      validator: (value) => [null, 'Candidate', 'Exercise'].includes(value),
    },
    parentId: {
      type: Number,
      required: false,
      default: null,
    },
    /**
     * file size in bytes.
     */
    maxFileSize: {
      type: Number,
      default: 1024 * 1024 * 200, // 200 MB
    },
    hasShareableFiles: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    changeUrl: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
      required: false,
    },
    usePrimaryButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['preview-is-open'],
  setup(props, { emit }) {
    const {
      collectionPath, disabled, maxFileSize, acceptedMimeTypes, acceptedFileTypes, hasShareableFiles,
    } = toRefs(props);
    const { error } = useNotifications();
    const { t } = useI18n();

    // Store setup

    const store = useMultiFileUploadStore();
    const { files, showDeleteDialog, fileToDelete } = storeToRefs(store);

    watch(collectionPath, async (newCollectionPath) => {
      try {
        store.setCollectionPath(newCollectionPath);

        store.fetchFiles();
      } catch (e) {
        error(t('components.multi_file_upload.error_loading_files'));
      }
    });
    onMounted(async () => {
      if (collectionPath.value.includes('undefined')) return;

      if (hasShareableFiles.value) {
        store.subscribeUpdates({ parentId: props.parentId, parentType: props.parentType });
      }

      try {
        store.setCollectionPath(collectionPath.value);
        store.setHasShareableFiles(hasShareableFiles.value);
        store.setAcceptedMimeTypes(acceptedMimeTypes.value);
        store.setMaxFileSize(maxFileSize.value);
        store.fetchFiles();
      } catch (e) {
        error(t('components.multi_file_upload.error_loading_files'));
      }
    });

    onUnmounted(() => {
      store.unsubscribe();
    });

    // Delete ConfirmModal

    const closeDeleteDialog = () => {
      store.closeDeleteDialog();
    };

    const deleteCurrentFile = async () => {
      const file = fileToDelete.value;
      const { filename } = file;

      try {
        store.destroyFile(file);
        store.closeDeleteDialog();
      } catch (err) {
        error(t('components.multi_file_upload.failed_to_delete_file', { filename }));
      }
    };

    // File preview overlay

    const previewFileId = ref(null);
    const previewFile = computed(() => {
      if (previewFileId.value) {
        return store.fileById(previewFileId.value);
      }
      return null;
    });
    const showFilePreview = ({ file: { id } }) => {
      previewFileId.value = id;
    };

    watch(previewFile, (newPreviewFile) => {
      emit('preview-is-open', !!newPreviewFile);
    });

    // File upload

    const fileInput = ref(null);

    const onFileUpload = ({ target }) => {
      if (disabled.value) return;

      const { files: filesToUpload } = target;
      store.uploadSelectedFiles(filesToUpload);
      target.files = new DataTransfer().files;
    };

    // Upload constraints formatting

    function formatBytes(bytes) {
      const k = 1024;
      const dm = 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    }

    const fileTypesAndSizeLimit = computed(() => {
      const sizeLimitString = props.maxFileSize
        ? t('components.single_file_upload.up_to', {
          size_limit: formatBytes(props.maxFileSize),
        })
        : '';
      return `${acceptedFileTypes.value} ${sizeLimitString}`;
    });

    return {
      files,
      store,

      fileToDelete,
      deleteCurrentFile,
      closeDeleteDialog,
      showDeleteDialog,

      showFilePreview,
      previewFile,
      previewFileId,

      fileInput,
      onUploadButtonClick() {
        fileInput.value.click();
      },
      onFileUpload,

      fileTypesAndSizeLimit,

    };
  },
});
</script>
