<template>
  <div class="mb-6">
    <PageHeading>
      {{ title }}
      <template #actions>
        <SaveButton
          :is-loading="submitting"
          form="edit-self-form"
        >
          {{ $t('defaults.save') }}
        </SaveButton>
      </template>
    </PageHeading>
  </div>

  <div
    v-if="user.unconfirmedEmail"
    class="w-full mb-6"
  >
    <alert type="warning">
      <template #content>
        {{ $t("components.edit_user_form.currently_waiting_confirmation_for_email", { email: user.unconfirmedEmail }) }}
        {{ $t("components.edit_user_form.waiting_for_confirmation_notice") }}
      </template>
    </alert>
  </div>

  <form
    id="edit-self-form"
    ref="form"
    :action="actionUrl"
    accept-charset="UTF-8"
    method="post"
    enctype="multipart/form-data"
    @submit="submitting = true"
  >
    <input
      type="hidden"
      name="_method"
      value="put"
    >
    <input
      type="hidden"
      name="authenticity_token"
      :value="$csrfToken"
    >
    <div class="space-y-5 mb-5">
      <TwoColumnCard
        :header="$t('components.edit_user_form.categories.profile.header')"
      >
        <div class="space-y-5">
          <div class="flex items-top space-x-6">
            <div class="flex-1">
              <BaseInput
                v-model="user.firstName"
                :label="$t('activerecord.attributes.user.first_name')"
                type="text"
                name="user[first_name]"
                qa-class="first-name"
                :error="getError('first_name')"
              />
            </div>
            <div class="flex-1">
              <BaseInput
                v-model="user.lastName"
                :label="$t('activerecord.attributes.user.last_name')"
                type="text"
                name="user[last_name]"
                qa-class="last-name"
                :error="getError('last_name')"
              />
            </div>
          </div>
          <BaseInput
            v-model="user.email"
            :label="$t('activerecord.attributes.user.email')"
            type="text"
            name="user[email]"
            :error="getError('email')"
          />
        </div>
      </TwoColumnCard>

      <TwoColumnCard
        :header="$t('components.edit_user_form.categories.preferences.header')"
        :helptext="$t('components.edit_self_form.categories.preferences.helptext')"
      >
        <div class="space-y-5">
          <SelectList
            :label="$t('activerecord.attributes.user.locale')"
            :selected="initialLocale"
            name="user[locale]"
            :options="localeOptions"
            :error="getError('locale')"
            qa-class="locale"
          />
          <SearchableSelectList
            id="select-timezone"
            :selected="initialTimezone"
            :label="$t('activerecord.attributes.user.time_zone')"
            :options="timezoneOptions"
            value-prop="name"
            label-prop="name"
            secondary-label-prop="secondary"
            name="user[time_zone]"
            :allow-reset="false"
            qa-class="time-zone"
          />
          <AfterLoginViewRadioList
            :label="$t('activerecord.attributes.user.after_login_view')"
            :value="user.afterLoginView"
            input-name="user[after_login_view]"
            :error="getError('after_login_view')"
          />
        </div>
      </TwoColumnCard>

      <TwoColumnCard
        v-if="passwordAuthEnabled"
        :header="$t('components.edit_self_form.categories.security.header')"
        :helptext="$t('components.edit_self_form.categories.security.helptext')"
      >
        <div class="space-y-5">
          <BaseInput
            type="password"
            autocomplete="off"
            :label="$t('activerecord.attributes.user.current_password')"
            name="user[current_password]"
            :error="getError('current_password')"
            qa-class="password-current"
          />
          <BaseInput
            v-model="password"
            type="password"
            autocomplete="off"
            :label="$t('activerecord.attributes.user.password')"
            name="user[password]"
            :error="getError('password')"
            qa-class="password-new"
          />
          <password-requirements v-model="password" />
          <BaseInput
            type="password"
            autocomplete="off"
            :label="$t('activerecord.attributes.user.password_confirmation')"
            name="user[password_confirmation]"
            :error="getError('password_confirmation')"
            qa-class="password-new-confirm"
          />
        </div>
      </TwoColumnCard>
      <div class="space-y-5 mb-5">
        <TwoColumnCard
          :header="$t('components.edit_self_form.categories.privacy')"
        >
          <div class="flex flex-row justify-between items-center space-x-2">
            <div>
              <p class="text-sm font-medium text-gray-900">
                {{ $t('components.policy_consent.analytics.heading') }}
              </p>
              <i18n-t
                class="text-sm font-normal text-gray-500 text-left"
                keypath="components.policy_consent.analytics.text"
                tag="p"
                for="components.policy_consent.analytics.heading"
              >
                <template #link>
                  <a
                    class="text-primary-500 underline"
                    :href="privacyPolicyURL"
                    target="_blank"
                  >{{ $t('components.policy_consent.analytics.link') }}</a>
                </template>
              </i18n-t>
            </div>
            <Toggle
              :model-value="analyticsPolicyEnabled"
              name="user[analytics_policy_consent]"
              qa-class="analytics"
            />
          </div>
        </TwoColumnCard>
      </div>
    </div>
  </form>
</template>

<script>
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import SaveButton from '@/components/generic/SaveButton/SaveButton.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import useLocaleOptions from '@/composables/localeOptions';
import { useTimezoneOptions } from '@/composables/timezoneOptions';
import AfterLoginViewRadioList from '@/components/Users/AfterLoginView/AfterLoginViewRadioList.vue';
import PasswordRequirements from '@/components/generic/PasswordRequirements/PasswordRequirements.vue';
import { authPath } from '@/util/url-helpers';
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    PageHeading,
    TwoColumnCard,
    BaseInput,
    SaveButton,
    SelectList,
    SearchableSelectList,
    AfterLoginViewRadioList,
    PasswordRequirements,
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    availableTimeZones: {
      type: Array,
      required: true,
    },
    passwordAuthEnabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const form = ref(null);

    const user = { ...props.userData };

    const analyticsPolicyEnabled = user.analyticsPolicyConsent;

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];
    const { availableLocales } = useI18n();

    const { timezoneOptions, initialTimezone } = useTimezoneOptions(props.availableTimeZones, user.timeZone);
    const { localeOptions, initialLocale } = useLocaleOptions(availableLocales, user.locale);

    const actionUrl = authPath();

    const submitting = ref(false);

    const privacyPolicyURL = 'https://applysia.de/download/596/';

    const password = ref('');

    onMounted(() => {
      form.value.addEventListener('turbo:before-fetch-response', (event) => {
        const { response } = event.detail.fetchResponse;
        if (response.status === 200) {
          event.preventDefault();
          window.location.href = response.url;
        }
      });
    });

    return {
      user,
      getError,
      submitting,
      localeOptions,
      initialLocale,
      timezoneOptions,
      initialTimezone,
      actionUrl,
      analyticsPolicyEnabled,
      privacyPolicyURL,
      form,
      password,
    };
  },
});
</script>
