export default class Utils {
  static defaultColors = [
    '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6',
    '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11',
    '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#651067',
  ];

  static setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;

  static internalConsistencyColor(value) {
    if (value == null) {
      return 'text-gray-500';
    }
    if (value < 0.7) {
      return 'text-orange-400';
    }
    if (value < 0.8) {
      return 'text-yellow-500';
    }
    return 'text-green-400';
  }

  static agreementColor(value) {
    if (value == null) {
      return 'text-gray-500';
    }
    if (value < 0.6) {
      return 'text-orange-400';
    }
    if (value < 0.8) {
      return 'text-yellow-500';
    }
    return 'text-green-400';
  }

  static observabilityColor(value) {
    if (value == null) {
      return 'text-gray-500';
    }
    if (value < 0.6) {
      return 'text-orange-400';
    }
    if (value < 0.8) {
      return 'text-yellow-500';
    }
    return 'text-green-400';
  }

  static internalConsistencyIcon(value) {
    if (value < 0.7) {
      return 'XCircleIcon';
    }
    if (value < 0.8) {
      return 'ExclamationCircleIcon';
    }
    return 'CheckCircleIcon';
  }

  static agreementIcon(value) {
    if (value < 0.6) {
      return 'XCircleIcon';
    }
    if (value < 0.8) {
      return 'ExclamationCircleIcon';
    }
    return 'CheckCircleIcon';
  }

  static observabilityIcon(value) {
    if (value < 0.6) {
      return 'XCircleIcon';
    }
    if (value < 0.8) {
      return 'ExclamationCircleIcon';
    }
    return 'CheckCircleIcon';
  }
}
