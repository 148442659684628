<template>
  <button
    :type="type"
    :name="name"
    :disabled="disabled"
    :class="classes"
    @click="$emit('click')"
  >
    <slot>
      <component
        :is="size === 'sm' ? 'Heroicon' : 'HeroiconOutline'"
        :icon="icon"
        :class="iconClasses"
        aria-hidden="true"
      />
    </slot>
  </button>
</template>

<script>
import { defineComponent, computed, toRefs } from 'vue';
import Heroicon from '@/components/Heroicon.vue';
import HeroiconOutline from '@/components/HeroiconOutline.vue';

export default defineComponent({
  components: {
    Heroicon,
    HeroiconOutline,
  },
  props: {
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg'].includes(value);
      },
    },
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props) {
    const { disabled, size } = toRefs(props);

    const classes = computed(() => [
      'inline-flex items-center border border-transparent rounded-full shadow-sm text-white',
      'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
      disabled.value ? 'bg-primary-300 cursor-not-allowed' : 'bg-primary-600 hover:bg-primary-700',
      {
        'p-1': size.value === 'sm',
        'p-2': ['md', 'lg'].includes(size.value),
      },
    ]);

    const iconClasses = computed(() => ({
      'h-5 w-5': size.value === 'sm',
      'h-6 w-6': size.value === 'md',
      'h-8 w-8': size.value === 'lg',
    }));

    return {
      classes,
      iconClasses,
    };
  },
});
</script>
