<template>
  <div class="mb-6">
    <PageHeading>
      {{ $t('components.candidates.candidate_form.create_candidate') }}
    </PageHeading>
  </div>

  <Alert
    v-if="isDemoCandidate"
    type="info"
    class="mb-6"
  >
    <template #content>
      {{ $t('components.candidates.candidate_form.add_demo_candidate_notice') }}
    </template>
  </Alert>

  <form
    id="candidate-form"
    :action="candidatesPath"
    accept-charset="UTF-8"

    method="post"
    enctype="multipart/form-data"
    @submit="submitting = true"
  >
    <input
      type="hidden"
      name="authenticity_token"
      :value="$csrfToken"
    >

    <input
      type="hidden"
      name="candidate[demo]"
      :value="isDemoCandidate"
    >

    <div class="space-y-5 mb-5">
      <TwoColumnCard
        :header="$t('components.candidates.candidate_form.categories.general.header')"
        :helptext="$t('components.candidates.candidate_form.categories.general.helptext')"
      >
        <div class="space-y-6">
          <div class="flex items-center space-x-6">
            <div class="flex-1">
              <BaseInput
                v-model="candidate.firstName"
                name="candidate[first_name]"
                :label="$t('activerecord.attributes.candidate.first_name')"
                :error="getError('first_name')"
                :disabled="isDemoCandidate"
                qa-class="candidate-first-name"
              />
            </div>
            <div class="flex-1">
              <BaseInput
                v-model="candidate.lastName"
                name="candidate[last_name]"
                :label="$t('activerecord.attributes.candidate.last_name')"
                :error="getError('last_name')"
                :disabled="isDemoCandidate"
                qa-class="candidate-last-name"
              />
            </div>
          </div>
          <div
            class="flex space-x-6"
          >
            <div class="flex-1">
              <SelectList
                :label="$t('activerecord.attributes.candidate.sex')"
                :selected="initialSex"
                name="candidate[sex]"
                :options="sexOptions"
                :error="getError('sex')"
                :disabled="assessmentCompleted"
                :hint="$t('defaults.optional')"
              />
            </div>
            <div class="flex-1">
              <date-picker
                v-model.string="candidate.birthdate"
                :locale="$i18n.locale"
                mode="date"
                color="gray"
                :masks="masks"
                :update-on-input="false"
              >
                <template #default="{ inputValue, inputEvents }">
                  <div>
                    <BaseInput
                      type="text"
                      leading-icon="ClockIcon"
                      :disabled="assessmentCompleted"
                      :model-value="inputValue"
                      :label="$t('activerecord.attributes.candidate.birthdate')"
                      :error="getError('birthdate')"
                      :hint="$t('defaults.optional')"
                      v-on="inputEvents"
                    />
                    <input
                      :value="candidate.birthdate"
                      type="hidden"
                      :disabled="assessmentCompleted"
                      name="candidate[birthdate]"
                    >
                  </div>
                </template>
              </date-picker>
            </div>
          </div>
          <SelectList
            :label="$t('activerecord.attributes.candidate.locale')"
            :selected="initialLocale"
            name="candidate[locale]"
            :options="localeOptions"
            :error="getError('locale')"
            :disabled="assessmentCompleted"
          />
          <BaseInput
            name="candidate[email]"
            :label="$t('activerecord.attributes.candidate.email')"
            :error="getError('email')"
            :hint="$t('defaults.optional')"
            qa-class="candidate-email"
          />
          <ColorPicker
            v-model="candidate.color"
            :custom-palette="colorPalette"
            name="candidate[color]"
            :error="getError('color')"
          />
        </div>
      </TwoColumnCard>
      <div
        class="flex justify-end space-x-5"
      >
        <BaseButton
          as="a"
          :href="candidatesPath"
          :is-primary="false"
        >
          {{ $t('defaults.cancel') }}
        </BaseButton>
        <div
          v-if="!assessmentCompleted"
          class="flex flex-row-reverse gap-5"
        >
          <BaseButton
            :is-loading="submitting"
            name="submit"
            type="submit"
            qa-class="save-button"
          >
            {{ $t('defaults.save') }}
          </BaseButton>
          <BaseButton
            :is-loading="submitting"
            name="submit_and_new"
            type="submit"
          >
            {{ $t('defaults.save_and_new') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import useLocaleOptions from '@/composables/localeOptions';
import { organisationAssessmentCandidatesPath, assessmentCandidatesPath } from '@/util/url-helpers';
import { DatePicker } from 'v-calendar';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    DatePicker,
    SelectList,
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    colorPalette: {
      type: Object,
      default: () => ({}),
    },
    candidateData: {
      type: Object,
      default: () => ({}),
    },
    assessmentId: {
      type: String,
      required: true,
    },
    organisationId: {
      type: String,
      required: false,
      default: null,
    },
    assessmentCompleted: {
      type: Boolean,
      required: true,
    },
    sexOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    const candidate = { ...props.candidateData };

    const candidatesPath = props.organisationId
      ? organisationAssessmentCandidatesPath(props.organisationId, props.assessmentId)
      : assessmentCandidatesPath(props.assessmentId);

    const submitting = ref(false);

    const initialSex = candidate.sex ? props.sexOptions.find((option) => option.value === candidate.sex) : null;

    const masks = { modelValue: 'YYYY-MM-DD' };

    const { availableLocales, locale } = useI18n();
    const { localeOptions, initialLocale } = useLocaleOptions(availableLocales, locale.value);

    const isDemoCandidate = ref(new URLSearchParams(window.location.search).get('demo') === 'true');

    return {
      getError,
      candidate,
      candidatesPath,
      submitting,

      masks,
      initialSex,

      localeOptions,
      initialLocale,
      isDemoCandidate,
    };
  },
};
</script>
