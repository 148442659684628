<template>
  <TransitionRoot
    as="template"
    :show="open"
  >
    <HeadlessDialog
      as="div"
      class="relative z-50"
      @close="$emit('click-outside')"
    >
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="bg-primary-500 px-4 py-6 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle
                        id="slide-over-heading"
                        class="flex justify-between w-full text-lg font-medium text-white"
                      >
                        <slot name="title" />
                        <div class="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            class="text-white hover:text-primary-900 focus:ring-2
                             focus:ring-primary-500 focus:outline-none qa-slide-over-close"
                            @click="$emit('close-clicked')"
                          >
                            <span class="sr-only">Close panel</span>
                            <Heroicon
                              class="h-6 w-6"
                              icon="XIcon"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </DialogTitle>
                    </div>
                    <div
                      v-if="false"
                      class="mt-1"
                    >
                      <p class="text-sm text-indigo-300">
                        <slot name="sub-title" />
                      </p>
                    </div>
                  </div>
                  <div class="overflow-y-scroll h-full">
                    <slot name="content" />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog as HeadlessDialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import Heroicon from '@/components/Heroicon.vue';

export default {
  components: {
    HeadlessDialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Heroicon,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click-outside', 'close-clicked'],
};
</script>
