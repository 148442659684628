<template>
  <Modal
    :open="true"
    :hide-overflow="false"
    @close="close"
  >
    <div class="flex flex-col h-full">
      <div class="w-full pt-6 px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pb-6">
          {{ $t('components.assessments.assessment_filters.title') }}
        </h3>
        <div class="space-y-6">
          <MultiSelectComboBox
            v-if="organisationOptions"
            v-model:selected-options="selectedOrganisations"
            :options="organisationOptions"
            :label="$t('components.assessments.assessment_filters.fields.organisations')"
            option-identifier="name"
          />
          <MultiSelectComboBox
            v-model:selected-options="selectedTargetPositions"
            :options="targetPositionOptions"
            :label="$t('components.assessments.assessment_filters.fields.target_positions')"
            option-identifier="name"
          />
          <div class="flex flex-col sm:flex-row z-50">
            <DateRangeInput
              v-model:date-range="selectedDateRange"
              :min-start-date="availableAssessmentFilters.startDate"
              :max-end-date="availableAssessmentFilters.endDate"
              :reset-start-date-button-label="$t('defaults.clear')"
              :reset-end-date-button-label="$t('defaults.clear')"
              leading-icon="CalendarIcon"
            />
          </div>
          <div class="flex space-x-6 pb-5">
            <BaseCheckbox
              v-model="selectedStatus['draft']"
              :label="$t('activerecord.enums.assessment.status.draft')"
              class="group"
              label-classes="bg-gray-100 text-gray-800 px-2.5 py-0.5 rounded-lg group-hover:bg-gray-200 select-none"
            />
            <BaseCheckbox
              v-model="selectedStatus['active']"
              :label="$t('activerecord.enums.assessment.status.active')"
              class="group"
              label-classes="bg-emerald-100 text-emerald-800 px-2.5 py-0.5
            select-none rounded-lg group-hover:bg-emerald-200 select-none"
            />
            <BaseCheckbox
              v-model="selectedStatus['completed']"
              :label="$t('activerecord.enums.assessment.status.completed')"
              class="group"
              label-classes="bg-blue-100 text-blue-800 px-2.5 py-0.5 rounded-lg group-hover:bg-blue-200 select-none"
            />
          </div>
        </div>
      </div>

      <div class="bg-gray-50 py-3 px-2 flex flex-row justify-end rounded-b-lg">
        <div
          class="w-full px-1 sm:flex sm:w-1 sm:flex-row-reverse"
        >
          <BaseButton
            :is-disabled="isLoading"
            :is-loading="isLoading"
            class="mx-auto p-2 w-full justify-center rounded-md border border-transparent
           shadow-sm sm:ml-3 sm:w-auto sm:text-sm"
            @click="applyFilters"
          >
            {{ $t('defaults.filter') }}
          </BaseButton>
          <BaseButton
            :is-primary="false"
            :disabled="isLoading"
            class="mt-3 w-full justify-center rounded-md border border-gray-300
           shadow-sm p-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="close"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import DateRangeInput from '@/components/generic/DateRangeInput/DateRangeInput.vue';
import Modal from '@/components/generic/Modal/Modal.vue';
import { ref } from 'vue';

export default {
  name: 'AssessmentFilterModal',
  components: {
    Modal,
    DateRangeInput,
  },
  props: {
    selectedFilters: {
      type: Object,
      default: () => {},
    },
    availableAssessmentFilters: {
      type: Object,
      required: true,
    },
    openFilterModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['apply-filters', 'close'],
  setup(props, { emit }) {
    const isLoading = ref(false);

    const selectedOrganisations = ref(props.selectedFilters.organisations
      .map((organisationId) => (props.availableAssessmentFilters.organisations
        .find((org) => String(org.id) === String(organisationId)))));
    const selectedTargetPositions = ref(props.selectedFilters.targetPositions.map((position) => ({
      name: position,
    })));
    const selectedDateRange = ref({
      startDate: props.selectedFilters.startDate,
      endDate: props.selectedFilters.endDate,
    });
    const selectedStatus = ref({
      draft: props.selectedFilters.status.includes('draft') || props.selectedFilters.status.length === 0,
      active: props.selectedFilters.status.includes('active') || props.selectedFilters.status.length === 0,
      completed: props.selectedFilters.status.includes('completed') || props.selectedFilters.status.length === 0,
    });

    const organisationOptions = ref(props.availableAssessmentFilters.organisations
      ?.map((organisation) => ({ id: organisation.id, name: organisation.name, disabled: false })));
    const targetPositionOptions = ref(props.availableAssessmentFilters.targetPositions
      .map((position) => ({ name: position, disabled: false })));

    const close = () => {
      emit('close');
    };

    const applyFilters = () => {
      isLoading.value = true;
      const filters = {
        organisations: selectedOrganisations.value.map((organisation) => organisation.id),
        targetPositions: selectedTargetPositions.value.map((position) => position.name),
        startDate: selectedDateRange.value.startDate,
        endDate: selectedDateRange.value.endDate,
        status: Object.keys(selectedStatus.value).filter((key) => selectedStatus.value[key]),
      };
      emit('apply-filters', filters);
      close();
    };

    return {
      isLoading,
      organisationOptions,
      targetPositionOptions,
      selectedOrganisations,
      selectedTargetPositions,
      selectedDateRange,
      selectedStatus,

      close,
      applyFilters,
    };
  },
};
</script>
