import getMetaPropertyContent from '@/util/getMetaPropertyContent';
import { posthog } from 'posthog-js';

let isNavigating = false;
let lastLocation = { ...window.location };

function usePosthog({
  userId, userName, tenantDomain, tenantName, emailDomain, apiHost, apiKey, enableCapturing = false,
} = {}) {
  posthog.init(apiKey, {
    api_host: apiHost,
    autocapture: false,
    rageclick: true,
    opt_out_capturing_by_default: true,
    disable_session_recording: !enableCapturing,
    loaded() {
      posthog.identify(
        `${tenantDomain}:${userId}`,
        {
          EmailDomain: emailDomain,
          Tenant: tenantName,
          User: userName,
        },
      );
    },
  });

  if (enableCapturing) {
    posthog.opt_in_capturing();
    // eslint-disable-next-line no-underscore-dangle
    posthog._start_queue_if_opted_in();
  } else {
    posthog.opt_out_capturing();
  }

  // Capture pageview and pageleave events manually, because turbo prevents posthog to capture them automatically
  // Taken from: https://github.com/PostHog/posthog-js/issues/386
  window.addEventListener('popstate', () => {
    if (!isNavigating && lastLocation.href !== window.location.href) {
      posthog.capture('$pageleave', {
        $current_url: lastLocation.href,
        $host: lastLocation.host,
        $pathname: lastLocation.pathname,
      });
      posthog.capture('$pageview');
    }

    lastLocation = { ...window.location };
  });

  function wrap(fn) {
    // eslint-disable-next-line func-names
    return function (...args) {
      const url = args[2];
      const isTracked = !isNavigating && url && url !== window.location.href;
      if (isTracked) {
        isNavigating = true;
        posthog.capture('$pageleave');
      }
      fn.apply(this, args);
      if (isTracked) {
        setTimeout(() => {
          posthog.capture('$pageview');
          lastLocation = { ...window.location };
          isNavigating = false;
        }, 50);
      }
    };
  }

  window.history.pushState = wrap(window.history.pushState);
  window.history.replaceState = wrap(window.history.replaceState);
}

function enableAllFeatureFlags() {
  posthog.featureFlags.override({
    'report-templates': true,
    'open-in-office': true,
    'new-login-flow': true,
    'automatic-competency-average': true,
  });
}

export const setupPosthog = () => {
  const environment = getMetaPropertyContent('applysia:environment');
  const userData = getMetaPropertyContent('applysia:user');
  const trackingConsent = getMetaPropertyContent('applysia:analytics_consent');

  const enableCapturing = !['development', 'test'].includes(environment) && trackingConsent;

  if (userData) {
    usePosthog(
      {
        ...JSON.parse(userData),
        apiKey: 'phc_g2t4mjn3PAmnsjfrTmosQlfHg7PrWaoiFh5RwdzpRAj',
        apiHost: 'https://eu.posthog.com',
        enableCapturing,
      },
    );
  }

  if (environment === 'development') {
    enableAllFeatureFlags();
  }
};

export default usePosthog;
