<template>
  <div class="flex flex-col relative">
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div
        class="py-2 overflow-hidden align-middle inline-block max-w-full min-w-full sm:px-6 lg:px-8"
        :class="{
          'pb-20': standalone
        }"
      >
        <div
          class="w-full"
          :class="{
            'shadow sm:rounded-lg': standalone
          }"
        >
          <div class="max-w-full overflow-x-auto">
            <div>
              <table class="min-w-full divide-y divide-gray-200 qa-base-table">
                <BaseTableHeader
                  :columns="columns"
                  :sort-param="sortParam"
                  :sort-direction-param="sortDirectionParam"
                  :sort-association-param="sortAssociationParam"
                  :preview="preview"
                />
                <tbody class="bg-white divide-y divide-gray-200">
                  <template v-if="records.length > 0">
                    <tr
                      v-for="(record, index) in records"
                      :key="index"
                      class="qa-base-table-row"
                      :class="[record.path || emitRowClick ?'group hover:bg-gray-50 hover:cursor-pointer' : '']"
                      @click="openRecordPath($event, record)"
                    >
                      <slot
                        name="default"
                        v-bind="{ record }"
                      >
                        <td
                          v-for="(column, columnIndex) in columns"
                          :key="`${index}-column-${columnIndex}`"
                          :class="[
                            'px-6 py-4 whitespace-nowrap text-sm',
                            columnIndex === 0 ? 'font-medium text-gray-900' : 'text-gray-500']"
                        >
                          {{ record[column.key] }}
                        </td>
                      </slot>
                    </tr>
                  </template>
                  <tr v-else>
                    <td
                      :colspan="columns.length"
                      class="px-6 py-4 text-sm text-gray-500 italic text-center"
                    >
                      {{ $t('components.base_table.no_records') }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Pagination
            v-if="!preview"
            :pages="pageSeries"
            :current-page="currentPage"
            class="w-full"
          />
          <div
            v-else
            class="px-4 py-2 border-gray-200 border-t flex justify-end"
          >
            <BaseButton
              as="a"
              :href="viewAllLink"
              :is-primary="false"
            >
              {{ $t('defaults.view_all') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import Pagination from '@/components/generic/Pagination/Pagination.vue';
import { computed, defineComponent } from 'vue';
import BaseTableHeader from './BaseTableHeader/BaseTableHeader.vue';

export default defineComponent({
  components: {
    BaseTableHeader,
    Pagination,
    BaseButton,
  },
  props: {
    /**
     * { key: string, association?: string, name: string, sortable?: boolean, hidden?: boolean }[]
     */
    columns: {
      type: Array,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },

    sortParam: {
      type: String,
      default: 'sort',
    },
    sortDirectionParam: {
      type: String,
      default: 'order',
    },
    sortAssociationParam: {
      type: String,
      default: 'association',
    },

    // Example: [1, "gap", 7, 8, "9", 10, 11, "gap", 36]
    // Example: {series: [1, "2", 3], current: 2}
    pages: {
      type: [Array, Object],
      required: true,
    },

    standalone: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    viewAllLink: {
      type: String,
      default: '/',
    },
    emitRowClick: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onRowClick'],
  setup(props, { emit }) {
    const pageSeries = computed(() => props.pages?.series ?? props.pages);
    const currentPage = computed(() => {
      const page = parseInt(new URLSearchParams(window.location.search).get('page'), 10);
      return props.pages?.current ?? (page > 0 ? page : 1);
    });

    const openRecordPath = (event, record) => {
      // Prevent default behavior if the click was on a link
      if (event.target.closest('a') || event.target.closest('button')) {
        return;
      }

      if (props.emitRowClick) {
        emit('onRowClick', record);
      } else if (record.path) {
        Turbo.visit(record.path);
      }
    };

    return {
      openRecordPath,
      currentPage,
      pageSeries,
    };
  },
});
</script>
