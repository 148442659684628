<template>
  <TwoColumnCard
    :header="$t('components.tenants.tenants_form.card.hogrefe_integration.heading')"
  >
    <div class="space-y-6">
      <div class="flex flex-row justify-between ">
        <div>
          {{ $t('components.tenants.tenants_form.card.hogrefe_integration.hogrefe_integration_enabled') }}
        </div>
        <Toggle
          v-model="hogrefeIntegrationEnabled"
        />
        <input
          type="hidden"
          name="tenant[hogrefe_integration][enabled]"
          :value="hogrefeIntegrationEnabled"
        >
      </div>
      <div class="flex space-x-6 grow w-full">
        <BaseInput
          v-model="serialNumber"
          class="flex-1"
          :label=" $t('components.tenants.tenants_form.card.hogrefe_integration.serial_number')"
          :disabled="!hogrefeIntegrationEnabled"
          name="tenant[hogrefe_integration][credentials][serialNumber]"
          :error="getHogrefeIntegrationError('credentials')"
        />
        <BaseInput
          v-model="password"
          class="flex-1"
          :type="'password'"
          :label=" $t('components.tenants.tenants_form.card.hogrefe_integration.password')"
          :disabled="!hogrefeIntegrationEnabled"
          name="tenant[hogrefe_integration][credentials][password]"
          :error="getHogrefeIntegrationError('credentials')"
        />
      </div>
      <BaseInput
        v-model="url"
        :label=" $t('components.tenants.tenants_form.card.hogrefe_integration.url')"
        :disabled="!hogrefeIntegrationEnabled"
        name="tenant[hogrefe_integration][url]"
        :error="getHogrefeIntegrationError('url')"
      />
    </div>
  </TwoColumnCard>
</template>

<script>
import { defineComponent, ref } from 'vue';
import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';

export default defineComponent({
  name: 'TenantForm',
  components: {
    TwoColumnCard,
    BaseInput,
  },
  props: {
    hogrefeIntegrationData: {
      type: Object,
      required: false,
      default: null,
    },
    hogrefeIntegrationErrors: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup(props) {
    const { enabled, url, credentials } = { ...props.hogrefeIntegrationData };
    const { serialNumber, password } = credentials || {};

    const hogrefeIntegrationEnabled = ref(enabled || false);

    const getHogrefeIntegrationError = (...keys) => props.hogrefeIntegrationErrors[keys.join('.')]?.[0];

    return {

      url,
      serialNumber,
      password,
      getHogrefeIntegrationError,
      hogrefeIntegrationEnabled,
    };
  },
});
</script>
