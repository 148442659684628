<template>
  <Card>
    <div class="px-4 sm:px-6 pt-6">
      <p class="mt-1 text-sm text-gray-500">
        {{ $t('components.insights.show.benchmark') }}
      </p>
      <h2 class="text-lg leading-6 font-medium text-gray-900 mb-2">
        {{ shouldShowClusters ? $t('components.insights.show.cluster_and_competencies_by_exercise') :
          $t('components.insights.show.competencies_by_exercise') }}
      </h2>
    </div>
    <div class="mt-6 flex flex-col">
      <div class="-my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full">
          <div class="overflow-hidden border-t border-gray-200">
            <table class="min-w-full divide-y divide-gray-200 table-auto">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ shouldShowClusters ? $t('components.insights.show.cluster_and_competence') :
                      $t('components.insights.show.competencies') }}
                  </th>
                  <th
                    v-for="exercise in exercises"
                    :key="exercise.id"
                    :title="exercise.name"
                    scope="col"
                    class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    {{ exercise.name }}
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    {{ $t('components.insights.show.mean') }}
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                  />
                </tr>
              </thead>
              <tbody
                v-for="cluster in clusters"
                :key="cluster.name"
                class="bg-white divide-y divide-gray-200"
              >
                <tr
                  v-if="shouldShowClusters"
                  class="bg-gray-100"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900 truncate"
                  >
                    <span v-if="cluster.isDefault"> {{ $t('components.insights.show.without_cluster') }} </span>
                    <span v-else>{{ cluster.name }}</span>
                  </td>
                  <td
                    v-for="exercise in exercises"
                    :key="exercise.name"
                    class="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900 text-right"
                  >
                    {{ getValue({clusterName: cluster.name, exerciseName: exercise.name}) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-semibold">
                    {{ getValue({clusterName: cluster.name}) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" />
                </tr>
                <tr
                  v-for="competency in cluster.competencies"
                  :key="competency.id"
                >
                  <td
                    :title="competency.name"
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate"
                  >
                    {{ competency.name }}
                  </td>
                  <td
                    v-for="exercise in exercises"
                    :key="exercise.name"
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right"
                  >
                    {{ getValue({
                      clusterName: cluster.name,
                      competencyName: competency.name,
                      exerciseName: exercise.name})
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-semibold">
                    {{ getValue({clusterName: cluster.name, competencyName: competency.name}) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a
                      v-if="competency.hasRatingAnchors"
                      :href="competencyDetailsPath(cluster, competency)"
                      class="text-primary-500 hover:text-primary-700"
                    >
                      {{ $t('defaults.details') }}
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900 truncate"
                  >
                    {{ shouldShowClusters ?
                      $t('components.insights.show.competency_mean') :
                      $t('components.insights.show.mean')
                    }}
                  </td>
                  <td
                    v-for="exercise in exercises"
                    :key="exercise.name"
                    class="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900 text-right"
                  >
                    {{ getValue({exerciseName: exercise.name}) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-bold">
                    {{ getValue({}) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import { insightCompetencyPath } from '@/util/url-helpers';
import { toRefs, computed } from 'vue';

export default {
  name: 'CompetenciesDetails',
  props: {
    competenceModel: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { competenceModel, data } = toRefs(props);

    const { exercises } = competenceModel.value;
    const clusters = competenceModel.value.competencyClusters;

    const getValue = ({ clusterName = null, competencyName = null, exerciseName = null }) => {
      const dataForCell = data.value.find((row) => row.clusterName === clusterName
                                            && row.competencyName === competencyName
                                            && row.exerciseName === exerciseName);
      if (dataForCell) {
        return `${dataForCell.avg || '-'} (${dataForCell.stddev || '-'})`;
      }
      return null;
    };

    const competencyDetailsPath = (competencyCluster, competency) => {
      const basePath = insightCompetencyPath(competenceModel.value, competencyCluster, competency);
      return basePath + window.location.search;
    };
    const shouldShowClusters = computed(() => clusters.length > 1 || !clusters[0].isDefault);

    return {
      exercises,
      clusters,
      shouldShowClusters,
      getValue,
      competencyDetailsPath,
    };
  },
};
</script>
