<template>
  <TransitionRoot
    as="template"
    :show="open"
  >
    <HeadlessDialog
      as="div"
      class="relative"
      :style="onTop ? 'z-index: 100' : 'z-index: 40'"
      @close="$emit('click-outside')"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          :class="['flex justify-center text-center items-center ',
                   outerModalClasses ? outerModalClasses : 'min-h-full p-4 sm:p-0'
          ]"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              as="div"
              :class="[ hideOverflow ? 'overflow-hidden' : 'overflow-visible',
                        innerModalClasses && innerModalClasses,
                        'relative bg-white rounded-lg text-left shadow-xl',
                        'transform transition-all mt-[70px] sm:max-w-3/4',

              ]"
            >
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script>
import { DialogPanel, Dialog as HeadlessDialog, TransitionChild, TransitionRoot } from '@headlessui/vue';

export default {
  components: {
    HeadlessDialog,
    TransitionChild,
    TransitionRoot,
    DialogPanel,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    hideOverflow: {
      type: Boolean,
      default: true,
    },
    outerModalClasses: {
      type: String,
      default: null,
    },
    innerModalClasses: {
      type: String,
      default: null,
    },
    onTop: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click-outside'],
};
</script>
