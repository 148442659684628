<template>
  <BaseButton
    icon="PlusIcon"
    @click="openCreateOrganisationMembershipsModal"
  >
    {{ $t('components.create_organisation_membership_button.add_users') }}
  </BaseButton>
  <CreateOrganisationMembershipsModal
    v-model:open="showCreateOrganisationMembershipsModal"
    :available-users="availableUsers"
    :organisation-id="organisationId"
    @close="showCreateOrganisationMembershipsModal = false"
  />
</template>

<script>
import { defineComponent, ref } from 'vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import CreateOrganisationMembershipsModal from '@/components/Organisations/OrganisationMembershipsTable/CreateOrganisationMembershipsModal.vue';

export default defineComponent({
  components: {
    BaseButton,
    CreateOrganisationMembershipsModal,
  },
  props: {
    availableUsers: {
      type: Array,
      required: true,
    },
    organisationId: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const showCreateOrganisationMembershipsModal = ref(false);

    const openCreateOrganisationMembershipsModal = () => {
      showCreateOrganisationMembershipsModal.value = true;
    };

    return {
      showCreateOrganisationMembershipsModal,
      openCreateOrganisationMembershipsModal,
    };
  },
});
</script>
