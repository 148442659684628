<template>
  <PdfViewer
    v-if="type === 'pdf'"
    v-model:scroll-position="scrollPosition"
    :url="url"
    :is-downloadable="isDownloadable"
    :text-selectable="textSelectable"
    :default-scale="defaultScale"
    :watermark="pdfWatermark"
    class="h-full"
    @click-outside="$emit('click-outside')"
  />
  <ImageViewer
    v-else-if="type === 'image'"
    :url="url"
    :is-downloadable="isDownloadable"
    class="h-full"
    @click-outside="$emit('click-outside')"
  />
  <VideoViewer
    v-else-if="type === 'video'"
    :url="url"
    :is-downloadable="isDownloadable"
    class="h-full"
  />
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import ImageViewer from '@/components/Viewers/Image/ImageViewer.vue';
import PdfViewer from '@/components/Viewers/PDF/PDFDocument.vue';
import VideoViewer from '@/components/Viewers/Video/VideoViewer.vue';

export default defineComponent({
  components: {
    ImageViewer,
    PdfViewer,
    VideoViewer,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
    textSelectable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: true,
    },
    defaultScale: {
      type: String,
      default: 'page-auto',
    },
    keepPdfScrollPositionOnUrlChange: {
      type: Boolean,
      default: false,
    },
    pdfWatermark: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['click-outside'],
  setup(props) {
    const scrollPosition = ref(0);

    watch(() => props.url, () => {
      if (!props.keepPdfScrollPositionOnUrlChange) {
        scrollPosition.value = 0;
      }
    });

    return {
      scrollPosition,
    };
  },
});
</script>
