<template>
  <BaseTableActions>
    <template #menu-items>
      <LinkDropdownItem
        :href="editOrganisationPath(organisation)"
        class="flex flex-row"
      >
        <div class="mr-4">
          <PencilIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">{{ $t('defaults.edit') }}</span>
      </LinkDropdownItem>

      <ActionDropdownItem
        class="flex flex-row"
        @click="$emit('merge-organisation', organisation)"
      >
        <div class="mr-4">
          <DocumentDuplicateIcon
            class="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </div>
        <span class="text-gray-700">{{ $t('defaults.merge') }}</span>
      </ActionDropdownItem>
    </template>
  </BaseTableActions>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue';
import { PencilIcon, DocumentDuplicateIcon } from '@heroicons/vue/solid';
import LinkDropdownItem from '@/components/generic/Dropdown/LinkDropdownItem/LinkDropdownItem.vue';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';
import { editOrganisationPath } from '@/util/url-helpers';
import { useI18n } from '@/plugins/i18n';
import BaseTableActions from '@/components/generic/BaseTable/BaseTableActions/BaseTableActions.vue';

export default defineComponent({
  components: {
    PencilIcon,
    DocumentDuplicateIcon,
    LinkDropdownItem,
    ActionDropdownItem,
    BaseTableActions,
  },
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  emits: ['merge-organisation'],
  setup(props) {
    const { organisation } = toRefs(props);
    const { t } = useI18n();

    const confirmDeletionMessage = computed(() => t(
      'components.organisations.organisations_table.organisation_actions.do_you_want_to_delete',
      { name: organisation.value.name },
    ));

    return {
      confirmDeletionMessage,
      editOrganisationPath,
    };
  },
});
</script>
