<template>
  <BaseButton
    name="submit"
    type="submit"
    :icon="icon"
    :is-loading="isLoading"
    :is-disabled="isDisabled"
    qa-class="save-button"
  >
    <slot>
      {{ $t('defaults.save') }}
    </slot>
  </BaseButton>
</template>

<script>
import { defineComponent } from 'vue';

import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';

export default defineComponent({
  components: {
    BaseButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'CheckIcon',
    },
  },
  emits: ['click'],
});
</script>
