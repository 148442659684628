<template>
  <Card>
    <div class="p-6">
      <AssessmentFeaturesForm
        :initial-features="initialFeatures"
        :assessment-id="assessmentId"
        :is-editable="isEditable"
      />
    </div>
  </Card>
</template>

<script>
import { defineComponent } from 'vue';
import Card from '@/components/generic/Card/Card.vue';
import AssessmentFeaturesForm from '../AssessmentFeaturesForm/AssessmentFeaturesForm.vue';

export default defineComponent({
  name: 'AssessmentFeatures',
  components: {
    AssessmentFeaturesForm,
    Card,
  },
  props: {
    initialFeatures: {
      type: Object,
      required: true,
    },
    assessmentId: {
      type: [Number, String],
      default: '',
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {

  },
});
</script>
