<template>
  <div class="space-y-5">
    <div class="flex flex-row justify-between items-center space-x-2">
      <div>
        <p class="text-sm font-medium text-gray-900">
          {{ $t('components.policy_consent.analytics.heading') }}
        </p>
        <p class="text-sm font-normal text-gray-500 text-left">
          {{ $t('components.policy_consent.analytics.text') }}
        </p>
      </div>
      <Toggle
        v-model="analyticsPolicyEnabled"
        :name="analyticsFieldName"
        qa-class="consent-analytics-toggle"
        @update:model-value="$emit('update:analyticsPolicyEnabled', analyticsPolicyEnabled)"
      />
    </div>
    <div class="flex flex-row justify-between items-center space-x-2">
      <div>
        <p class="text-sm font-medium text-gray-900">
          {{ $t('components.policy_consent.privacy.heading') }}
        </p>
        <i18n-t
          class="text-sm font-normal text-gray-500 text-left"
          keypath="components.policy_consent.privacy.text"
          tag="p"
          for="components.policy_consent.privacy.heading"
        >
          <template #link>
            <a
              class="text-primary-500 underline"
              :href="privacyPolicyURL"
              target="_blank"
            >{{ $t('components.policy_consent.privacy.link') }}</a>
          </template>
        </i18n-t>
      </div>
      <Toggle
        v-model="privacyPolicyEnabled"
        :name="privacyFieldName"
        qa-class="consent-privacy-policy-toggle"
        @update:model-value="$emit('update:privacyPolicyEnabled', privacyPolicyEnabled)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PolicyConsent',
  components: {
  },
  props: {
    privacyPolicyConsent: {
      type: Boolean,
      required: true,
    },
    analyticsPolicyConsent: {
      type: Boolean,
      required: true,
    },
    privacyFieldName: {
      type: String,
      default: null,
      required: false,
    },
    analyticsFieldName: {
      type: String,
      default: null,
      required: false,
    },
  },
  emits: ['update:privacyPolicyEnabled', 'update:analyticsPolicyEnabled'],
  setup(props) {
    const privacyPolicyEnabled = ref(props.privacyPolicyConsent);
    const analyticsPolicyEnabled = ref(props.analyticsPolicyConsent);

    const privacyPolicyURL = 'https://applysia.de/download/596/';

    return {
      privacyPolicyURL,
      privacyPolicyEnabled,
      analyticsPolicyEnabled,
    };
  },
});
</script>
