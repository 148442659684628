<template>
  <Modal
    :open="open"
    inner-modal-classes="w-full sm:min-w-3/4 sm:max-w-full md:min-w-0  md:max-w-6xl"
    outer-modal-classes="h-full px-0 pb-0 sm:px-4"
    @click-outside="$emit('close')"
  >
    <div class="bg-white py-2 px-3 sm:p-4 w-full qa-workflow-settings-modal">
      <div class="mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <div class="flex w-full space-x-4 justify-between">
          <div>
            <span class="text-gray-900 text-lg leading-6 font-medium block">
              {{ $t('components.assessment_workflow_setting.header') }}
            </span>
            <span class="text-gray-500 text-sm leading-5 block mt-1">
              {{ $t('components.assessment_workflow_setting.help_text') }}
              <a
                :href="$t('components.assessment_workflow_setting.support_link')"
                class="text-primary-500 text-sm leading-5 inline-block"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ $t('defaults.more_information') }}
              </a>
            </span>
          </div>
        </div>
        <div class="mt-4 border-4 rounded-xl border-gray-100 px-7 pt-12 pb-4 overflow-x-auto">
          <div class="grow min-w-0 mt-4 md:mt-0">
            <div class="w-full">
              <div class="flex">
                <div class="m-0 flex w-full items-center py-8">
                  <StateTransition
                    :state-name="$t('activerecord.enums.assessment.status.draft')"
                    color="gray"
                    :editable="true"
                    transition-name="activation_transition"
                    interval-unit="hour"
                  />
                  <StateTransition
                    :state-name="$t('activerecord.enums.assessment.status.active')"
                    color="green"
                    :editable="true"
                    transition-name="completion_transition"
                    interval-unit="day"
                  />
                  <StateTransition
                    :state-name="$t('activerecord.enums.assessment.status.completed')"
                    color="blue"
                    :editable="true"
                    transition-name="finalization_transition"
                    interval-unit="week"
                  />
                  <div class="relative shrink-0 w-[200px] pr-2">
                    <SelectBox
                      v-model="selectedFinalStatus"
                      :options="finalStatusOptions"
                      value-prop="id"
                      lable-prop="name"
                      qa-class="final-status-select"
                      :custom-color="{background: 'bg-gray-50', text: 'text-primary-500'}"
                      @update:model-value="store.finalStatus = selectedFinalStatus.id"
                    >
                      <template
                        #default="{option}"
                      >
                        <Badge
                          :color="store.getFinalStatusColor(option.id)"
                        >
                          {{ option?.name }}
                        </Badge>
                      </template>
                    </SelectBox>
                    <span
                      v-if="selectedFinalStatus.id === 'insights'"
                      class="absolute inset-x-0  text-gray-500 text-sm font-normal text-center px-2"
                    >
                      {{ $t('components.assessment_workflow_setting.insights_archive_explanation') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 pb-4 flex flex-row justify-end">
      <BaseButton
        :is-disabled="false"
        :is-primary="true"
        qa-class="workflow-save-button"
        @click="$emit('close')"
      >
        {{ $t('defaults.submit') }}
      </BaseButton>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/generic/Modal/Modal.vue';
import SelectBox from '@/components/generic/SelectBox/SelectBox.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAssessmentWorkflowSettingsStore } from '@/components/AssessmentWorkflowSettingsCard/stores/assessmentWorkflowSettingsStore';
import StateTransition from './StateTransition.vue';

export default {
  name: 'AssessmentWorkflowSettingsModal',
  components: {
    Modal,
    StateTransition,
    SelectBox,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'save'],
  setup() {
    const { t } = useI18n();

    const store = useAssessmentWorkflowSettingsStore();

    const finalStatusOptions = ref([
      { id: 'insights', name: t('components.assessment_workflow_setting.final_status.insights') },
      { id: 'deleted', name: t('components.assessment_workflow_setting.final_status.deleted') },
    ]);
    const selectedFinalStatus = ref(finalStatusOptions.value.find(
      (option) => option.id === store.finalStatus,
    ));

    return {
      store,
      selectedFinalStatus,
      finalStatusOptions,
    };
  },
};
</script>
