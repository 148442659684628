<template>
  <Badge :color="statusColor">
    <HeroiconOutline
      class="h-5 mr-1"
      :icon="statusIcon"
      aria-hidden="true"
    />
    {{ $t(`activerecord.enums.test.status.${testStatus}`) }} {{ testProgress }}
  </Badge>
</template>

<script>

import {
  defineComponent, computed,
} from 'vue';
import HeroiconOutline from '@/components/HeroiconOutline.vue';

export default defineComponent({
  components: {
    HeroiconOutline,
  },
  props: {
    testStatus: {
      type: String,
      required: true,
    },
    testProgress: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const statusColor = computed(() => {
      switch (props.testStatus) {
        case 'waiting':
          return 'gray';
        case 'started':
          return 'blue';
        case 'paused':
          return 'yellow';
        case 'completed':
          return 'green';
        case 'aborted':
          return 'orange';
        case 'canceled':
          return 'orange';
        case 'error':
          return 'red';
        case 'deleted':
          return 'red';
        default:
          return 'gray';
      }
    });

    const statusIcon = computed(() => {
      switch (props.testStatus) {
        case 'waiting':
          return 'ClockIcon';
        case 'started':
          return 'PlayIcon';
        case 'paused':
          return 'PauseIcon';
        case 'completed':
          return 'CheckIcon';
        case 'aborted':
          return 'BanIcon';
        case 'canceled':
          return 'BanIcon';
        case 'error':
          return 'ExclamationIcon';
        case 'deleted':
          return 'ExclamationIcon';
        default:
          return '';
      }
    });

    return {
      statusColor,
      statusIcon,
    };
  },
});
</script>
