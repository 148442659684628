export default {
  eventTooltipFeature: {
    tools: [
      {
        cls: 'b-fa b-fa-trash block',
        handler() {
          this.eventRecord.remove();
          this.hide();
        },
      },
      {
        cls: 'b-fa b-fa-angle-up block',
        handler() {
          this.eventRecord.shift(-1);
        },
      },
      {
        cls: 'b-fa b-fa-angle-down block',
        handler() {
          this.eventRecord.shift(1);
        },
      },
    ],

    header: {
      titleAlign: 'start',
    },

    template: (data) => {
      const candidates = data.eventRecord.getCandidates().map((c) => `<span>${c.name}</span>`).join(',&nbsp;');
      const observers = data.eventRecord.getObservers().map((o) => `<span>${o.name}</span>`).join(',&nbsp;');

      return `
        <dl class="w-60">
          <dt class="text-lg font-bold mb-4">
            ${data.eventRecord.name}
          </dt>

          <dt class="text-base font-bold">
            ${window.i18n.translations[window.i18n.locale].activerecord.models.candidate.other}:
          </dt>
          <dd class="ph-no-capture flex flex-wrap mt-1 mb-4">
            ${candidates}
          </dd>

          <dt class="text-base font-bold">
            ${window.i18n.translations[window.i18n.locale].activerecord.models.observer_assignment.other}:
          </dt>
          <dd class="ph-no-capture flex flex-wrap mt-1 mb-4">
            ${observers}
          </dd>

          <dt class="text-base font-bold ${data.eventRecord.leadObserverName === '-' ? 'text-red-600' : ''}">
            Lead:
          </dt>
          <dd class="ph-no-capture mt-1">
            ${data.eventRecord.leadObserverName}
          </dd>
        </dl>
      `;
    },
    hideDelay: 100,
    hoverDelay: 250,
  },
};
