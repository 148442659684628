<template>
  <div
    class="w-full relative"
  >
    <div
      v-if="label || hint"
      :class="['flex', label ? 'justify-between' : 'justify-end' ]"
    >
      <label
        v-if="label"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </label>
      <span
        v-if="hint"
        class="text-sm text-gray-500"
      >
        {{ hint }}
      </span>
    </div>
    <div
      id="selectionList"
      ref="displayRef"
      class="w-full relative mt-1"
    >
      <div ref="trigger">
        <BaseInput
          v-model="selectedOptionsString"
          :disabled="disabled"
          :readonly="true"
          :trailing-icon-clickable="true"
          :truncate="true"
          class="ph-no-capture"
          @click="toggleIsOpenState"
        >
          <template #trailing-icon>
            <div class="flex flex-row">
              <p
                v-if="selectedOptionsStringTruncatedWordCount > 0"
                class="text-gray-400 text-sm mr-1"
              >
                {{ `+${selectedOptionsStringTruncatedWordCount}` }}
              </p>
              <button
                class="transition-colors duration-200 cursor-pointer text-gray-400 focus:outline-none"
                :disabled="disabled"
                @mousedown="toggleIsOpenState"
              >
                <ChevronUpIcon
                  v-if="isOpen"
                  class="h-5 w-5 text-gray-400"
                />
                <ChevronDownIcon
                  v-else
                  class="h-5 w-5 text-gray-400"
                />

                <span class="sr-only">{{ $t('components.search_input.toggle') }}</span>
              </button>
            </div>
          </template>
        </BaseInput>
      </div>
    </div>
    <div
      v-if="isOpen"
      ref="optionsBox"
    >
      <div
        ref="container"
        class="absolute w-full bg-white ring-1 ring-black/5 rounded-lg shadow-lg mt-2 z-20"
      >
        <Combobox
          v-model="currentSelectedOptions"
          multiple
          class-name="fixed inset-0 z-10 overflow-y-auto pointer-events-none"
          as="div"
        >
          <div class="px-4 pt-3 pb-1">
            <span class="flex flex-wrap gap-2">
              <BaseInput
                v-model="query"
                :trailing-icon-clickable="true"
                :focus="true"
                class="w-full"
                :placeholder="$t('defaults.search')"
                leading-icon="SearchIcon"
                @keyup.exact.prevent.stop.esc="query = ''"
              >
                <template #trailing-icon>
                  <button
                    v-if="query != ''"
                    class="transition-colors duration-200 cursor-pointer text-gray-300 hover:text-gray-500
                     focus:outline-none focus:ring ring-primary-500 focus:text-gray-500 rounded-sm"
                    @focus.stop
                    @click.stop="query=''"
                  >
                    <XIcon
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                    <span class="sr-only">{{ $t('components.search_input.clear') }}</span>
                  </button>
                </template>
              </BaseInput>
            </span>
          </div>
          <span>
            <div
              class="flex justify-between flex-col bottom-shadow"
            >
              <div class="flex justify-between">
                <button
                  type="button"
                  class="text-primary-600 hover:text-primary-700 active:underline
                   py-2 pl-4 cursor-pointer text-sm font-normal"
                  @click="selectAll"
                >
                  {{ $t('defaults.select') }}
                </button>
                <button
                  type="button"
                  class="text-primary-600 hover:text-primary-700 active:underline
                   py-2 pr-4 cursor-pointer text-sm font-normal"
                  @click="unselectAll"
                >
                  {{ $t('defaults.reset') }}
                </button>
              </div>
            </div>
            <ComboboxOptions
              static
              class="shadow-xs max-h-52 overflow-auto rounded-md py-1
                     text-base leading-6 focus:outline-none sm:text-sm sm:leading-5 scroll"
            >
              <ComboboxOption
                v-for="option in filteredOptions.selected"
                :key="option[optionIdentifier]"
                :value="option"
                :disabled="option.disabled"
              >
                <li
                  :class="['relative flex select-none py-2 pl-3 pr-9 focus:outline-none',
                           option.disabled ? 'bg-gray-50': 'hover:bg-gray-100 cursor-pointer' ]"
                >
                  <span
                    class="flex items-center pr-4"
                  >
                    <BaseCheckbox
                      :model-value="isSelected(option[optionIdentifier])"
                      :disabled="option.disabled"
                    />
                  </span>
                  <div class="flex w-full space-x-2">
                    <span
                      class="ph-no-capture block truncate"
                    >
                      {{ option[optionLabel] }}
                    </span>
                    <span
                      v-if="secondaryOptionLabel"
                      class="ph-no-capture block truncate text-gray-500"
                    >
                      {{ option[secondaryOptionLabel] }}
                    </span>
                  </div>
                </li>
              </ComboboxOption>
              <hr
                v-if="filteredOptions.selected.length > 0 && filteredOptions.unselected.length > 0"
                class="text-gray-200"
              >
              <ComboboxOption
                v-for="option in filteredOptions.unselected"
                :key="option[optionIdentifier]"
                :value="option"
                :disabled="option.disabled"
              >
                <li
                  :class="['relative flex select-none py-2 pl-3 pr-9 focus:outline-none',
                           option.disabled ? 'bg-gray-50': 'hover:bg-gray-100 cursor-pointer' ]"
                >
                  <span
                    class="flex items-center pr-4"
                  >
                    <BaseCheckbox
                      :model-value="isSelected(option[optionIdentifier])"
                      :disabled="option.disabled"
                    />
                  </span>
                  <div class="flex w-full space-x-2">
                    <span class="ph-no-capture block truncate">
                      {{ option[optionLabel] }}
                    </span>
                    <span
                      v-if="secondaryOptionLabel"
                      class="ph-no-capture block truncate text-gray-500"
                    >
                      {{ option[secondaryOptionLabel] }}
                    </span>
                  </div>
                </li>
              </ComboboxOption>
            </ComboboxOptions>
            <div
              class="flex justify-center space-x-3 py-2 border-t border-gray-100 px-2"
            >
              <BaseButton
                type="button"
                class="w-full"
                :is-primary="false"
                :is-disabled="false"
                @click="cancelSelection"
              >
                {{ $t('defaults.cancel') }}
              </BaseButton>
              <BaseButton
                class="w-full"
                type="button"
                :is-loading="false"
                @click="submitSelection"
              >
                {{ $t('defaults.apply') }}
              </BaseButton>
            </div>
          </span>
        </Combobox>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/generic/BaseCheckbox/BaseCheckbox.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import { onClickOutside } from '@vueuse/core';
import { defineComponent, ref, watchEffect } from 'vue';

import { Combobox, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/vue/solid';
import usePopper from '@/util/use-popper';
import { countStringsToFitMaxWidth } from './displayed-string-size';

export default defineComponent({
  name: 'MultiSelectComboBox',
  components: {
    BaseButton,
    BaseInput,
    BaseCheckbox,
    ChevronDownIcon,
    ChevronUpIcon,
    XIcon,
    Combobox,
    ComboboxOptions,
    ComboboxOption,
  },
  props: {
    selectedOptions: {
      type: Array,
      default: () => [],
      required: true,
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    optionLabel: {
      type: String,
      required: false,
      default: 'name',
    },
    secondaryOptionLabel: {
      type: String,
      required: false,
      default: null,
    },
    optionIdentifier: {
      type: String,
      required: false,
      default: 'id',
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selectedOptions'],
  setup(props, { emit }) {
    const isOpen = ref(false);

    const currentSelectedOptions = ref(props.selectedOptions);
    const selectionList = ref(null);

    function isSelected(id) {
      return currentSelectedOptions.value.map((option) => option[props.optionIdentifier]).includes(id);
    }

    const sortSelectedOptions = (a, b) => {
      if (a[props.optionLabel] > b[props.optionLabel]) {
        return 1;
      }
      return ((b[props.optionLabel] > a[props.optionLabel]) ? -1 : 0);
    };

    const query = ref('');
    const filteredOptions = ref({ selected: [], unselected: [] });
    watchEffect(() => {
      if (query.value === '') {
        filteredOptions.value = {
          selected: currentSelectedOptions.value.sort(sortSelectedOptions),
          unselected: props.options
            .filter((option) => !isSelected(option[props.optionIdentifier])).sort(sortSelectedOptions),
        };
      }
      filteredOptions.value = {
        selected: currentSelectedOptions.value.filter((option) => option[props.optionLabel]
          .toLowerCase().includes(query.value.toLowerCase())).sort(sortSelectedOptions),
        unselected: props.options.filter((option) => option[props.optionLabel]
          .toLowerCase().includes(query.value.toLowerCase())
          && !isSelected(option[props.optionIdentifier])).sort(sortSelectedOptions),
      };
    });

    // computes the displayed string and the remaining options which are truncated
    const selectedOptionsStringTruncatedWordCount = ref(0);
    const selectedOptionsString = ref('');
    const displayRef = ref(null);

    function displayString(option) {
      return `${option[props.optionLabel]}${props.secondaryOptionLabel && option[props.secondaryOptionLabel] !== ''
        ? `/${option[props.secondaryOptionLabel]}`
        : ''}`;
    }

    watchEffect(() => {
      if (currentSelectedOptions.value.length < 1) {
        selectedOptionsStringTruncatedWordCount.value = 0;
        selectedOptionsString.value = '';
      } else if (displayRef.value) {
        const maxWidth = displayRef.value.offsetWidth - 45;

        const numberOfDisplayedOptions = countStringsToFitMaxWidth(
          currentSelectedOptions.value.map(
            (option) => displayString(option),
          ),
          maxWidth,
        ) + 1; // + 1 approximation for truncated item

        selectedOptionsStringTruncatedWordCount.value = currentSelectedOptions.value.length - numberOfDisplayedOptions;
        selectedOptionsString.value = currentSelectedOptions.value
          .slice(0, numberOfDisplayedOptions)
          .map((option) => displayString(option)).join(', ');
      }
    });

    function selectQuery() {
      currentSelectedOptions.value = filteredOptions.value.selected;
      query.value = '';
    }

    function selectAll() {
      currentSelectedOptions.value = filteredOptions.value.selected.concat(filteredOptions.value.unselected)
        .filter((option) => !option.disabled);
      query.value = '';
    }

    function unselectAll() {
      currentSelectedOptions.value = [];
      query.value = '';
    }

    function submitSelection() {
      emit('update:selectedOptions', currentSelectedOptions.value);
      isOpen.value = false;
    }

    function cancelSelection() {
      currentSelectedOptions.value = props.selectedOptions;
      isOpen.value = false;
      query.value = '';
    }

    function toggleIsOpenState() {
      if (isOpen.value) {
        cancelSelection();
      } else {
        isOpen.value = true;
      }
    }

    const optionsBox = ref(null);
    onClickOutside(optionsBox, () => {
      if (isOpen.value) {
        cancelSelection();
      }
    });

    const [trigger, container] = usePopper({
      placement: 'bottom-end',
      modifiers: [
        { name: 'offset', options: { offset: [0, 5] } },
      ],
    });

    return {
      currentSelectedOptions,
      isOpen,
      selectedOptionsString,
      query,
      filteredOptions,
      selectionList,
      selectedOptionsStringTruncatedWordCount,
      optionsBox,
      displayRef,

      toggleIsOpenState,
      isSelected,
      submitSelection,
      cancelSelection,
      selectAll,
      unselectAll,
      selectQuery,
      trigger,
      container,
    };
  },
});

</script>
<style scoped>
.bottom-shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
/* box-shadow: 0 5px 5px -5px #333;*/
/*
  Chrome, Safari
*/
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #C0C0C0;
  border-radius: 1px;
}

/* Firefox */
.scroll {
  scrollbar-width:thin;
  scrollbar-color: #C0C0C0 white;
}
</style>
