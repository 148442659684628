/**
 *- Custom Event model
*
* Taken from the vanilla dragfromgrid example
*/
import { EventModel } from '@bryntum/scheduler';

export default class UnplannedAssessmentEvent extends EventModel {
  static get defaults() {
    return {
      durationUnit: 'm',
    };
  }

  static get fields() {
    return [
      { name: 'eventColor', type: 'string', readOnly: true, persist: false },
      { name: 'candidateName', type: 'string', readOnly: true, persist: false },
      { name: 'candidateFirstName', type: 'string', readOnly: true, persist: false },
      { name: 'candidateLastName', type: 'string', readOnly: true, persist: false },
      { name: 'candidateId', type: 'string', readOnly: true, persist: false },
      { name: 'exerciseId', type: 'string', readOnly: true, persist: false },
    ];
  }

  getCandidates() {
    return [{
      id: this.candidateId,
      type: 'candidate',
      name: this.candidateName,
      firstName: this.candidateFirstName,
      lastName: this.candidateLastName,
      eventColor: this.eventColor,
    }];
  }
}
